<template>
  <v-container fluid style="max-height: 400px">
    <!-- Logo -->
    <v-row justify="center" style="margin-top: -14px">
      <v-img max-width="300" src="@/assets/logo.svg"></v-img>
    </v-row>
    <!-- Striche -->
    <svg height="300" width="750" style="position: fixed; bottom: -1%; left: -1%">
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stop-color="rgba(110, 163, 189, 1)" />
          <stop offset="100%" stop-color="rgba(24, 93, 122, 1)" />
        </linearGradient>
      </defs>
      <line x1="400" y1="0" x2="0" y2="400" stroke="url(#gradient)"  stroke-width="2" />
    </svg>
    <!-- Striche -->
    <svg height="200" width="500" style="position: fixed; bottom: -1%; right: -15%">
      <line x1="200" y1="0" x2="0" y2="200" stroke="url(#gradient)"  stroke-width="2" />
    </svg>
    <!-- Striche -->
    <svg height="150" width="250" style="position: fixed; top: 40%; left: 80%">
      <line x1="150" y1="0" x2="0" y2="150" stroke="url(#gradient)"  stroke-width="2" />
    </svg>
    <!-- Striche -->
    <svg height="150" width="250" style="position: fixed; top: 30%; left: 10%">
      <defs>
        <linearGradient id="gradient2" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stop-color="rgba(245, 228, 49, 1)" />
          <stop offset="100%" stop-color="rgba(169, 123, 64, 1)" />
        </linearGradient>
      </defs>
      <line x1="150" y1="0" x2="0" y2="150" stroke="url(#gradient2)"  stroke-width="2" />
    </svg>
    <!-- Striche -->
    <svg height="300" width="500" style="position: fixed; bottom: 5%; right: 10%">
      <line x1="250" y1="0" x2="0" y2="250" stroke="url(#gradient2)"  stroke-width="2" />
    </svg>
    <!-- Striche -->
    <svg height="150" width="250" style="position: fixed; top: 35%; right: 10%">
      <line x1="100" y1="0" x2="0" y2="100" stroke="url(#gradient)"  stroke-width="2" />
    </svg>
    <!-- Titel -->
    <v-row align="center" style="margin-top: 40%; margin-bottom: 50%">
      <v-col cols="12" class="text-center">
        <h1 style="margin-left: auto; margin-right: auto">Welcome!</h1>
        <h3 class="grey--text font-weight-thin">Let's learn together.</h3>
        <br><br><br><br>
        <!-- Getting Started -->
        <v-btn color="primary" outlined @click="jumpStep('img2')" class="mr-1">Get Started</v-btn>
        <v-btn color="rgba(169, 123, 64, 1)" outlined @click="jumpStep('img3')" class="ml-1">Test/Exam</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    name: 'HomeMobile',

    components: {

    },

    data: () => {
      return{
        //
      }
    },

    mounted() {
      // this.$http.post('index.php',  { 'task'          : 'get_table',
      //                                 'table'         : 'colcons_user',
      //                                 // 'field_list'    : [ 'usr_name', 'usr_mail', 'usr_first_name' ],
      //                                 // 'limit'         : { 'start' : 0, 'count' : 5 },
      //                                 // 'order'         : [ { field: 'usr_name', dir: 'ASC' }, { field: 'usr_first_name', dir: 'DSC' } ],
      //                                 // 'groupby'       : [ 'usr_role' ],
      //                                 'filter'        : [ { op: 'eq', field: 'usr_name', value: 'aaron.zi' } ],
      //                               })
      //           .then(response => {
      //             console.log('Get Table: ', response.body);
      //           });
    },

    computed: {
      // sessionID from the store
        SessionID () {
          // console.log('SessionIDComponent', this.$store.getters.sessionID)
          return this.$store.getters.sessionID;
        },
    },

    methods: {
    },
    
  }
</script>

