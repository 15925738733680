<template>
    <v-container fluid class="pa-0 ma-0">
        <v-card elevation="0" color="rgba(0,0,0,0)" style="overflow-y: auto" class="pt-2">
            <v-row justify="center" v-if="SuccessfullLogin == false" class="mx-1 mb-1 mt-3">
                <v-col class="text-center">
                    <div>{{ $t('AppNavigationMenu.login-to-see') }}</div>
                    <v-btn @click="$store.dispatch('getLoginEvent', true)" class="mt-5">Login</v-btn>
                </v-col>
            </v-row>
            <v-card outlined elevation="3" @click="solveTest(test)" v-for="(test, i) in myTests" :key="i" class="my-3 mx-5">
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>{{ test.test_name }}</v-list-item-title>
                        <v-list-item-subtitle :class="getColor(test) + '--text'">{{ $t('Exercises.valid-to') + ': ' + test.valid_to.slice(0,10) }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-icon class="mr-2" @click.stop="deletePossession(test)" :disabled="isValid(test)">mdi-delete</v-icon>
                    <v-icon @click.stop="renewPossession(test)" :disabled="isValid(test)">mdi-credit-card-sync</v-icon>
                </v-list-item>
            </v-card>
        </v-card>
    </v-container>
</template>

<script>

  export default {
    name: 'MyTestsMobile',

    components: {

    },

    data: () => {
        return {
            //
        }
    },

    computed: {

        // gets confirmation from the store if the login was successfull
        SuccessfullLogin() {
            // console.log('User logged in?', this.$store.getters.successfullLogin)
            return this.$store.getters.successfullLogin
        },
        
        // sessionID from the store
        SessionID() {
            // console.log('SessionIDComponent', this.$store.getters.sessionID)
            return this.$store.getters.sessionID
        },

        // User Object from the store
        UserObject() {
            return this.$store.getters.userData
        },

        
        // gets own Tests from the store
        myTests() {
            // console.log(this.$store.getters.MyTests)
            let tests = this.$store.getters.MyTests
            let nameObject = {}
            tests.forEach(element => {
                if(element.test_name in nameObject) {
                    nameObject[element.test_name] += 1
                } else {
                    nameObject[element.test_name] = 0
                }
                element['iterator'] = nameObject[element.test_name]
            });
            return this.$store.getters.MyTests
        },
    },

    methods: {

        renewPossession(test) {
            console.log(test)
        },

        getColor(item) {
            if(new Date(item.valid_from) < new Date() && new Date(item.valid_to) > new Date()) {
                return 'success'
            } else {
                return 'error'
            }
        },

        isValid(item) {
            if(new Date(item.valid_from) < new Date() && new Date(item.valid_to) > new Date()) {
                return true
            } else {
                return false
            }
        },

        deletePossession(test) {
            var confirmed = confirm(this.$t('Exercises.confirm'))
            if(confirmed) {
                this.$http.post('index.php',    {   task:   'delete_possession',
                                                    userObject: this.UserObject,
                                                    testObject: test
                                                })
                        .then(response => {
                            if(response.body == 'success') {
                                this.$store.dispatch('getMyTests', this.UserObject)
                                this.$store.dispatch('getSnackbar', {status: true, timeout: 4000, color: 'success', btnColor: 'buttonText', text: this.$t('Exercises.removed') })
                            } else {
                                this.$store.dispatch('getSnackbar', {status: true, timeout: 4000, color: 'error', btnColor: 'buttonText', text: this.$t('Exercises.sessid') })
                            }
                        });
            }
        },
        solveTest(test) {
            // console.log(test)
            this.$store.dispatch('getCurrentMenu', 'Exercises')
            this.$store.dispatch('getCurrentTest', test)
            this.$router.push('/mobile/dashboard/exercises/' + test.test_name.replace(/\s/g, ''))
        },
    }

  }
</script>