import Vue from 'vue';
import VueResource from 'vue-resource'
import { Storage } from '@capacitor/storage';

Vue.use(VueResource, {})

const state = {
    SessionID: '',
    userData: {},
    successfullLogin: false,
    failedLogin: false,
    LoginEvent: false,
    Wishlist: [],
};

const getters = {
    sessionID: state => {
        return state.SessionID
    },
    userData: state => {
        return state.userData
    },
    successfullLogin: state => {
        return state.successfullLogin
    },
    failedLogin: state => {
        return state.failedLogin
    },
    loginEvent: state => {
        return state.LoginEvent
    },
    wishlist: state => {
        return state.Wishlist
    },
};

const actions = {
    getAutoLogin({ commit }) {
        const checkSessionData = async () => {
            const {value} = await Storage.get({ key: 'authObject' });
            // console.log(value);
            let authObject = JSON.parse(value);
            if (authObject) {
                Vue.http.post('index.php',  {   task:   'login_user', 
                                                authObject: authObject,
                                            })
                        .then(response => {
                            // console.log(response.body);
                            if(response.body.error) {
                                commit('successfullLogin', false)
                            } else {
                                // console.log('User Data:', response.body)
                                commit('setLoginData', response.body.data);
                                commit('successfullLogin', true)
                                const setSessionData = async () => {
                                    await Storage.set({
                                        key: 'authObject',
                                        value: JSON.stringify(authObject),
                                    });
                                };
                                setSessionData();
                            }

                        });
            }
        };
        checkSessionData();
    },
    getLoginData({commit}, authObject) {
        // console.log('getLoginData', authObject);
        Vue.http.post('index.php',  {   task:       'login_user', 
                                        authObject: authObject,
                                    })
            .then(response => {
                // console.log(response.body);
                if(response.body.error) {
                    // console.log(response.body)
                    commit('successfullLogin', false)
                    commit('failedLogin', true)
                } else {
                    // console.log('User Data:', response.body.data)
                    commit('setLoginData', response.body.data);
                    commit('successfullLogin', true)
                    const setSessionData = async () => {
                        await Storage.set({
                            key: 'authObject',
                            value: JSON.stringify(authObject),
                        });
                    };
                    setSessionData();
                }

            });
    },
    getLogout({commit}, currentUser) {
        // console.log('logoutAction', currentUser)
        Vue.http.post('index.php',  {   task:           'logout_user',
                                        currentUser:    currentUser 
                                    })
            .then(response => {
                if(response.body == 'false') {
                    var v = false
                }
                commit('setLogout', v)
                const removeSessionData = async () => {
                    await Storage.remove({ key: 'authObject' });
                };
                removeSessionData();
            });
    },
    updateUserData({commit}, updatedUser) {
        commit('updateUser', updatedUser)
    },
    getLoginEvent({commit}, event) {
        commit('setLoginEvent', event)
    },
    resetFail({commit}) {
        commit('setResetFail')
    },
    getWishlist({commit}, currentUser) {
        // console.log(this.getters.userData.usr_id, this.getters.sessionID)
        Vue.http.post('index.php',  {   'task'      :   'get_table',
                                        'table'     :   'colcons_wishlist',
                                        'filter'    :   [{ op: 'eq', field: 'usr_id', value: currentUser.usr_id }],
                                        'usr_id'    :   this.getters.userData.usr_id,
                                        'sessionid' :   this.getters.sessionID,
                                    })
            .then(response => {
                if(!response.body.sessionerror) {
                    commit('setWishlist', response.body)
                }
            });
    }
};

const mutations = {
    setLoginData: (state, userdata) => {
        // console.log('setLoginData', userdata)
        state.SessionID = userdata.usr_session_id
        state.userData = userdata
    },
    successfullLogin: (state, v) => {
        state.successfullLogin = v
        state.failedLogin = false
    },
    failedLogin: (state, v) => {
        state.failedLogin = v
        state.successfullLogin = false
    },
    setLogout: (state, v) => {
        // console.log('logoutMutation')
        state.successfullLogin = v
    },
    updateUser: (state, updatedUser) => {
        state.userData.usr_first_name = updatedUser.usr_first_name
        state.userData.usr_last_name = updatedUser.usr_last_name
        state.userData.usr_name = updatedUser.usr_name
        state.userData.usr_mail = updatedUser.usr_mail
        state.userData.usr_id = updatedUser.usr_id
    },
    setLoginEvent: (state, event) => {
        state.LoginEvent = event
    },
    setResetFail: (state) => {
        state.failedLogin = false
    },
    setWishlist: (state, wishlist) => {
        state.Wishlist = wishlist
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};