<template>
  <v-container fluid class="ma-0 pa-0">
    <v-card elevation="0" color="rgba(0,0,0,0)">
      <v-row class="ma-2" justify="space-between" align="center" style="margin-top: -2px">
        <!-- Searchbar -->
        <v-col class="pa-0">
          <v-text-field v-model="searchTerm" @input="filterStore()" append-icon="mdi-magnify" :label="$t('testStore.search')" solo-inverted dense hide-details single-line style="width: calc(100vw - 160px)" clearable></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <!-- Filter Button -->
        <v-col cols="auto" class="pa-0">
          <v-btn icon elevation="3" class="button mr-3" @click="filterDialog = true"><v-icon>mdi-filter-outline</v-icon></v-btn>
        </v-col>
        <!-- Sort Button -->
        <v-col cols="auto" class="pa-0">
          <v-btn icon elevation="3" class="button mr-3" @click="sortDialog = true"><v-icon>mdi-sort</v-icon></v-btn>
        </v-col>
        <!-- Redeem Key Button -->
        <v-col cols="auto" class="pa-0">
          <testStoreKey btnStyle="mobile"></testStoreKey>
        </v-col>
      </v-row>
      <v-card color="card" style="overflow-y: auto" height="calc(100vh - 166px)" class="ma-2">
        <v-row class="mx-1" v-if="tests != 'null'">
          <v-col md="4" sm="6" xs="12" lg="3" xl="3" v-for="(test,i) in tests" :key="test.test_id">
            <!-- single shop item -->
            <v-hover v-slot:default="{ hover }">
              <v-card :elevation="hover ? 12 : 5" :ripple="false" class="ma-1" color="subNavigation">
                <v-card @click.native="emitStoreItem(test)" color="rgba(0,0,0,0)" elevation="0" tile>
                  <!-- <v-overlay absolute :value="hover" opacity="0.05"></v-overlay> -->
                  <v-card-title class="menu" style="border-top-left-radius: 4px; border-top-right-radius: 4px">
                    <v-clamp autoresize :max-lines="1">{{ test.test_name }}</v-clamp>
                  </v-card-title>
                  <!-- product image -->
                  <v-img :aspect-ratio="16/9" :src="test.test_img" contain style="top: 10px"></v-img>
                  <v-row style="position: relative; top: 20px; z-index: 2" justify="space-around" class="mx-3" align="center">
                    <!-- wishlist button -->
                    <v-btn color="error buttonText--text" fab small @click.stop="wishlistItem(test)">
                      <v-icon v-if="Wishlist.length > 0 && Wishlist != 'null'">{{ Wishlist.find(element => element.test_id == test.test_id) != undefined  ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
                      <v-icon v-else> mdi-heart-outline</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <!-- add to cart -->
                    <v-btn color="primary buttonText--text" fab small @click.stop="toCart(test)" class="mr-3">
                      <v-icon>mdi-cart-plus</v-icon>
                    </v-btn>
                    <!-- Buy Button -->
                    <v-btn v-if="parseInt(test.test_price) != 0" color="rgb(249, 195, 56)" rounded  @click.stop="openBuy(test)" class="elevation-5 buttonText--text">
                      {{ test.test_price + '€' }}
                    </v-btn>
                    <v-btn v-else color="success buttonText--text" class="elevation-5" rounded @click.stop="openBuy(test)">
                      {{ $t('testStore.free') }}
                    </v-btn>
                  </v-row>
                  <!-- test Info -->
                  <div class="menu" style="height: 100px">
                    <div class="mx-3 pt-8">
                      <v-clamp autoresize :max-lines="3">{{ test.test_info }}</v-clamp>
                    </div>
                  </div>
                </v-card>
                <!-- test tags -->
                <v-chip-group show-arrows class="menu" v-if="chips[i].length > 0">
                  <v-chip v-for="chip in chips[i]" :key="chip.id" small>{{ chip.name }}</v-chip>
                </v-chip-group>
                <v-card-actions v-else style="height: 40px" class="menu mx-0">
                  <v-btn icon disabled><v-icon>mdi-chevron-left</v-icon></v-btn>
                  <v-spacer></v-spacer>
                  <v-btn icon disabled><v-icon>mdi-chevron-right</v-icon></v-btn>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
        <!-- no data text -->
        <v-row v-else>
          <v-col cols="12" class="text-center">
            <div class="subtit--text">{{ $t('testStore.no-tests') }}</div>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
    <!-- filter dialog -->
    <v-dialog v-model="filterDialog" transition="dialog-bottom-transition">
      <v-card height="40vh">
        <v-card-title>
          <div>{{ $t('testStore.filter') }}</div>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="filterDialog = false">{{ $t('testStore.close') }}</v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-select outlined dense :label="$t('testStore.language')" clearable v-model="selectedLanguage" :items="languages" class="mt-6" multiple @change="filterStore()" :menu-props="{ offsetY: true }"></v-select>
          <v-select outlined dense :label="$t('testStore.question-type')" clearable v-model="selectedType" :items="questionTypes" multiple @change="filterStore()" :menu-props="{ offsetY: true }"></v-select>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- sort dialog -->
    <v-dialog v-model="sortDialog" transition="dialog-bottom-transition">
      <v-card height="40vh">
        <v-card-title>
          <div>{{ $t('testStore.sort2') }}</div>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="sortDialog = false">{{ $t('testStore.close') }}</v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <!-- select option -->
          <v-select :label="$t('testStore.options')" class="mt-6" outlined dense :items="sortItems" v-model="selectedSortOption" clearable return-object @change="filterStore()"></v-select>
          <!-- price slider -->
          <v-range-slider :label="$t('testStore.price')" thumb-label="always" :value="[0.00,100.00]" hide-details v-model="priceRange" class="mt-5" @change="filterStore()">
            <template v-slot:thumb-label="{ value }">
              <div>{{ value }}€</div>
            </template>
          </v-range-slider>
          <!-- questions slider -->
          <v-range-slider :label="$t('testStore.questions')" thumb-label="always" :value="[0,200]" hide-details v-model="questionsRange" class="mt-12" @change="filterStore()">
            <template v-slot:thumb-label="{ value }">
              <div>{{ value * 2 }}</div>
            </template>
          </v-range-slider>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import testStoreKey from './testStoreKey';
  import VClamp       from 'vue-clamp';

  export default {
    name: 'testStoreMobile',
    components: {
      testStoreKey,
      VClamp,
    },
    data: function() {
      return{
        page: 1,
        tests: [], // array with all the available Tests
        chips: [],

        searchTerm: null,
        filterDialog: false,
        sortDialog: false,
        sortItems: [
          { text: this.$t('testStore.price-asc'),     value: 'test_price_asc',        order: 'ASC', field: 'test_price' },
          { text: this.$t('testStore.price-dsc'),     value: 'test_price_dsc',        order: 'DSC', field: 'test_price' },
          { text: this.$t('testStore.questions-asc'), value: 'test_question_nr_asc',  order: 'ASC', field: 'test_question_nr' },
          { text: this.$t('testStore.questions-dsc'), value: 'test_question_nr_dsc',  order: 'DSC', field: 'test_question_nr' },
        ],
        selectedSortOption: null,
        languages: [],
        selectedLanguage: [],
        questionTypes: [
          { text: this.$t('testStore.mul'), value: 'mul', field: 'tag_1' },
          { text: this.$t('testStore.sor'), value: 'sor', field: 'tag_2' },
        ],
        selectedType: [],
        priceRange: [0.00, 100.00],
        questionsRange: [0, 200],
      }
    },

    mounted() {
      // get store tests from DB
      this.$http.post('index.php',  { 'task'    : 'get_table',
                                      'table'   : 'colcons_tests',
                                      'filter'  : [ { op: 'eq', field: 'test_in_store', value: '1' } ],
                                    })
            .then(response => {
                // console.log(response.body)
                let tests = response.body;
                if(tests == 'null') tests = [];
                tests.forEach(element => {
                  element['icon'] = false;
                  let chipGroup = [];
                  if(element.tag_1 != null) chipGroup.push({id:1, name: element.tag_1});
                  if(element.tag_2 != null) chipGroup.push({id:2, name: this.$t('EditDB.' + element.tag_2)});
                  if(element.tag_3 != null) chipGroup.push({id:3, name: this.$t('EditDB.' + element.tag_3)});
                  if(element.tag_4 != null) chipGroup.push({id:4, name: element.tag_4});
                  if(element.tag_5 != null) chipGroup.push({id:5, name: element.tag_5});
                  this.chips.push(chipGroup);
                });
                this.tests = tests;
            });
      // get languages from DB
      this.$http.post('index.php',  { 'task'        : 'get_table',
                                      'table'       : 'colcons_tests',
                                      'field_list'  : 'tag_1',
                                      'filter'      : [
                                                        { op: 'eq', field: 'test_in_store', value: '1' },
                                                        this.selectedLanguage.length      > 0 ? { op: 'like', field: 'tag_1', value: this.selectedLanguage.join(',') } : null,
                                                        this.selectedType.includes('mul') > 0 ? { op: 'eq',   field: 'tag_2', value: 'mul' }                 : null,
                                                        this.selectedType.includes('sor') > 0 ? { op: 'eq',   field: 'tag_3', value: 'sor' }                 : null,
                                                        this.searchTerm != null && this.searchTerm != '' ? { op: 'like', field: 'test_name', value: '%' + this.searchTerm + '%' } : null,
                                                        { op: 'gtq', field: 'test_price', value: this.priceRange[0] + '.00' },
                                                        { op: 'ltq', field: 'test_price', value: this.priceRange[1] + '.00' },
                                                        { op: 'gtq', field: 'test_question_nr', value: this.questionsRange[0] },
                                                        { op: 'ltq', field: 'test_question_nr', value: this.questionsRange[1] },
                                                      ],
                                      'groupby'     : ['tag_1'],
                                    })
            .then(response => {
              // console.log(response.body);
              this.languages = response.body.map(lan => lan.tag_1);
            })
      // get wishlist from DB on component mount
      if(Object.keys(this.$store.getters.userData).length != 0) {
        this.$store.dispatch('getWishlist', this.UserObject)
      }
    },

    watch: {
      // get wishlist from DB when user logs in
      UserObject() {
        if(Object.keys(this.$store.getters.userData).length != 0) {
          this.$store.dispatch('getWishlist', this.UserObject)
        }
      }
    },

    computed: {
      // User Object from the store
      UserObject() {
          return this.$store.getters.userData
      },
      // gets confirmation from the store if the login was successfull
      SuccessfullLogin() {
        // console.log('User logged in?', this.$store.getters.successfullLogin)
        return this.$store.getters.successfullLogin
      },
      // wishlist array from store
      Wishlist() {
        // console.log(this.$store.getters.wishlist)
        return this.$store.getters.wishlist
      }
    },

    methods: {
      filterStore() {
        // console.log('Searchterm: ', this.searchTerm);
        // console.log('Selected Language: ', this.selectedLanguage);
        // console.log('Selected Type: ', this.selectedType);
        // console.log('Selected Sort Option: ', this.selectedSortOption);
        this.$http.post('index.php',  { 'task'        : 'get_table',
                                        'table'       : 'colcons_tests',
                                        'filter'      : [
                                                          { op: 'eq', field: 'test_in_store', value: '1' },
                                                          this.selectedLanguage.length      > 0 ? { op: 'like', field: 'tag_1', value: this.selectedLanguage.join(',') } : null,
                                                          this.selectedType.includes('mul') > 0 ? { op: 'eq',   field: 'tag_2', value: 'mul' }                 : null,
                                                          this.selectedType.includes('sor') > 0 ? { op: 'eq',   field: 'tag_3', value: 'sor' }                 : null,
                                                          this.searchTerm != null && this.searchTerm != '' ? { op: 'like', field: 'test_name', value: '%' + this.searchTerm + '%' } : null,
                                                          { op: 'gtq', field: 'test_price', value: this.priceRange[0] + '.00' },
                                                          { op: 'ltq', field: 'test_price', value: this.priceRange[1] + '.00' },
                                                          { op: 'gtq', field: 'test_question_nr', value: this.questionsRange[0] },
                                                          { op: 'ltq', field: 'test_question_nr', value: this.questionsRange[1] },
                                                        ],
                                        'order'       : this.selectedSortOption != null ?  [{ field: this.selectedSortOption.field, dir: this.selectedSortOption.order }] : null,
                                      })
            .then(response => {
              // console.log(response.body);
              let tests = response.body;
              if(tests == 'null') tests = [];
              tests.forEach(element => {
                element['icon'] = false;
                let chipGroup = [];
                if(element.tag_1 != null) chipGroup.push({id:1, name: element.tag_1});
                if(element.tag_2 != null) chipGroup.push({id:2, name: this.$t('EditDB.' + element.tag_2)});
                if(element.tag_3 != null) chipGroup.push({id:3, name: this.$t('EditDB.' + element.tag_3)});
                if(element.tag_4 != null) chipGroup.push({id:4, name: element.tag_4});
                if(element.tag_5 != null) chipGroup.push({id:5, name: element.tag_5});
                this.chips.push(chipGroup);
              });
              this.tests = tests;
            })
      },
      // add/remove test from wishlist
      wishlistItem(test) {
        if(Object.keys(this.$store.getters.userData).length === 0) {
          this.$store.dispatch('getSnackbar', {status: true, timeout: 5000, color: 'error', btnColor: 'buttonText', text: this.$i18n.t('testStore.login') })
          return
        }
        var wish_id
        var remove
        if(this.Wishlist.length > 0 && this.Wishlist != 'null') {
          if(this.Wishlist.find(element => element.test_id == test.test_id)) {
            remove = true
            wish_id = this.Wishlist.find(element => element.test_id == test.test_id).wish_id
          } else {
            remove = false
            wish_id = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) );
          }
        } else {
          remove = false
          wish_id = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) );
        }
        this.$http.post('index.php',  { task:       'wishlist_test',
                                        userObject: this.UserObject,
                                        testObject: test,
                                        wishID:     wish_id,
                                        remove:     remove,
                                      })
            .then(response => {
              if(response.body == 'success') {
                this.$store.dispatch('getWishlist', this.UserObject)
                if(remove) {
                  this.$store.dispatch('getSnackbar', {status: true, timeout: 4000, color: 'success', btnColor: 'buttonText', text: this.$i18n.t('testStore.remove-wish') })
                } else {
                  this.$store.dispatch('getSnackbar', {status: true, timeout: 4000, color: 'success', btnColor: 'buttonText', text: this.$i18n.t('testStore.success-wish') })
                }
              } else {
                this.$store.dispatch('getSnackbar', {status: true, timeout: 4000, color: 'error', btnColor: 'buttonText', text: this.$i18n.t('UserSettings.sessID-outdated') })
              }
            });
      },
      // add Test to shopping Cart
      toCart: function(test) {
        this.$store.dispatch('getCartItems', test)
        this.$store.dispatch('getSnackbar', {status: true, timeout: 3000, color: 'primary', btnColor: 'buttonText', text: this.$i18n.t('testStore.added') })
        this.$root.$emit('buttonEvent', true)
      },
      // open the buy window
      openBuy: function(test) {
        test.buy_number = 1
        var items = []
        items[0] = test
        // console.log(items)
        this.$store.dispatch('getBuyItems', items)
        // this.$store.dispatch('getCurrentMenu', 'testStoreBuy')
        this.$router.push('/mobile/dashboard/store/buy/article-overview')
      },
      // open detail item
      emitStoreItem: function(storeItem) {
        this.$router.push({path: '/mobile/dashboard/store/product/' + storeItem.test_name.replace(/\s/g, ''), query: {test_id: storeItem.test_id}})
      },
    },
  }
</script>
<style scoped>
::v-deep .v-dialog {
  margin: 0 !important;
  height: 40vh !important;
  top: 60vh !important;
  position: fixed !important;
  overflow-y: auto;
  left: 0 !important;
  border-radius: 14px 14px 0 0 !important;
}
</style>