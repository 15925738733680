<template>
    <v-container class="ma-0 pa-0" fluid>
        <v-card color="card" min-height="284px">
            <v-card-text class="px-0 py-3">
                <v-row align="center" class="mx-1">
                    <v-col class="pa-0">
                        <v-combobox solo-inverted color="primary" label="Add People" multiple chips v-model="model" :disabled="disableEditing" hide-details class="ml-2 mr-1" :allow-overflow="false" @input.native="searchUsers($event.srcElement.value)" :items="items" :search-input.sync="search" hide-selected auto-select-first @change="clearInput()" @blur="clearInput()">
                            <template v-slot:selection="{ attrs, item, parent, selected }">
                                <v-chip v-bind="attrs" :input-value="selected" close @click:close="parent.selectItem(item)">
                                    <v-avatar class="primary white--text" left v-text="item.text.slice(0, 1).toUpperCase()"></v-avatar>
                                    <span class="pr-2">{{ '@' + item.text }}</span>
                                </v-chip>
                            </template>
                        </v-combobox>
                    </v-col>
                    <v-col class="pa-0" cols="auto">
                        <v-btn icon elevation="3" class="ml-1 mr-2 buttonText--text elevatedCard" :disabled="disableEditing || model.length == 0? true : false" @click="addMaintainer()">
                            <v-icon color="primary">mdi-plus</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card class="mx-3 mt-3" color="elevatedCard">
                    <div class="subtitle-1 py-1 pl-4 elevatedCard font-weight-light">Members</div>
                    <v-divider></v-divider>
                    <v-card height="161px" style="overflow-y: auto" :class="scrollbarTheme" elevation="0" color="rgba(0,0,0,0)">
                        <v-list dense color="elevatedCard">
                            <v-list-item v-for="member in members" :key="member.usr_id">
                                <v-list-item-avatar color="primary">
                                    <span class="buttonText--text title">{{ member.usr_first_name.charAt(0) + member.usr_last_name.charAt(0) }}</span>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ member.usr_first_name + ' ' + member.usr_last_name }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ '@' + member.usr_name }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-chip>{{ member.role }}</v-chip>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-card>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>

    export default {
        name: 'EditDBTestMaintainer',
        props: ['disableEditing'],
        data: function() {
            return {
                creator: {},
                model: [],
                nonce: 1,
                futureMembers: [],
                items: [],
                search: null,
                members: [],
            }
        },
        mounted() {
            this.getTestMaintainer()
        },
        watch: {
            getTestData() {
                this.getTestMaintainer()
            },
            model (val, prev) {
                // console.log(this.model)
                if (val.length === prev.length) return
                this.model = val.map(v => {
                if (typeof v === 'string') {
                    v = {
                        text: v,
                    }
                    this.futureMembers.push(v)
                    this.nonce++
                }
                    return v
                })
            },
        },
        computed: {
            // test data from the store
            getTestData() {
                // console.log(this.$store.getters.TestData)
                return this.$store.getters.TestData
            },
            // custom scrollbar theme
            scrollbarTheme() {
                return this.$vuetify.theme.dark ? 'dark' : 'light';
            },
            // sessionID from the store
            SessionID() {
                // console.log('SessionIDComponent', this.$store.getters.sessionID)
                return this.$store.getters.sessionID
            },
            // User Object from the store
            UserObject() {
                return this.$store.getters.userData
            },
        },
        methods: {
            // search for app user to add them as maintainer
            searchUsers(data) {
                // console.log(data)
                // console.log(this.model)
                if(data != ''){
                    this.$http.post('index.php',    {   'task'      : 'get_table',
                                                        'table'     : 'colcons_user',
                                                        'field_list': [ 'usr_name', 'usr_id' ],
                                                        'filter'    : [{ op: 'like', field: 'usr_name', value: data + '%' }],
                                                        'usr_id'    : this.UserObject.usr_id,
                                                        'sessionid' : this.SessionID,
                                                    })
                            .then(response => {
                                // console.log(response.body)
                                if (response.body.sessionerror) {
                                    this.$store.dispatch('getSnackbar', { status: true, timeout: 3000, color: 'error', btnColor: 'buttonText', text: this.$i18n.t('UserSettings.sessID-outdated') });
                                    this.items = [];
                                } else {
                                    response.body.forEach(element => {
                                        this.items.push({text: element.usr_name, id: element.usr_id});
                                    });
                                }
                            });
                } else {
                    this.items = []
                }
            },
            // clear combobox for user search
            clearInput() {
                this.search = null
                this.items = []
            },
            // add a maintainer for the test
            addMaintainer() {
                // console.log(this.model)
                var id = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) );
                this.$http.post('index.php',    {   task:           'add_user',
                                                    newUserObject:  this.model,
                                                    testObject:     this.getTestData,
                                                    id:             id,
                                                })
                        .then(response => {
                            if(response.body == 'success') {
                                this.$store.dispatch('getSnackbar', {status: true, timeout: 4000, color: 'success', btnColor: 'buttonText', text: 'Successfully added User' })
                                this.model = []
                                this.getTestMaintainer()
                            } else {
                                this.$store.dispatch('getSnackbar', {status: true, timeout: 4000, color: 'error', btnColor: 'buttonText', text: 'An Error occured!' })
                            }
                        });
            },
            // method to get the test creator and maintainers from DB
            getTestMaintainer() {
                // get test creator from DB
                this.$http.post('index.php',    {   'task'      : 'get_table',
                                                    'table'     : 'colcons_user',
                                                    'filter'    : [{ op: 'eq', field: 'usr_id', value: this.getTestData.test_creator_id }],
                                                    'field_list': [ 'usr_id' ],
                                                    'usr_id'    : this.UserObject.usr_id,
                                                    'sessionid' : this.SessionID,
                                                })
                        .then(response => {
                            // console.log('getCreator', response.body)
                            if (response.body.sessionerror) {
                                this.$store.dispatch('getSnackbar', { status: true, timeout: 3000, color: 'error', btnColor: 'buttonText', text: this.$i18n.t('UserSettings.sessID-outdated') });
                            } else {
                                this.creator = response.body[0];
                                this.$http.post('index.php',    {   'task'          : 'get_test_maintainer',
                                                                    'test_id'       : this.getTestData.test_id,
                                                                    'usr_id'        : this.UserObject.usr_id,
                                                                    'sessionid'     : this.SessionID,
                                                            })
                                            .then(response => {
                                                // console.log(response.body)
                                                if (response.body.sessionerror) {
                                                    this.$store.dispatch('getSnackbar', { status: true, timeout: 3000, color: 'error', btnColor: 'buttonText', text: this.$i18n.t('UserSettings.sessID-outdated') });
                                                } else {
                                                    response.body.forEach(element => {
                                                        if(element.usr_id == this.creator.usr_id) {
                                                            element.role = 'Owner';
                                                        } else {
                                                            element.role = 'Maintainer';
                                                        }
                                                    });
                                                    this.members = response.body;
                                                }
                                            });
                            }
                        });
            },
        }
    }
</script>