<template>
  <v-container fluid class="pa-0 ma-0">
    <v-card elevation="0" color="rgba(0,0,0,0)">
      <v-row class="ma-2">
        <v-col cols="12" class="pa-0">
          <v-card color="card">
            <v-row align="center" justify="space-between" class="mx-1">
                <!-- Back to mobile Testlist -->
                <v-col cols="auto" class="pa-0">
                    <v-btn icon @click="backToTests()"><v-icon>mdi-arrow-left</v-icon></v-btn>
                </v-col>
                <!-- Test Name -->
                <v-col cols="auto">
                    <div class="my-1">{{ getTestData.test_name }}</div>
                </v-col>
                <v-spacer></v-spacer>
                <!-- Status of Test Validity -->
                <v-col cols="auto">
                    <v-chip v-if="!loading" dense outlined :color="testValid ? 'success' : 'error'">{{ testValid ? $t('Exercises.valid') : $t('Exercises.not-valid') }}</v-chip>
                    <v-skeleton-loader v-if="loading" transition="fade-transition" type="text" width="100px"></v-skeleton-loader>
                </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-card color="card" height="calc(100vh - 184px)" class="ma-2">
        <!-- Tab Header -->
        <v-tabs v-model="selectedTab" style="margin-left: -0px; width: calc(100vw -52px); position: absolute" grow>
          <v-tab key="tab-2" style="width: 50%" class="card">{{ $t('Exercises.exercise-mode') }}</v-tab>
          <v-tab key="tab-1" style="width: 50%" class="card">{{ $t('Exercises.exam-mode') }}</v-tab>
          <!-- First Tab (Exercise Mode) -->
          <v-tab-item class="pa-2" style="overflow-y: auto; height: calc(100vh - 232px)">
            <v-card outlined class="mb-2">
              <v-card-title>
                <!-- Start Button for Exercise Mode -->
                <v-btn block color="primary buttonText--text" @click="startExercise" :disabled="!testValid || !valid || disabled3" :loading="loading">{{ $t('Exercises.button-start') }}</v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <!-- Subtopic Selection -->
              <v-select :label="$t('Exercises.subtopic')" class="mx-1 px-3 mt-5" :items="getHF" dense multiple chips outlined v-model="selectedHF" :menu-props="{ offsetY: true }" clearable hide-details color="primary">
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggle">
                    <v-list-item-action>
                      <v-icon :color="selectedHF.length > 0 ? 'primary' : ''">{{ icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('Exercises.select-all')}}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{item}">
                  <v-chip small>{{ item }}</v-chip>
                </template>
              </v-select>
              <!-- Enable Assists Checkbox -->
              <v-row class="mx-1">
                <v-col cols="12" class="py-0">
                  <v-checkbox v-model="assists" class="ml-3" :label="$t('Exercises.disable-assists')" color="primary"></v-checkbox>
                </v-col>
              </v-row>
              <!-- Test Mode Selection -->
              <v-radio-group v-model="active" dense class="mt-0 mx-4 mb-0 pb-0">
                <!-- Question limited Mode -->
                <v-alert outlined :text="option1" dense>
                  <v-row justify="start">
                    <v-radio :label="$t('Exercises.question-number')" value="radio-1" class="ml-3 mt-1" color="primary"></v-radio>
                  </v-row>
                  <v-row>
                    <v-slider class="mt-9 mx-3" :disabled="disabled1" thumb-label="always" v-model="slider" :max="getTestData.test_question_nr" min="1" hide-details dense color="primary" ></v-slider>
                  </v-row>
                </v-alert>
                <!-- Time limited Mode -->
                <v-alert outlined :text="option2" class="mb-0" dense>
                  <v-row align="center">
                    <v-radio :label="$t('Exercises.timelimit')" value="radio-2" class="ml-3 mt-1" color="primary"></v-radio>
                  </v-row>
                  <v-row>
                    <v-text-field outlined :suffix="$t('Exercises.minutes')" label="Time" class="mx-3 mt-5 mb-3" dense v-model="timelimit" type="number" :disabled="disabled2" hide-details></v-text-field>
                  </v-row>
                </v-alert>
              </v-radio-group>
            </v-card>
            <!-- Exercise Mode Explanation/Help -->
            <v-alert dismissible color="warning" outlined text border="left" icon="mdi-comment-question-outline" v-model="alert" style="margin-bottom: -1px">
              <v-card-text class="pl-0">
                <ol>
                  <li>{{ $t('Exercises.exercise_info_1') }}</li>
                  <li>{{ $t('Exercises.exercise_info_2') }}</li>
                  <li>{{ $t('Exercises.exercise_info_3') }}</li>
                  <li>{{ $t('Exercises.exercise_info_4') }}</li>
                  <li>{{ $t('Exercises.exercise_info_5') }}</li>
                </ol>
              </v-card-text>
            </v-alert>
          </v-tab-item>
          <!-- Second Tab (Exam Mode) -->
          <v-tab-item class="pa-2" style="overflow-y: auto; height: calc(100vh - 232px)">
            <v-card class="mb-2" outlined>
              <v-card-title>
                <!-- Start Button for Exam Mode -->
                <v-btn block color="primary buttonText--text" @click="startExercise" :disabled="!testValid || !valid" :loading="loading">{{ $t('Exercises.button-start') }}</v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <!-- Exam Mode Explanation -->
              <v-card-text style="text-align: justify">
                <ul>
                  <li>{{ $t('Exercises.exam_alert_li_1') }}</li>
                  <li>{{ $t('Exercises.exam_alert_li_2') }}</li>
                  <li>{{ $t('Exercises.exam_alert_li_3') }}</li>
                  <li>{{ $t('Exercises.exam_alert_li_4') }}</li>
                  <li>{{ $t('Exercises.exam_alert_li_5') }}</li>
                </ul>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
        <v-divider style="position: absolute; top: 48px; width: 100vw"></v-divider>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>

  export default {
    name: 'ExercisesMobile',

    data: () => {
      return {
          // loading state of the component
          loading: false, // loading state of the component
          // exercise mode variables
          active: 'radio-1', // stores which radiobutton is selected
          slider: 70, // stores the slider value
          assists: true, // disables the number of right answers Note in the Test
          timelimit: 45,  // stores timelimit textfield value
          disabled1: false, // disables the slider
          disabled2: true, // disables the textfield
          disabled3: true, // if true, tooltip is shown on start button (and button is disabled)
          allHF: [], // variable that stores all subtopics (Handlungsfelder)
          selectedHF: [], // stores the selected subtopic(s)
          // Validation Variables for the Test
          validationObject: {},
          valid: true,
          valid_from: '',
          valid_to: '',
          // Mode Selection
          exercises: false, // if the exam tab is active
          exam: false,  // if the exercise tab is active
          option1: false, // if option 1 is selected in exercise mode
          option2: false, // if option 2 is selected in exercise mode
          mode: '', // Test Mode
          selectedTab: 0, // currently selected tab
          alert: true, // if the info alert is shown
      }
    },

    mounted() {
      // set loading state to true when mounted
      this.loading = true
    },

    created() {
      // initialize component when created
      this.initialize()
    },

    watch: {
      // change radio state if active radio (selected radio) changes
      active() {
        this.radioState()
      },
      // stare all subtopics in global variable
      getHF() {
        this.allHF = this.getHF
        if(this.getHF.length > 0) {
          this.selectedHF = this.getHF
        }
      },
      // set disabled state of start button according to selected subtopics
      selectedHF() {
        if(this.selectedHF.length != 0) {
          // console.log('not disabled')
          if(this.exam == true || this.exercises == true) {
            this.disabled3 = false
          }
        } else {
          // console.log('disabled')
          this.disabled3 = true
        }
      },
      // set disabled state of start-button according to selected subtopics
      getTestData() {
        // initialize component on test data change
        this.initialize()
        this.loading = true
        if(this.selectedHF.length != 0) {
          // console.log('not disabled')
          if(this.exam == true || this.exercises == true) {
            this.disabled3 = false
          }
        } else {
          // console.log('disabled')
          this.disabled3 = true
        }
      },
      // set disabled state of start-button according to selected subtopics
      exercises() {
        if(this.selectedHF.length != 0) {
          // console.log('not disabled')
          if(this.exam == true || this.exercises == true) {
            this.disabled3 = false
          }
        } else {
          // console.log('disabled')
          this.disabled3 = true
        }
      },
      // set disabled state of start-button according to selected subtopics
      exam() {
        if(this.selectedHF.length != 0) {
          // console.log('not disabled')
          if(this.exam == true || this.exercises == true) {
            this.disabled3 = false
          }
        } else {
          // console.log('disabled')
          this.disabled3 = true
        }
      },
      // watch the tab to determine which mode is selected (exam/exercise)
      selectedTab() {
        // console.log(this.selectedTab)
        if(this.selectedTab == 0) {
          this.exam = true
          this.exercises = false
        } else {
          this.exam = false
          this.exercises = true
        }
      },
    },

    computed: {
      // check if test is valid
      testValid() {
        if(new Date(this.valid_from) < new Date() && new Date(this.valid_to) > new Date()) {
          return true
        } else {
          return false
        } 
      },
      // when all subtopics are selected
      selectedAllHF() {
        return this.selectedHF.length === this.allHF.length
      },
      // when some subtopics are selected
      selectedSomeHF() {
        return this.selectedHF.length > 0 && !this.selectedAllHF
      },
      // icon handling for select menu
      icon() {
        if (this.selectedAllHF) return 'mdi-close-box'
        if (this.selectedSomeHF) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      // get all subtopics from store
      getHF() {
        // console.log(this.$store.getters.allHF)
        return this.$store.getters.allHF
      },
      // get the selected test from store
      getTestData() {
        // console.log(this.$store.getters.TestData)
        return this.$store.getters.TestData
      },
      // User Object from the store
      UserObject() {
          return this.$store.getters.userData
      },
      // sessionID from the store
      SessionID() {
        // console.log('SessionIDComponent', this.$store.getters.sessionID)
        return this.$store.getters.sessionID
      },
    },

    methods: {
      // get subtopics from DB when the component get's initialized
      initialize() {
        this.disabled3 = true
        this.exercises = false
        this.exam = true
        this.option1 = true
        this.selectedHF = this.getHF
        this.$store.dispatch('getHF', this.getTestData.test_subtopic_id)
        this.slider = this.getTestData.test_question_nr
        this.timelimit = this.getTestData.test_duration

        this.$http.post('index.php',  { 'task'      : 'get_table',
                                        'table'     : 'colcons_test_possession',
                                        'filter'    : [{ op: 'eq', field: 'test_id', value: this.getTestData.test_id }, { op: 'eq', field: 'usr_id', value: this.UserObject.usr_id }],
                                        'usr_id'    : this.UserObject.usr_id,
                                        'sessionid' : this.SessionID,
                                      })
            .then(response => {
              // console.log(response.body);
              this.loading = false;
              if (response.body.sessionerror) {
                this.$store.dispatch('getSnackbar', { status: true, timeout: 3000, color: 'error', btnColor: 'buttonText', text: this.$i18n.t('UserSettings.sessID-outdated') });
              } else {
                if(response.body.length == 1) {
                  // console.log(response.body)
                  this.valid_from = response.body[0].valid_from;
                  this.valid_to = response.body[0].valid_to;
                } else {
                  // console.log(response.body, this.getTestData)
                  this.valid_from = response.body[this.getTestData.iterator].valid_from;
                  this.valid_to = response.body[this.getTestData.iterator].valid_to;
                }
              }
            });
      },
      // Go back to my Tests
      backToTests() {
          this.$router.push({ name: 'TestListMobile' });
      },
      // handle the subtopic selections
      toggle () {
        this.$nextTick(() => {
          if (this.selectedAllHF) {
            this.selectedHF = []
          } else {
            this.selectedHF = this.allHF.slice()
          }
        })
      },
      // set the disabled values according to the radio states
      radioState() {
        if(this.active == 'radio-1') {
          this.disabled1 = false
          this.disabled2 = true
          this.option1 = true
          this.option2 = false
        } else if(this.active == 'radio-2') {
          this.disabled1 = true
          this.disabled2 = false
          this.option1 = false
          this.option2 = true
        } else {
          // console.log('error')
        }
      },
      // start the exercise when "start" is pressed (questions component get's launched and request get's sent to DB)
      startExercise() {
        // send store message if the test is executed with assists (in the exercise mode)
        this.$store.dispatch('getAssists', this.assists)
        // check if exercise or exam mode is selected with the tabs
        // console.log('exercises:', this.exercises, 'exam:', this.exam)
        var amount = this.slider
        if(this.exercises == true && this.exam == false) {
          // check if question limited or time limited mode is selected
          if(this.active == 'radio-1') {
            this.mode = 'question_limit'
          } else {
            this.mode = 'time_limit'
            // send the timelimit to the store
            amount = this.getTestData.test_question_nr
            this.$store.dispatch('getTimeLimit', this.timelimit)
          }
        } else {
          // select all subtopics for the exam mode
          this.selectedHF = this.allHF
          amount = this.getTestData.test_question_nr
          this.mode = 'exam_preparation'
        }
        // send test mode to store
        this.$store.dispatch('getMode', this.mode)
        // open the test question component
        this.$store.dispatch('getCurrentMenu', 'Questions')
        // send the test conditions to the store
        this.$store.dispatch('getAllQuestions', [amount, this.selectedHF, this.getTestData.test_name_db])
      },
    },
  }
</script>