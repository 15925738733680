<template>
  <v-app id="colcons">
    <AppNavigation></AppNavigation>
    <v-main>
      <!-- page content -->
      <v-container fluid class="pa-0" :style="{'margin-top': !isMobile ? '-1px' : ''}">
          <router-view></router-view>
      </v-container>
      <AppNavigationMenuMobile v-if="isMobile"></AppNavigationMenuMobile>
      <!-- trigger button for footer -->
      <v-speed-dial v-model="footer" bottom right absolute v-if="menu != 'Questions' && menu != 'EditDBMultipleChoice' && menu != 'EditDBReview' && menu != 'Home' && !isMobile" fixed>
        <template v-slot:activator>
          <v-btn v-model="footer" :color="color" dark fab>
            <v-icon v-if="footer" class="buttonText--text">mdi-close</v-icon>
            <v-icon v-else class="buttonText--text">mdi-chevron-up</v-icon>
          </v-btn>
        </template>
      </v-speed-dial>
    </v-main>
    <!-- footer with privacy policy -->
    <template>
      <v-footer color="footer1" padless fixed app v-if="footer && !isMobile" inset style="position: fixed">
        <v-row
          justify="center"
          no-gutters
        >
          <v-row v-if="isMobile" class="footer3">
            <v-spacer></v-spacer>
            <v-btn small icon fab class="mx-3" @click="footer = false">
              <v-icon color="buttonText">mdi-close</v-icon>
            </v-btn>
          </v-row>
          <v-row justify="center" no-gutters>
            <v-btn
              v-for="link in links"
              :key="link.id"
              text
              rounded
              class="my-2"
            >
              <a :href="link.link" target="_blank" class="text-decoration-none buttonText--text">{{ link.title }}</a>  
            </v-btn>
          </v-row>
          <v-col
            class="footer2 py-4 text-center buttonText--text"
            cols="12"
          >
            {{ new Date().getFullYear() }} — <strong>COLCONS Corporate Learning Consultans by Dr. Daniel Büttner</strong>
          </v-col>
        </v-row>
      </v-footer>
    </template>
    <!-- feedback button -->
    <template v-if="menu != 'Questions' && menu != 'EditDBMultipleChoice' && menu != 'EditDBReview'  && menu != 'createExams' && menu != 'Home' && !isMobile">
      <v-fab-transition>
        <v-btn absolute top right color="primary" :style="{top: '50%', transform:'translateY(-50%)', transform: 'rotate(-90deg)', 'margin-right': '-56px', 'z-index': 10}">
          <a href="mailto: academy@colcons.de?subject=Feedback COLCONS Prüfungstrainer" class="text-decoration-none buttonText--text">
            Feedback
          </a>
        </v-btn>
      </v-fab-transition>
    </template>
    <!-- global snackbar -->
    <v-snackbar v-model="Snackbar.status" :color="Snackbar.color" :timeout="Snackbar.timeout">
      <span class="buttonText--text">{{ Snackbar.text }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn :color="Snackbar.btnColor" rounded outlined v-bind="attrs" @click="closeSnackbar()">{{ $t('Questions.close') }}</v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import AppNavigation from './components/app_navigation/AppNavigation';
import AppNavigationMenuMobile from './components/app_navigation/AppNavigationMenuMobile';

import { isMobile } from 'mobile-device-detect';
import { Storage } from '@capacitor/storage';

export default {
  name: 'App',

  components: {
    AppNavigation,
    AppNavigationMenuMobile,
  },

  data: function() {
    return {
      menu: 'home',
      footer: true,
      color: 'primary',
      isMobile: false,
      links: [
        {title: this.$i18n.t('Footer.colcons'), link: 'https://colcons.de/', id: 1},
        {title: this.$i18n.t('Footer.imprint'), link: 'https://colcons.de/impressum/', id: 2},
        {title: this.$i18n.t('Footer.term-conditions'), link: 'https://colcons.de/agb/'},
        {title: this.$i18n.t('Footer.payment-methods'), link: 'https://colcons.de/bezahlmoeglichkeiten/', id: 3},
        {title: this.$i18n.t('Footer.cancellation-policy'), link: 'https://colcons.de/widerruf/', id: 4},
        {title: this.$i18n.t('Footer.privacy-policy'), link: 'https://colcons.de/datenschutzerklaerung/', id: 5},
      ],
    }
  },

  beforeCreate() {
    // console.log(this.$route.fullPath )
    // dont go to home because those entries can be accessed without login
    if(this.$route.fullPath.charAt(1) != '?' && !this.$route.path.includes('/dashboard/store') && this.$route.path != '/dashboard' && this.$route.path != '/demo') {
      if (this.isMobile) {
        // console.log('test2')
        this.$router.push({path: '/mobile/', query: {page: 'welcome'}})
        this.footer = false;
      } else {
        this.$router.push({path: '/', query: {page: 'welcome'}})
        this.footer = true
        // code to open login
        // redirict to entered route after login
      }
    }

    // got to home if link is buy on create
    if(this.$route.path.includes('/dashboard/store/buy')) {
      this.$router.push({path: '/', query: {page: 'welcome'}})
      this.footer = true
    }
    if((this.$route.path == '/' || this.$route.path == '/mobile/') && this.$route.fullPath.slice(this.$route.fullPath.length - 14) != '/?page=welcome') {
      this.$router.push({query: {page: 'welcome'}})
      this.footer = true
    }
  },

  beforeMount() {
    // add event listener for page reload
    window.addEventListener("beforeunload", this.preventNav)
    // console.log(isMobile)
    if(isMobile == false) {
      this.menu = this.currentMenu
    } else {
      this.menu = this.currentMenu + 'Mobile'
    }
    this.isMobile = isMobile
    // theme initialisation
    const checkThemeData = async () => {
      const {value} = await Storage.get({ key: 'theme' });
      // console.log(value);
      if (value === undefined || value === null) {
        // console.log('no theme data')
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          this.$vuetify.theme.dark = true;
        } else {
          this.$vuetify.theme.dark = false;
        }
      } else {
        this.$vuetify.theme.dark = JSON.parse(value);
      }
    };
    checkThemeData();
    // language initialisation
    // get default browser language and set app language to this language on start
    let lang = window.navigator.languages ? window.navigator.languages[0] : null;
    lang = lang || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage;

    let shortLang = lang;
    if (shortLang.indexOf('-') !== -1)
        shortLang = shortLang.split('-')[0];

    if (shortLang.indexOf('_') !== -1)
        shortLang = shortLang.split('_')[0];

    // console.log(shortLang);
    const checkLanguageData = async () => {
        const {value} = await Storage.get({ key: 'language' });
        // console.log(value);
        if (value === undefined || value === null) {
            // console.log('no language data')
            if(shortLang == 'de') {
                this.$i18n.locale = shortLang
            } else {
                this.$i18n.locale = 'en'
            }
        } else {
            this.$i18n.locale = value;
        }
    };
    checkLanguageData();
  },

  mounted() {
    // console.log(this.$route.fullPath)
    if(this.$route.fullPath.charAt(1) != '?') {
      this.footer = false
    }

    // auto login
    this.$store.dispatch('getAutoLogin')

  },

  beforeDestroy() {
    // remove event listener for page reload
    window.removeEventListener("beforeunload", this.preventNav);
  },

  methods: {

    closeSnackbar() {
      this.$store.dispatch('getSnackbar', { status: false })
    },

    // method that prevents the page reload
    preventNav(event) {
      // delete sessionID when leaving the side
      this.$http.post('index.php',  { task: 'logout_user',
                                      currentUser:  this.UserObject.usr_id
                                    })
      // prevent reload or leaving the side when in a test
      if (!this.isEditing) return

      event.preventDefault()
      event.returnValue = ""
    },

    
    emitMenu: function(menu) {
        this.$store.dispatch('getCurrentMenu', menu)
    },
    
  },

  watch: {
    Snackbar() {
      if(this.Snackbar.status == true) {
        setTimeout(() => this.closeSnackbar(), this.Snackbar.timeout);
      }
    },
    // when in a test, the page cant be reloaded
    currentMenu() {
      // console.log(this.currentMenu)
      if(this.isEditing == false) {
        // console.log(isMobile)
        if(isMobile == false) {
          this.menu = this.currentMenu
        } else {
          this.menu = this.currentMenu + 'Mobile'
        }
      }
      // console.log(this.currentMenu)
      if(this.menu == 'Questions') {
        this.$store.dispatch('getIsEditing', true)
      } else {
        this.$store.dispatch('getIsEditing', false)
      }
      // show footer permanently when on Home
      // console.log(this.currentMenu)
      if(this.$route.fullPath.charAt(1) != '?') {
        this.footer = false
      } else {
        this.footer = true
      }
    },
    leaveTest() {
      if(this.leaveTest == true) {
        this.menu = this.currentMenu
        this.$store.dispatch('leaveTest', false)
        this.$store.dispatch('getIsEditing', false)
      }
    },

    footer() {
      if(this.footer == true) {
        this.color = "rgba(30,30,30,1)"
      } else {
        this.color = "primary"
      }
    },

    isMobile() {
      this.isMobile = isMobile
      this.$router.push({path: '/mobile/', query: {page: 'welcome'}})
      this.footer = false;
    },

    // get confirmation from store if user is logged in
    SuccessfullLogin() {
      if(this.SuccessfullLogin == true) {
        this.$store.dispatch('getMyTests', this.UserObject)
      }
    },
  },

  created() {
    // get default browser language and set app language to this language on start
        let lang = window.navigator.languages ? window.navigator.languages[0] : null;
        lang = lang || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage;

        let shortLang = lang;
        if (shortLang.indexOf('-') !== -1)
            shortLang = shortLang.split('-')[0];

        if (shortLang.indexOf('_') !== -1)
            shortLang = shortLang.split('_')[0];

        // console.log(shortLang);
        if(shortLang == 'de') {
            //this.currentLanguage = 'Deutsch'
            this.$i18n.locale = shortLang
        } else {
            //this.currentLanguage = 'English'
            this.$i18n.locale = 'en'
        }   
  },

  computed: {

    // gets confirmation from the store if the login was successfull
    SuccessfullLogin() {
      // console.log('User logged in?', this.$store.getters.successfullLogin)
      return this.$store.getters.successfullLogin
    },

    // get the current menu from the store
    currentMenu() {
      // console.log(this.$store.getters.currentMenu)
      return this.$store.getters.currentMenu
    },

    // get leave test info from store
    leaveTest() {
      return this.$store.getters.leave
    },

    // User Object from the store
    UserObject () {
        return this.$store.getters.userData
    },

    // sessionID from the store
    SessionID() {
        // console.log('SessionIDComponent', this.$store.getters.sessionID)
        return this.$store.getters.sessionID
    },

    // get info from store if user is currently in test
    isEditing() {
      return this.$store.getters.isEditing
    },

    // get snackbar data from store
    Snackbar() {
      return this.$store.getters.snackbar
    },

  },

};
</script>
<style>
  html{
    overflow-y: auto;
  }
  .fab-text-custom {
    position: absolute;
    right: 50px;
    background-color: rgba(0,0,0,0.5);
    padding: 10px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
  }
  .light::-webkit-scrollbar {
    width: 15px;
  }

  .light::-webkit-scrollbar-track {
    background: #e6e6e6;
    border-left: 1px solid #dadada;
  }

  .light::-webkit-scrollbar-thumb {
    background: #b0b0b0;
    border: solid 3px #e6e6e6;
    border-radius: 7px;
  }

  .light::-webkit-scrollbar-thumb:hover {
    background: black;
  }

  .dark::-webkit-scrollbar {
    width: 15px;
  }

  .dark::-webkit-scrollbar-track {
    background: #202020;
    border-left: 1px solid #2c2c2c;
  }

  .dark::-webkit-scrollbar-thumb {
    background: #3e3e3e;
    border: solid 3px #202020;
    border-radius: 7px;
  }

  .dark::-webkit-scrollbar-thumb:hover {
    background: white;
  }
</style>
