import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueResource from 'vue-resource';
import VueRouter from 'vue-router';
import goTo from 'vuetify/es5/services/goto'
import "./plugins/vuetify-money.js";
import {routes} from './routes';
import VueGoogleCharts from 'vue-google-charts';
import i18n from './i18n';
import 'babel-polyfill';
import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
// import { StatusBar } from '@capacitor/status-bar';
import store from './store/index.js';

if (Capacitor.isNativePlatform()) {

  // enable app navigation (gestures, buttons)
  CapacitorApp.addListener('backButton', ({ canGoBack }) => {
    if (!canGoBack) {
      CapacitorApp.exitApp();
    } else {
      window.history.back();
    }
  });

  async () => {
    // Hide the splash (you should do this on app launch)
    await SplashScreen.hide();

    // Show the splash for two seconds and then automatically hide it:
    await SplashScreen.show({
      showDuration: 5000,
      autoHide: true
    });
  };

  // const hideStatusBar = async () => {
  //   await StatusBar.hide();
  // };
  // hideStatusBar();

  window.screen.orientation.lock('portrait');
}

Vue.use(VueResource, {});
Vue.use(VueRouter);
Vue.use(VueGoogleCharts);
// Vue.use(GAuth, gauthOption);

Vue.config.productionTip = false

// Link to the Backend
Vue.http.options.root = 'https://pruefungs-trainer.app/';

// configuration of the vue router
const router = new VueRouter({
  routes,
  mode: 'hash',
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo)
  },
});

// Name of Application (Tab Name)
document.title = 'COLCONS | Prüfungstrainer'

new Vue({
  vuetify,
  i18n,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
