import Vue from 'vue';
import Vuex from 'vuex';

import menu from './modules/menu'
import questions from './modules/questions'
import user from './modules/user'
import tests from './modules/tests'

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        menu,
        questions,
        user,
        tests,
    },
});