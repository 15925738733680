<template>
  <v-card tile :min-height="windowHeight - 76" elevation="0" color="rgba(0,0,0,0)">
    <v-row align="center" justify="center">
      <!-- layout of the exercises component -->
      <v-card color="rgba(0,0,0,0)" elevation="0" class="mx-5" width="1525">
        <!-- top Navigation -->
        <v-row class="pa-0 ma-0">
          <v-breadcrumbs :items="breadCrumbs" class="ma-0">
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
          <v-spacer></v-spacer>
          <v-row v-if="!loading" align="center" justify="end" class="mr-5">
            <v-icon :color="testValid ? 'success' : 'error'" class="mr-5" large>{{ testValid ? 'mdi-check' : 'mdi-close' }}</v-icon>
            <div class="mr-3 body-2">{{ $t('Exercises.valid-from') }}</div>
            <v-chip outlined :color="testValid ? 'success' : 'error'">{{ valid_from.substring(0,10) }}</v-chip>
            <div class="mx-3 body-2">{{ $t('Exercises.valid-to2') }}</div>
            <v-chip outlined :color="testValid ? 'success' : 'error'">{{ valid_to.substring(0,10) }}</v-chip>
          </v-row>
          <v-skeleton-loader v-else transition="fade-transition" type="text" class="mb-1 mr-3" width="280px" style="margin-top: 16px"></v-skeleton-loader>
        </v-row>

        <v-divider></v-divider>

        <v-card-text :style="{height: windowHeight - 122 + 'px'}" class="scroll pa-0" :class="scrollbarTheme">
          <!-- Test Info -->
          <v-row class="ma-1">
            <v-col cols="12" class="pa-1">
              <v-card color="card">
                <v-card-title>
                  <div>{{ getTestData.test_name }}</div>
                  <v-spacer></v-spacer>
                  <v-tooltip bottom v-if="alert == false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon color="primary" @click="alert = true" outlined v-bind="attrs" v-on="on">
                        <v-icon>mdi-help</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('Exercises.tutorial') }}</span>
                  </v-tooltip>
                </v-card-title>
                <v-card-text>{{ getTestData.test_info }}</v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <!-- Mode selection-->
          <v-row class="mx-1">
            <!-- Exam Preparation Mode -->
            <v-col class="px-1 py-0">
              <v-card color="card">
                <v-card-title>
                  <div>{{ $t('Exercises.exam-mode') }}</div>
                  <v-spacer></v-spacer>
                  <!-- start button -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-btn color="primary buttonText--text" @click="startExercise(false)" :disabled="!testValid" :loading="loading">{{ $t('Exercises.button-start') }}</v-btn>
                      </div>
                    </template>
                    <!-- tooltip when a subtipoc is selected -->
                    <span>{{ $t('Exercises.tooltip2-state2') }}</span>
                  </v-tooltip>
                </v-card-title>
                <v-card-text>
                  <ul>
                    <li>{{ $t('Exercises.exam_alert_li_1') }}</li>
                    <li>{{ $t('Exercises.exam_alert_li_2') }}</li>
                    <li>{{ $t('Exercises.exam_alert_li_3') }}</li>
                    <li>{{ $t('Exercises.exam_alert_li_4') }}</li>
                    <li>{{ $t('Exercises.exam_alert_li_5') }}</li>
                  </ul>
                </v-card-text>
              </v-card>
              <!-- exam mode tutorial/info -->
              <v-alert dismissible color="warning" outlined text border="left" icon="mdi-comment-question-outline" prominent v-model="alert" class="mt-2">
                <div class="mb-1">{{ $t('Exercises.exam_info_1') }}</div>
                <div>{{ $t('Exercises.exam_info_2') }}</div>
              </v-alert>
            </v-col>
            <!-- exercise Mode -->
            <v-col class="px-1 py-0">
              <!-- exercise mode Settings -->
              <v-card color="card">
                <v-card-title>
                  <div>{{ $t('Exercises.exercise-mode') }}</div>
                  <v-spacer></v-spacer>
                  <!-- start button -->
                  <v-tooltip v-if="disabled3" bottom>
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <!-- button to start the exercise -->
                        <v-btn color="primary" class="white--text" disabled>{{ $t('Exercises.button-start') }}</v-btn>
                      </div>
                    </template>
                    <!-- tooltip when no subtopic is selected -->
                    <span>{{ $t('Exercises.tooltip2-state1') }}</span>
                  </v-tooltip>
                  <v-tooltip v-else bottom>
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-btn color="primary buttonText--text" @click="startExercise(true)" :disabled="!testValid" :loading="loading">{{ $t('Exercises.button-start') }}</v-btn>
                      </div>
                    </template>
                    <!-- tooltip when a subtipoc is selected -->
                    <span>{{ $t('Exercises.tooltip2-state2') }}</span>
                  </v-tooltip>
                </v-card-title>
                <v-select :label="$t('Exercises.subtopic')" class="mx-1 px-3" :items="getHF" multiple chips solo-inverted v-model="selectedHF" :menu-props="{ offsetY: true }" clearable hide-details color="primary">
                  <!-- select subtopics -->
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="toggle">
                      <v-list-item-action>
                        <v-icon :color="selectedHF.length > 0 ? 'primary' : ''">{{ icon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('Exercises.select-all')}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:selection="{item}">
                    <v-chip outlined color="primary">{{ item }}</v-chip>
                  </template>
                </v-select>
                <!-- hints checkbox -->
                <v-checkbox class="ml-5 mt-5" v-model="assists" :label="$t('Exercises.disable-assists')" color="primary"></v-checkbox>
                <v-radio-group v-model="active" dense class="mt-0 mx-4 mb-0 pb-0">
                  <!-- first option (question limit) -->
                  <v-alert outlined :text="option1">
                    <v-row justify="center">
                      <v-radio :label="$t('Exercises.question-number')" value="radio-1" class="mx-3 mt-10" color="primary"></v-radio>
                      <v-slider class="mt-9 mx-5" :disabled="disabled1" thumb-label="always" v-model="slider" :max="getTestData.test_question_nr" min="1" hide-details dense color="primary" ></v-slider>
                    </v-row>
                  </v-alert>
                  <!-- second option (time limit) -->
                  <v-alert outlined :text="option2" class="mb-0">
                    <v-row justify="center" align="center">
                      <v-radio :label="$t('Exercises.timelimit')" value="radio-2" class="mx-3 mt-1" color="primary"></v-radio>
                      <v-text-field outlined :suffix="$t('Exercises.minutes')" label="Time" class="mx-5 mt-3 mb-3" dense v-model="timelimit" type="number" :disabled="disabled2" hide-details></v-text-field>
                      <v-spacer></v-spacer>
                    </v-row>
                  </v-alert>
                </v-radio-group>
              </v-card>
              <!-- exercise mode tutorial/info -->
              <v-alert dismissible color="warning" outlined text border="left" icon="mdi-comment-question-outline" prominent v-model="alert" class="mt-2">
                <v-card-text>
                  <ol>
                    <li>{{ $t('Exercises.exercise_info_1') }}</li>
                    <li>{{ $t('Exercises.exercise_info_2') }}</li>
                    <li>{{ $t('Exercises.exercise_info_3') }}</li>
                    <li>{{ $t('Exercises.exercise_info_4') }}</li>
                    <li>{{ $t('Exercises.exercise_info_5') }}</li>
                  </ol>
                </v-card-text>
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
  </v-card>
</template>

<script>

  export default {
    name: 'Exercises',

    components: {

    },

    data: function() {
      return {
          loading: false, // loading state of the component

          active: 'radio-1', // stores which radiobutton is selected
          slider: 70, // stores the slider value
          maxQuestions: 70, // limit for the slider (max value)
          assists: true, // disables the number of right answers Note in the Test
          timelimit: 45,  // stores timelimit textfield value
          disabled1: false, // disables the slider
          disabled2: true, // disables the textfield
          disabled3: true, // if true tooltip is shown on start button (and button is disabled)
          allHF: [], // variable that stores all subtopics (Handlungsfelder)
          selectedHF: [], // stores the selected subtopic(s)
          // Validation Variables for the Test
          validationObject: {},
          valid_from: '',
          valid_to: '',
          // Mode Selection
          option1: false,
          option2: false,
          mode: '', // Test Mode

          alert: false, // activate tips

          breadCrumbs: [
            {text: this.$i18n.t('AppNavigationMenu.dashboard'), disabled: false, to: '/dashboard', exact: true},
            {text: this.$i18n.t('Exercises.my-tests'), disabled: false, to: '/dashboard/exercises', exact: true},
            {text: '', disabled: true, to: '/dashboard/exercises/test-name', exact: true},
          ],

          windowWidth: 0, // width of the window
          windowHeight: 0, // height of the window
      }
    },

    mounted() {

      // event listener for window size
      this.$nextTick(function() {
        window.addEventListener('resize', this.getWindowWidth);
        window.addEventListener('resize', this.getWindowHeight);

        // Init
        this.getWindowWidth()
        this.getWindowHeight()
      })

      this.loading = true
      
    },

    created() {
      // initialize component when created
      if(this.getTestData.test_id) this.initialize()
    },

    beforeDestroy() {

      // remove eventlisteners when the component is destroyed
      window.removeEventListener('resize', this.getWindowWidth);
      window.removeEventListener('resize', this.getWindowHeight);
    },

    watch: {
      // change radio state if active radio (selected radio) changes
      active() {
        this.radioState()
      },
      // stare all subtopics in global variable
      getHF() {
        this.allHF = this.getHF
        if(this.getHF.length > 0) {
          this.selectedHF = this.getHF
        }
      },
      // set disabled state of start button according to selected subtopics
      selectedHF() {
        if(this.selectedHF.length > 0) {
          this.disabled3 = false
        } else {
          this.disabled3 = true
        }
      },
      getTestData() {
        this.initialize()
        this.loading = true
        if(this.selectedHF.length > 0) {
          this.disabled3 = false
        } else {
          this.disabled3 = true
        }
      },
    },

    computed: {
      // new styling of the scrollbar
      scrollbarTheme() {
        return this.$vuetify.theme.dark ? 'dark' : 'light';
      },
      // check if test is valid
      testValid() {
        if(new Date(this.valid_from) < new Date() && new Date(this.valid_to) > new Date()) {
          return true
        } else {
          return false
        } 
      },
      // when all subntopics are selected
      selectedAllHF() {
        return this.selectedHF.length === this.allHF.length
      },
      // when some subtopics are selected
      selectedSomeHF() {
        return this.selectedHF.length > 0 && !this.selectedAllHF
      },
      // icon handling for select menu
      icon() {
        if (this.selectedAllHF) return 'mdi-close-box'
        if (this.selectedSomeHF) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      // get all subtopics from store
      getHF() {
        return this.$store.getters.allHF
      },
      // get the selected test from store
      getTestData() {
        // console.log(this.$store.getters.TestData)
        return this.$store.getters.TestData
      },
      // User Object from the store
      UserObject() {
          return this.$store.getters.userData
      },
      // sessionID from the store
      SessionID() {
        // console.log('SessionIDComponent', this.$store.getters.sessionID)
        return this.$store.getters.sessionID
      },
    },

    methods: {
      // handle the subtopic selections
      toggle () {
        this.$nextTick(() => {
          if (this.selectedAllHF) {
            this.selectedHF = []
          } else {
            this.selectedHF = this.allHF.slice()
          }
        })
      },
      // set the disabled values according to the radio states
      radioState() {
        if(this.active == 'radio-1') {
          this.disabled1 = false
          this.disabled2 = true
          this.option1 = true
          this.option2 = false
        } else {
          this.disabled1 = true
          this.disabled2 = false
          this.option1 = false
          this.option2 = true
        }
      },
      // get subtopics from DB when the component get#s initialized
      initialize() {
        this.$store.dispatch('getHF', this.getTestData.test_subtopic_id)
        this.disabled3 = true
        this.breadCrumbs[2].text = this.getTestData.test_name
        this.selectedHF = this.getHF
        this.radioState()
        
        this.slider = this.getTestData.test_question_nr
        this.timelimit = this.getTestData.test_duration

        this.$http.post('index.php',  { 'task'      : 'get_table',
                                        'table'     : 'colcons_test_possession',
                                        'filter'    : [{ op: 'eq', field: 'test_id', value: this.getTestData.test_id }, { op: 'eq', field: 'usr_id', value: this.UserObject.usr_id }],
                                        'usr_id'    : this.UserObject.usr_id,
                                        'sessionid' : this.SessionID,
                                      })
            .then(response => {
              // console.log(response.body);
              this.loading = false;
              if (response.body.sessionerror) {
                this.$store.dispatch('getSnackbar', { status: true, timeout: 3000, color: 'error', btnColor: 'buttonText', text: this.$i18n.t('UserSettings.sessID-outdated') });
              } else {
                if(response.body.length == 1) {
                  this.valid_from = response.body[0].valid_from;
                  this.valid_to = response.body[0].valid_to;
                } else {
                  this.valid_from = response.body[this.getTestData.iterator].valid_from;
                  this.valid_to = response.body[this.getTestData.iterator].valid_to;
                }
              }
            });
      },
      // start the exercise when "start" is pressed (questions component get's launched and request get's sent to DB)
      startExercise(val) {
        this.$router.push('/test/Question-1') // set the new route to start the test
        this.$store.dispatch('getAssists', this.assists) // set if assists are enabled
        var amount = this.slider
        if(val) {
          if(this.active == 'radio-1') {
            this.mode = 'question_limit'
          } else {
            this.mode = 'time_limit'
            this.$store.dispatch('getTimeLimit', this.timelimit)
            amount = this.getTestData.test_question_nr
          }
        } else {
          this.selectedHF = this.allHF
          this.mode = 'exam_preparation'
          amount = this.getTestData.test_question_nr
        }
        this.$store.dispatch('getMode', this.mode)
        this.$store.dispatch('getCurrentMenu', 'Questions')
        this.$store.dispatch('getAllQuestions', [amount, this.selectedHF, this.getTestData.test_name_db])
      },
      // get the width of the window
      getWindowWidth() {
        this.windowWidth = document.documentElement.clientWidth;
      },
      // get the height of the window
      getWindowHeight() {
        this.windowHeight = document.documentElement.clientHeight;
      },
    },
  }
</script>