<template>
    <div>
        <v-dialog v-model="menu" v-if="isMobile" fullscreen>
            <template v-slot:activator="{ on: menu, attrs }">
                <!-- menu button when logged in  -->
                <v-btn v-if="SuccessfullLogin == true" icon v-on="menu" v-bind="attrs">
                    <v-avatar color="primary">
                        <span class="buttonText--text headline">{{ UserObject.usr_first_name.charAt(0) + UserObject.usr_last_name.charAt(0) }}</span>
                    </v-avatar>
                </v-btn>
                <!-- menu button when not logged in -->
                <v-btn icon v-on="menu" v-bind="attrs" class="mx-1" v-else>
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
            </template>
            <!-- menu card -->
            <v-card color="menu" tile>
                <!-- toolbar with close button -->
                <v-toolbar color="primary">
                    <v-toolbar-title class="buttonText--text">{{ $t('AppNavigationUser.menu') }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="menu = false" color="buttonText"><v-icon>mdi-close</v-icon></v-btn>
                </v-toolbar>
                <!-- user info display when logged in -->
                <v-list color="menu" v-if="SuccessfullLogin == true">
                    <v-list-item>
                        <v-list-item-avatar color="primary">
                            <span class="buttonText--text title">{{ UserObject.usr_first_name.charAt(0) + UserObject.usr_last_name.charAt(0) }}</span>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>{{ UserObject.usr_first_name + ' ' + UserObject.usr_last_name }}</v-list-item-title>
                            <v-list-item-subtitle>{{ '@' + UserObject.usr_name }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <v-divider v-if="SuccessfullLogin == true"></v-divider>
                <!-- language and theme select -->
                <v-list color="menu">
                    <AppNavigationLanguage></AppNavigationLanguage>
                    <AppNavigationTheme></AppNavigationTheme>
                </v-list>

                <v-divider v-if="SuccessfullLogin == true"></v-divider>
                <!-- user settings -->
                <v-list color="menu" v-if="SuccessfullLogin">
                    <v-list-item @click="emitMenu('UserSettings')">
                        <v-list-item-icon>
                            <v-icon>mdi-cog-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t('AppNavigationUser.user-settings') }}</v-list-item-title>
                    </v-list-item>
                </v-list>

                <v-divider></v-divider>
                <!-- logout button -->
                <v-list color="menu">
                    <v-list-item @click="$store.dispatch('getAutoLogin')">
                        <v-list-item-icon>
                            <v-icon>mdi-reload</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t('AppNavigationUser.relog') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="logoutFunction" v-if="SuccessfullLogin">
                        <v-list-item-icon>
                            <v-icon>mdi-login-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t('AppNavigationUser.sign-out') }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-dialog>
        <!-- desktop menu -->
        <v-menu v-model="menu" :close-on-content-click="false" offset-y color="menu" v-if="!isMobile">
            <template v-slot:activator="{ on: menu, attrs }">
                <!-- menu button when logged in  -->
                <v-tooltip bottom v-if="SuccessfullLogin == true">
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn v-if="SuccessfullLogin == true" icon v-on="{ ...tooltip, ...menu }" v-bind="attrs">
                            <v-avatar color="primary">
                                <span class="buttonText--text headline">{{ UserObject.usr_first_name.charAt(0) + UserObject.usr_last_name.charAt(0) }}</span>
                            </v-avatar>
                        </v-btn>
                    </template>
                    <span>{{ $t('AppNavigationUser.user-menu') }}</span>
                </v-tooltip> 
                <!-- menu button when not logged in -->
                <v-tooltip bottom v-else>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn icon v-on="{ ...tooltip, ...menu }" v-bind="attrs" class="mx-1">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('AppNavigationUser.menu') }}</span>
                </v-tooltip>   
            </template>

            <v-card>
                <v-list color="menu" v-if="SuccessfullLogin == true">
                    <v-list-item>
                        <v-list-item-avatar color="primary">
                            <span class="buttonText--text title">{{ UserObject.usr_first_name.charAt(0) + UserObject.usr_last_name.charAt(0) }}</span>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>{{ UserObject.usr_first_name + ' ' + UserObject.usr_last_name }}</v-list-item-title>
                            <v-list-item-subtitle>{{ '@' + UserObject.usr_name }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <v-divider v-if="SuccessfullLogin == true"></v-divider>

                <v-list color="menu">
                    <AppNavigationLanguage></AppNavigationLanguage>
                    <AppNavigationTheme></AppNavigationTheme>
                </v-list>

                <v-divider v-if="SuccessfullLogin == true"></v-divider>

                <v-list color="menu" v-if="SuccessfullLogin == true">
                    <v-list-item @click="emitMenu('UserSettings')">
                        <v-list-item-icon>
                            <v-icon>mdi-cog-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t('AppNavigationUser.user-settings') }}</v-list-item-title>
                    </v-list-item>
                </v-list>

                <v-divider v-if="SuccessfullLogin == true"></v-divider>

                <v-list color="menu" v-if="SuccessfullLogin == true">
                    <v-list-item @click="logoutFunction">
                        <v-list-item-icon>
                            <v-icon>mdi-login-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t('AppNavigationUser.sign-out') }}</v-list-item-title>
                    </v-list-item>
                </v-list>

            </v-card>
        </v-menu>
    </div>
</template>

<script>
// component import
import AppNavigationLanguage from './AppNavigationLanguage';
import AppNavigationTheme from './AppNavigationTheme';
// mobile device detection (plugin)
import { isMobile } from 'mobile-device-detect';

export default {
    // component name
    name: 'AppNavigationUser',
    // component intialize
    components: {
        AppNavigationLanguage,
        AppNavigationTheme,
    },

    data: () => {
        return {
            menu: false, // stores if the user menu is opened
            isMobile: false, // stores which type of device the user has
        }
    },

    mounted() {
        // sets the mobile variable on mount of the side
        this.isMobile = isMobile;
    },

    computed: {

        // gets confirmation from the store if the login was successfull
        SuccessfullLogin () {
            // console.log('User logged in?', this.$store.getters.successfullLogin)
            return this.$store.getters.successfullLogin;
        },
        
        // sessionID from the store
        SessionID () {
            // console.log('SessionIDComponent', this.$store.getters.sessionID)
            return this.$store.getters.sessionID;
        },

        // User Object from the store
        UserObject () {
            return this.$store.getters.userData;
        },

    },

    methods: {

        logoutFunction() {
            // console.log('logout')
            this.$store.dispatch('getLogout', this.UserObject.usr_id);
            this.$store.dispatch('getLogoutTests');
            // close dialog
            this.menu = false;
            // return to homescreen on logout
            if(this.isMobile) {
                this.$router.push({path: '/mobile/'});
            } else {
                this.$router.push({path: '/', query: {page: 'welcome'}});
            }
        },

        emitMenu() {
            if(this.$route.name != 'UserSettings' && this.$route.name != 'UserSettingsMobile') {
                if (this.isMobile) {
                    this.$router.push({path: '/mobile/dashboard/settings'});
                } else {
                    this.$router.push({path: '/dashboard/settings'})
                }
                this.menu = false;
            }
        },

    }

};

</script>