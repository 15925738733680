<template>
  <v-card tile :min-height="windowHeight - 76" elevation="0" color="rgba(0,0,0,0)">
    <v-row align="center" justify="center">
      <!-- layout of the editDB component -->
      <v-card color="rgba(0,0,0,0)" elevation="0" class="mx-5" width="1525">
        <!-- top Navigation -->
        <v-breadcrumbs :items="breadCrumbs" class="ma-0">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-divider></v-divider>
        <v-card-text :style="{height: windowHeight - 122 + 'px'}" class="scroll py-0 px-0" :class="scrollbarTheme">
          <!-- first row -->
          <v-row class="ma-1">
            <!-- test Name and Info -->
            <v-col class="pa-1" cols="6">
              <EditDBTestNameInfo :disabled="disableEditing"></EditDBTestNameInfo>
            </v-col>
            <!-- Test Creator -->
            <v-col class="pa-1" cols="4">
              <EditDBTestCreator></EditDBTestCreator>
            </v-col>
            <!-- Test Creation Date -->
            <v-col class="pa-1" cols="2">
              <EditDBTestDate></EditDBTestDate>
            </v-col>
          </v-row>
          <!-- second row -->
          <v-row class="mx-1">
            <!-- edit, review, key -->
            <v-col cols="3" class="px-1 py-0">
              <EditDBTestActions></EditDBTestActions>
            </v-col>
            <!-- add maintainers -->
            <v-col cols="6" class="px-1 pt-0 pb-1">
              <EditDBTestMaintainer :disableEditing="disableEditing"></EditDBTestMaintainer>
            </v-col>
            <!-- test settings -->
            <v-col cols="3" class="px-1 py-0">
              <EditDBTestSettings :disableEditing="disableEditing"></EditDBTestSettings>
            </v-col>
          </v-row>
          <!-- third row -->
          <v-row class="ma-1">
            <!-- Tags -->
            <v-col cols="4" class="px-1 py-0">
              <EditDBTestTags :disableEditing="disableEditing"></EditDBTestTags>
            </v-col>
            <!-- edit test content -->
            <v-col cols="4" class="px-1 py-0">
              <EditDBTestImage :disableEditing="disableEditing"></EditDBTestImage>
            </v-col>
            <!-- release in store -->
            <v-col cols="4" class="px-1 py-0">
              <EditDBTestRelease :disableEditing="disableEditing"></EditDBTestRelease>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>  
    </v-row>

  </v-card>
</template>

<script>
  import EditDBTestNameInfo from './EditDBTestNameInfo'
  import EditDBTestCreator from './EditDBTestCreator'
  import EditDBTestDate from './EditDBTestDate'
  import EditDBTestMaintainer from './EditDBTestMaintainer'
  import EditDBTestActions from './EditDBTestActions'
  import EditDBTestSettings from './EditDBTestSettings'
  import EditDBTestImage from './EditDBTestImage'
  import EditDBTestRelease from './EditDBTestRelease'
  import EditDBTestTags from './EditDBTestTags'

  export default {
    name: 'EditDB',

    components: {
      EditDBTestNameInfo,
      EditDBTestCreator,
      EditDBTestDate,
      EditDBTestMaintainer,
      EditDBTestActions,
      EditDBTestSettings,
      EditDBTestImage,
      EditDBTestRelease,
      EditDBTestTags,
    },

    data: function() {
      return {
        disableEditing: true, // disable inputs
        
        // breadcrumbs
        breadCrumbs: [
          {text: this.$i18n.t('AppNavigationMenu.dashboard'), disabled: false, to: '/dashboard', exact: true},
          {text: this.$i18n.t('AppNavigationMenu.edit-tests2'), disabled: false, to: '/dashboard/edit', exact: true},
          {text: '', disabled: true,to: '/dashboard/edit/test-name', exact: true},
        ],
        //dimensions
        windowWidth: 0, // width of the window
        windowHeight: 0, // height of the window
      }
    },

    mounted() {
      // event listener for window size
      this.$nextTick(function() {
        window.addEventListener('resize', this.getWindowWidth);
        window.addEventListener('resize', this.getWindowHeight);

        // Init
        this.getWindowWidth()
        this.getWindowHeight()
      })
      
    },

    created() {
      this.initialize()
    },

    beforeDestroy() {
      // remove eventlisteners when the component is destroyed
      window.removeEventListener('resize', this.getWindowWidth);
      window.removeEventListener('resize', this.getWindowHeight);
    },

    watch: {
      // initialize if data is fetched
      getTestData() {
        this.initialize()
      },
    },
    
    computed: {
      // gets the theme from the vuetify plugin (dark/light)
      theme() {
        return this.$vuetify.theme.dark
      },
      // custom scrollbar theme
      scrollbarTheme() {
        return this.$vuetify.theme.dark ? 'dark' : 'light';
      },
      // test data from the store
      getTestData() {
        // console.log(this.$store.getters.TestData)
        return this.$store.getters.TestData
      },
      // User Object from the store
      UserObject() {
          return this.$store.getters.userData
      },
    },

    methods: {
      // component initialize
      initialize() {
        this.breadCrumbs[2].text = this.getTestData.test_name
        // console.log(this.getTestData.test_creator_id, this.UserObject.usr_id)
        if(this.getTestData.test_creator_id == this.UserObject.usr_id) {
          this.disableEditing = false
        } else {
          this.disableEditing = true
        }
      },
      // get the width of the window
      getWindowWidth() {
        this.windowWidth = document.documentElement.clientWidth;
      },
      // get the height of the window
      getWindowHeight() {
        this.windowHeight = document.documentElement.clientHeight;
      },
    },
  }
</script>

<style scoped>
</style>