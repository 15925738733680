<template>
    <div>
        <v-menu v-model="menu" :close-on-content-click="false" offset-y color="menu">
            <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn icon v-on="{ ...tooltip, ...menu }" v-bind="attrs">
                            <v-badge overlap v-if="cartItems.length > 0" :content="badgeNumber" color="error">
                                <v-icon>mdi-cart</v-icon>
                            </v-badge>
                            <v-icon v-else>mdi-cart</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('testStore.cart') }}</span>
                </v-tooltip>  
            </template>

            <v-card width="1100px">
                <v-card-title class="cardHeader">
                    <div class="white--text">{{ $t('testStore.cart') }}</div>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 600px;" class="scroll pa-0">
                    <v-row v-if="cartItems.length == 0" justify="center" class="mt-10 mx-3">
                        <div>{{ $t('testStore.empty') }}</div>
                    </v-row>
                    <v-list two-line color="card" v-else>
                        <template v-for="(item, index) in cartItems">
                            <v-list-item :key="item.test_id + index">
                                <template>
                                    <v-list-item-icon>
                                        <v-avatar>
                                            <span>{{ item.buy_number }}</span>
                                        </v-avatar>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="item.test_name" class="font-weight-bold"></v-list-item-title>
                                        <v-list-item-subtitle class="text--primary" v-text="item.test_info"></v-list-item-subtitle>
                                        <v-list-item-subtitle v-text="item.test_question_nr + ' ' + $t('testStore.questions') "></v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn text color="error" small @click="removeFromCart(index)">{{ $t('testStore.remove') }}</v-btn>
                                        <v-list-item-action-text v-text="item.test_price.replace('.', ',') + ' €'" class="mr-6"></v-list-item-action-text>
                                    </v-list-item-action>
                                </template>
                            </v-list-item>
                            <v-divider v-if="index < cartItems.length - 1" :key="index"></v-divider>
                        </template>
                    </v-list>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-row align="center" class="mx-1">
                        <div class="mr-2" v-if="cartItems.length != 0">{{ $t('testStore.sum') + ': ' }}</div>
                        <div class="font-weight-bold"  v-if="cartItems.length != 0">{{ cartSumme + ' €'}}</div>
                        <v-btn color="error" outlined class="mx-3" @click="removeAll" v-if="cartItems.length != 0">{{ $t('testStore.remove-all') }}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" class="buttonText--text" :disabled="cartItems.length == 0 ? true : false" @click="checkout">{{ $t('testStore.proceed-to-checkout') }} <v-icon class="ml-2">mdi-chevron-right</v-icon></v-btn>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-menu>
    </div>
</template>

<script>

export default {
    name: 'testStoreCart',


    data: () => {
        return {
            menu: false,
            cartSumme: '0.00',
            badgeNumber: 0,
        }
    },


    mounted() {
        this.$root.$on('buttonEvent', this.BadgeNumber)
        this.$root.$on('buttonEvent', this.cartSum)
        this.BadgeNumber()
        this.cartSum()
    },

    beforeDestroy() {
        this.$root.$off('buttonEvent', this.BadgeNumber)
        this.$root.$off('buttonEvent', this.cartSum)
    },

    computed: {

        // get Cart Items from Store
        cartItems() {
            return this.$store.getters.CartItems
        },

    },

    methods: {

        // calculation of Badge Number
        BadgeNumber() {
            var number = 0
            this.cartItems.forEach(element => {
                number += element.buy_number
            });
            this.badgeNumber = number
        },

         // sum of articles
        cartSum() {
            var sum = 0
            this.cartItems.forEach(element => {
                // console.log(element.buy_number)
                sum += (parseFloat(element.test_price) * parseInt(element.buy_number))
            });
            this.cartSumme = sum.toFixed(2).replace('.',',')
        },

        // checkout with all articles in the shopping cart
        checkout() {
            var items = []
            var i = 0
            this.cartItems.forEach(element => {
                items[i] = element
                i++
            });
            // console.log(items)
            this.$store.dispatch('getBuyItems', items)
            this.$store.dispatch('getCurrentMenu', 'testStoreBuy')
            this.$router.push('/dashboard/store/buy/article-overview')
            this.menu = false
        },

        // remove Item from Cart
        removeFromCart: function(number) {
            this.$store.dispatch('getRemoveCartItem', number)
            this.cartSum()
        },

        // remove all items from Cart
        removeAll() {
            this.$store.dispatch('getRemoveAll')
            this.cartSum()
        },
    }

};

</script>

<style scoped>
  .scroll {
    overflow-y: auto
  }
</style>