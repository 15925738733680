<template>
  <v-card tile elevation="0" color="rgba(0,0,0,0)" class="pl-3 pr-7 scroll">
    <v-container fluid>
      <v-row align="start" justify="center" no-gutters>
        <v-card width="1700" elevation="0" color="rgba(0,0,0,0)">
          <v-row>
            <v-col xs="12" sm="12" md="12" lg="12" xl="12" class="pt-4">
              <!-- multiple choice question card -->
              <QuestionsHeader :getAllQuestions="getAllQuestions" :iterator="iterator" :assists="assists" :testMode="testMode" :checkInput="checkInput" :noLoad="false"></QuestionsHeader>
            </v-col>
          </v-row>
        </v-card>
      </v-row>
    </v-container>

    <!-- bottom navigation in fixed footer -->
    <v-footer padless fixed app inset elevation="3">
      <!-- help button -->
      <QuestionsHelp></QuestionsHelp>
      <!-- mark button -->
      <v-btn class="mb-1 mx-3" @click="markQuestion" :color="markCheckbox? '#FFC107' : '' " icon>
        <v-icon>{{markCheckbox? 'mdi-star' : 'mdi-star-outline' }}</v-icon>
      </v-btn>
      <!-- elapsed time -->
      <div v-if="testMode == 'question_limit' || testMode == 'exam_preparation'" class="mx-3">
        <div class="header center font-weight-regular">{{ $t('Questions.elapsed') }}</div>
        <div class="title center">{{ time }}</div>
      </div>
      <!-- remaining time -->
      <div v-if="testMode == 'time_limit' || testMode == 'exam_preparation'">
        <div class="header center font-weight-regular">{{ $t('Questions.remaining') }}</div>
        <div :class="timeColor">{{ timeRemaining }}</div>
      </div>
      <v-spacer></v-spacer>
      <!-- button to check the users chosen answers -->
      <v-btn class="mx-2 mb-1" @click="checkUserInput" :disabled="disabled" outlined v-if="testMode != 'exam_preparation'"><v-icon v-if="disabled" class="mr-3">mdi-checkbox-marked</v-icon>{{ $t('Questions.check') }}</v-btn>
      <!-- End Test on last Question -->
      <v-btn color="success" class="mr-2 mb-1" @click="endTestDialog = true" v-if="questionNumber == this.iterator+1">
        {{ $t('Questions.end-test') }}
      </v-btn>
      <!-- see which question the user is in -->
      <div class="mb-1" style="border: solid; border-radius: 4px; border-width: 1px; height: 36px">
        <v-row class="mx-3" align="center">
          <span style="margin-bottom: 2px">{{ iterator +1 }}</span>
          <div style="border-left: solid; height: 35px; border-width: 1px;" class="mx-3"></div>
          <span style="margin-bottom: 2px">{{ $t('Questions.from') + ' ' + questionNumber }}</span>
        </v-row>
      </div>
      <!-- button to jump to previous question -->
      <v-btn fab small class="mx-2 mb-1 elevation-3" @click="iterator -= 1" :disabled="iterator == 0" color="primary">
        <v-icon color="buttonText">mdi-chevron-left</v-icon>
      </v-btn>
      <!-- button to jump to the next question -->
      <v-btn fab small class="mx-3 mb-1 elevation-3" @click="iterator += 1" :disabled="iterator+2 > questionNumber" color="primary">
        <v-icon color="buttonText">mdi-chevron-right</v-icon>
      </v-btn>
    </v-footer>

    <!-- navigation element for test -->
    <v-navigation-drawer v-model="drawer" absolute right color="navigation" class="drawer"  app overflow style="position: fixed; right: 0">
      <v-container class="mt-1">
        <v-row class="px-8">
          <v-icon large>mdi-menu-down</v-icon>
          <v-spacer></v-spacer>
          <v-icon large>mdi-menu-down</v-icon>
          <v-spacer></v-spacer>
          <v-icon large>mdi-menu-down</v-icon>
        </v-row>
        <v-row justify="center" class="mb-3">
          <!-- End Test on last Question -->
          <v-btn color="success" class="mr-2 mt-6" @click="endTestDialog = true" outlined small>
            {{ $t('Questions.end-test') }}
          </v-btn>
        </v-row>
        <v-row justify="center">
          <div class="title font-weight-light">{{ $t('Questions.question-overview')}}</div>
        </v-row>
        <v-row v-if="allQuestions.length > 0" class="px-2 mt-6">
          <v-col v-for="i in questionNumber" :key="i" align="center" class="my-0 pa-0">
            <v-card elevation="0" height="40px" width="28px" class="mx-1 mb-2 pa-0" v-if="testMode == 'exam_preparation' || testMode == 'question_limit' || maxIterator >= i-1" color="navigation">
              <v-card height="34px" width="28px" @click="iterator = i - 1" :class="allQuestions[i-1].outlinedProgress">
                <div style="text-align:center" class="mt-1">{{i}}</div>
              </v-card>
              <hr class="solid mt-1" v-if="iterator == i - 1">
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-navigation-drawer>
    <!-- bar to show/hide test navigation -->
    <v-card :style="{height: windowHeight - 66 + 'px', width: '16px', position: 'fixed', top: '65px', right: drawer? '256px' : '0px'}" @click.stop="drawer = !drawer" class="subNavigation" tile elevation="0"></v-card>
    <v-icon v-if="drawer" :style="{position: 'fixed', right: drawer? '252px' : '-4px', top: '50%'}" @click.stop="drawer = !drawer" tile elevation="0" color="navText--text" disabled>mdi-menu-right</v-icon>
    <v-icon v-else :style="{position: 'fixed', right: drawer? '252px' : '-4px', top: '50%'}" @click.stop="drawer = !drawer" tile elevation="0" color="navText--text" disabled>mdi-menu-left</v-icon>

    <!-- endscreen dialog -->
    <v-dialog v-model="endDialog" fullscreen hide-overlay :transition="false">
      <v-card tile color="background">
        <!-- navigation toolbar -->
        <v-toolbar dark color="primary">
          <v-toolbar-title class="buttonText--text font-weight-medium">{{ $t('Questions.results') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false, returnToExercises()" class="buttonText--text">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text :style="{height: windowHeight-64 + 'px'}" class="scroll" v-if="testMode2 != 'exam_mode'">
          <v-row align="center" justify="center">
            <!-- result statistics -->
            <v-card elevation="0" class="ma-5" width="1525" color="rgba(0,0,0,0)">
              <v-list two-line subheader color="rgba(0,0,0,0)">
                <v-subheader>{{ $t('Questions.test-statistics') }}</v-subheader>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('Questions.percentage-right') }}</v-list-item-title>
                    <v-row>
                      <v-col cols="4">
                        <br><br>
                        <div class="display-3 font-weight-medium">{{ Number.parseFloat(rightPercentage).toPrecision(3) + '%' }}</div>
                      </v-col>
                      <v-col cols="4">
                        <!-- <resultChart :data="chartData" :options="chartOptions"></resultChart> -->
                        <v-progress-circular v-if="getTestData.point_system == 'point'" size="300" :value="rightPercentage" :rotate="-90" width="50" color="primary">{{ points + '/' + possiblePoints + ' ' + $t('Questions.points') }}</v-progress-circular>
                        <v-progress-circular v-if="getTestData.point_system == 'question'" size="300" :value="rightPercentage" :rotate="-90" width="50" color="primary">{{ chartdata[0] + '/' + possiblePoints + ' ' + $t('Questions.points') }}</v-progress-circular>
                        <v-progress-circular v-if="getTestData.point_system == 'macedonia'" size="300" :value="rightPercentage" :rotate="-90" width="50" color="primary">{{ macPoints + '/' + possiblePoints + ' ' + $t('Questions.points') }}</v-progress-circular>
                      </v-col>
                      <v-col cols="4"></v-col>
                    </v-row>
                    <v-list-item-subtitle>{{ resultMessage }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-row>
          <!-- test results in test visualization -->
          <QuestionsHeader :getAllQuestions="allQuestions" :iterator="iterator" :assists="assists" :testMode="testMode" :checkInput="checkInput" :noLoad="true"></QuestionsHeader>
          
          <v-container fluid v-if="endDialog == true">
            <v-row align="start" justify="center" no-gutters>
              <v-card width="1700" elevation="0" color="background">
              <v-row>
              <!-- multiple choice question card -->
              <v-col xs="10" sm="10" md="10" lg="10" xl="10">
                <v-card color="card" elevation="2" class="my-5" >

                  <!-- bottom navigation -->
                  <v-card-actions>
                    <!-- help button -->
                    <helpScreen></helpScreen>
                    <v-spacer></v-spacer>
                    <!-- button to jump to previous question -->
                    <v-btn icon class="mx-2 mb-2" @click="iterator -= 1" color="primary" outlined v-if="iterator > 0">
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <!-- button to jump to the next question -->
                    <v-btn icon class="mr-2 mb-2" @click="iterator += 1" color="primary" outlined v-if="questionNumber > iterator+1">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>

              <!-- question progress and time on the right side of panel -->
              <v-col xs="2" sm="2" md="2" lg="2" xl="2">
                <v-card color="rgba(255, 0, 0, 0.0)" elevation="0" class="ml-0 my-0 mr-0 mt-3" width="220" height="700">
                  <!-- element that shows the elapsed time of the current test -->
                  <v-container>
                    <v-row>
                      <v-col align="center">
                        <v-card class="px-2 mb-10 mt-0" color="rgba(255, 0, 0, 0.0)" elevation="0">
                          <v-row>
                            <v-col v-if="testMode == 'question_limit' || testMode == 'exam_preparation'">
                              <div class="header center font-weight-regular">{{ $t('Questions.elapsed') }}</div>
                              <div class="title center">{{ endTime[0] }}</div>
                            </v-col>
                            <v-col v-if="testMode == 'time_limit' || testMode == 'exam_preparation'">
                              <div class="header center font-weight-regular">{{ $t('Questions.remaining') }}</div>
                              <div :class="timeColor">{{ endTime[1] }}</div>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                  <!-- navigation element for test -->
                  <v-container>
                    <v-row v-if="allQuestions.length > 0">
                      <v-col v-for="i in questionNumber" :key="i" align="center" class="ma-0 pa-0">
                        <v-card elevation="0" height="40px" width="28px" class="mx-1 mb-2 pa-0" v-if="testMode == 'exam_preparation' || testMode == 'question_limit' || maxIterator >= i-1">
                          <v-card height="34px" width="28px" @click="iterator = i - 1" :class="allQuestions[i-1].outlinedProgress">
                            <div style="text-align:center" class="mt-1">{{i}}</div>
                          </v-card>
                          <hr class="solid mt-1" v-if="iterator == i - 1">
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
              </v-row>
              </v-card>
            </v-row>
          </v-container>
        </v-card-text>
        <!-- screen for exam mode end -->
        <v-card-text v-else>
          <br><br><br><br>
          <div class="title ml-12">Congratulation!</div>
          <div class="ml-12">Thank you for participating.</div>
          <div class="ml-12">Your Exam Results will be visible for you soon.</div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- warning dialog when ending the test -->
    <v-dialog v-model="endTestDialog" max-width="340">
      <v-card>
        <v-card-title>{{ $t('Questions.submit-test') }}</v-card-title>
        <v-card-text>{{ $t('Questions.submit-info') }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="endTestDialog = false">{{ $t('Questions.stay') }}</v-btn>
          <v-btn color="success" text @click="endTest">{{ $t('Questions.submit') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
  import QuestionsHeader from './QuestionsHeader';
  import QuestionsHelp from './QuestionsHelp';

  export default {
    name: 'Questions',

    components: {
      QuestionsHeader,
      QuestionsHelp,
    },

    data: () => {
      return {
        iterator: 0, // equals the current question number
        oldIterator: 0, // equals the question number of the question that was answered before
        maxIterator: 0, // equals the highest reached iterator during the exercise

        assists: true, // wether the number of right questions assist is shown

        allQuestions: [], // array with all questions 

        disabled: false, // set to true after cklicking "check" button, used to disable user input
        right: 0, // used to calculate how many answers were correctly ticked
        points: 0, // for the alternative point system
        macPoints: 0, // for the macedonian point system
        possiblePoints: 0,
        rightPercentage: null, // percentage of right answers
        checkInput: false, // stores if the check button was clicked

        markCheckbox: false, // checkbox to mark the question

        endTestDialog: false, // warning dialog when ending the test
        endDialog: false, // navigation variable to handle the end dialog state
        resultMessage: '', // message that is shown on the result-screen

        windowWidth: 0, // width of the window
        windowHeight: 0, // height of the window

        // result chart variables
        chartdata: [0, 0], // number of right answers, number of wrong answers

        // variables for right side card
        time: '00:00', // elapsed time
        timeRemaining: '', // remaining time
        endTime: [], // time when the test ended
        timeColor: 'title center', // color of remaining Time
        interval: null, // interval handling variable for calculation of elapsed time
        interval2: null, // interval handling variable for calculation of remaining time

        start_date: new Date().toISOString().substr(0, 10), // date Variable for datepicker
        start_time: new Date().toLocaleTimeString('en-US', {  hour12: false,
                                                        hour: "numeric",
                                                        minute: "numeric"}), // time Variable for time Picker

        drawer: null, // drawer variable for question overview
      }
    },

    beforeRouteLeave (to, from, next) {
      if(!this.endDialog) {
        const answer = window.confirm(this.$i18n.t('Questions.leave-info'))
        if (answer) {
          next()
        } else {
          next(false)
        }
      } else {
        next()
      }
    },

    watch: {

      CurrentQuestion() {
        if(!this.endDialog) {
          this.saveProgress() // write user input into DB for the last solved question
        }
      },
      // when iterator changes the new current question get's set
      iterator() {
        this.$router.push({params: {iterator: 'Question-' + parseInt(this.iterator +1)}}) // alter route on iterator change
        // set the max Iterator for the timelimited mode
        if(this.iterator > this.oldIterator && this.iterator > this.maxIterator) {
          this.maxIterator = this.iterator
        }
        var numberOfTicked = this.allQuestions[this.oldIterator].answers.filter(x => x.selected == 1).length
        // changing outlines of current question in the exam mode navigation (right side) after a new question is shown and the old one was worked on
        if((this.testMode == 'exam_preparation' || this.testMode == 'time_limit') && numberOfTicked > 0 || this.allQuestions[this.oldIterator].typ == "sor") {
          // check if the question is marked (marked color is more important)
          if(this.allQuestions[this.oldIterator].markCheckbox != true && this.endDialog == false) {
            this.allQuestions[this.oldIterator].outlinedProgress = 'outlinedChecked'
          }
        }
        this.oldIterator = this.iterator
        // set the checked status of mark button according to the currently shown question
        this.markCheckbox = this.allQuestions[this.iterator].markCheckbox
      },
      getAllQuestions() {
        // set all questions array with all the test data
        this.allQuestions = this.getAllQuestions
      },
      // watch remaining time
      timeRemaining() {
        // when the time is over in the timelimited mode(s) the test ends
        if(this.timeRemaining == '00:00') {
          if(this.testMode == 'exam_preparation' || this.testMode == 'time_limit') {
            this.endTest()
          }
        }
      },

    },

    mounted() {
      // set interval that calculates elapsed time
      this.interval = setInterval(this.incrementTime, 1000)
      // set interval that calculates remaining time
      this.interval2 = setInterval(this.decrementTime, 1000)
      // set initial remaining time
      if(this.$store.getters.mode == 'exam_mode') {
        this.timeRemaining = this.getTimeLimit + ':' + '00'
      } else if(this.testMode == 'exam_preparation') {
        this.timeRemaining = this.getTestData.test_duration + ':' + '00'
      } else {
        this.timeRemaining = this.getTimeLimit + ':' + '00'
      }

      // event listener for window size
      this.$nextTick(function() {
        window.addEventListener('resize', this.getWindowWidth);
        window.addEventListener('resize', this.getWindowHeight);

        // Init
        this.getWindowWidth();
        this.getWindowHeight();
      });

      // get the assist status from store when mounted
      this.getAssists();
    },

    beforeDestroy() {

      // clear the interval that increments/decrements the time
      clearInterval(this.interval)
      clearInterval(this.interval2)

      // remove eventlisteners when the component is destroyed
      window.removeEventListener('resize', this.getWindowWidth);
      window.removeEventListener('resize', this.getWindowHeight);
    },

    computed: {

      // gets the theme from the vuetify plugin (dark/light)
      theme() {
        return this.$vuetify.theme.dark
      },
      // get questions array from store
      getAllQuestions() {
          // console.log('QuestionData:', this.$store.getters.allQuestions.exam_data)
          return this.$store.getters.allQuestions.exam_data
      },
      // gets the number of questions from the store
      questionNumber() {
        // console.log(this.$store.getters.questionNumber)
        return this.$store.getters.questionNumber
      },
      // get the time limit from the store
      getTimeLimit() {
        return this.$store.getters.timeLimit
      },
      // get Test Mode from Store (with altering)
      testMode() {
        // console.log(this.$store.getters.mode)
        if(this.$store.getters.mode == 'exam_mode'){
          return 'exam_preparation'
        } else {
          return this.$store.getters.mode
        }
      },
      // get test mode from store (without altering)
      testMode2() {
        return this.$store.getters.mode
      },
      // get the selected test from store
      getTestData() {
        // console.log(this.$store.getters.TestData)
        return this.$store.getters.TestData
      },
      // User Object from the store
      UserObject() {
          return this.$store.getters.userData
      },
      // Exam Key from the Store
      KeyObject() {
          return this.$store.getters.examKey
      },
      // current question from store
      CurrentQuestion() {
        return this.$store.getters.currentQuestion
      },
      

    },

    methods: {
      // function to calculate the users results
      validateResults() {
        // iterate through all questions + their answers
        this.allQuestions.forEach(element => {
          if(element.typ == "mul") {
            let comparison = [0, 0, 0, 0, 0, 0]
            let right = 0
            // iterate through all answers of one question and compare user input with DB values
            element.answers.forEach((el, i) => {
              if(el.selected == el.state) {
                comparison[i] = 1;
                if(el.state == 1) {
                  right += 1
                }
              }
            })
            // COLOR CARDS FOR THE RESULT SCREEN
            // show "no rating" message when to many answers where ticked
            if(element.number_right == right && element.answers.filter(choice => choice.selected == 1).length > element.number_right) {
              element.alternateResults = true
            } else {
              element.alternateResults = false
            }
            // color the checkboxes depending on the correctness of the user input
            element.answers.forEach((val,x) => {
              if(comparison[x] == 1 && val.selected == 1) {
                val.userCheck = 'right'
              } else {
                  if(val.selected == 1) {
                    val.userCheck = 'false'
                  } else {
                    val.userCheck = 'blank'
                  }
              }
            });
            // outline of the inner card after clicking "check" (shows wether the answers are true or false)
            element.answers.forEach(ans => {
              if(ans.state == 1) {
                ans.outline = 'outlinedTrue'
                ans.badgeIcon = 'mdi-check'
                ans.badgeColor = 'green'
              } else {
                ans.outline = 'outlinedFalse'
                ans.badgeIcon = 'mdi-close'
                ans.badgeColor = 'red'
              }
            });
            // calculate points with the different systems
            this.points += this.calcNormalPoints([right, element])
            this.macPoints += this.calcMacedonianPoints([right, element]);
            this.chartdata[0] += this.calcQuestionPoints([right, element]);
            // color the navigation card of the endscreen
            if(right == element.number_right && element.answers.filter(choice => choice.selected == 1).length == element.number_right) {
              element.outlinedProgress = 'outlinedGreen'
            } else if(right == element.number_right && element.answers.filter(choice => choice.selected == 1).length > element.number_right) {
              element.outlinedProgress = 'outlinedMarked'
            } else {
              element.outlinedProgress = 'outlinedRed'
            }
            // save the result to the questions Object
            element.userRight = right
            element.checked = true
          } else {
            // compare user input with the right answers from the DB
            let comparison = [0, 0, 0, 0, 0, 0]
            let right = 0
            element.answers.forEach( (element,i) => {
                if(element.rightPosition+1 == element.userPosition) {
                    comparison[i] = 1
                    right += 1
                    element['badgeColor'] = 'green'
                    element['badgeIcon'] = 'mdi-check'
                    element['outline'] = 'outlinedTrue'
                } else {
                    element['badgeColor'] = 'red'
                    element['badgeIcon'] = 'mdi-close'
                    element['outline'] = 'outlinedFalse'
                }
            })
            // calculate points with the different systems
            this.points += this.calcNormalPoints([right, element])
            this.macPoints += this.calcMacedonianPoints([right, element]);
            this.chartdata[0] += this.calcQuestionPoints([right, element]);
            // color the navigation card of the endscreen
            if(right == element.answers.length) {
              element.outlinedProgress = 'outlinedGreen'
            }  else {
              element.outlinedProgress = 'outlinedRed'
            }
            element.checked = true // save that the answer got checked
            element.userRight = right // save the result to the questions Object
            element.number_right = element.answers.length // how many answers are right
          }
        })
      },
      // calculate points with the point system
      calcNormalPoints: function(val) {
        if(val[1].typ == "mul") {
          let numberPoints = val[0] - (val[1].answers.filter(choice => choice.selected == 1).length - val[0])
          if(numberPoints > 0) {
            return parseFloat(numberPoints / val[1].number_right)
          } else {
            return 0
          }
        } else {
          let numberPoints = val[0] - (val[1].answers.length - val[0])
          if(numberPoints > 0) {
            return parseFloat(numberPoints / val[1].answers.length)
          } else {
            return 0
          }
        }
      },
      // calculate points with macedonian system
      calcMacedonianPoints: function(val) {
        if(val[1].typ == "mul") {
          if((val[1].answers.filter(choice => choice.selected == 1)).length == parseInt(val[1].number_right)) {
            if(val[0] == parseInt(val[1].number_right)) {
              return 1
            } else if (val[0] == parseInt(val[1].number_right) - 1) {
              return 0.5
            } else {
              return 0
            }
          } else {
            return 0
          }
        } else {
          if(val[0] == val[1].answers.length) {
            return 1
          } else if (val[0] >= val[1].answers.length / 2) {
            return 0.5
          } else {
            return 0
          }
        }
      },
      // calculate points with all or nothing system
      calcQuestionPoints: function(val) {
        if(val[1].typ == "mul") {
          if(val[0] == val[1].number_right && val[1].answers.filter(choice => choice.selected == 1).length == val[1].number_right) {
            return 1
          } else {
            return 0
          }
        } else {
          if(val[0] == val[1].answers.length) {
            return 1
          } else {
            return 0
          }
        }
      },
      // save the progress during the test
      saveProgress() {
        var progressObject = {}
        progressObject.progression_id = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) ),
        progressObject.usr_id = this.UserObject.usr_id
        progressObject.test_id = this.getTestData.test_id
        progressObject.start_time = this.start_date + ' ' + this.start_time + ':00'
        progressObject.test_mode = this.$store.getters.mode
        progressObject.question_id = this.CurrentQuestion.question_id
        progressObject.answer_1_usr = null
        progressObject.answer_2_usr = null
        progressObject.answer_3_usr = null
        progressObject.answer_4_usr = null
        progressObject.answer_5_usr = null
        progressObject.answer_6_usr = null
        if(this.CurrentQuestion.typ == "mul") {
          this.CurrentQuestion.answers.forEach(element => {
            switch(element.id) {
              case 0:
                progressObject.answer_1_usr = element.selected
                break;
              case 1:
                progressObject.answer_2_usr = element.selected
                break;
              case 2:
                progressObject.answer_3_usr = element.selected
                break;
              case 3:
                progressObject.answer_4_usr = element.selected
                break;
              case 4:
                progressObject.answer_5_usr = element.selected
                break;
              case 5:
                progressObject.answer_6_usr = element.selected
                break;
            }
          });
        } else {
          this.CurrentQuestion.answers.forEach(element => {
            switch(element.id) {
              case 0:
                progressObject.answer_1_usr = element.userPosition
                break;
              case 1:
                progressObject.answer_2_usr = element.userPosition
                break;
              case 2:
                progressObject.answer_3_usr = element.userPosition
                break;
              case 3:
                progressObject.answer_4_usr = element.userPosition
                break;
              case 4:
                progressObject.answer_5_usr = element.userPosition
                break;
              case 5:
                progressObject.answer_6_usr = element.userPosition
                break;
            }
          });
        }
        // console.log(progressObject)
        this.$http.post('index.php',  { task:           'write_progress',
                                        progressObject: progressObject,
                                      })
            // .then(response => {
            //   console.log(response.body)
            // });
      },
      // method that ends the test and shows the results
      endTest() {
        // clear test time intervals
        clearInterval(this.interval)
        clearInterval(this.interval2)
        // different test endings for the 3 different test modes
        // QUESTION LIMIT MODE
        if(this.testMode == 'question_limit') {
          this.validateResults()
          this.chartdata[1] = this.questionNumber - this.chartdata[0] // calculation of wrong answers
          if(this.getTestData.point_system == 'question') {
            this.rightPercentage = (this.chartdata[0] / (this.chartdata[0] + this.chartdata[1])) * 100 // calculation of right percentage
          } else if(this.getTestData.point_system == 'point') {
            this.rightPercentage = (this.points / (this.chartdata[0] + this.chartdata[1])) * 100 // calculation of right percentage
          } else {
            this.rightPercentage = (this.macPoints / (this.chartdata[0] + this.chartdata[1])) * 100 // calculation of right percentage
          }
          // number of points that were possible to archieve
          this.possiblePoints = this.chartdata[0] + this.chartdata[1]
          // calculate corresponding result message
          if(this.rightPercentage >= 50) {
            this.resultMessage = this.$t('Questions.congratulations') + Number.parseFloat(this.rightPercentage).toPrecision(3) + this.$t('Questions.percent-right1')
          } else {
            this.resultMessage = this.$t('Questions.you-got') + Number.parseFloat(this.rightPercentage).toPrecision(3) + this.$t('Questions.percent-right2')
          }
        // TIME LIMIT MODE
        } else if(this.testMode == 'time_limit') {
          this.validateResults()
          this.chartdata[1] = this.maxIterator +1 - this.chartdata[0] // calculation of wrong answers
          if(this.getTestData.point_system == 'question') {
            this.rightPercentage = (this.chartdata[0] / (this.maxIterator +1)) * 100 // calculation of right percentage
          } else if(this.getTestData.point_system == 'point') {
            this.rightPercentage = (this.points / (this.maxIterator +1)) * 100 // calculation of right percentage
          } else {
            this.rightPercentage = (this.macPoints / (this.maxIterator +1)) * 100 // calculation of right percentage
          }

          this.possiblePoints = this.maxIterator +1
          // calculate corresponding result message
          if(this.rightPercentage >= 50) {
            this.resultMessage = this.$t('Questions.congratulations') + Number.parseFloat(this.rightPercentage).toPrecision(3) + this.$t('Questions.percent-right1')
          } else {
            this.resultMessage = this.$t('Questions.you-got') + Number.parseFloat(this.rightPercentage).toPrecision(3) + this.$t('Questions.percent-right2')
          }
        // EXAM MODE
        } else {
          this.validateResults()
          this.chartdata[1] = this.questionNumber - this.chartdata[0] // calculation of wrong answers
          if(this.getTestData.point_system == 'question') {
            this.rightPercentage = (this.chartdata[0] / (this.chartdata[0] + this.chartdata[1])) * 100 // calculation of right percentage
          } else if(this.getTestData.point_system == 'point') {
            this.rightPercentage = (this.points / (this.chartdata[0] + this.chartdata[1])) * 100 // calculation of right percentage
          } else {
            this.rightPercentage = (this.macPoints / (this.chartdata[0] + this.chartdata[1])) * 100 // calculation of right percentage
          }
          // CALUCLATION OF POSSIBLE POINTS
          this.possiblePoints = this.chartdata[0] + this.chartdata[1]
          // calculate corresponding result message
          if(this.rightPercentage >= 50) {
            this.resultMessage = this.$t('Questions.congratulations') + Number.parseFloat(this.rightPercentage).toPrecision(3) + this.$t('Questions.percent-right1')
          } else {
            this.resultMessage = this.$t('Questions.you-got') + Number.parseFloat(this.rightPercentage).toPrecision(3) + this.$t('Questions.percent-right2')
          }
        }

        // open the result/end dialog of the current test
        this.iterator = 0
        this.disabled = true
        this.endTime = [this.time, this.timeRemaining]
        this.endDialog = true

        // write results of test into DB
        var resultObject =  {
                              result_id: ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) ),
                              test_name: this.getTestData.test_name,
                              test_id: this.getTestData.test_id,
                              usr_name: this.UserObject.usr_name,
                              usr_id: this.UserObject.usr_id,
                              points: this.chartdata[0],
                              part_points: this.points.toFixed(2),
                              part_points_macedonia: this.macPoints.toFixed(1),
                              possible_points: this.possiblePoints,
                              percentage: this.rightPercentage,
                              test_mode: this.$store.getters.mode,
                              duration: this.time,
                              start_time: this.start_date + ' ' + this.start_time + ':00',
                            }
        var key
        if(this.$store.getters.mode == 'exam_mode') {
          key = this.KeyObject
        } else {
          key = ''
        }
        this.$http.post('index.php',  { task:         'write_results',
                                        resultObject: resultObject,
                                        key:          key,
                                        mode:         this.$store.getters.mode,
                                      })
            // .then(response => {
            //   console.log(response.body)
            // });
        this.$store.dispatch('getCurrentQuestion', '') // clear the current question in store
      },
      // mark question(s) with button
      markQuestion() {
        // console.log(this.allQuestions[this.iterator].markCheckbox)
        if(this.allQuestions[this.iterator].markCheckbox == false) {
          this.markCheckbox = true
          this.allQuestions[this.iterator].markCheckbox = true
          this.allQuestions[this.iterator].outlinedProgress = 'outlinedMarked'
        } else if(this.allQuestions[this.iterator].checked == true) {
          this.markCheckbox = false
          this.allQuestions[this.iterator].markCheckbox = false
          this.allQuestions[this.iterator].outlinedProgress = 'outlinedChecked'
        } else {
          this.markCheckbox = false
          this.allQuestions[this.iterator].markCheckbox = false
          this.allQuestions[this.iterator].outlinedProgress = 'outlined'
        }
      },
      // calculation of elapsed time
      incrementTime() {
        var minutes = this.time.slice(0, -3)
        var seconds = this.time.slice(this.time.length -2)
        seconds = parseInt(seconds) + 1
        if(seconds % 60 == 0) {
          minutes = parseInt(minutes) + 1
          seconds = 0
        }
        minutes = minutes.toString()
        if (minutes.length < 2) {
          minutes = '0' + minutes
        }
        seconds = seconds.toString()
        if (seconds.length < 2) {
          seconds = '0' + seconds
        }
        this.time = minutes + ':' + seconds
      },
      // calculation of remaining time
      decrementTime() {
        if(this.testMode != 'question_limit') {
          var remainingSeconds = parseInt(this.timeRemaining.slice(this.timeRemaining.length -2))
          var remainingMinutes = parseInt(this.timeRemaining.slice(0, -3))
          if(remainingMinutes < 10) {
            this.timeColor = 'title center red--text'
          }
          if(remainingSeconds % 60 != 0) {
            remainingSeconds = remainingSeconds - 1
          } else if(remainingSeconds % 60 == 0) {
            remainingMinutes = remainingMinutes - 1
            remainingSeconds = 59
          }
          remainingMinutes = remainingMinutes.toString()
          if (remainingMinutes.length < 2) {
            remainingMinutes = '0' + remainingMinutes
          }
          remainingSeconds = remainingSeconds.toString()
          if (remainingSeconds.length < 2) {
            remainingSeconds = '0' + remainingSeconds
          }
          this.timeRemaining = remainingMinutes + ':' + remainingSeconds
        }
      },
      // get info from store if the test is assisted
      getAssists() {
        this.assists = this.$store.getters.assists
      },
      // go back to exercises startscreen
      returnToExercises() {
        this.$store.dispatch('getIsEditing', false)
        if(this.$store.getters.mode == 'exam_mode') {
          this.$router.push({path: '/dashboard/exams/participate'})
        } else {
          this.$router.push({path: '/dashboard'})
        }
      },
      // check the user input after clicking "check"-button (compare with right answers from DB)
      checkUserInput() {
        // console.log(this.checkInput)
        this.checkInput = !this.checkInput
      },
      // get the width of the window
      getWindowWidth() {
        this.windowWidth = document.documentElement.clientWidth;
      },
      // get the height of the window
      getWindowHeight() {
        this.windowHeight = document.documentElement.clientHeight;
      },

    },

  }
</script>

<style>
  .v-card.outlined {
    border: 1px solid #9E9E9E;
    border-radius:4px;
    height: 56px;
    width: 56px;
  }

  .v-card.outlinedChecked {
    border: 2px solid #1976D2;
    border-radius:4px;
    height: 56px;
    width: 56px;
    background-color: rgba(25, 118, 210, 0.15);
  }

    .v-card.outlinedMarked {
    border: 2px solid #FFC107;
    border-radius:4px;
    height: 56px;
    width: 56px;
    background-color: rgba(255, 193, 7, 0.15);
  }

  .v-card.outlinedGreen {
    border: 2px solid green;
    border-radius:4px;
    height: 56px;
    width: 56px;
    background-color: rgba(0, 128, 0, 0.15);
  }
  .v-card.outlinedRed {
    border: 2px solid red;
    border-radius:4px;
    height: 56px;
    width: 56px;
    background-color: rgba(255, 0, 0, 0.15);
  }

  .v-card.outlinedFalse {
    border: 2px dashed #F44336;
    border-radius:4px;
    height: 56px;
    width: 56px;
  }

  .v-card.outlinedTrue {
    border: 2px solid #4CAF50;
    border-radius:4px;
    height: 56px;
    width: 56px;
  }

  .center {
    text-align: center;
  }

  .v-card__text, .v-card__title {
    word-break: normal; /* maybe !important  */
  }

  .scroll {
    overflow-y: auto
  }
</style>