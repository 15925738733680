<template>
    <v-footer app :style="{'border-top': 'solid', 'border-width': '1px', 'border-color': $vuetify.theme.dark ? '#3b3b3b' : '#d2d2d2', 'z-index': 5}">
        <v-layout row wrap>
            <v-flex class="py-0 text-center">
                <v-btn text exact to="/mobile/" max-width="70px" min-width="70px" :ripple="false" active-class="no-active">
                    <v-col cols="12" class="pa-0 text-center">
                        <v-icon>{{ $route.name == 'HomeMobile' ? 'mdi-home' : 'mdi-home-outline' }}</v-icon>
                        <div style="font-size: 10px">Home</div>
                    </v-col>
                </v-btn>
            </v-flex>
            <v-flex class="py-0 text-center">
                <v-btn text exact to="/mobile/dashboard/store" max-width="70px" min-width="70px" :ripple="false" active-class="no-active">
                    <v-col cols="12" class="pa-0 text-center">
                        <v-icon>{{ $route.name == 'testStoreMobile' || $route.name == 'testStoreDetailsMobile' || $route.name == 'testStoreBuyMobile' ? 'mdi-cart' : 'mdi-cart-outline' }}</v-icon>
                        <div style="font-size: 10px">{{ $t('AppNavigationMenu.test-store') }}</div>
                    </v-col>
                </v-btn>
            </v-flex>
            <v-flex class="py-0 text-center">
                <v-btn icon outlined exact to="/mobile/dashboard/" :ripple="false" active-class="no-active" :color="$vuetify.theme.isDark ? '#FFFFFF' : '#202020'">
                    <v-icon>{{ $route.name == 'DashboardMobile' ? 'mdi-view-dashboard' : 'mdi-view-dashboard-outline' }}</v-icon>
                </v-btn>
            </v-flex>
            <v-flex class="py-0 text-center">
                <v-btn text exact to="/mobile/dashboard/exercises" max-width="70px" min-width="70px" :ripple="false" active-class="no-active">
                    <v-col cols="12" class="pa-0 text-center">
                        <v-icon>{{ $route.name == 'TestListMobile' || $route.name == 'ExercisesMobile' ? 'mdi-folder-open' : 'mdi-folder-outline' }}</v-icon>
                        <div style="font-size: 10px">{{ $t('AppNavigationMenu.my-tests') }}</div>
                    </v-col>
                </v-btn>
            </v-flex>
            <v-flex class="py-0 text-center">
                <v-btn text exact to="/mobile/dashboard/statistics" max-width="70px" min-width="70px" :ripple="false" active-class="no-active">
                    <v-col cols="12" class="pa-0 text-center">
                        <v-icon>{{ $route.name == 'StatisticsMobile' ? 'mdi-chart-box' : 'mdi-chart-box-outline' }}</v-icon>
                        <div style="font-size: 10px">{{ $t('AppNavigationMenu.statistics') }}</div>
                    </v-col>
                </v-btn>
            </v-flex>
        </v-layout>
    </v-footer>
</template>
<script>
export default {
    name: 'AppNavigationMenuMobile',

    data: function() {
        return {
            //
        }
    },

    methods: {
        //
    },
}
</script>

<style>
    .v-btn--active.no-active::before {
        opacity: 0 !important;
    }
</style>