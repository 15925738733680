<template>
    <!-- activate key dialog -->
    <v-dialog v-model="dialog" width="600px" :fullscreen="isMobile">
        <template v-slot:activator="{ on }">
            <v-btn v-if="btnStyle == 'desktop'" color="primary" outlined v-on="on" :disabled="!SuccessfullLogin">{{ $t('testStore.activate-key') }}</v-btn>
            <v-btn @click.prevent="dialog=true" :disabled="!SuccessfullLogin" v-if="btnStyle == 'dashboard'" absolute color="#689401" class="white--text" fab right top><v-icon>mdi-key</v-icon></v-btn>
            <v-btn @click.prevent="dialog=true" :disabled="!SuccessfullLogin" v-if="btnStyle == 'dashboardMobile'" absolute color="#689401" class="white--text" fab right top small><v-icon>mdi-key</v-icon></v-btn>
            <v-btn v-on="on" :disabled="!SuccessfullLogin" v-if="btnStyle == 'mobile'" icon class="button" elevation="3"><v-icon>mdi-key</v-icon></v-btn>
        </template>
        <v-card color="card" :tile="isMobile">
            <v-toolbar class="cardTitle navText--text">
                <v-toolbar-title>{{ $t('testStore.activate-key') }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-if="isMobile" icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text :style="{height: isMobile ? 'calc(100vh - 110px)' : ''}" class="px-3 py-3">
                <!-- alert that shows session Id error -->
                <v-alert type="error" text outlined v-model="card1" border="left" dismissible color="error" icon="mdi-alert-circle-outline" close-text="Close Alert">
                    {{ $t('testStore.sessID-outdated') }}
                </v-alert>
                <!-- wrong key alert -->
                <v-alert type="error" text outlined v-model="card2" border="left" dismissible color="error" icon="mdi-alert-circle-outline" close-text="Close Alert">
                    {{ $t('testStore.invalid-key') }}
                </v-alert>
                <!-- key already used alert -->
                <v-alert type="error" text outlined v-model="card3" border="left" dismissible color="error" icon="mdi-alert-circle-outline" close-text="Close Alert">
                    {{ $t('testStore.key-used') }}
                </v-alert>
                <!-- Key activation Textfield -->
                <v-row align="center" justify="space-between" class="mx-0">
                    <v-col cols="12" sm="8" md="8" class="pa-0">
                        <v-text-field v-model="key" :label="$t('testStore.enter-key')" placeholder="xxxx-xxxx-xxxx-xxxx" outlined class="mt-2" hide-details></v-text-field>
                    </v-col>
                    <v-col v-if="!isMobile" cols="12" sm="4" md="4" class="pa-0 text-right">
                        <v-btn color="success" class="buttonText--text mt-3" @click="activateKey">{{ $t('testStore.activate') }}</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer v-if="!isMobile"></v-spacer>
                <v-btn v-if="!isMobile" outlined @click="dialog = false" color="error">{{ $t('testStore.cancel') }}</v-btn>
                <v-spacer v-if="isMobile"></v-spacer>
                <v-btn v-if="isMobile" color="success" rounded class="buttonText--text" @click="activateKey">{{ $t('testStore.activate') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { isMobile } from 'mobile-device-detect';

    export default {
        name: 'testStoreKey',
        props: ['btnStyle'],
        data: function() {
            return {
                dialog: false,
                key: '',
                card1: false,
                card2: false,
                card3: false,
                isMobile: false, // stores which type of device the user has
            }
        },
        mounted() {
            this.isMobile = isMobile;
        },
        
        computed: {
            // gets confirmation from the store if the login was successfull
            SuccessfullLogin() {
                // console.log('User logged in?', this.$store.getters.successfullLogin)
                return this.$store.getters.successfullLogin
            },
            // Response when trying to activate key
            activationResponse() {
                console.log(this.activationResponse)
                return this.$store.getters.ActivationResponse
            },
            // User Object from the store
            UserObject() {
                return this.$store.getters.userData
            },
        },
        methods: {
            // activates a Key for the current user
            activateKey() {
                this.$http.post('index.php',    {   task:           'activate_key',
                                                    key:            this.key,
                                                    userObject:     this.UserObject,
                                                    transactionID:  ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) )
                                                })
                .then(response => {
                    this.key = ''
                    if(response.body == 'sessionID-outdated') {
                        this.card1 = true
                    } else if(response.body == 'wrong-key') {
                        this.card2 = true
                    } else if(response.body == 'key-used') {
                        this.card3 = true
                    } else {
                        this.dialog = false
                        this.$store.dispatch('getSnackbar', {status: true, timeout: 3000, color: 'success', btnColor: 'buttonText', text: this.$i18n.t('testStore.success-unlock') })
                        this.$store.dispatch('getMyTests', this.UserObject)
                    }
                });
            },
        },
    }
</script>