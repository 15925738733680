<template>
    <v-container class="ma-0 pa-0" fluid>
        <v-card color="card" height="284" class="pa-3">
            <v-card height="258" color="elevatedCard">
                <v-row align="center" justify ="space-between" class="pr-6 pl-2">
                    <v-col cols="9">
                        <v-list color="elevatedCard" class="mt-2">
                            <v-list-item>
                                <v-list-item-avatar color="primary">
                                    <v-icon color="buttonText">mdi-translate</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>Test Language:</v-list-item-title>
                                    <v-list-item-subtitle>
                                        <v-chip color="primary" outlined small v-if="chipLanguage != null">{{ chipLanguage }}</v-chip>
                                        <div v-else class="font-weight-light caption mt-1">empty</div>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-avatar color="primary">
                                    <v-icon color="buttonText">mdi-format-list-bulleted-type</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>Test Question Types:</v-list-item-title>
                                    <v-list-item-subtitle>
                                        <v-chip color="primary" outlined small v-if="chipQuestionType[0] != null">{{ $t('EditDB.' + chipQuestionType[0]) }}</v-chip>
                                        <v-chip color="primary" outlined small class="ml-1" v-if="chipQuestionType[1] != null">{{ $t('EditDB.' + chipQuestionType[1]) }}</v-chip>
                                        <div v-if="chipQuestionType[0] == null && chipQuestionType[1] == null" class="font-weight-light caption mt-1">empty</div>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-avatar color="primary">
                                    <v-icon color="buttonText">mdi-tag-text-outline</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>Test Content:</v-list-item-title>
                                    <v-list-item-subtitle>
                                        <v-chip color="primary" outlined small v-if="chipContent[0] != null">{{ chipContent[0] }}</v-chip>
                                        <v-chip color="primary" outlined small class="ml-1" v-if="chipContent[1] != null">{{ chipContent[1] }}</v-chip>
                                        <div v-if="chipContent[0] == null && chipContent[1] == null" class="font-weight-light caption mt-1">empty</div>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn icon elevation="2" class="appBar" @click="dialog = true" :disabled="disableEditing"><v-icon>mdi-pencil</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-card>
        <!-- edit tags dialog -->
        <v-dialog width="650px" v-model="dialog">
            <v-card color="card">
                <v-card-title class="headline cardHeader white--text">Edit Test Tags</v-card-title>
                <v-divider></v-divider>
                <v-card-text class="mt-5">
                    <v-form ref="form" v-model="form">
                        <v-select outlined small-chips label="Language" clearable hide-details v-model="language" :items="isoLangs" class="mt-1"></v-select>
                        <v-select outlined small-chips deletable-chips label="Question Type(s)" clearable multiple class="my-3" v-model="questionType" :rules="chipRules" :items="questionTypes" item-text="name"></v-select>
                        <v-combobox outlined small-chips deletable-chips label="Content" clearable append-icon="" multiple v-model="content" :rules="chipRules"></v-combobox>
                    </v-form>
                </v-card-text>
                <v-divider style="margin-top: -20px"></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="error" @click="dialog = false">cancel</v-btn>
                    <v-btn class="buttonText--text" color="primary" @click="addTags()" :disabled="!form || disableEditing">Save Tags</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>

    export default {
        name: 'EditDBTestTags',
        props: ['disableEditing'],
        data: function() {
            return {
                dialog: false,
                form: false,
                
                chipLanguage: '',
                chipQuestionType: [],
                chipContent: [],
                language: '',
                questionType: [],
                content: [],

                questionTypes: [
                    {value: 'mul', name: this.$i18n.t('EditDB.mul')},
                    {value: 'sor', name: this.$i18n.t('EditDB.sor')},
                ],

                isoLangs : [
                    {"name":"Abkhaz","text":"аҧсуа"},
                    {"name":"Afar","text":"Afaraf"},
                    {"name":"Afrikaans","text":"Afrikaans"},
                    {"name":"Akan","text":"Akan"},
                    {"name":"Albanian","text":"Shqip"},
                    {"name":"Amharic","text":"አማርኛ"},
                    {"name":"Arabic","text":"العربية"},
                    {"name":"Aragonese","text":"Aragonés"},
                    {"name":"Armenian","text":"Հայերեն"},
                    {"name":"Assamese","text":"অসমীয়া"},
                    {"name":"Avaric","text":"авар мацӀ, магӀарул мацӀ"},
                    {"name":"Avestan","text":"avesta"},
                    {"name":"Aymara","text":"aymar aru"},
                    {"name":"Azerbaijani","text":"azərbaycan dili"},
                    {"name":"Bambara","text":"bamanankan"},
                    {"name":"Bashkir","text":"башҡорт теле"},
                    {"name":"Basque","text":"euskara, euskera"},
                    {"name":"Belarusian","text":"Беларуская"},
                    {"name":"Bengali","text":"বাংলা"},
                    {"name":"Bihari","text":"भोजपुरी"},
                    {"name":"Bislama","text":"Bislama"},
                    {"name":"Bosnian","text":"bosanski jezik"},
                    {"name":"Breton","text":"brezhoneg"},
                    {"name":"Bulgarian","text":"български език"},
                    {"name":"Burmese","text":"ဗမာစာ"},
                    {"name":"Catalan; Valencian","text":"Català"},
                    {"name":"Chamorro","text":"Chamoru"},
                    {"name":"Chechen","text":"нохчийн мотт"},
                    {"name":"Chichewa; Chewa; Nyanja","text":"chiCheŵa, chinyanja"},
                    {"name":"Chinese","text":"中文 (Zhōngwén), 汉语, 漢語"},
                    {"name":"Chuvash","text":"чӑваш чӗлхи"},
                    {"name":"Cornish","text":"Kernewek"},
                    {"name":"Corsican","text":"corsu, lingua corsa"},
                    {"name":"Cree","text":"ᓀᐦᐃᔭᐍᐏᐣ"},
                    {"name":"Croatian","text":"hrvatski"},
                    {"name":"Czech","text":"česky, čeština"},
                    {"name":"Danish","text":"dansk"},
                    {"name":"Divehi; Dhivehi; Maldivian;","text":"ދިވެހި"},
                    {"name":"Dutch","text":"Nederlands, Vlaams"},
                    {"name":"English","text":"English"},
                    {"name":"Esperanto","text":"Esperanto"},
                    {"name":"Estonian","text":"eesti, eesti keel"},
                    {"name":"Ewe","text":"Eʋegbe"},
                    {"name":"Faroese","text":"føroyskt"},
                    {"name":"Fijian","text":"vosa Vakaviti"},
                    {"name":"Finnish","text":"suomi, suomen kieli"},
                    {"name":"French","text":"français, langue française"},
                    {"name":"Fula; Fulah; Pulaar; Pular","text":"Fulfulde, Pulaar, Pular"},
                    {"name":"Galician","text":"Galego"},
                    {"name":"Georgian","text":"ქართული"},
                    {"name":"German","text":"Deutsch"},
                    {"name":"Greek, Modern","text":"Ελληνικά"},
                    {"name":"Guaraní","text":"Avañeẽ"},
                    {"name":"Gujarati","text":"ગુજરાતી"},
                    {"name":"Haitian; Haitian Creole","text":"Kreyòl ayisyen"},
                    {"name":"Hausa","text":"Hausa, هَوُسَ"},
                    {"name":"Hebrew (modern)","text":"עברית"},
                    {"name":"Herero","text":"Otjiherero"},
                    {"name":"Hindi","text":"हिन्दी, हिंदी"},
                    {"name":"Hiri Motu","text":"Hiri Motu"},
                    {"name":"Hungarian","text":"Magyar"},
                    {"name":"Interlingua","text":"Interlingua"},
                    {"name":"Indonesian","text":"Bahasa Indonesia"},
                    {"name":"Interlingue","text":"Interlingue"},
                    {"name":"Irish","text":"Gaeilge"},
                    {"name":"Igbo","text":"Asụsụ Igbo"},
                    {"name":"Inupiaq","text":"Iñupiaq, Iñupiatun"},
                    {"name":"Ido","text":"Ido"},
                    {"name":"Icelandic","text":"Íslenska"},
                    {"name":"Italian","text":"Italiano"},
                    {"name":"Inuktitut","text":"ᐃᓄᒃᑎᑐᑦ"},
                    {"name":"Japanese","text":"日本語 (にほんご／にっぽんご)"},
                    {"name":"Javanese","text":"basa Jawa"},
                    {"name":"Kalaallisut, Greenlandic","text":"kalaallisut, kalaallit oqaasii"},
                    {"name":"Kannada","text":"ಕನ್ನಡ"},
                    {"name":"Kanuri","text":"Kanuri"},
                    {"name":"Kashmiri","text":"कश्मीरी, كشميري‎"},
                    {"name":"Kazakh","text":"Қазақ тілі"},
                    {"name":"Khmer","text":"ភាសាខ្មែរ"},
                    {"name":"Kikuyu, Gikuyu","text":"Gĩkũyũ"},
                    {"name":"Kinyarwanda","text":"Ikinyarwanda"},
                    {"name":"Kirghiz, Kyrgyz","text":"кыргыз тили"},
                    {"name":"Komi","text":"коми кыв"},
                    {"name":"Kongo","text":"KiKongo"},
                    {"name":"Korean","text":"한국어 (韓國語), 조선말 (朝鮮語)"},
                    {"name":"Kurdish","text":"Kurdî, كوردی‎"},
                    {"name":"Kwanyama, Kuanyama","text":"Kuanyama"},
                    {"name":"Latin","text":"latine, lingua latina"},
                    {"name":"Luxembourgish, Letzeburgesch","text":"Lëtzebuergesch"},
                    {"name":"Luganda","text":"Luganda"},
                    {"name":"Limburgish, Limburgan, Limburger","text":"Limburgs"},
                    {"name":"Lingala","text":"Lingála"},
                    {"name":"Lao","text":"ພາສາລາວ"},
                    {"name":"Lithuanian","text":"lietuvių kalba"},
                    {"name":"Luba-Katanga","text":"Luba-Katanga"},
                    {"name":"Latvian","text":"latviešu valoda"},
                    {"name":"Manx","text":"Gaelg, Gailck"},
                    {"name":"Macedonian","text":"македонски јазик"},
                    {"name":"Malagasy","text":"Malagasy fiteny"},
                    {"name":"Malay","text":"bahasa Melayu, بهاس ملايو‎"},
                    {"name":"Malayalam","text":"മലയാളം"},
                    {"name":"Maltese","text":"Malti"},
                    {"name":"Māori","text":"te reo Māori"},
                    {"name":"Marathi (Marāṭhī)","text":"मराठी"},
                    {"name":"Marshallese","text":"Kajin M̧ajeļ"},
                    {"name":"Mongolian","text":"монгол"},
                    {"name":"Nauru","text":"Ekakairũ Naoero"},
                    {"name":"Navajo, Navaho","text":"Diné bizaad, Dinékʼehǰí"},
                    {"name":"Norwegian Bokmål","text":"Norsk bokmål"},
                    {"name":"North Ndebele","text":"isiNdebele"},
                    {"name":"Nepali","text":"नेपाली"},
                    {"name":"Ndonga","text":"Owambo"},
                    {"name":"Norwegian Nynorsk","text":"Norsk nynorsk"},
                    {"name":"Norwegian","text":"Norsk"},
                    {"name":"Nuosu","text":"ꆈꌠ꒿ Nuosuhxop"},
                    {"name":"South Ndebele","text":"isiNdebele"},
                    {"name":"Occitan","text":"Occitan"},
                    {"name":"Ojibwe, Ojibwa","text":"ᐊᓂᔑᓈᐯᒧᐎᓐ"},
                    {"name":"Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic","text":"ѩзыкъ словѣньскъ"},
                    {"name":"Oromo","text":"Afaan Oromoo"},
                    {"name":"Oriya","text":"ଓଡ଼ିଆ"},
                    {"name":"Ossetian, Ossetic","text":"ирон æвзаг"},
                    {"name":"Panjabi, Punjabi","text":"ਪੰਜਾਬੀ, پنجابی‎"},
                    {"name":"Pāli","text":"पाऴि"},
                    {"name":"Persian","text":"فارسی"},
                    {"name":"Polish","text":"polski"},
                    {"name":"Pashto, Pushto","text":"پښتو"},
                    {"name":"Portuguese","text":"Português"},
                    {"name":"Quechua","text":"Runa Simi, Kichwa"},
                    {"name":"Romansh","text":"rumantsch grischun"},
                    {"name":"Kirundi","text":"kiRundi"},
                    {"name":"Romanian, Moldavian, Moldovan","text":"română"},
                    {"name":"Russian","text":"русский язык"},
                    {"name":"Sanskrit (Saṁskṛta)","text":"संस्कृतम्"},
                    {"name":"Sardinian","text":"sardu"},
                    {"name":"Sindhi","text":"सिन्धी, سنڌي، سندھی‎"},
                    {"name":"Northern Sami","text":"Davvisámegiella"},
                    {"name":"Samoan","text":"gagana faa Samoa"},
                    {"name":"Sango","text":"yângâ tî sängö"},
                    {"name":"Serbian","text":"српски језик"},
                    {"name":"Scottish Gaelic; Gaelic","text":"Gàidhlig"},
                    {"name":"Shona","text":"chiShona"},
                    {"name":"Sinhala, Sinhalese","text":"සිංහල"},
                    {"name":"Slovak","text":"slovenčina"},
                    {"name":"Slovene","text":"slovenščina"},
                    {"name":"Somali","text":"Soomaaliga, af Soomaali"},
                    {"name":"Southern Sotho","text":"Sesotho"},
                    {"name":"Spanish; Castilian","text":"español, castellano"},
                    {"name":"Sundanese","text":"Basa Sunda"},
                    {"name":"Swahili","text":"Kiswahili"},
                    {"name":"Swati","text":"SiSwati"},
                    {"name":"Swedish","text":"svenska"},
                    {"name":"Tamil","text":"தமிழ்"},
                    {"name":"Telugu","text":"తెలుగు"},
                    {"name":"Tajik","text":"тоҷикӣ, toğikī, تاجیکی‎"},
                    {"name":"Thai","text":"ไทย"},
                    {"name":"Tigrinya","text":"ትግርኛ"},
                    {"name":"Tibetan Standard, Tibetan, Central","text":"བོད་ཡིག"},
                    {"name":"Turkmen","text":"Türkmen, Түркмен"},
                    {"name":"Tagalog","text":"Wikang Tagalog"},
                    {"name":"Tswana","text":"Setswana"},
                    {"name":"Tonga (Tonga Islands)","text":"faka Tonga"},
                    {"name":"Turkish", "text":"Türkçe"},
                    {"name":"Tsonga","text":"Xitsonga"},
                    {"name":"Tatar","text":"татарча, tatarça, تاتارچا‎"},
                    {"name":"Twi","text":"Twi"},
                    {"name":"Tahitian","text":"Reo Tahiti"},
                    {"name":"Uighur, Uyghur","text":"Uyƣurqə, ئۇيغۇرچە‎"},
                    {"name":"Ukrainian","text":"українська"},
                    {"name":"Urdu","text":"اردو"},
                    {"name":"Uzbek","text":"zbek, Ўзбек, أۇزبېك‎"},
                    {"name":"Venda","text":"Tshivenḓa"},
                    {"name":"Vietnamese","text":"Tiếng Việt"},
                    {"name":"Volapük","text":"Volapük"},
                    {"name":"Walloon","text":"Walon"},
                    {"name":"Welsh","text":"Cymraeg"},
                    {"name":"Wolof","text":"Wollof"},
                    {"name":"Western Frisian","text":"Frysk"},
                    {"name":"Xhosa","text":"isiXhosa"},
                    {"name":"Yiddish","text":"ייִדיש"},
                    {"name":"Yoruba","text":"Yorùbá"},
                    {"name":"Zhuang, Chuang","text":"Saɯ cueŋƅ, Saw cuengh"}
                ],

                chipRules: [
                    v => v.length < 3 || 'Only two Tags for this Category!',
                ],
            }
        },
        mounted() {
            this.chipLanguage = this.getTestData.tag_1
            this.chipQuestionType = [this.getTestData.tag_2, this.getTestData.tag_3]
            this.chipContent = [this.getTestData.tag_4, this.getTestData.tag_5]
        },
        watch: {
            getTestData() {
                // console.log('test')
                this.chipLanguage = this.getTestData.tag_1
                this.chipQuestionType = [this.getTestData.tag_2, this.getTestData.tag_3]
                this.chipContent = [this.getTestData.tag_4, this.getTestData.tag_5]
            },
            dialog() {
                if(this.dialog) {
                    if(this.getTestData.tag_1 != null) {
                        this.language = this.getTestData.tag_1
                    } else {
                        this.language = ''
                    }
                    if(this.getTestData.tag_2 != null && this.getTestData.tag_3 != null) {
                        this.questionType = [this.getTestData.tag_2, this.getTestData.tag_3]
                    } else if(this.getTestData.tag_2 != null && this.getTestData.tag_3 == null) {
                        this.questionType = [this.getTestData.tag_2]
                    } else if(this.getTestData.tag_2 == null && this.getTestData.tag_3 != null) {
                        this.questionType = [this.getTestData.tag_3]
                    } else {
                        this.questionType = []
                    }

                    if(this.getTestData.tag_4 != null && this.getTestData.tag_5 != null) {
                        this.content = [this.getTestData.tag_4, this.getTestData.tag_5]
                    } else if(this.getTestData.tag_4 != null && this.getTestData.tag_5 == null) {
                        this.content = [this.getTestData.tag_4]
                    } else if(this.getTestData.tag_4 == null && this.getTestData.tag_5 != null) {
                        this.content = [this.getTestData.tag_4]
                    } else {
                        this.content = []
                    }
                }
            },
        },
        computed: {
            // test data from the store
            getTestData() {
                // console.log(this.$store.getters.TestData)
                return this.$store.getters.TestData
            },
            // User Object from the store
            UserObject() {
                return this.$store.getters.userData
            },
        },
        methods: {
            // method to add test tags
            addTags() {
                //console.log(this.chipLanguage, this.chipQuestionType, this.chipContent)
                this.$http.post('index.php',    {   task:         'add_tags',
                                                    userObject:   this.UserObject,
                                                    testObject:   this.getTestData,
                                                    tag_1:        this.language,
                                                    tag_23:       this.questionType,
                                                    tag_45:       this.content,
                                                })
                        .then(response => {
                            if(response.body == 'success') {
                                this.$store.dispatch('getMyTests', this.UserObject)
                                this.$store.dispatch('getMyEdits', this.UserObject)
                                this.$store.dispatch('getSnackbar', {status: true, timeout: 4000, color: 'success', btnColor: 'buttonText', text: 'Updated Test Tags' })
                                this.chipLanguage = this.language
                                this.chipQuestionType = this.questionType,
                                this.chipContent = this.content,
                                this.dialog = false
                            } else {
                                this.$store.dispatch('getSnackbar', {status: true, timeout: 4000, color: 'error', btnColor: 'buttonText', text: 'SessionID Outdated. Log in again!' })
                            }
                        });
            },
        }
    }
</script>