<template>
    <v-container class="ma-0 pa-0" fluid>
        <v-card color="card" height="284px" class="pa-3">
            <v-card color="elevatedCard" height="258">
                <v-row align="center" justify ="space-between" class="pr-6 pl-2">
                    <v-col cols="9">
                        <v-img contain :aspect-ratio="4/3" height="235" :src="getTestData.test_img" class="ml-1"></v-img>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn icon elevation="2" class="appBar" @click="dialog = true" :disabled="disableEditing"><v-icon>mdi-pencil</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-card>
        <v-dialog v-model="dialog" width="650">
            <v-card>
                <v-card-title class="headline cardHeader white--text">Upload Store Image</v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 216px" class="mt-5">
                    <v-file-input :rules="fileRules" accept="image/*" placeholder="Pick an Image" label="Store Image" outlined append-icon="mdi-image" show-size prepend-icon="" chips truncate-length="42" v-model="file"></v-file-input>
                    <v-img contain :aspect-ratio="4/3" :src="getTestData.test_img" height="120px" width="160px" :style="{'border-style': 'solid', 'border-width': 1 + 'px', 'border-radius': 5 + 'px', 'border-color': theme.isDark ? '#3B3B3B' : '#D2D2D2'}"></v-img>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="error" @click="dialog = false">cancel</v-btn>
                    <v-btn class="buttonText--text" color="primary" @click="uploadImage">Upload</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
    import Vue from 'vue';

    export default {
        name: 'EditDBTestImage',
        props: ['disableEditing'],
        data: function() {
            return {
                dialog: false,

                fileRules: [
                    value => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
                ],

                file: undefined, // image file for store image
                result: undefined, // image file es byte array
                fileName: '',
            }
        },
        computed: {
            // test data from the store
            getTestData() {
                // console.log(this.$store.getters.TestData)
                return this.$store.getters.TestData
            },
            // gets the theme from the vuetify plugin (dark/light)
            theme() {
                return this.$vuetify.theme.dark
            },
            // User Object from the store
            UserObject() {
                return this.$store.getters.userData
            },
        },
        methods: {
            // method to convert image file to byte array
            uploadImage() {
                // console.log(this.file)
                if(!this.file)
                    return;
                var reader = new FileReader();
                var scope = this
                reader.onload = function () {
                    // console.log(this.result);
                    scope.onUpload(this.result);
                }
                reader.readAsArrayBuffer(this.file);
            },
            // method to upload image
            onUpload(imgData) {
                var binary = '';
                var bytes = new Uint8Array( imgData );
                var len = bytes.byteLength;
                for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode( bytes[ i ] );
                }
                // console.log(btoa(binary))
                this.$http.post('index.php',    {   task:         'upload_store_image',
                                                    userObject:   this.UserObject,
                                                    imageObject:  btoa(binary),
                                                    fileName:     this.getTestData.test_id + '.' + this.file.type.slice(6,9),
                                                    link:         Vue.http.options.root + 'store_img/',
                                                    testObject:   this.getTestData,
                                                })
                        .then(response => {
                            if(response.body == 'success') {
                                this.file = undefined
                                this.result = undefined
                                this.$store.dispatch('getSnackbar', {status: true, timeout: 4000, color: 'success', btnColor: 'buttonText', text: 'Updated Test Image' })
                                this.dialog = false
                                this.$store.dispatch('getMyTests', this.UserObject)
                                this.$store.dispatch('getMyEdits', this.UserObject)
                            } else {
                                this.$store.dispatch('getSnackbar', {status: true, timeout: 4000, color: 'error', btnColor: 'buttonText', text: 'SessionID Outdated. Log in again!' })
                            }
                        });
            },
        }
    }
</script>
