<template>
    <v-card tile :min-height="windowHeight - 76" elevation="0" color="rgba(0,0,0,0)">
        <v-row align="center" justify="center">
            <!-- layout of the create exam component -->
            <v-card color="rgba(0,0,0,0)" elevation="0" class="mx-5" width="1525">
                <!-- top Navigation -->
                <v-breadcrumbs :items="breadCrumbs" class="ma-0">
                    <template v-slot:divider>
                        <v-icon>mdi-chevron-right</v-icon>
                    </template>
                </v-breadcrumbs>

                <v-divider></v-divider>

                <v-card-text :style="{height: windowHeight - 122 + 'px'}" class="scroll py-0 px-1" :class="scrollbarTheme">
                    <v-expansion-panels class="mt-2 mx-1" multiple v-model="panels">
                        <v-expansion-panel class="card">
                            <v-expansion-panel-header class="title">
                                {{ $t('Exam.create-exam') }}
                                <template v-slot:actions>
                                    <v-icon :color="color">{{ icon }}</v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-form ref="form" v-model="form">
                                    <v-row>
                                        <!-- select from your tests -->
                                        <v-col cols="6">
                                            <v-select v-model="selectedTest" :items="selectableTests" :rules="testRules" outlined :label="$t('Exam.select-test')" :disabled="disabled1"></v-select>
                                        </v-col>
                                        <!-- choose exam name -->
                                        <v-col>
                                            <v-text-field outlined :label="$t('Exam.exam-name')" :rules="nameRules" v-model="examName" :disabled="disabled1"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <!-- add participants -->
                                        <v-col cols="6">
                                            <v-combobox outlined :label="$t('Exam.invite-mail')" multiple chips v-model="model" :disabled="disabled1">
                                                <template v-slot:selection="{ attrs, item, parent, selected }">
                                                    <v-chip v-bind="attrs" :input-value="selected" close @click:close="parent.selectItem(item)">
                                                        <v-avatar class="primary white--text" left v-text="item.mail.slice(0, 1).toUpperCase()"></v-avatar>
                                                        <span class="pr-2">
                                                            {{ item.mail }}
                                                        </span>
                                                    </v-chip>
                                                </template>
                                            </v-combobox>
                                        </v-col>
                                        <v-col cols="6" class="pa-0">
                                            <v-row class="ma-0">
                                                <!-- choose date -->
                                                <v-col>
                                                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="290px">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <!-- textfield for valid from date -->
                                                            <v-text-field v-model="date" :label="$t('Exam.date')" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined hide-details :disabled="disabled1"></v-text-field>
                                                        </template>
                                                        <!-- datepicker 1 -->
                                                        <v-date-picker v-model="date" no-title scrollable :first-day-of-week="1" :locale="$i18n.locale" color="primary">
                                                            <v-spacer></v-spacer>
                                                            <v-btn text color="primary" @click="menuClose">Cancel</v-btn>
                                                            <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <!-- choose time -->
                                                <v-col>
                                                    <v-text-field outlined type="time" :label="$t('Exam.start-time')" :rules="timeRules" v-model="time" :disabled="disabled1"></v-text-field>
                                                </v-col>
                                                <!-- choose duration -->
                                                <v-col>
                                                    <v-text-field outlined :label="$t('Exam.duration')" v-model="examDuration" :rules="durationRules" type="number" suffix="min" :disabled="disabled1"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <!-- create exam Button -->
                                            <v-row justify="start" style="margin-top: 16px">
                                                <v-btn color="primary" class="mx-6" :disabled="!form || disabled1 || model.length == 0" @click="createExam">{{ $t('Exam.create') }}</v-btn>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel class="card" :disabled="disabled2">
                            <v-expansion-panel-header class="title">{{ $t('Exam.send-invites') }}</v-expansion-panel-header>
                            <v-expansion-panel-content eager>
                                <v-card style="height: 320px;" class="scroll pa-0" :class="scrollbarTheme">
                                    <div v-for="(mail, i) in mails" :key="mail.key_id">
                                        <v-row align="center" class="mx-3 my-3">
                                            <v-icon v-if="mail.mail_send == 0" color="error">mdi-close-circle</v-icon>
                                            <v-icon v-else color="success">mdi-check-circle</v-icon>
                                            <v-spacer></v-spacer>
                                            <div>{{ mail.mail }}</div>
                                            <v-spacer></v-spacer>
                                            <v-btn @click="sendMail(i)" color="primary" v-if="mail.mail_send == 0">{{ $t('Exam.invite') }}</v-btn>
                                            <v-btn disabled color="primary" v-else>{{ $t('Exam.invite') }}</v-btn>
                                        </v-row>
                                        <v-divider class="my-3" v-if="i < mails.length"></v-divider>
                                        <div v-else class="my-3"></div>
                                    </div>
                                    <div class="mx-3 my-3">{{ $t('Exam.info') }}</div>
                                </v-card>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card-text>
            </v-card>
        </v-row>
    </v-card>
</template>

<script>

  export default {
    name: 'createExams',

    components: {

    },

    data: function() {
        return {
            form: false, // form for create exam
            selectableTests: [], // tests that can be selected for exam
            selectedTest: '', // selected Test
            examName: '', // name of Exam
            examDuration: null, // lengtht of exam
            menu: false, // variable for datepicker menu
            menuTemp : false,
            date: new Date().toISOString().substr(0, 10), // date Variable for datepicker
            time: new Date().toLocaleTimeString('en-US', {  hour12: false, 
                                                            hour: "numeric", 
                                                            minute: "numeric"}), // time Variable for time Picker

            testRules: [
                v => !!v || this.$i18n.t('Exam.select-test2'),
            ],
            nameRules: [
                v => !!v || this.$i18n.t('Exam.name-requiered'),
                v => (v && v.length <= 50) || this.$i18n.t('Exam.name-length'),
            ],
            timeRules: [
                v => !!v || this.$i18n.t('Exam.valid-time'),
            ],
            durationRules: [
                v => !!v || this.$i18n.t('Exam.duration-requiered'),
                v => (v && v.length <= 8) || this.$i18n.t('Exam.duration-length'),
            ],

            model: [],
            mails: [],
            nonce: 1,

            disabled1: false,
            disabled2: true,
            panels: [0],
            color: '',
            icon: '$expand',

            message: '',

            breadCrumbs: [
                {text: this.$i18n.t('AppNavigationMenu.dashboard'), disabled: false, to: '/dashboard', exact: true},
                {text: this.$i18n.t('Exam.exams'), disabled: false, to: '(/dashboard/exams)', exact: true},
                {text: this.$i18n.t('Exam.create1'), disabled: true, href: 'breadcrumbs_create'},
            ],

            windowWidth: 0, // width of the window
            windowHeight: 0, // height of the window
        }
    },

    mounted() {

        // event listener for window size
        this.$nextTick(function() {
            window.addEventListener('resize', this.getWindowWidth);
            window.addEventListener('resize', this.getWindowHeight);

            // Init
            this.getWindowWidth()
            this.getWindowHeight()
        })
    },

    created() {
        this.initialize()
    },

    beforeDestroy() {

        // remove eventlisteners when the component is destroyed
        window.removeEventListener('resize', this.getWindowWidth);
        window.removeEventListener('resize', this.getWindowHeight);
    },

    watch: {

        // when datepicker 1 is closed the date value has to be saved
        menu() {
            if(this.menu == false && this.menuTemp == false) {
                this.$refs.menu.save(this.date) 
            } else if(this.menu == true) {
                this.menuTemp = false
            }
        },

        model (val, prev) {
            // console.log(this.model)
            if (val.length === prev.length) return

            this.model = val.map(v => {
                if (typeof v === 'string') {
                    v = {
                        mail: v,
                    }
                    this.mails.push(v)
                    this.nonce++
                }
                return v
            })
        },

    },

    computed: {

        scrollbarTheme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light';
        },

        // User Object from the store
        UserObject() {
            return this.$store.getters.userData
        },

        // gets own editable Tests from Store
        myEdits() {
            return this.$store.getters.MyEdits
        },
    },

    methods: {

        // invite people by mail
        sendMail: function(i) {
            this.mails[i].mail_send = 1
            window.open('mailto:' + this.mails[i].mail + '?subject=' + this.mails[i].exam_name + '; ' + this.mails[i].valid_from + '&body=Key: ' + this.mails[i].key_phrase)
            this.$http.post('index.php',    {   task:       'mail_send',
                                                mailObject: this.mails[i],
                                            })
                    .then(response => {
                        // console.log(response.body)
                        this.message = response.body
                        this.panels = [1]
                    });
        },

        // create exame functionality
        createExam() {
            var examID = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) );
            this.mails.forEach(element => {
                // random in function
                function getRandomInt( min, max ) {
                    return Math.floor( Math.random() * ( max - min + 1 ) ) + min;
                }
                // function that generates a product key
                function generateProductKey() {
                    var tokens = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
                        chars = 4,
                        segments = 4,
                        keyString = ""; 
                    for( var i = 0; i < segments; i++ ) {
                        var segment = "";
                        for( var j = 0; j < chars; j++ ) {
                            var k = getRandomInt( 0, 35 );
                            segment += tokens[ k ];
                        }
                        keyString += segment;
                        if( i < ( segments - 1 ) ) {
                            keyString += "-";
                        }
                    }  
                    return keyString;
                }
                element.key_phrase = generateProductKey()
                element.key_id = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) );
                element.exam_id = examID
                this.myEdits.forEach(element1 => {
                    if(element1.test_name == this.selectedTest) {
                        element.test_name = element1.test_name_db
                        element.test_id = element1.test_id
                        element.point_system = element1.point_system
                    }
                });
                element.exam_name = this.examName
                element.valid_from = this.date + ' ' +  this.time + ':00'
                var hours = parseInt(this.time.substr(0,2))
                var min = parseInt(this.time.substr(3,4))
                min += 15
                if(min >= 60) {
                    if(hours < 23) {
                        hours += 1
                        min -= 60
                    } else {
                        min += (59-min)
                    }
                }
                element.valid_to = this.date + ' ' +  hours + ':' + min + ':00'
                element.duration = parseInt(this.examDuration)
                element.exam_creator_id = this.UserObject.usr_id
                element.participants = this.mails.length
                element.mail_send = 0
            });
            
            // console.log(this.mails)
            this.$http.post('index.php',    {   task:       'create_exam_key',
                                                keyObject:  this.mails, 
                                                userObject: this.UserObject,
                                            })
                    .then(response => {
                        console.log(response.body)
                        if(response.body == 'success') {
                            this.disabled1 = true
                            this.disabled2 = false
                            this.panels = [1]
                            this.color = 'success'
                            this.icon = 'mdi-check'
                        } else {
                            this.message ='sessionID outdated!'
                            //console.log('sessionID outdated!')
                    }  
            });
        },

        // close function for the first datepicker
        menuClose() {
            this.menu = false
            this.menuTemp = true
        },

        // get the width of the window
        getWindowWidth() {
            this.windowWidth = document.documentElement.clientWidth;
        },

        // get the height of the window
        getWindowHeight() {
            this.windowHeight = document.documentElement.clientHeight;
        },

        // get test results from DB
        initialize() {
            this.myEdits.forEach(element => {
                this.selectableTests.push(element.test_name)
            });
        },

    },

  }
</script>

<style scoped>
  .scroll {
    overflow-y: auto
  }
</style>