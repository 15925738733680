<template>
    <v-container fluid class="ma-0 pa-0">
        <!-- intro and number of right answers -->
        <v-row>
            <v-col cols="12">
                <v-row justify="end" align="center">
                <!-- number of right answer choices -->
                <v-alert outlined text class="mr-5" dense color="primary" border="right" v-if="!currentQuestion.checked && assists && !loadingState">
                    {{ $t('Questions.number-right') + currentQuestion.number_right }}
                </v-alert>
                <v-skeleton-loader :loading="true" type="text" class="mr-5" width="280" v-if="loadingState"></v-skeleton-loader>
                <!-- number of users right answers -->
                <v-alert outlined text class="mr-5" dense color="success" border="right" v-if="currentQuestion.checked && !currentQuestion.alternateResults && currentQuestion.userRight == currentQuestion.number_right">
                    {{ currentQuestion.userRight + $t('Questions.out-of') + currentQuestion.number_right + $t('Questions.correct')  }}
                </v-alert>
                <v-alert outlined text class="mr-5" dense color="error" border="right" v-if="currentQuestion.checked && !currentQuestion.alternateResults && currentQuestion.userRight != currentQuestion.number_right">
                    {{ currentQuestion.userRight + $t('Questions.out-of') + currentQuestion.number_right + $t('Questions.correct')  }}
                </v-alert>
                <v-alert outlined text class="mr-5" dense color="warning" border="right" v-if="currentQuestion.checked && currentQuestion.alternateResults">
                    <v-row>
                        <div class="ml-3">{{ $t('Questions.no-rating') }}</div>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon small class="ml-1 mr-3" v-on="on" color="warning">mdi-information-outline</v-icon>
                            </template>
                            <span>{{ $t('Questions.tooltip') }}</span>
                        </v-tooltip>
                    </v-row>
                </v-alert>
                </v-row>
                <!-- intro text -->
                <v-row justify="start" align="center" v-if="currentQuestion.intro != null">
                    <div class="body-1 ml-6">{{ currentQuestion.intro }}</div>
                    <v-icon class="mx-3">mdi-arrow-down</v-icon>
                </v-row>
            </v-col>
        </v-row>
        <!-- skeleton loader for answer choices -->
        <div v-if="loadingState">
            <v-row v-for="i in 5" :key="i" class="my-3 mx-3" align="center">
                <v-col cols="1">
                <v-skeleton-loader :loading="true" type="avatar" v-if="loadingState"></v-skeleton-loader>
                </v-col>
                <v-col cols="11">
                <v-skeleton-loader :loading="true" type="text" class="mt-2 mx-3" width="800" v-if="loadingState"></v-skeleton-loader>
                </v-col>
            </v-row>
        </div>
        <!-- answer choices -->
        <v-row v-for="(answer, i) in answers" :key="i" class="mb-2 ml-2 mr-2">
            <v-container fluid class="ma-0 pa-0">
                <!-- one card/box for each possible answer, THIS PART IS SHOWN WHEN THE CHECK BUTTON IS NOT PRESSED -->
                <v-card style="border: solid; border-width: 1px" height="70px" elevation="0" @click="cardClicked(i)" v-ripple v-if="!currentQuestion.checked">
                    <v-row style="height: 70px;" align="center">
                        <!-- checkbox that mirrors the users selection of possible right answers (implemented with checkbox icons) -->
                        <v-icon class="ml-8" v-if="answer.selected == 0">mdi-checkbox-blank-outline</v-icon>
                        <v-icon class="ml-8" v-if="answer.selected == 1" color="primary">mdi-checkbox-intermediate</v-icon>
                        <v-card elevation="0" tile color="rgba(255, 255, 255, 0)">
                            <!-- text of each possible answer -->
                            <div class="ml-3 subtitle-1 questionText--text">{{ answer.choice }}</div>
                        </v-card>
                    </v-row>
                </v-card>
                <!-- one card/box for each possible answer, THIS PART IS SHOWN WHEN THE CHECK BUTTON IS PRESSED -->
                <v-card :style="{'border': answer.outline == 'outlinedTrue' ? 'solid' : 'dashed', 'border-color': answer.outline == 'outlinedTrue' ? '#5AB55E' : '#F65757' }" height="70px" elevation="0" v-else>
                    <v-row style="height: 70px;" align="center">
                        <!-- checkbox that mirrors the users selection of possible right answers and shows if the user selection was correct (implemented with checkbox icons) -->
                        <v-icon class="ml-8" v-if="answer.userCheck == 'right'" color="checkboxTrue">mdi-checkbox-marked</v-icon>
                        <v-icon class="ml-8" v-if="answer.userCheck == 'false'" color="checkboxFalse">mdi-minus-box</v-icon>
                        <v-icon class="ml-8" v-if="answer.userCheck == 'blank'" color="checkboxEmpty">mdi-checkbox-blank-outline</v-icon>
                        <v-card elevation="0" tile color="rgba(255, 255, 255, 0)">
                            <!-- text of each possible answer -->
                            <div class="ml-3 subtitle-1 questionText--text">{{ answer.choice }}</div>
                        </v-card>
                    </v-row>
                    <!-- badge on right side of each answer (only shown after checking the answers) -->
                    <v-badge :value="badge" overlap :icon="answer.badgeIcon" :color="answer.badgeColor" v-if="currentQuestion.checked" style="position: absolute; right: -1px; top: -1px"></v-badge>
                </v-card>
            </v-container>
        </v-row>
    </v-container>
</template>
<script>
    export default {
        name: 'QuestionsContentMul',
        props: ['assists', 'loadingState', 'noLoad', 'currentQuestion', 'answers', 'checked', 'testMode', 'checkInput', 'allQuestions'],
        data () {
            return {
                badge: 0, // set to one after cklicking "check" => badges on top right side
                right: 0, // used to calculate how many answers were correctly ticked
                numberOfTicked: 0, // number of ticked answer choices
                disableCheck: true, // disable checking answers when rules dont apply
            }
        },

        mounted() {
            if(!this.noLoad) {
                this.applyAnswerStyle() // applys the correct styling to answer cards
            } else {
                this.badge = 1
            }
        },

        watch: {
            // build answers object, calculate the number of answer choices, calculate size of situation text
            currentQuestion() {
                this.disableCheck = true // reset variable so that the user cant check if not enough is selected
                if(!this.noLoad) {
                    this.applyAnswerStyle() // applys the correct styling to answer cards
                } else {
                    this.badge = 1
                }
            },
            // check functionality triggered by parent component
            checkInput() {
                this.checkUserInput()
            }
        },

        methods: {
            // set visibility and style for answer cards
            applyAnswerStyle() {
                // dont show the solution to the current question because it isnt answered yet
                if(!this.currentQuestion.checked) {
                    this.badge = 0
                    this.right = 0
                    this.numberOfTicked = 0
                // shows the solution of the current question when it is already answered (in non exam mode)
                } else if(this.currentQuestion.checked && this.testMode != 'exam_preparation') {
                    this.badge = 1
                }
            },
            // switch checkbox status when clicking possible question card
            cardClicked: function(id) {
                if(this.answers[id].selected == 0) {
                    this.numberOfTicked += 1 // increment number of ticked elements
                    // show snackbar when all answers are ticked
                    if (this.numberOfTicked >= this.answers.length) {
                        this.$store.dispatch('getSnackbar', {status: true, timeout: 3000, color: '', btnColor: 'error', text: this.$i18n.t('Questions.allAnswersWarning') })
                        this.numberOfTicked -= 1
                    } else {
                        this.answers[id].selected = 1
                    }
                } else {
                    this.answers[id].selected = 0 // deselect element if it was already ticked
                    this.numberOfTicked -= 1 // decrement number of ticked elements
                }
                // disable check when not enough answers ticked in assisted mode
                if(this.assists == true && this.numberOfTicked < parseInt(this.currentQuestion.number_right)) {
                    if(this.testMode != 'exam_preparation') {
                        this.disableCheck = true
                    }
                } else {
                    this.disableCheck = false
                }
            },

            // check the user input after clicking "check"-button (compare with right answers from DB)
            checkUserInput() {
                // disable checking if the user didn't select enough answers
                if(this.disableCheck == true) {
                    this.$store.dispatch('getSnackbar', {status: true, timeout: 3000, color: '', btnColor: 'error', text: this.$i18n.t('Questions.checkWarning') })
                    return
                }
                // compare user input with the right answers from the DB
                var comparison = [0, 0, 0, 0, 0, 0]
                var i = 0
                this.answers.forEach(element => {
                    // console.log('userinput;', element.selected, 'DB:', element.state)
                    if(element.selected == element.state) {
                        comparison[i] = 1;
                        if(element.state == 1) {
                            this.right += 1
                        }
                    }
                    i++
                })
                // show "no rating" message when to many answers where ticked
                if(this.currentQuestion.number_right == this.right && this.numberOfTicked > this.currentQuestion.number_right) {
                    this.currentQuestion.alternateResults = true
                } else {
                    this.currentQuestion.alternateResults = false
                }
                // color the checkboxes depending on the correctness of the user input
                this.answers.forEach( (element, j) => {
                    // console.log('selected: ', element.selected, 'answered right: ', comparison[j])
                    if(element.selected != 1) {
                        element['userCheck'] = 'blank'
                    } else {
                        if(element.selected == comparison[j]) {
                            element['userCheck'] = 'right'
                        } else {
                            element['userCheck'] = 'false'
                        }
                    }
                });
                // outline of the inner card after clicking "check" (shows wether the answers are true or false)
                this.answers.forEach(element => {
                    // console.log(element.state)
                    if(element.state == 1) {
                        element.outline = 'outlinedTrue'
                        element.badgeIcon = 'mdi-check'
                        element.badgeColor = 'green'
                    } else {
                        element.outline = 'outlinedFalse'
                        element.badgeIcon = 'mdi-close'
                        element.badgeColor = 'red'
                    }
                })
                // show DB Values/checkboxes, disable checkboxes, show badge as addition to the colored borders
                this.badge = 1
                // set the border for test navigation on the right side of screen
                if(this.currentQuestion.markCheckbox == false) {
                    this.currentQuestion.outlinedProgress = 'outlinedChecked'
                }
                this.currentQuestion.checked = true // save that the answer got checked
                this.currentQuestion.userRight = this.right // save the result to the questions Object
                this.$store.dispatch('getAlterAllQuestions', this.currentQuestion) // write results into store
            },

        },

    }
</script>