<template>
    <v-container fluid class="ma-0 pa-0">
        <v-card color="card" elevation="2">
            <v-card-title class="cardHeader" style="height:170px">
                <!-- loading bar when initializing component -->
                <v-progress-linear :active="loadingState" :indeterminate="loadingState" absolute bottom color="primary"></v-progress-linear>
                <!-- Question Number left corner -->
                <div class="white--text display-1 mx-2" v-if="loadingState == false">{{ iterator + 1 }}</div>
                <v-skeleton-loader :loading="true" type="avatar" dark v-if="loadingState == true"></v-skeleton-loader>
                <v-divider vertical color="white" class="ml-4 mr-6"></v-divider>
                <v-col>
                    <v-row>
                        <!-- Question Situation -->
                        <div :class="textSize" v-if="loadingState == false">{{ currentQuestion.situation }}</div>
                        <v-skeleton-loader :loading="true" type="text" dark v-else width="800" class="mb-3"></v-skeleton-loader>
                    </v-row>
                    <v-row>
                        <!-- Question -->
                        <div class="white--text title" v-if="loadingState == false">{{ currentQuestion.question }}</div>
                        <v-skeleton-loader :loading="true" type="heading" dark v-else width="800"></v-skeleton-loader>
                    </v-row>
                </v-col>
            </v-card-title>
            <!-- card text -->
            <v-card-text style="height: 606px">
                <!-- different Question Components -->
                <QuestionsContentMul v-if="currentQuestion.typ == 'mul'" :assists="assists" :loadingState="loadingState" :currentQuestion="currentQuestion" :answers="answers" :checked="checked" :testMode="testMode" :checkInput="checkInput" :allQuestions="getAllQuestions" :noLoad="noLoad"></QuestionsContentMul>
                <QuestionsContentSor v-if="currentQuestion.typ == 'sor'" :assists="assists" :loadingState="loadingState" :currentQuestion="currentQuestion" :checked="checked" :testMode="testMode" :checkInput="checkInput" :allQuestions="getAllQuestions" :noLoad="noLoad"></QuestionsContentSor>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
    import QuestionsContentMul from "./QuestionsContentMul";
    import QuestionsContentSor from "./QuestionsContentSor";
    export default {
        name: 'QuestionsHeader',
        components: {
            QuestionsContentMul,
            QuestionsContentSor,
        },
        props: ['getAllQuestions', 'iterator', 'assists', 'testMode', 'checkInput', 'noLoad'],
        data () {
            return {
                currentQuestion: {}, // holds the currently shown question
                answers: [], // array with all answers of current question
                textSize: '', // needed for scaling the situation text (includes it's size)
                loadingState: false, // component is loading if data is still being fetched
                checked: false,
            }
        },

        mounted() {
            // start loading on mounted
            if(this.noLoad == false) {
                this.loadingState = true
            } else {
                this.currentQuestion = this.getAllQuestions[this.iterator]
            }
        },

        watch: {
            // disable loading after data is retrieved
            getAllQuestions() {
                this.loadingState = false
                this.currentQuestion = this.getAllQuestions[this.iterator] // set the current question for the first time
                this.checked = this.currentQuestion.checked // set the checked variable for the first time
            },
            iterator() {
                this.$store.dispatch('getCurrentQuestion', this.currentQuestion) // write the results of the old current question into the store
                this.currentQuestion = this.getAllQuestions[this.iterator] // set the current question when the iterator changes
                this.checked = this.currentQuestion.checked // set the checked variable if the iterator changes
            },
            // build answers object, calculate the number of answer choices, calculate size of situation text
            currentQuestion() {
                // set the anwers array for the current Question
                this.answers = this.currentQuestion.answers
                this.calcTextSize() // shring textsize if the situation is to long
            },
        },

        methods: {
            // calculate situation text size
            calcTextSize() {
                if(this.currentQuestion.situation.length > 260) {
                    this.textSize = 'grey--text text--lighten-1 mb-2 body-2'
                } else {
                    this.textSize = 'grey--text text--lighten-1 mb-2 body-1'
                }
            },
        },
    }
</script>