import Vue from 'vue';
import VueResource from 'vue-resource'

Vue.use(VueResource, {})

const state = {
    cartItems: [],
    buyItem: [],
    currentStoreItem: {},
    myTests: [],
    myEdits: [],
    testData: {},
};

const getters = {
    CartItems: state => {
        return state.cartItems
    },
    BuyItem: state => {
        return state.buyItem
    },
    CurrentStoreItem: state => {
        return state.currentStoreItem
    },
    MyTests: state => {
        return state.myTests
    },
    MyEdits: state => {
        return state.myEdits
    },
    TestData: state => {
        return state.testData
    },
};

const actions = {
    getRemoveAll({commit}) {
        commit('setRemoveAll')
    },
    getRemoveCartItem({commit}, number) {
        commit('setRemoveCartItem', number)
    },
    getCartItems({commit}, cartItem) {
        commit('setCartItem', cartItem)
    },
    getBuyItems({commit}, buyItem) {
        commit('setBuyItems', buyItem)
    },
    getCurrentStoreItem({commit}, storeItem) {
        commit('setCurrentStoreItem', storeItem)
    },
    getMyTests({commit}, UserObject) {
        Vue.http.post('index.php', {    'task'      : 'get_table',
                                        'table'     : 'colcons_test_possession',
                                        'filter'    : [{ op: 'eq', field: 'usr_id', value: UserObject.usr_id }],
                                        'usr_id'    : this.getters.userData.usr_id,
                                        'sessionid' : this.getters.sessionID,
                                    })
            .then(response => {
                if(response.body.sessionerror) {
                    this.dispatch('getSnackbar', { status: true, timeout: 3000, color: 'error', btnColor: 'buttonText', text: response.body.sessionerror })
                } else {
                    // console.log(response.body)
                    let ids = [];
                    response.body.forEach((element) => {
                        ids.push(element.test_id)
                    })
                    Vue.http.post('index.php',  {   'task'      : 'get_table',
                                                    'table'     : 'colcons_tests',
                                                    'filter'    : [{ op: 'like', field: 'test_id', value: ids.join(',') }],
                                                    'usr_id'    : this.getters.userData.usr_id,
                                                    'sessionid' : this.getters.sessionID,
                                                })
                                .then(response2 => {
                                    if (response2.body.sessionerror) {
                                        this.dispatch('getSnackbar', { status: true, timeout: 3000, color: 'error', btnColor: 'buttonText', text: response2.body.sessionerror })
                                    } else {
                                        // console.log(response2.body)
                                        let merged = [];
                                        for (let i = 0; i < response.body.length; i++) {
                                            merged.push({
                                                ...response.body[i],
                                                ...(response2.body.find((itmInner) => itmInner.test_id === response.body[i].test_id))
                                            });
                                        }
                                        // console.log(merged);
                                        commit('setMyTests', merged)
                                    }
                                });
                }
            });
    },
    getMyEdits({commit}, UserObject) {
        Vue.http.post('index.php',  {   task:       'get_my_edits', 
                                        userObject: UserObject,
                                    })
            .then(response => {
                if(response.body == 'sessionID-outdated') {
                    // console.log('Session ID is outdated')
                } else {
                    // console.log(response.body)
                    commit('setMyEdits', response.body)
                }
            });
    },
    getCurrentTest({commit}, testData) {
        commit('setCurrentTest', testData)
    },
    getLogoutTests({commit}) {
        commit('setLogoutTests')
    },
};

const mutations = {
    setRemoveAll: (state) => {
        state.cartItems = []
    },
    setRemoveCartItem: (state, number) => {
        state.cartItems.splice(number, 1)
    },
    setCartItem: (state, cartItem) => {
        var tests = [] // temp variable with all used test id's
        state.cartItems.forEach(element => { // this does not do anything when the first item is added (right behavior)
            tests.push(element.test_id)
        });
        if(state.cartItems.length == 0) { // gets executed when the first item is added to the cart
            cartItem.buy_number = 1
            state.cartItems.push(cartItem)
            // console.log('added new item, init')
        } else { // gets executed everytime except the first time
            if(tests.includes(cartItem.test_id)) {
                var number = state.cartItems[tests.indexOf(cartItem.test_id)].buy_number
                number++
                cartItem.buy_number = number
                // console.log(number)
                // console.log('increased buy number')
                state.cartItems[tests.indexOf(cartItem.test_id)] = cartItem
            } else {
                cartItem.buy_number = 1
                state.cartItems.push(cartItem)
                // console.log('added new item')
            }
        }
    },
    setBuyItems: (state, buyItem) => {
        state.buyItem = buyItem
    },
    setCurrentStoreItem: (state, storeItem) => {
        state.currentStoreItem = storeItem
    },
    setMyTests: (state, myTests) => {
        state.myTests = myTests
    },
    setMyEdits: (state, myEdits) => {
        state.myEdits = myEdits
    },
    setCurrentTest: (state, testData) => {
        state.testData = testData
    },
    setLogoutTests: (state) => {
        state.myTests = []
        state.myEdits = []
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};