<template>
    <v-container class="ma-0 pa-0" fluid>
        <v-card color="card" height="102px" class="pa-3">
            <v-list color="elevatedCard" rounded v-if="creator.usr_name != undefined" elevation="3">
                <v-list-item>
                    <v-list-item-avatar color="primary">
                        <span class="buttonText--text title">{{ creator.usr_first_name.charAt(0) + creator.usr_last_name.charAt(0) }}</span>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{ creator.usr_first_name + ' ' + creator.usr_last_name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ '@' + getTestData.test_creator }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </v-container>
</template>
<script>

    export default {
        name: 'EditDBTestCreator',
        data: function() {
            return {
                creator: {}
            }
        },
        mounted() {
            this.getCreator()
        },
        watch: {
            getTestData() {
                this.getCreator()
            }
        },
        computed: {
            // test data from the store
            getTestData() {
                // console.log(this.$store.getters.TestData)
                return this.$store.getters.TestData
            },
            // User Object from the store
            UserObject() {
                return this.$store.getters.userData
            },
            // sessionID from the store
            SessionID() {
                // console.log('SessionIDComponent', this.$store.getters.sessionID)
                return this.$store.getters.sessionID
            },
        },
        methods: {
            // get the Test creator from DB
            getCreator() {
                this.$http.post('index.php',    {   'task'      : 'get_table',
                                                    'table'     : 'colcons_user',
                                                    'filter'    : [{ op: 'eq', field: 'usr_id', value: this.getTestData.test_creator_id }],
                                                    'field_list': ['usr_first_name', 'usr_last_name', 'usr_name'],
                                                    'usr_id'    : this.UserObject.usr_id,
                                                    'sessionid' : this.SessionID,
                                                })
                        .then(response => {
                            // console.log('getCreator', response.body)
                            if (response.body.sessionerror) {
                                this.$store.dispatch('getSnackbar', { status: true, timeout: 3000, color: 'error', btnColor: 'buttonText', text: this.$i18n.t('UserSettings.sessID-outdated') });
                            } else {
                                this.creator = response.body[0]
                            }
                        });
            },
        },
    }
</script>
