<template>
    <v-container fluid class="ma-0 pa-0">
        <v-card elevation="0" color="rgba(0,0,0,0)" style="overflow-y: auto">
            <v-row justify="center" v-if="SuccessfullLogin == false" class="mx-1 mb-1 mt-5">
                <v-col cols="12" class="text-center">
                    <div>{{ $t('AppNavigationMenu.login-to-see') }}</div>
                    <v-btn @click="$store.dispatch('getLoginEvent', true)" class="mt-5">Login</v-btn>
                </v-col>
            </v-row>
            <v-row justify="center" class="ma-1" v-else>
                <!-- progress for right answers -->
                <v-col cols="6" sm="4" md="3" class="pa-1 full-height">
                    <v-card color="card" height="100%">
                        <v-card-title>{{ $t('Statistic.right-answers') }}</v-card-title>
                        <v-card-text>
                            <v-row justify="center">
                                <v-progress-circular size="120" :value="globalPercentageRight" :rotate="-90" width="10" color="primary">{{ globalPercentageRight + '%' }}</v-progress-circular>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <!-- progress for wrong answers -->
                <v-col cols="6" sm="4" md="3" class="pa-1 full-height">
                    <v-card color="card" height="100%">
                        <v-card-title>{{ $t('Statistic.wrong-answers') }}</v-card-title>
                        <v-card-text>
                            <v-row justify="center">
                                <v-progress-circular size="120" :value="globalPercentageWrong" :rotate="-90" width="10" color="primary">{{ globalPercentageWrong + '%' }}</v-progress-circular>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <!-- progress for test finished -->
                <v-col cols="6" sm="4" md="3" class="pa-1 full-height">
                    <v-card color="card" height="100%">
                        <v-card-title>{{ $t('Statistic.solved-tests') }}</v-card-title>
                        <v-card-text>
                            <v-row style="height: 100px" align="center" class="mx-1">
                                <v-progress-linear color="primary" height="25" v-model="testsFinishedPercent">
                                    <template>
                                        <strong>{{ testsFinished + '/' + testsDivider }}</strong>
                                    </template>
                                </v-progress-linear>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <!-- progress for questions answered -->
                <v-col cols="6" sm="4" md="3" class="pa-1 full-height">
                    <v-card color="card" height="100%">
                        <v-card-title>{{ $t('Statistic.solved-answers') }}</v-card-title>
                        <v-card-text>
                            <v-row style="height: 100px" align="center" class="mx-1">
                                <v-progress-linear color="primary" height="25" v-model="questionsAnsweredPercent">
                                    <template>
                                        <strong>{{ questionsAnswered + '/' + questionsDivider }}</strong>
                                    </template>
                                </v-progress-linear>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <!-- Time spent in Tests -->
                <v-col cols="12" sm="4" md="3" class="pa-1">
                    <v-card color="card">
                        <v-card-title>{{ $t('Statistic.time-spent') }}</v-card-title>
                        <v-card-text style="height: 320px;">
                            <v-row justify="center">
                                <v-progress-circular size="300" :value="timePercent" :rotate="-90" width="30" color="primary">{{ this.hours + 'h' + this.minutes + 'm' + this.seconds + 's' + '/' + timeDivider + 'h' }}</v-progress-circular>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <!-- list with results -->
                <v-col cols="12" sm="12" md="12" class="pa-1">
                    <v-card color="card">
                        <v-toolbar flat color="card">
                            <div class="ml-5 mr-9 font-weight-bold">%</div>
                            <div class="font-weight-bold ml-5">{{ $t('Statistic.test-results') }}</div>
                            <v-spacer></v-spacer>
                            <div class="mr-3 font-weight-bold">{{ $t('Statistic.date') }}</div>
                        </v-toolbar>
                        <v-divider></v-divider>
                        <v-card-text class="pa-0">
                            <v-list two-line color="card" rounded>
                                <v-list-item-group>
                                    <template v-for="result in results">
                                        <v-list-item :key="result.result_id">
                                            <template>
                                                <v-list-item-icon>
                                                    <v-progress-circular :value="result.percentage" color="primary" class="ma-2" size="40" :rotate="-90">{{ result.percentage }}</v-progress-circular>
                                                </v-list-item-icon>
                                                <v-list-item-content style="margin-top: -15px">
                                                    <v-list-item-title>{{ result.test_name }}</v-list-item-title>
                                                    <!-- <v-list-item-subtitle class="text--primary">{{ result.points + '/' + result.possible_points + $t('Statistic.questions-answered') }}</v-list-item-subtitle> -->
                                                    <v-list-item-subtitle>{{ $t(`Statistic.${result.test_mode}`) }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action style="margin-top: -5px" class="mr-3">
                                                    <v-list-item-action-text>{{ result.finish_time }}</v-list-item-action-text>
                                                </v-list-item-action>
                                            </template>
                                        </v-list-item>
                                    </template>
                                </v-list-item-group>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>

  export default {
    name: 'Statistics',

    data: function() {
        return {
            results: [], // Array with all user test results
            rightAnswers: 0, // number of global right answers
            wrongAnswers: 0, // number of global wrong answers
            testsFinished: 0, // number of completed tests
            testsFinishedPercent: 0,
            testsDivider: 0,
            questionsAnswered: 0, // number of questions answered
            questionsAnsweredPercent: 0,
            questionsDivider: 0,
            globalPercentageRight: 0,
            globalPercentageWrong: 0,
            seconds: 0,
            minutes: 0,
            hours: 0,
            timePercent: 0,
            timeDivider: 0,
        }
    },

    created() {
        this.initialize()
    },

    watch: {
        SuccessfullLogin() {
            if(this.SuccessfullLogin) this.initialize();
        }
    },

    computed: {
        // User Object from the store
        UserObject() {
            return this.$store.getters.userData
        },
        // sessionID from the store
        SessionID() {
            // console.log('SessionIDComponent', this.$store.getters.sessionID)
            return this.$store.getters.sessionID
        },
        // gets confirmation from the store if the login was successfull
        SuccessfullLogin() {
            // console.log('User logged in?', this.$store.getters.successfullLogin)
            return this.$store.getters.successfullLogin
        },
    },

    methods: {
        // get test results from DB
        initialize() {
            
            this.$http.post('index.php',    {   'task'      : 'get_table',
                                                'table'     : 'colcons_test_results',
                                                'usr_id'    : this.UserObject.usr_id,
                                                'sessionid' : this.SessionID,
                                            })
                .then(response => {
                    // console.log(response.body)
                    if (response.body.sessionerror) {
                        this.$store.dispatch('getSnackbar', { status: true, timeout: 3000, color: 'error', btnColor: 'buttonText', text: this.$i18n.t('UserSettings.sessID-outdated') });
                    } else {
                        // write results into local array
                        var results = response.body.sort((x, y) => +new Date(y.finish_time) - +new Date(x.finish_time))
                        this.results = results.filter(element => element.test_mode != 'exam_mode')
                        // create variables for questions
                        var answeredQuestions = 0
                        var rightQuestions = 0
                        var minutes = 0
                        var seconds = 0
                        // calculate global question number (and right question number)
                        response.body.forEach(element => {
                            answeredQuestions += parseInt(element.possible_points)
                            rightQuestions += parseInt(element.points)
                            var timeDivider = element.duration.indexOf(":")
                            minutes += parseInt(element.duration.substring(0, timeDivider))
                            seconds += parseInt(element.duration.substring(timeDivider + 1, timeDivider + 3))
                        });
                        // global time in seconds
                        var time = minutes*60 + seconds
                        // calculation of time in hms format
                        this.hours = 0
                        this.minutes = parseInt(time/60)
                        this.seconds = time%60
                        if(this.minutes/60 >= 1) {
                            this.hours = parseInt(this.minutes/60)
                            this.minutes = this.minutes%60
                        }
                        // calculate percentages for time
                        if(time <= 10*60*60) {
                            this.timePercent = (time/(10*60*60))*100
                            this.timeDivider = 10
                        } else if(time <= 100*60*60) {
                            this.timePercent = (time/(100*60*60))*100
                            this.timeDivider = 100
                        } else {
                            this.timePercent = (time/(1000*60*60))*100
                            this.timeDivider = 1000
                        }
                        // calculate percentages for questions
                        this.globalPercentageRight = ((rightQuestions / (answeredQuestions)) * 100).toFixed(2)
                        this.globalPercentageWrong = (100 - (rightQuestions / (answeredQuestions)) * 100).toFixed(2)
                        // number of answered questions
                        this.questionsAnswered = answeredQuestions
                        // calculate percentages for answered questions number
                        if(answeredQuestions <= 1000) {
                            this.questionsAnsweredPercent = answeredQuestions/10
                            this.questionsDivider = 1000
                        } else if(answeredQuestions <= 10000) {
                            this.questionsAnsweredPercent = answeredQuestions/100
                            this.questionsDivider = 10000
                        } else {
                            this.questionsAnsweredPercent = answeredQuestions/1000
                            this.questionsDivider = 100000
                        }
                        // calculate global test number
                        var finishedTests = response.body.length
                        // number of solved tests
                        this. testsFinished = finishedTests
                        // calculate percentages for solved test numbers
                        if(finishedTests <= 100) {
                            this.testsFinishedPercent = finishedTests
                            this.testsDivider = 100
                        } else if(finishedTests <= 1000) {
                            this.testsFinishedPercent = finishedTests/10
                            this.testsDivider = 1000
                        } else {
                            this.testsFinishedPercent = finishedTests/100
                            this.testsDivider = 10000
                        }
                    }
                });
        },

    },

  }
</script>