<template>
    <v-container fluid class="ma-0 pa-0">
        <v-card :min-height="height - 60" elevation="0" color="rgba(0,0,0,0)" style="overflow-y: auto">
            <v-card color="card" class="ma-3">
                <v-card-title class="py-0 cardTitle">
                    <v-list-item class="px-0">
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('UserSettings.main-settings') }}</v-list-item-title>
                            <v-list-item-subtitle>{{ $t('UserSettings.update-profile') }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <!-- alert that shows update success -->
                    <v-alert type="success" text outlined v-model="card2" border="left" dismissible color="success" icon="mdi-checkbox-marked-circle-outline" close-text="Close Alert">{{ $t('UserSettings.success-change') }}</v-alert>
                    <!-- alert that shows session Id error -->
                    <v-alert type="error" text outlined v-model="card1" border="left" dismissible color="error" icon="mdi-alert-circle-outline" close-text="Close Alert">{{ $t('UserSettings.sessID-outdated') }}</v-alert>
                    <!-- Main/Profile Settings/Information -->
                    <v-form ref="form" v-model="form1">
                        <v-text-field outlined :label="$t('UserSettings.first-name')" v-model="updateObject.usr_first_name" autocomplete="given-name" :rules="firstNameRules"></v-text-field>
                        <v-text-field outlined :label="$t('UserSettings.last-name')" v-model="updateObject.usr_last_name" autocomplete="family-name" :rules="lastNameRules"></v-text-field>
                        <v-text-field outlined :label="$t('UserSettings.username')" v-model="updateObject.usr_name" name="username" autocomplete="off" :rules="userNameRules" :error-messages="checkName2()" @input="checkName()"></v-text-field>
                        <v-text-field outlined label="E-Mail" v-model="updateObject.usr_mail" autocomplete="email" :rules="mailRules" :error-messages="checkMail2()" @input="checkMail()"></v-text-field>
                    </v-form>
                    <v-text-field outlined label="User ID" :value="UserObject.usr_id" disabled v-model="updateObject.usr_id"></v-text-field>
                    <div class="error--text">{{ $t('UserSettings.warning') }}</div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="buttonText--text" color="success" @click="updateUser" :disabled="!form1">{{ $t('UserSettings.update-user') }}</v-btn>
                </v-card-actions>
            </v-card>
            <v-card class="ma-3">
                <v-card-title class="py-0 cardTitle">
                    <v-list-item class="px-0">
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('UserSettings.password') }}</v-list-item-title>
                            <v-list-item-subtitle>{{ $t('UserSettings.change-password') }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <!-- alert that shows update success -->
                    <v-alert type="success" text outlined v-model="card5" border="left" dismissible color="success" icon="mdi-checkbox-marked-circle-outline" close-text="Close Alert">{{ $t('UserSettings.password-changed') }}</v-alert>
                    <!-- alert that shows session Id error -->
                    <v-alert type="error" text outlined v-model="card3" border="left" dismissible color="error" icon="mdi-alert-circle-outline" close-text="Close Alert">{{ $t('UserSettings.sessID-outdated') }}</v-alert>
                    <!-- alert that shows old pass error -->
                    <v-alert type="error" text outlined v-model="card4" border="left" dismissible color="error" icon="mdi-alert-circle-outline" close-text="Close Alert">{{ $t('UserSettings.password-wrong') }}</v-alert>
                    <!-- Change Password -->
                    <v-form v-model="form2" ref="form">
                        <v-text-field v-show="show2" outlined label="Username" v-model="updateObject.usr_name" name="username" autocomplete="off" :rules="userNameRules"></v-text-field>
                        <v-text-field outlined hide-details v-model="passwordOld" :label="$t('UserSettings.current-password')" class="my-2" type="password" autocomplete="current-password"></v-text-field>
                        <div class="font-weight-light ml-1">{{ $t('UserSettings.password-provide') }}</div>
                        <v-text-field outlined class="mt-5" v-model="password" :label="$t('UserSettings.new-password')" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show1 = !show1" :type="show1 ? 'text' : 'password'" autocomplete="new-password" :rules="passwordRules"></v-text-field>
                        <v-text-field outlined v-model="password2" :label="$t('UserSettings.confirm-password')" type="password" autocomplete="new-password" :error-messages="passwordMatchError()"></v-text-field>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="buttonText--text" color="success" @click="changePassword" :disabled="!form2">{{ $t('UserSettings.change-pass') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-card>
    </v-container>
</template>

<script>

export default {
    name: 'UserSettings',

    data: function() {
        return {
            height: 0,

            updateObject: {},

            card1: false,
            card2: false,
            card3: false,
            card4: false,
            card5: false,
            show1: false,
            show2: false,

            form1: false,
            form2: false,

            password: '',
            password2: '',
            passwordOld: '',
            passwordObject: {},

            nameExists: false,
            mailExists: false,

            firstNameRules: [
                v => !!v || this.$i18n.t('UserSettings.first-name-requiered'),
                v => (v && v.length <= 100) || this.$i18n.t('UserSettings.first-name-length'),
            ],
            lastNameRules: [
                v => !!v || this.$i18n.t('UserSettings.last-name-requiered'),
                v => (v && v.length <= 100) || this.$i18n.t('UserSettings.last-name-length'),
            ],
            userNameRules: [
                v => !!v || this.$i18n.t('UserSettings.username-requiered'),
                v => (v && v.length <= 50) || this.$i18n.t('UserSettings.username-length'),
                v => (v && v.length >= 3) || this.$i18n.t('UserSettings.username-rules'),
            ],
            passwordRules: [
                v => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[[!@#§/()=?_-€<>°$%^&*\]])(?=.{8,})/.test(v) || this.$i18n.t('UserSettings.password-rules'),
            ],
            mailRules: [
                v => !!v || this.$i18n.t('UserSettings.mail-requiered'),
                v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || this.$i18n.t('UserSettings.mail-rules'),
            ],
        }
    },

    created() {
        this.updateObject.usr_first_name = this.UserObject.usr_first_name;
        this.updateObject.usr_last_name = this.UserObject.usr_last_name;
        this.updateObject.usr_name = this.UserObject.usr_name;
        this.updateObject.usr_mail = this.UserObject.usr_mail;
        this.updateObject.usr_id = this.UserObject.usr_id;
    },

    mounted() {
        this.height = screen.height;
    },

    computed: {
        // User Object from the store
        UserObject () {
            return this.$store.getters.userData;
        },
        // sessionID from the store
        SessionID () {
            // console.log('SessionIDComponent', this.$store.getters.sessionID);
            return this.$store.getters.sessionID;
        },
    },

    methods: {
        // check if username already exists
        checkName() {
            this.$http.post('index.php',    {   task        :   'check_name',
                                                name        :   this.updateObject.usr_name,
                                                userObject  :   this.UserObject
                                            })
            .then(response => {
                // console.log(response.body.substring(1, response.body.length-1))
                if (response.body.sessionerror) {
                    this.$store.dispatch('getSnackbar', { status: true, timeout: 3000, color: 'error', btnColor: 'buttonText', text: this.$i18n.t('UserSettings.sessID-outdated') });
                } else {
                    if (response.body == 'null' || response.body.substring(1, response.body.length-1) == this.UserObject.usr_name) {
                        this.nameExists = false;
                    } else {
                        this.nameExists = true;
                    }
                }
            });    
        },
        checkName2() {
            return (this.nameExists === false) ? '' : this.$i18n.t('UserSettings.username-taken');
        },
        // check if e-mail already exists
        checkMail() {
            this.$http.post('index.php',    {   task:       'check_mail',
                                                mail:       this.updateObject.usr_mail,
                                                userObject: this.UserObject
                                            })
            .then(response => {
                // console.log(response.body)
                if(response.body != 'sessionID-outdated' && (typeof response.body) != 'object') {
                    if((response.body.slice(1,-1) === this.updateObject.usr_mail) && this.updateObject.usr_mail != this.UserObject.usr_mail) {
                        // console.log('exists')
                        this.mailExists = true;
                    } else {
                        // console.log('dont exist')
                        this.mailExists = false;
                    }
                } else {
                    // console.log('dont exist')
                    this.mailExists = false;
                }
            });    
        },
        checkMail2() {
            return (this.mailExists === false) ? '' : this.$i18n.t('UserSettings.mail-taken');
        },
        // confirm password
        passwordMatchError () { 
            return (this.password === this.password2) ? '' : this.$i18n.t('UserSettings.password-match');
        },
        // change Password
        changePassword() {
            this.passwordObject.usr_password_old = this.passwordOld
            this.passwordObject.usr_password = this.password
            this.passwordObject.usr_id = this.UserObject.usr_id
            this.$http.post('index.php',    {   task:           'change_password',
                                                passwordObject: this.passwordObject,
                                                sessionID:      this.SessionID
                                            })
            .then(response => {
                if(response.body.sessionerror) {
                    // console.log('session ID is outdated!')
                    this.card3 = true;
                } else if(response.body.error){
                    // console.log('old Password was incorrect!')
                    this.card4 = true;
                } else {
                    // console.log('Password successfully changed')
                    this.card5 = true;
                    this.password = '';
                    this.password2 = '';
                }
            });
        },
        // update existing User
        updateUser() {
            this.$http.post('index.php',    {   task:           'update_user',
                                                updateObject:   this.updateObject,
                                                sessionID:      this.SessionID
                                            })
            .then(response => {
                if(response.body.sessionerror) {
                    // console.log('session ID is outdated!')
                    this.card1 = true;
                } else {
                    this.updateObject.usr_first_name = response.body.updated_user.usr_first_name;
                    this.updateObject.usr_last_name = response.body.updated_user.usr_last_name;
                    this.updateObject.usr_name = response.body.updated_user.usr_name;
                    this.updateObject.usr_mail = response.body.updated_user.usr_mail;
                    this.updateObject.usr_id = response.body.updated_user.usr_id;
                    this.$store.dispatch('updateUserData', response.body.updated_user);
                    this.card2 = true;
                }
            });
        },
    }
};
</script>