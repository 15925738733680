<template>
  <v-card tile :min-height="windowHeight - 76" elevation="0" color="rgba(0,0,0,0)">
    <v-row align="center" justify="center">
      <v-card color="rgba(0,0,0,0)" elevation="0" class="mx-5" width="1525">
        <!-- top Navigation -->
        <v-breadcrumbs :items="breadCrumbs" class="ma-0">
            <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
            </template>
        </v-breadcrumbs>

        <v-divider></v-divider>

        <v-card-text :style="{height: windowHeight - 122 + 'px'}" class="scroll py-0 px-0" :class="scrollbarTheme">
          <v-card color="card" elevation="1" class="mx-2 mt-2">
            <v-toolbar flat color="card">
              <!-- Store Title -->
              <v-toolbar-title>Test Store</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <!-- search bar -->
              <v-text-field v-model="searchTerm" @input="filterStore('suchen')" append-icon="mdi-magnify" :label="$t('testStore.search')" solo-inverted dense hide-details single-line class="mr-3"></v-text-field>
              <v-divider class="mx-4" inset vertical></v-divider>
              <!-- sort asc -->
              <div>{{ $t('testStore.sort') }}</div>
              <v-btn icon color="primary">
                <v-icon>mdi-sort-ascending</v-icon>
              </v-btn>
              <!-- sort desc -->
              <v-btn icon color="primary">
                <v-icon>mdi-sort-descending</v-icon>
              </v-btn>
              <v-divider class="mx-4" inset vertical></v-divider>
              <!-- categories -->
              <v-menu bottom offset-y v-model="menu">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" v-bind="attrs" v-on="on" text>
                    <v-icon left>mdi-chevron-down</v-icon>
                    {{ $t('testStore.categories') }}
                  </v-btn>
                </template>
                <v-card>
                  <v-list>
                    <v-menu right offset-x>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item v-bind="attrs" v-on="on">
                          <v-list-item-title>language</v-list-item-title>
                          <v-list-item-icon><v-icon>mdi-chevron-right</v-icon></v-list-item-icon>
                        </v-list-item>
                      </template>
                      <v-list>
                        <v-list-item v-for="(lan, i) in languages" :key="i" @click="filterStore(lan)">
                          <v-list-item-title>{{ lan }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list>
                </v-card>
              </v-menu>
              <v-spacer></v-spacer>
              <!-- redeem key component -->
              <testStoreKey btnStyle="desktop"></testStoreKey>
            </v-toolbar>
            <v-divider></v-divider>
            <!-- Shop Items -->
            <v-card-text :style="{height: windowHeight - 265 + 'px'}" class="scroll" :class="scrollbarTheme">
              <v-row class="mx-1" v-if="tests != 'null'">
                <v-col md="4" sm="6" xs="12" lg="3" xl="3" v-for="(test,i) in tests" :key="test.test_id">
                  <!-- single shop item -->
                  <v-hover v-slot:default="{ hover }">
                    <v-card :elevation="hover ? 12 : 5" :ripple="false" class="ma-1" color="subNavigation">
                      <v-card @click.native="emitStoreItem(test)" color="rgba(0,0,0,0)" elevation="0" tile>
                        <!-- <v-overlay absolute :value="hover" opacity="0.05"></v-overlay> -->
                        <v-card-title class="menu" style="border-top-left-radius: 4px; border-top-right-radius: 4px">
                          <v-clamp autoresize :max-lines="1">{{ test.test_name }}</v-clamp>
                        </v-card-title>
                        <!-- product image -->
                        <v-img :aspect-ratio="16/9" :src="test.test_img" contain style="top: 10px"></v-img>
                        <v-row style="position: relative; top: 20px; z-index: 2" justify="space-around" class="mx-3" align="center">
                          <!-- wishlist button -->
                          <v-btn color="error buttonText--text" fab small @click.stop="wishlistItem(test)">
                            <v-icon v-if="Wishlist.length > 0 && Wishlist != 'null'">{{ Wishlist.find(element => element.test_id == test.test_id) != undefined  ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
                            <v-icon v-else> mdi-heart-outline</v-icon>
                          </v-btn>
                          <v-spacer></v-spacer>
                          <!-- add to cart -->
                          <v-btn color="primary buttonText--text" fab small @click.stop="toCart(test)" class="mr-3">
                            <v-icon>mdi-cart-plus</v-icon>
                          </v-btn>
                          <!-- Buy Button -->
                          <v-btn v-if="parseInt(test.test_price) != 0" color="rgb(249, 195, 56)" rounded  @click.stop="openBuy(test)" class="elevation-5 buttonText--text">
                            {{ test.test_price + '€' }}
                          </v-btn>
                          <v-btn v-else color="success buttonText--text" class="elevation-5" rounded @click.stop="openBuy(test)">
                            {{ $t('testStore.free') }}
                          </v-btn>
                        </v-row>
                        <!-- test Info -->
                        <div class="menu" style="height: 100px">
                          <div class="mx-3 pt-8">
                            <v-clamp autoresize :max-lines="3">{{ test.test_info }}</v-clamp>
                          </div>
                        </div>
                      </v-card>
                      <!-- test tags -->
                      <v-chip-group show-arrows class="menu" v-if="chips[i].length > 0">
                        <v-chip v-for="chip in chips[i]" :key="chip.id" small>{{ chip.name }}</v-chip>
                      </v-chip-group>
                      <v-card-actions v-else style="height: 40px" class="menu mx-0">
                        <v-btn icon disabled><v-icon>mdi-chevron-left</v-icon></v-btn>
                        <v-spacer></v-spacer>
                        <v-btn icon disabled><v-icon>mdi-chevron-right</v-icon></v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
              <!-- no data text -->
              <v-row v-else>
                <v-col cols="12" class="text-center">
                  <div class="subtit--text">{{ $t('testStore.no-tests') }}</div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <!-- Bottom Navigation -->
            <v-card-actions >
              <v-spacer></v-spacer>
              <!-- pagination -->
              <v-pagination :length="1" circle v-model="page"></v-pagination>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-card-text>
      </v-card>
    </v-row>
  </v-card>
</template>

<script>
  import testStoreKey from './testStoreKey'
  import VClamp from 'vue-clamp'

  export default {
    name: 'testStore',
    components: {
      testStoreKey,
      VClamp,
    },
    data: function() {
      return{
        page: 1,
        tests: [], // array with all the available Tests
        chips: [],

        searchTerm: '',
        language: undefined,
        menu: false,
        languages: [{tag_1: this.$t('testStore.all')}],

        breadCrumbs: [
          {text: this.$i18n.t('AppNavigationMenu.dashboard'), disabled: false, to: '/dashboard', exact: true},
          {text: this.$i18n.t('AppNavigationMenu.test-store'), disabled: true, to: '/dashboar/store', exact: true},
        ],

        windowWidth: 0, // width of the window
        windowHeight: 0, // height of the window
      }
    },

    mounted() {
      // event listener for window size
      this.$nextTick(function() {
        window.addEventListener('resize', this.getWindowWidth);
        window.addEventListener('resize', this.getWindowHeight);

        // Init
        this.getWindowWidth()
        this.getWindowHeight()
      })
      // get store tests from DB
      this.$http.post('index.php',  { 'task'    : 'get_table',
                                      'table'   : 'colcons_tests',
                                      'filter'  : [ { op: 'eq', field: 'test_in_store', value: '1' } ],
                                    })
            .then(response => {
                // console.log(response.body)
                let tests = response.body;
                tests.forEach(element => {
                  element['icon'] = false;
                  let chipGroup = [];
                  if(element.tag_1 != null) chipGroup.push({id:1, name: element.tag_1});
                  if(element.tag_2 != null) chipGroup.push({id:2, name: this.$t('EditDB.' + element.tag_2)});
                  if(element.tag_3 != null) chipGroup.push({id:3, name: this.$t('EditDB.' + element.tag_3)});
                  if(element.tag_4 != null) chipGroup.push({id:4, name: element.tag_4});
                  if(element.tag_5 != null) chipGroup.push({id:5, name: element.tag_5});
                  this.chips.push(chipGroup);
                });
                this.tests = tests;
            });
      // get languages from DB
      this.$http.post('index.php',  { 'task'        : 'get_table',
                                      'table'       : 'colcons_tests',
                                      'field_list'  : 'tag_1',
                                      'filter'      : [ { op: 'eq', field: 'test_in_store', value: '1' } ],
                                      'groupby'     : ['tag_1'],
                                    })
            .then(response => {
              // console.log(response.body);
              this.languages = response.body.map(lan => lan.tag_1);
            })
      // get wishlist from DB
      if(Object.keys(this.$store.getters.userData).length != 0) {
        this.$store.dispatch('getWishlist', this.UserObject)
      }
    },

    beforeDestroy() {
      // remove eventlisteners when the component is destroyed
      window.removeEventListener('resize', this.getWindowWidth);
      window.removeEventListener('resize', this.getWindowHeight);
    },

    watch: {
      UserObject() {
        // get wishlist from DB
        if(Object.keys(this.$store.getters.userData).length != 0) {
          this.$store.dispatch('getWishlist', this.UserObject)
        }
      }
    },

    computed: {
      // custom toolbar theme
      scrollbarTheme() {
        return this.$vuetify.theme.dark ? 'dark' : 'light';
      },
      // User Object from the store
      UserObject() {
          return this.$store.getters.userData
      },
      // wishlist array from store
      Wishlist() {
        // console.log(this.$store.getters.wishlist)
        return this.$store.getters.wishlist
      }
    },

    methods: {
      filterStore(lan) {
        var searchTerm = this.searchTerm
        if(!searchTerm.includes('%')) {
          searchTerm = '%' + searchTerm + '%'
        }
        if(searchTerm == '%' || searchTerm == '%%' || searchTerm == '') {
          searchTerm = undefined
        }
        if(lan != 'suchen') {
          this.language = lan.tag_1
        }
        console.log(searchTerm, this.language)
        this.$http.post('index.php',  { task:           'filter_store',
                                        searchTerm:     searchTerm,
                                        filterLanguage: this.language
                                      })
            .then(response => {
              this.tests = response.body
              this.menu = false
            })
      },
      // add/remove test from wishlist
      wishlistItem(test) {
        if(Object.keys(this.$store.getters.userData).length === 0) {
          this.$store.dispatch('getSnackbar', {status: true, timeout: 5000, color: 'error', btnColor: 'buttonText', text: this.$i18n.t('testStore.login') })
          return
        }
        var wish_id
        var remove
        if(this.Wishlist.length > 0 && this.Wishlist != 'null') {
          if(this.Wishlist.find(element => element.test_id == test.test_id)) {
            remove = true
            wish_id = this.Wishlist.find(element => element.test_id == test.test_id).wish_id
          } else {
            remove = false
            wish_id = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) );
          }
        } else {
          remove = false
          wish_id = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) );
        }
        this.$http.post('index.php',  { task:       'wishlist_test',
                                        userObject: this.UserObject,
                                        testObject: test,
                                        wishID:     wish_id,
                                        remove:     remove,
                                      })
            .then(response => {
              if(response.body == 'success') {
                this.$store.dispatch('getWishlist', this.UserObject)
                if(remove) {
                  this.$store.dispatch('getSnackbar', {status: true, timeout: 4000, color: 'success', btnColor: 'buttonText', text: this.$i18n.t('testStore.remove-wish') })
                } else {
                  this.$store.dispatch('getSnackbar', {status: true, timeout: 4000, color: 'success', btnColor: 'buttonText', text: this.$i18n.t('testStore.success-wish') })
                }
              } else {
                this.$store.dispatch('getSnackbar', {status: true, timeout: 4000, color: 'error', btnColor: 'buttonText', text: this.$i18n.t('UserSettings.sessID-outdated') })
              }
            });
      },
      // add Test to shopping Cart
      toCart: function(test) {
        this.$store.dispatch('getCartItems', test)
        this.$store.dispatch('getSnackbar', {status: true, timeout: 3000, color: 'primary', btnColor: 'buttonText', text: this.$i18n.t('testStore.added') })
        this.$root.$emit('buttonEvent', true)
      },
      // open the buy window
      openBuy: function(test) {
        test.buy_number = 1
        var items = []
        items[0] = test
        // console.log(items)
        this.$store.dispatch('getBuyItems', items)
        // this.$store.dispatch('getCurrentMenu', 'testStoreBuy')
        this.$router.push('/dashboard/store/buy/article-overview')
      },
      // open detail item
      emitStoreItem: function(storeItem) {
        this.$store.dispatch('getCurrentMenu', 'testStoreDetails')
        this.$store.dispatch('getCurrentStoreItem', storeItem)
        this.$router.push({path: '/dashboard/store/product/' + storeItem.test_name.replace(/\s/g, ''), query: {test_id: storeItem.test_id}})
      },
      // get the width of the window
      getWindowWidth() {
          this.windowWidth = document.documentElement.clientWidth;
      },
      // get the height of the window
      getWindowHeight() {
          this.windowHeight = document.documentElement.clientHeight;
      },
    },
  }
</script>

<style scoped>
</style>