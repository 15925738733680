<template>
  <v-card tile :min-height="windowHeight - 76" elevation="0" color="rgba(0,0,0,0)">
    <v-row justify="center">
      <v-card color="rgba(0,0,0,0)" elevation="0" class="mx-5" width="1525">
         <!-- top Navigation -->
          <v-breadcrumbs :items="breadCrumbs" class="ma-0">
              <template v-slot:divider>
                  <v-icon>mdi-chevron-right</v-icon>
              </template>
          </v-breadcrumbs>

          <v-divider></v-divider>

          <v-card-text :style="{height: windowHeight - 122 + 'px'}" class="scroll py-0 px-0">
            <v-row class="ma-1">
              <!-- Home -->
              <v-col cols="3"  class="pa-1">
                <v-hover v-slot="{ hover }">
                  <v-card color="card" height="300px" :to="'/'" class="text-center mx-auto">
                    <v-img height="235" src="@/assets/ImageHome.svg" contain>
                      <v-expand-transition>
                        <div v-if="hover" class="d-flex transition-fast-in-fast-out v-card--reveal" style="height: 100%; background-color: rgb(255, 176, 0)">
                          <v-col>
                            <div class="font-weight-light title buttonText--text">{{ $t('Dashboard.content') }}</div>
                            <br>
                            <div class="buttonText--text">{{ $t('Dashboard.getting-started') }}</div>
                            <div class="buttonText--text">{{ $t('Dashboard.features') }}</div>
                            <div class="buttonText--text">{{ $t('Dashboard.tutorials') }}</div>
                          </v-col>
                        </div>
                      </v-expand-transition>
                    </v-img>
                    <v-card-text style="position: relative; height: 120" class="menu">
                      <div class="title">{{ $t('AppNavigationMenu.home') }}</div>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-col>
              <!-- Store -->
              <v-col cols="3"  class="pa-1">
                <v-hover v-slot="{ hover }">
                  <v-card color="card" height="300px" :to="'/dashboard/store'" class="text-center mx-auto">
                    <v-img height="235" src="@/assets/ImageStore.svg" contain>
                      <v-expand-transition>
                        <div v-if="hover" class="d-flex transition-fast-in-fast-out v-card--reveal" style="height: 100%; background-color: rgb(146, 209, 0)">
                          <v-col>
                            <div class="font-weight-light title buttonText--text">{{ $t('Dashboard.content') }}</div>
                            <br>
                            <div class="buttonText--text">{{ $t('Dashboard.buy') }}</div>
                            <div class="buttonText--text">{{ $t('Dashboard.keys') }}</div>
                            <div class="buttonText--text">{{ $t('Dashboard.memberships') }}</div>
                          </v-col>
                        </div>
                      </v-expand-transition>
                    </v-img>
                    <v-card-text style="position: relative; height: 120" class="menu">
                      <testStoreKey btnStyle="dashboard"></testStoreKey>
                      <div class="title">{{ $t('AppNavigationMenu.test-store') }}</div>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-col>
              <!-- tests -->
              <v-col cols="3"  class="pa-1">
                <v-hover v-slot="{ hover }">
                  <v-card color="card" height="300px" :to="'/dashboard/exercises'" class="text-center mx-auto">
                    <v-img height="235" src="@/assets/ImageTests.svg" contain>
                      <v-expand-transition>
                        <div v-if="hover" class="d-flex transition-fast-in-fast-out v-card--reveal" style="height: 100%; background-color: rgb(0, 149, 255)">
                          <v-col>
                            <div class="font-weight-light title buttonText--text">{{ $t('Dashboard.content') }}</div>
                            <br>
                            <div class="buttonText--text">{{ $t('Dashboard.my-tests') }}</div>
                          </v-col>
                        </div>
                      </v-expand-transition>
                    </v-img>
                    <v-card-text style="position: relative; height: 120" class="menu">
                      <v-btn absolute color="#005a9c" class="white--text" fab right top to="/dashboard/store">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                      <div class="title">{{ $t('AppNavigationMenu.my-tests') }}</div>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-col>
              <!-- Exams -->
              <v-col cols="3"  class="pa-1">
                <v-hover v-slot="{ hover }">
                  <v-card color="card" height="300px" class="text-center mx-auto">
                    <v-img height="235" src="@/assets/ImageExams.svg" contain>
                      <v-expand-transition>
                        <div v-if="hover" class="d-flex transition-fast-in-fast-out v-card--reveal" style="height: 100%; background-color: rgb(255, 176, 0)">
                          <v-col>
                            <v-btn v-if="UserObject.usr_role == 'admin'" rounded to="/dashboard/exams/create">{{ $t('AppNavigationMenu.create') }}</v-btn><br>
                            <v-btn v-if="UserObject.usr_role == 'admin'" rounded to="/dashboard/exams/manage" class="my-3">{{ $t('AppNavigationMenu.manage') }}</v-btn><br>
                            <v-btn rounded to="/dashboard/exams/participate">{{ $t('AppNavigationMenu.participate') }}</v-btn>
                          </v-col>
                        </div>
                      </v-expand-transition>
                    </v-img>
                    <v-card-text style="position: relative; height: 120" class="menu">
                      <div class="title">{{ $t('AppNavigationMenu.exams') }}</div>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
            <!-- statistics -->
            <v-row class="ma-1">
              <v-col cols="3"  class="pa-1">
                <v-hover v-slot="{ hover }">
                  <v-card color="card" height="300px" :to="'/dashboard/statistics'" class="text-center mx-auto">
                    <v-img height="235" src="@/assets/ImageStatistics.svg" contain>
                      <v-expand-transition>
                        <div v-if="hover" class="d-flex transition-fast-in-fast-out v-card--reveal" style="height: 100%; background-color: rgb(255, 94, 121)">
                          <v-col>
                            <div class="font-weight-light title buttonText--text">{{ $t('Dashboard.content') }}</div>
                            <br>
                            <div class="buttonText--text">{{ $t('Dashboard.statistics') }}</div>
                            <div class="buttonText--text">{{ $t('Dashboard.results') }}</div>
                          </v-col>
                        </div>
                      </v-expand-transition>
                    </v-img>
                    <v-card-text style="position: relative; height: 120" class="menu">
                      <div class="title">{{ $t('AppNavigationMenu.statistics') }}</div>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-col>
              <!-- achievements -->
              <v-col cols="3"  class="pa-1">
                <v-hover v-slot="{ hover }">
                  <v-card color="card" height="300px" class="text-center mx-auto">
                    <v-img height="235" src="@/assets/ImageAchievements.svg" contain>
                      <v-expand-transition>
                        <div v-if="hover" class="d-flex transition-fast-in-fast-out v-card--reveal" style="height: 100%; background-color: rgb(0, 149, 255)">
                          <v-col>
                            <div class="font-weight-light title buttonText--text">{{ $t('Dashboard.content') }}</div>
                            <br>
                            <div class="buttonText--text">{{ $t('Dashboard.achievements') }}</div>
                            <div class="buttonText--text">{{ $t('Dashboard.badges') }}</div>
                            <div class="buttonText--text">{{ $t('Dashboard.progress') }}</div>
                          </v-col>
                        </div>
                      </v-expand-transition>
                    </v-img>
                    <v-card-text style="position: relative; height: 120" class="menu">
                      <div class="title">{{ $t('AppNavigationMenu.achievements') }}</div>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-col>
              <!-- edit -->
              <v-col cols="3"  class="pa-1" v-if="(UserObject.usr_role == 'admin' || UserObject.usr_role == 'editor')">
                <v-hover v-slot="{ hover }">
                  <v-card color="card" height="300px" :to="'/dashboard/edit'" class="text-center mx-auto">
                    <v-img height="235" src="@/assets/ImageEdit.svg" contain>
                      <v-expand-transition>
                        <div v-if="hover" class="d-flex transition-fast-in-fast-out v-card--reveal" style="height: 100%; background-color: rgb(146, 209, 0)">
                          <v-col>
                            <div class="font-weight-light title buttonText--text">{{ $t('Dashboard.content') }}</div>
                            <br>
                            <div class="buttonText--text">{{ $t('Dashboard.edit') }}</div>
                            <div class="buttonText--text">{{ $t('Dashboard.release') }}</div>
                            <div class="buttonText--text">{{ $t('Dashboard.manage') }}</div>
                          </v-col>
                        </div>
                      </v-expand-transition>
                    </v-img>
                    <v-card-text style="position: relative; height: 120" class="menu">
                      <AppNavigationNewTest :pill="true"></AppNavigationNewTest>
                      <div class="title">{{ $t('AppNavigationMenu.edit-tests') }}</div>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-col>
              <!-- settings -->
              <v-col cols="3"  class="pa-1" v-if="SuccessfullLogin == true">
                <v-hover v-slot="{ hover }">
                  <v-card color="card" height="300px" :to="'/dashboard/settings'" class="text-center mx-auto">
                    <v-img height="235" src="@/assets/ImageSettings.svg" contain>
                      <v-expand-transition>
                        <div v-if="hover" class="d-flex transition-fast-in-fast-out v-card--reveal" style="height: 100%; background-color: rgb(255, 94, 121)">
                          <v-col>
                            <div class="font-weight-light title buttonText--text">{{ $t('Dashboard.content') }}</div>
                            <br>
                            <div class="buttonText--text">{{ $t('Dashboard.userdata') }}</div>
                            <div class="buttonText--text">{{ $t('Dashboard.password') }}</div>
                          </v-col>
                        </div>
                      </v-expand-transition>
                    </v-img>
                    <v-card-text style="position: relative; height: 120" class="menu">
                      <v-btn absolute color="#9c2d40" class="white--text" fab right top small @click.prevent="changeTheme()">
                        <v-icon>mdi-weather-sunny</v-icon>
                      </v-btn>
                      <v-btn absolute color="#9c2d40" class="white--text" fab right top small style="right: 70px" @click.prevent="changeLanguage()">
                        <v-icon>mdi-translate</v-icon>
                      </v-btn>
                      <div class="title">{{ $t('AppNavigationUser.user-settings') }}</div>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-col>

            </v-row>
          </v-card-text>
      </v-card>
    </v-row>
  </v-card>
</template>

<script>
  import testStoreKey from './test_store/testStoreKey'
  import AppNavigationNewTest from './app_navigation/AppNavigationNewTest'

  export default {
    name: 'Dashboard',
    components: {
      testStoreKey,
      AppNavigationNewTest,
    },
    data () {
      return{
        breadCrumbs: [
          {text: this.$i18n.t('AppNavigationMenu.dashboard'), disabled: true, to: '/dashboard', exact: true},
        ],
        windowWidth: 0, // width of the window
        windowHeight: 0, // height of the window
      }
    },
    mounted() {
      // event listener for window size
      this.$nextTick(function() {
        window.addEventListener('resize', this.getWindowWidth);
        window.addEventListener('resize', this.getWindowHeight);

        // Init
        this.getWindowWidth()
        this.getWindowHeight()
      })
    },
    beforeDestroy() {
      // remove eventlisteners when the component is destroyed
      window.removeEventListener('resize', this.getWindowWidth);
      window.removeEventListener('resize', this.getWindowHeight);
    },
    computed: {
      // gets confirmation from the store if the login was successfull
      SuccessfullLogin() {
        // console.log('User logged in?', this.$store.getters.successfullLogin)
        return this.$store.getters.successfullLogin
      },
      // User Object from the store
      UserObject() {
        return this.$store.getters.userData
      },
    },
    methods: {
      // quick method for theme change
      changeTheme() {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      },
      // quick method for lan change
      changeLanguage() {
        if(this.$i18n.locale == 'en' ) {
          this.$i18n.locale = 'de' 
        } else {
          this.$i18n.locale = 'en' 
        }
      },
      // get the width of the window
      getWindowWidth() {
          this.windowWidth = document.documentElement.clientWidth;
      },
      // get the height of the window
      getWindowHeight() {
          this.windowHeight = document.documentElement.clientHeight;
      },
    },
  }
</script>

<style>
  .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: 1;
    position: absolute;
    width: 100%;
  }
</style>

