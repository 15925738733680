<template>
    <v-container fluid class="ma-0 pa-0">
        <!-- intro and number of right answers -->
        <v-row>
            <v-col cols="12">
                <v-row justify="end" align="center">
                    <!-- <v-skeleton-loader :loading="true" type="text" class="mr-5" width="280" v-if="loadingState"></v-skeleton-loader> -->
                    <!-- number of users right answers -->
                    <v-alert outlined text class="mr-5" dense color="success" border="right" v-if="currentQuestion.checked && !currentQuestion.alternateResults && currentQuestion.userRight == currentQuestion.number_right">
                        {{ currentQuestion.userRight + $t('Questions.out-of') + currentQuestion.number_right + $t('Questions.correct')  }}
                    </v-alert>
                    <v-alert outlined text class="mr-5" dense color="error" border="right" v-if="currentQuestion.checked && !currentQuestion.alternateResults && currentQuestion.userRight != currentQuestion.number_right">
                        {{ currentQuestion.userRight + $t('Questions.out-of') + currentQuestion.number_right + $t('Questions.correct')  }}
                    </v-alert>
                </v-row>
                <!-- intro text -->
                <v-row justify="start" align="center" v-if="currentQuestion.intro != null">
                    <div class="body-1 ml-6">{{ currentQuestion.intro }}</div>
                    <v-icon class="mx-3">mdi-arrow-down</v-icon>
                </v-row>
            </v-col>
        </v-row>
        <!-- skeleton loader for answer choices -->
        <div v-if="loadingState">
            <v-row v-for="i in 5" :key="i" class="my-3 mx-3" align="center">
                <v-col cols="1">
                <v-skeleton-loader :loading="true" type="avatar" v-if="loadingState"></v-skeleton-loader>
                </v-col>
                <v-col cols="11">
                <v-skeleton-loader :loading="true" type="text" class="mt-2 mx-3" width="800" v-if="loadingState"></v-skeleton-loader>
                </v-col>
            </v-row>
        </div>
        <!-- answer choices -->
        <draggable :list="answers" :style="{'margin-top': badge == 1 ? '-20px': '' }" :disabled="badge == 1 ? true: false">
            <v-row v-for="answer in answers" :key="answer.id" class="mb-2 ml-2 mr-2">
                <v-container fluid class="ma-0 pa-0">
                    <!-- one card/box for each possible answer, THIS PART IS SHOWN WHEN THE CHECK BUTTON IS NOT PRESSED -->
                    <v-card style="border: solid; border-width: 1px" height="80px" elevation="0" v-if="!currentQuestion.checked">
                        <v-row style="height: 80px;" align="center" justify="space-around">
                            <div style="width: 60px" class="ml-8">
                                <v-select :value="answer.userPosition" filled rounded dense hide-details :items="number" :menu-props="{ offsetY: true }" append-icon="" @change="changeOrder(answer, $event)"></v-select>
                            </div>
                            <v-card elevation="0" tile color="rgba(255, 255, 255, 0)">
                                <!-- text of each possible answer -->
                                <div class="ml-3 subtitle-1 questionText--text">{{ answer.choice }}</div>
                            </v-card>
                            <v-spacer></v-spacer>
                            <v-col class="pa-0 mr-8" cols="1">
                                <v-row align="center" justify="end">
                                    <v-btn icon @click="moveUp(answer)">
                                        <v-icon>mdi-chevron-up</v-icon>
                                    </v-btn>
                                </v-row>
                                <v-row align="center" justify="end">
                                    <v-btn icon @click="moveDown(answer)">
                                        <v-icon>mdi-chevron-down</v-icon>
                                    </v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>
                    <!-- one card/box for each possible answer, THIS PART IS SHOWN WHEN THE CHECK BUTTON IS PRESSED -->
                    <v-card :style="{'border': answer.outline == 'outlinedTrue' ? 'solid' : 'dashed', 'border-color': answer.outline == 'outlinedTrue' ? '#5AB55E' : '#F65757' }" height="80px" elevation="0" v-if="currentQuestion.checked == true || badge == 1">
                        <v-row style="height: 80px;" align="center" justify="space-around">
                            <div style="width: 60px" class="ml-8">
                                <v-select :value="answer.userPosition" filled rounded dense hide-details :items="number" :menu-props="{ offsetY: true }" append-icon="" @change="changeOrder(answer, $event)" disabled></v-select>
                            </div>
                            <v-card elevation="0" tile color="rgba(255, 255, 255, 0)">
                                <!-- text of each possible answer -->
                                <div class="ml-3 subtitle-1 questionText--text">{{ answer.choice }}</div>
                            </v-card>
                            <v-spacer></v-spacer>
                            <v-col class="pa-0 mr-8" cols="1">
                                <v-row align="center" justify="end">
                                    <v-btn icon @click="moveUp(answer)" disabled>
                                        <v-icon>mdi-chevron-up</v-icon>
                                    </v-btn>
                                </v-row>
                                <v-row align="center" justify="end">
                                    <v-btn icon @click="moveDown(answer)" disabled>
                                        <v-icon>mdi-chevron-down</v-icon>
                                    </v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-badge :value="badge" overlap :icon="answer.badgeIcon" :color="answer.badgeColor" style="position: absolute; right: -1px; top: -1px"></v-badge>
                    </v-card>
                </v-container>
            </v-row>
        </draggable>
    </v-container>
</template>
<script>
    import draggable from "../../../node_modules/vuedraggable";
    export default {
        name: 'QuestionsContentSor',
        components: {
            draggable
        },
        props: ['assists', 'loadingState', 'noLoad', 'currentQuestion', 'checked', 'testMode', 'checkInput', 'allQuestions'],
        data () {
            return {
                badge: 0, // set to one after cklicking "check" => badges on top right side
                right: 0, // used to calculate how many answers were correctly ticked

                answers: [], 
                number: [],
            }
        },

        mounted() {
            this.answers = this.currentQuestion.answers
            this.number = this.currentQuestion.number
            if(!this.noLoad) {
                this.applyAnswerStyle() // applys the correct styling to answer cards
            } else {
                this.badge = 1
            }
        },

        watch: {
            // check functionality triggered by parent component
            checkInput() {
                this.checkUserInput()
            },
            answers: {
                deep: true,
                handler() {
                    this.answers.forEach( (element,i) => {
                        element.userPosition = i+1
                    });
                }
            },
        },

        methods: {
            // set visibility and style for answer cards
            applyAnswerStyle() {
                // dont show the solution to the current question because it isnt answered yet
                if(!this.currentQuestion.checked) {
                    this.badge = 0
                    this.right = 0
                // shows the solution of the current question when it is already answered (in non exam mode)
                } else if(this.currentQuestion.checked && this.testMode != 'exam_preparation') {
                    this.badge = 1
                }
            },
            // selected anwer gets moved one up
            moveUp: function(answer) {
                if(answer.userPosition != 1) {
                    Array.prototype.move = function (from, to) {
                        this.splice(to, 0, this.splice(from, 1)[0]);
                    };
                    this.answers.move(answer.userPosition-1, answer.userPosition-2)
                }
            },
            // selected answer gets moved one down
            moveDown: function(answer) {
                if(answer.userPosition != this.answers.length) {
                    Array.prototype.move = function (from, to) {
                        this.splice(to, 0, this.splice(from, 1)[0]);
                    };
                    this.answers.move(answer.userPosition-1, answer.userPosition)
                }
            },
            // change the order with select
            changeOrder(oldItem, newItem) {
                Array.prototype.move = function (from, to) {
                    this.splice(to, 0, this.splice(from, 1)[0]);
                };
                this.answers.move(oldItem.userPosition-1, newItem-1)

            },
            // check the user input after clicking "check"-button (compare with right answers from DB)
            checkUserInput() {
                // compare user input with the right answers from the DB
                var comparison = [0, 0, 0, 0, 0, 0]
                this.answers.forEach( (element,i) => {
                    if(element.rightPosition+1 == element.userPosition) {
                        comparison[i] = 1
                        this.right += 1
                        element['badgeColor'] = 'green'
                        element['badgeIcon'] = 'mdi-check'
                        element['outline'] = 'outlinedTrue'
                    } else {
                        element['badgeColor'] = 'red'
                        element['badgeIcon'] = 'mdi-close'
                        element['outline'] = 'outlinedFalse'
                    }
                })
                // show DB Values/checkboxes, disable checkboxes, show badge as addition to the colored borders
                this.badge = 1
                // set the border for test navigation on the right side of screen
                if(this.currentQuestion.markCheckbox == false) {
                    this.currentQuestion.outlinedProgress = 'outlinedChecked'
                }
                this.currentQuestion.checked = true // save that the answer got checked
                this.currentQuestion.userRight = this.right // save the result to the questions Object
                this.currentQuestion.number_right = this.answers.length // how many answers are right
                console.log(this.currentQuestion)
                this.$store.dispatch('getAlterAllQuestions', this.currentQuestion) // write results into store
            },

        },

    }
</script>