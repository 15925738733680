<template>
  <v-card tile :min-height="windowHeight - 76" elevation="0" color="rgba(0,0,0,0)">
    <v-row align="center" justify="center">
        <!-- layout of the editDB component -->
        <v-card color="rgba(0,0,0,0)" elevation="0" class="mx-5" width="1525">
            <!-- top Navigation -->
            <v-breadcrumbs :items="breadCrumbs" class="ma-0">
                <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                </template>
            </v-breadcrumbs>
            <v-divider></v-divider>
            <v-card-text :style="{height: windowHeight - 122 + 'px'}" class="scroll py-0 px-0" :class="scrollbarTheme">
                <v-data-table class="ma-2 elevation-2" :headers="headers" :items="myTests" :height="windowHeight - 200 + 'px'" @click:row="solveTest" fixed-header :locale="$i18n.locale" :footer-props="{'items-per-page-text': $t('Exercises.per-page')}">
                    <template v-slot:item.valid_to="{ item }">
                        <v-chip outlined :color="getColor(item)"> {{ item.valid_to.slice(0,10) }}</v-chip>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon class="mr-2" @click.stop="deletePossession(item)" :disabled="isValid(item)">mdi-delete</v-icon>
                        <v-icon @click.stop="renewPossession(item)" :disabled="isValid(item)">mdi-credit-card-sync</v-icon>
                    </template>
                    <template v-slot:item.test_name="{ item }">
                        <div class="subtitle-1 mt-3">{{ item.test_name }}</div>
                        <div class="body-2 mb-3 subtit--text">{{ item.test_info }}</div>
                    </template>
                    <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} {{ $t('Exercises.of') }} {{ items.itemsLength }} </template>
                </v-data-table>
            </v-card-text>
        </v-card>  
    </v-row>

  </v-card>
</template>

<script>

export default {
    name: 'TestList',

    components: {
    },

    data: function() {
      return {
        headers: [
            { text: this.$t('Exercises.actions'), value: 'actions', sortable: false, width: '100px', align: 'center', class: "subtitle-1"},
            { text: this.$t('Exercises.name'), value: 'test_name',  align: 'start', class: "subtitle-1"},
            { text: this.$t('Exercises.valid-to'), value: 'valid_to', align: 'end', class: "subtitle-1"},
        ],
        
        // breadcrumbs
        breadCrumbs: [
            {text: this.$i18n.t('AppNavigationMenu.dashboard'), disabled: false, to: '/dashboard', exact: true},
            {text: this.$i18n.t('Exercises.my-tests'), disabled: true, to: '/dashboard/exercises', exact: true},
        ],
        //dimensions
        windowWidth: 0, // width of the window
        windowHeight: 0, // height of the window
      }
    },

    mounted() {
        // event listener for window size
        this.$nextTick(function() {
            window.addEventListener('resize', this.getWindowWidth);
            window.addEventListener('resize', this.getWindowHeight);

            // Init
            this.getWindowWidth()
            this.getWindowHeight()
        })
      
    },

    beforeDestroy() {
        // remove eventlisteners when the component is destroyed
        window.removeEventListener('resize', this.getWindowWidth);
        window.removeEventListener('resize', this.getWindowHeight);
    },

    watch: {
    },
    
    computed: {
        // gets the theme from the vuetify plugin (dark/light)
        theme() {
            return this.$vuetify.theme.dark
        },
        // custom scrollbar theme
        scrollbarTheme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light';
        },
        // User Object from the store
        UserObject() {
            return this.$store.getters.userData
        },
        // gets own Tests from the store
        myTests() {
            // console.log(this.$store.getters.MyTests)
            return this.$store.getters.MyTests
        },
    },

    methods: {
        getColor(item) {
            if(new Date(item.valid_from) < new Date() && new Date(item.valid_to) > new Date()) {
                return 'success'
            } else {
                return 'error'
            }
        },
        isValid(item) {
            if(new Date(item.valid_from) < new Date() && new Date(item.valid_to) > new Date()) {
                return true
            } else {
                return false
            }
        },
        deletePossession(test) {
            var confirmed = confirm(this.$t('Exercises.confirm'))
            if(confirmed) {
                this.$http.post('index.php',    {   task:   'delete_possession',
                                                    userObject: this.UserObject,
                                                    testObject: test
                                                })
                        .then(response => {
                            if(response.body == 'success') {
                                this.$store.dispatch('getMyTests', this.UserObject)
                                this.$store.dispatch('getSnackbar', {status: true, timeout: 4000, color: 'success', btnColor: 'buttonText', text: this.$t('Exercises.removed') })
                            } else {
                                this.$store.dispatch('getSnackbar', {status: true, timeout: 4000, color: 'error', btnColor: 'buttonText', text: this.$t('Exercises.sessid') })
                            }
                        });
            }
        },
        renewPossession(test) {
            console.log(test)
        },
        solveTest(test) {
            this.$store.dispatch('getCurrentMenu', 'Exercises')
            this.$store.dispatch('getCurrentTest', test)
            this.$router.push('/dashboard/exercises/' + test.test_name.replace(/\s/g, ''))
        },
        // get the width of the window
        getWindowWidth() {
            this.windowWidth = document.documentElement.clientWidth;
        },
        // get the height of the window
        getWindowHeight() {
            this.windowHeight = document.documentElement.clientHeight;
        },
    },
}
</script>

<style scoped>
</style>