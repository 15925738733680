<template>
    <v-container class="ma-0 pa-0" fluid>
        <v-card color="card" height="284" class="pa-3">
            <!-- edit Content -->
            <v-list color="elevatedCard" rounded elevation="3">
                <v-list-item @click="editQuestions(getTestData.test_name_db)">
                    <v-list-item-avatar color="primary">
                        <v-icon color="buttonText">mdi-table-edit</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{ 'Edit Content' }}</v-list-item-title>
                        <v-list-item-subtitle>{{ 'Modify Questions' }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <!-- Review Content -->
            <v-list color="elevatedCard" rounded elevation="3" class="mt-3">
                <v-list-item @click="reviewQuestions(getTestData.test_name_db)">
                    <v-list-item-avatar color="primary">
                        <v-icon color="buttonText">mdi-eye-check-outline</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{ 'Review Content' }}</v-list-item-title>
                        <v-list-item-subtitle>{{ 'Evaluate Questions' }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <!-- Open Key Dialog -->
            <v-list color="elevatedCard" rounded elevation="3" class="mt-3">
                <v-list-item @click="dialog = true">
                    <v-list-item-avatar color="primary">
                        <v-icon color="buttonText">mdi-key</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{ 'Generate Key' }}</v-list-item-title>
                        <v-list-item-subtitle>{{ 'for Test Access' }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
        <!-- create key dialog -->
        <v-dialog v-model="dialog" width="600px" persistent>
            <v-card color="card">
                <v-card-title class="headline cardHeader white--text">Generate Test Key</v-card-title>
                <v-divider></v-divider>
                <!-- alert that shows success in creating key -->
                <v-alert class="my-2 mx-6" type="success" text outlined v-model="alert1" border="left" dismissible color="success" icon="mdi-checkbox-marked-circle-outline" close-text="Close Alert">
                    Successfully generated Test Key
                </v-alert>
                <!-- alert that shows session Id error -->
                <v-alert class="my-2 mx-6" type="error" text outlined v-model="alert2" border="left" dismissible color="error" icon="mdi-alert-circle-outline" close-text="Close Alert">
                    {{ $t('UserSettings.sessID-outdated') }}
                </v-alert>
                <v-card-text>
                    <!-- valid from textfields -->
                    <v-row align="center" class="mt-5 mb-4">
                        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <!-- textfield for valid from date -->
                                <v-text-field v-model="date" label="Valid from Date" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined hide-details class="mx-3"></v-text-field>
                            </template>
                            <!-- datepicker 1 -->
                            <v-date-picker v-model="date" no-title scrollable :first-day-of-week="1" :locale="$i18n.locale" color="primary">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menuClose">Cancel</v-btn>
                                <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                            </v-date-picker>
                        </v-menu>
                        <v-text-field outlined type="time" label="valid from Time" hide-details class="mx-3" v-model="time"></v-text-field> 
                    </v-row>
                    <!-- valid to textfields -->
                    <v-row align="center" class="mb-3 mt-4">
                        <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :return-value.sync="date2" transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on, attrs2 }">
                                <!-- textfield for valid to date -->
                                <v-text-field v-model="date2" label="Valid to Date" append-icon="mdi-calendar" readonly v-bind="attrs2" v-on="on" outlined hide-details class="mx-3"></v-text-field>
                            </template>
                            <!-- datepicker 2 -->
                            <v-date-picker v-model="date2" no-title scrollable :first-day-of-week="1" :locale="$i18n.locale" color="primary">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menuClose2">Cancel</v-btn>
                                <v-btn text color="primary" @click="$refs.menu2.save(date2)">OK</v-btn>
                            </v-date-picker>
                        </v-menu>
                        <v-text-field outlined type="time" label="valid to Time" hide-details class="mx-3" v-model="time2"></v-text-field> 
                    </v-row>
                    <v-row align="center" class="mt-3 px-3" v-if="generated == true">
                        <!-- textfield containing the generated key -->
                        <v-text-field readonly v-model="key" ref="textToCopy" outlined append-outer-icon="mdi-file-document-multiple-outline" @click:append-outer="copyText" hide-details></v-text-field>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <!-- close button for the key generator dialog -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="error" @click="close">close</v-btn>
                    <v-btn class="buttonText--text" color="primary" @click="generateKey">Generate</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>

    export default {
        name: 'EditDBTestActions',
        data: function() {
            return {
                dialog: false,

                date: new Date().toISOString().substr(0, 10),
                menu: false,
                menuTemp : false,
                time: new Date().toLocaleTimeString('en-US', { hour12: false, 
                                                    hour: "numeric", 
                                                    minute: "numeric"}),
                date2: new Date().toISOString().substr(0, 10),
                menu2: false,
                menuTemp2: false,
                time2: new Date().toLocaleTimeString('en-US', { hour12: false, 
                                                    hour: "numeric", 
                                                    minute: "numeric"}),
                keyObject: {},
                key: '',
                generated: false,
                alert1: false,
                alert2: false,
            }
        },
        watch: {
            // when datepicker 1 is closed the date value has to be saved
            menu() {
                if(this.menu == false && this.menuTemp == false) {
                    this.$refs.menu.save(this.date) 
                } else if(this.menu == true) {
                    this.menuTemp = false
                }
            },
            // when datepicker 2 is closed the second date has to be saved
            menu2() {
                if(this.menu2 == false && this.menuTemp2 == false) {
                    this.$refs.menu2.save(this.date2) 
                } else if(this.menu2 == true) {
                    this.menuTemp2 = false
                }
            },
        },
        computed: {
            // test data from the store
            getTestData() {
                // console.log(this.$store.getters.TestData)
                return this.$store.getters.TestData
            },
            // User Object from the store
            UserObject() {
                return this.$store.getters.userData
            },
        },
        methods: {
            // open edit DB Table component
            editQuestions: function(testNameDB) {
                // console.log(testNameDB)
                this.$store.dispatch('getCurrentMenu', 'EditDBTable')
                this.$store.dispatch('getQuestions', testNameDB)
                this.$router.push('/edit-test/table-view')
            },
            // open edit DB Review component
            reviewQuestions: function(testNameDB) {
                // console.log(testNameDB)
                this.$store.dispatch('getCurrentMenu', 'EditDBReview')
                this.$store.dispatch('getQuestions', testNameDB)
                this.$router.push('/edit-test/list-view')
            },
            // generate Test key and send it to the DB
            generateKey() {
                // console.log('Valid from: ' ,this.date + ' ' + this.time + ':00')
                this.keyObject.valid_from = this.date + ' ' + this.time + ':00'
                // console.log('Valid to: ' ,this.date2 + ' ' + this.time2 + ':00')
                this.keyObject.valid_to = this.date2 + ' ' + this.time2 + ':00'
                // random in function
                function getRandomInt( min, max ) {
                    return Math.floor( Math.random() * ( max - min + 1 ) ) + min;
                }
                // function that generates a product key
                function generateProductKey() {
                    var tokens = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
                        chars = 4,
                        segments = 4,
                        keyString = ""; 
                    for( var i = 0; i < segments; i++ ) {
                        var segment = "";
                        for( var j = 0; j < chars; j++ ) {
                            var k = getRandomInt( 0, 35 );
                            segment += tokens[ k ];
                        }
                        keyString += segment;
                        if( i < ( segments - 1 ) ) {
                            keyString += "-";
                        }
                    }  
                    return keyString;
                }
                var key = generateProductKey()
                // console.log(key)
                this.keyObject.key_phrase = key
                this.key = key
                this.keyObject.key_id = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) );
                this.keyObject.test_id = this.getTestData.test_id
                this.keyObject.test_name = this.getTestData.test_name_db
                this.keyObject.usr_session_id = this.UserObject.usr_session_id
                this.keyObject.usr_id = this.UserObject.usr_id
                // post to DB
                this.$http.post('index.php',    {   task:       'create_key',
                                                    keyObject:  this.keyObject, 
                                                })
                        .then(response => {
                            // console.log(response.body)
                            if(response.body == 'success') {
                                // console.log('success')
                                this.generated = true
                                this.alert1 = true
                            } else {
                                // console.log('sessionID outdated!')
                                this.alert2 = true
                            }
                        });
            },
            // close function for the test key generator dialog
            close() {
                this.dialog = false
                this.generated = false
                this.keyObject = {}
                this.date = new Date().toISOString().substr(0, 10),
                this.menu = false,
                this.time = new Date().toLocaleTimeString('en-US', { hour12: false, 
                                                    hour: "numeric", 
                                                    minute: "numeric"})
                this.date2 = new Date().toISOString().substr(0, 10),
                this.menu2 = false,
                this.time2 = new Date().toLocaleTimeString('en-US', { hour12: false, 
                                                    hour: "numeric", 
                                                    minute: "numeric"})
                this.alert1 = false
                this.alert2 = false
            },
            // close function for the first datepicker
            menuClose() {
                this.menu = false
                this.menuTemp = true
            },
            // close function for the second datepicker
            menuClose2() {
                this.menu2 = false
                this.menuTemp2 = true
            },
            // function to copy the test key
            copyText () {
                let textToCopy = this.$refs.textToCopy.$el.querySelector('input')
                textToCopy.select()
                document.execCommand("copy");
            },
        }
    }
</script>
