<template>
    <v-container class="ma-0 pa-0" fluid>
        <v-card :height="windowHeight - 109 + 'px'" class="mt-5 mx-2" elevation="1">
            <!-- toolbar with search and filtering functionality -->
            <v-toolbar elevation="0">
                <v-toolbar-title>{{ 'Review ' + getTestData.test_name }}</v-toolbar-title>
                <v-divider vertical class="mx-4" inset></v-divider>
                <!-- search functionality -->
                <v-combobox label="Search/Filter" hide-selected multiple small-chips solo style="margin-top: 28px" v-model="model" :hide-no-data="!search" :items="items" :search-input.sync="search" :filter="filter">
                    <template v-slot:no-data>
                        <v-list-item>
                            <span class="subheading">Create</span>
                            <v-chip :color="`${colors[nonce - 1]} lighten-1`" label small>{{ search }}</v-chip>
                        </v-list-item>
                    </template>
                    <template v-slot:selection="{ attrs, item, parent, selected }">
                        <v-chip v-if="item === Object(item)" v-bind="attrs" :color="`${item.color} lighten-1`" :input-value="selected" label small>
                            <span class="pr-2">{{ item.text }}</span>
                            <v-icon small @click="parent.selectItem(item)">mdi-close</v-icon>
                        </v-chip>
                    </template>
                    <template v-slot:item="{ index, item }">
                        <v-text-field v-if="editing === item" v-model="editing.text" autofocus flat background-color="transparent" hide-details solo @keyup.enter="edit(index, item)"></v-text-field>
                        <v-chip v-else :color="`${item.color} lighten-1`" dark label small>{{ item.text }}</v-chip>
                        <v-spacer></v-spacer>
                        <v-list-item-action @click.stop>
                            <v-btn icon @click.stop.prevent="edit(index, item)">
                                <v-icon>{{ editing !== item ? 'mdi-pencil' : 'mdi-check' }}</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </template>
                </v-combobox>
                <!-- sorting menu -->
                <v-menu offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark v-bind="attrs" v-on="on" class="mx-3" outlined>Sort<v-icon>mdi-chevron-down</v-icon></v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                            <v-list-item-action>
                                <v-checkbox v-model="checkbox"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-title>only unverified</v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-radio-group mandatory v-model="radio">
                            <v-list-item>
                                <v-list-item-action>
                                    <v-radio value="radio-1"></v-radio>
                                </v-list-item-action>
                                <v-list-item-title>last edited date</v-list-item-title>
                                <v-list-item-action>
                                    <v-btn icon @click.stop="arrow1 = !arrow1">
                                        <v-icon v-if="arrow1">mdi-arrow-down</v-icon>
                                        <v-icon v-else>mdi-arrow-up</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-action>
                                    <v-radio value="radio-2"></v-radio>
                                </v-list-item-action>
                                <v-list-item-title>added date</v-list-item-title>
                                <v-list-item-action>
                                    <v-btn icon @click.stop="arrow2 = !arrow2">
                                        <v-icon v-if="arrow2">mdi-arrow-down</v-icon>
                                        <v-icon v-else>mdi-arrow-up</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-radio-group>
                    </v-list>
                </v-menu>
                <v-switch class="mx-3 mt-1 mb-1" label="Review Mode" hide-details v-model="switchMode" @change="emitMenu"></v-switch>
                <!-- Dialog (+ Button to open the Dialog) for adding a new Question (or edit an existing one) -->
                <EditDBDialog @closed="alterData" :triggerEdit="triggerEdit" :editItem="editedItem" :editIndex="editedIndex"></EditDBDialog>
            </v-toolbar>
            <v-divider></v-divider>
            <!-- List with all Questions -->
            <v-card-text :style="{height: windowHeight - 233 + 'px'}" class="scroll pa-0" :class="scrollbarTheme">
                <v-list dense two-line>
                    <v-list-item-group>
                        <template v-for="(item, i) in questions">
                            <v-list-item :key="item.question_id">
                                <!-- edit button -->
                                <v-list-item-action>
                                    <v-btn icon @click="editItem(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                                </v-list-item-action>
                                <v-list-item-content @click="dialogPreview = true" @click.prevent="selected = i">
                                    <v-list-item-subtitle>{{ item.situation }}</v-list-item-subtitle>
                                    <v-list-item-title>{{ item.question }}</v-list-item-title>
                                    <v-list-item-subtitle v-if="item.verified == 1" class="success--text">verified</v-list-item-subtitle>
                                    <v-list-item-subtitle v-else class="error--text">not verified</v-list-item-subtitle>
                                </v-list-item-content>
                                <!-- delete button -->
                                <v-list-item-action>
                                    <v-list-item-action-text>{{ item.last_edited_date }}</v-list-item-action-text>
                                    <v-btn icon @click="deleteItem(item)"><v-icon>mdi-delete</v-icon></v-btn>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider v-if="i < questions.length - 1" :key="i"></v-divider>
                        </template>
                    </v-list-item-group>
                </v-list>
            </v-card-text>
            <v-divider></v-divider>
            <!-- bottom bar with stytistic content -->
            <v-card-actions>
                <div class="mt-2 ml-3">Visible Items:</div><div class="mt-2 mr-3 ml-2 primary--text">{{ questions.length }}</div>
                <div class="mt-2 ml-3">All Items:</div><div class="mt-2 mr-3 ml-2 primary--text">{{ questions.length }}</div>
                <div class="mt-2 ml-3">Items in public Test:</div><div class="mt-2 mr-3 ml-2 success--text">{{ questions.filter(question => question.verified == 1).length }}</div>
            </v-card-actions>
        </v-card>
        <!-- dialog that shows layout preview -->
        <v-dialog v-model="dialogPreview" width="1200">
            <v-card class="scroll" height="776" :class="scrollbarTheme">
                <QuestionsHeader :getAllQuestions="questions" :iterator="selected" :assists="false" :testMode="'question_limit'" :checkInput="false" :noLoad="true"></QuestionsHeader>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
    import QuestionsHeader from '../test_exercises/QuestionsHeader';
    import EditDBDialog from './EditDBDialog';

    export default {
        name: 'EditDBReview',
        components: {
            QuestionsHeader,
            EditDBDialog,
        },
        data: () => {
            return {
                switchMode: true, // wether the review mode is active or not
                search: null, // search variable with the user input
                model: [], // array with active chips
                items: [ // suggested search items
                    { header: 'Select an option or create one' },
                    {
                        text: 'question = "your search"',
                        color: 'blue',
                    },
                    {
                        text: 'situation = "your search"',
                        color: 'red',
                    },
                    {
                        text: 'intro = "your search"',
                        color: 'lime',
                    },
                    {
                        text: 'answer_1 = "your search"',
                        color: 'amber',
                    },
                    {
                        text: 'last_edited_by = "your search"',
                        color: 'purple',
                    },
                    {
                        text: 'added_by = "your search"',
                        color: 'teal',
                    },
                    {
                        text: 'last_edited_date > "your search"',
                        color: 'orange',
                    },
                ],
                activator: null,
                attach: null,
                colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange'],
                editing: null,
                
                nonce: 1,
                menu: false,
                x: 0,
                y: 0,

                radio: 'radio-1',
                arrow1: false, //   last edited date sort up/down
                arrow2: false, // added date sort up/down
                checkbox: false, // hide non verified items
                sortCase: 0, // which sort is currently applied

                selected: '', // which list item is selected
                
                dialogPreview: false, // wether to show the preview question dialog

                questions: [], // all the data for the list
                editingIndex: -1,

                editedItem: {}, // all data from the currently edited item in the table (or from the new item)
                editedIndex: -1, // index of the edited Question (also used to handle the title of the dialog)
                triggerEdit: false, // triggers edit dialog in child component

                windowWidth: 0, // width of the window
                windowHeight: 0, // height of the window
            }
        },

        mounted() {
            if(this.getAllQuestions.length > 0) {
                this.questions = this.getAllQuestions
                this.$store.dispatch('getHF', this.getTestData.test_subtopic_id)
            }
            // event listener for window size
            this.$nextTick(function() {
                window.addEventListener('resize', this.getWindowWidth);
                window.addEventListener('resize', this.getWindowHeight);

                // Init
                this.getWindowWidth()
                this.getWindowHeight()
            })
        },

        beforeDestroy() {
            // remove eventlisteners when the component is destroyed
            window.removeEventListener('resize', this.getWindowWidth);
            window.removeEventListener('resize', this.getWindowHeight);
        },

        watch: {
            switchMode() {
                if(this.switchMode == false) {
                    this.$router.push('/edit-test/table-view')
                }
            },
            arrow1() {
                this.caseCalc()
            }, 
            arrow2() {
                this.caseCalc()
            },
            radio() {
                this.caseCalc()
            },
            checkbox() {
                this.caseCalc()
            },
            model (val, prev) {
                if (val.length === prev.length) return
                this.model = val.map(v => {
                if (typeof v === 'string') {
                    v = {
                    text: v,
                    color: this.colors[this.nonce - 1],
                    }
                    this.items.push(v)
                    this.nonce++
                }
                return v
                })
            },
            // reset trigger 1 second after it launched
            triggerEdit() {
                setTimeout(() => this.triggerEdit = false, 1000);
            },
            getAllQuestions() {
                this.questions = this.getAllQuestions
                this.$store.dispatch('getHF', this.getTestData.test_subtopic_id)
            },
        },

        computed: {
            // get questions array from store
            getAllQuestions() {
                // console.log('QuestionData:', this.$store.getters.questions)
                return this.$store.getters.questions
            },
            // get the test data from the store
            getTestData() {
                // console.log(this.$store.getters.TestData)
                return this.$store.getters.TestData
            },
            // custom scrollbar
            scrollbarTheme() {
                return this.$vuetify.theme.dark ? 'dark' : 'light';
            },
            
        },

        methods: {
            alterData(item, index) {
                if(index > -1) {
                    Object.assign(this.questions[index], item)
                } else {
                    this.questions.push(item)
                }
                this.caseCalc()
            },
            caseCalc() {
                if(this.radio == 'radio-1') {
                    if(this.arrow1 == true) {
                        if(this.checkbox == true) {
                            this.questions.sort((x, y) => +new Date(x.last_edited_date) - +new Date(y.last_edited_date)).filter(question => question.verified == 0);
                        } else {
                            this.questions.sort((x, y) => +new Date(x.last_edited_date) - +new Date(y.last_edited_date));
                        }
                    } else {
                        if(this.checkbox == true) {
                            this.questions.sort((x, y) => +new Date(y.last_edited_date) - +new Date(x.last_edited_date)).filter(question => question.verified == 0);
                        } else {
                            this.questions.sort((x, y) => +new Date(y.last_edited_date) - +new Date(x.last_edited_date));
                        }
                    }
                } else {
                    if(this.arrow2 == true) {
                        if(this.checkbox == true) {
                            this.questions.sort((x, y) => +new Date(x.added_date) - +new Date(y.added_date)).filter(question => question.verified == 0);
                        } else {
                            this.questions.sort((x, y) => +new Date(x.added_date) - +new Date(y.added_date));
                        }
                    } else {
                        if(this.checkbox == true) {
                            this.questions.sort((x, y) => +new Date(y.added_date) - +new Date(x.added_date)).filter(question => question.verified == 0);
                        } else {
                            this.questions.sort((x, y) => +new Date(y.added_date) - +new Date(x.added_date));
                        }
                    }
                }
            },
            edit (index, item) {
                if (!this.editing) {
                this.editing = item
                this.editingIndex = index
                } else {
                this.editing = null
                this.editingIndex = -1
                }
            },
            filter (item, queryText, itemText) {
                if (item.header) return false

                const hasValue = val => val != null ? val : ''

                const text = hasValue(itemText)
                const query = hasValue(queryText)

                return text.toString()
                .toLowerCase()
                .indexOf(query.toString().toLowerCase()) > -1
            },
            // edit the question
            editItem (item) {
                this.editedIndex = this.questions.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.triggerEdit = true
            },
            // delete the question
            deleteItem (item) {
                const index = this.questions.indexOf(item)
                var confirmed = confirm('Are you sure you want to delete this item?') && this.questions.splice(index, 1)
                if(confirmed != false) {
                this.$http.post('index.php',    {   task:       'delete_question',
                                                    questionID: item.question_id,
                                                    testName:   this.getTestData.test_name_db
                                                })
                    .then(response => {
                        // console.log('delete response:', response.body)
                        this.$store.dispatch('getSnackbar', {status: true, timeout: 3000, color: '', btnColor: 'error', text: 'deleted item: ' + response.body.delete_item })
                    });
                }
            },
            emitMenu: function() {
                this.$store.dispatch('getCurrentMenu', 'EditDBMultipleChoice')
            },
            // get the width of the window
            getWindowWidth() {
                this.windowWidth = document.documentElement.clientWidth;
            },
            // get the height of the window
            getWindowHeight() {
                this.windowHeight = document.documentElement.clientHeight;
            },
        },
    }
</script>

<style scoped>
  .scroll {
    overflow-y: auto
  }
</style>