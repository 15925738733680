<template>
    <v-container class="ma-0 pa-0" fluid>
        <v-card color="card" min-height="284" class="pa-3">
            <v-card color="elevatedCard" height="258">
                <v-card-title>Release in Store</v-card-title>
                <v-card-text>
                    <div>Make the Test available for all Users to purchase. Every verified Question can be Answered in the 3 different Test-Modes.</div>
                    <v-alert outlined text :color="testInStore == 1 ? 'success' : 'error' " class="mt-5">
                        <v-card-title>
                            <v-btn icon :color="testInStore == 1 ? 'success' : 'error'" @click="saveStoreValue()" class="mr-3" :disabled="disableEditing">
                                <v-icon large>{{ testInStore == 1 ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
                            </v-btn>
                            <div>{{ testInStore == 1 ? 'Test available in Store' : 'Test private' }}</div>
                        </v-card-title>
                    </v-alert>
                </v-card-text>
            </v-card>
        </v-card>
    </v-container>
</template>
<script>

    export default {
        name: 'EditDBTestRelease',
        props: ['disableEditing'],
        data: function() {
            return {
                testInStore: 0,
            }
        },
        mounted() {
            this.testInStore = parseInt(this.getTestData.test_in_store)
        },
        watch: {
            getTestData() {
                this.testInStore = parseInt(this.getTestData.test_in_store)
            }
        },
        computed: {
            // test data from the store
            getTestData() {
                // console.log(this.$store.getters.TestData)
                return this.$store.getters.TestData
            },
            // User Object from the store
            UserObject() {
                return this.$store.getters.userData
            },
        },
        methods: {
            // save if the test is shown in the store
            saveStoreValue() {
                if(this.testInStore == 1) {
                    this.testInStore = 0
                } else {
                    this.testInStore = 1
                }
                // console.log(this.getTestData.test_id)
                this.$http.post('index.php',    {   task:                   'save_store_value',
                                                    saveStoreValueObject:   this.testInStore,
                                                    testObject:             this.getTestData.test_id,
                                                    userObject:             this.UserObject 
                                                })
                        .then(response => {
                            // console.log(response.body)
                            if(response.body == 'success') {
                                this.$store.dispatch('getMyTests', this.UserObject)
                                this.$store.dispatch('getMyEdits', this.UserObject)
                                this.$store.dispatch('getSnackbar', {status: true, timeout: 4000, color: 'success', btnColor: 'buttonText', text: this.testInStore == 1 ? 'Published Test' : 'Test is now private' })
                            } else {
                                // console.log('sessionID outdated!')
                                this.$store.dispatch('getSnackbar', {status: true, timeout: 4000, color: 'error', btnColor: 'buttonText', text: 'SessionID Outdated. Log in again!' })
                            }
                        });
            },
        }
    }
</script>
