import Vue from 'vue';
import VueResource from 'vue-resource'

Vue.use(VueResource, {})

const state = {
    Questions: {},
    AllQuestions: {},
    TimeLimit: 45,
    QuestionNumber: 3,
    HF: [],
    AllTypes: {},
    CurrentQuestion: {},
};

const getters = {
    questions: state => {
        return state.Questions
    },
    allQuestions: state => {
        return state.AllQuestions
    },
    timeLimit: state => {
        return state.TimeLimit
    },
    questionNumber: state => {
        return state.QuestionNumber
    },
    allHF: state => {
        return state.HF
    },
    allTypes: state => {
        return state.AllTypes
    },
    currentQuestion: state => {
        return state.CurrentQuestion
    },
};

const actions = {
    getQuestions({commit}, testNameDB) {
        // console.log('Limit:', data[0])
        // console.log('Handlungsfelder:', data[1])
        Vue.http.post('index.php',  {   task:       'get_all_questions',
                                        testName:   testNameDB
                                    })
            .then(response => {
                response.body.exam_data.forEach(element => {
                    if(element.typ == "mul") {
                        // build answer choices array
                        var answersDB = [   element.answer_1,
                                            element.answer_2,
                                            element.answer_3,
                                            element.answer_4,
                                            element.answer_5,
                                            element.answer_6,
                                        ]
                        // build right answers array
                        var rightAnswer =   [   element.answer_1_state, 
                                                element.answer_2_state, 
                                                element.answer_3_state, 
                                                element.answer_4_state, 
                                                element.answer_5_state,
                                                element.answer_6_state
                                            ]
                        // remove empty answer choices
                        var x = 0
                        for (var i = 0; i < 6; i++) {
                            // console.log('answersDB:', answersDB[i])
                            if(answersDB[i] === null || answersDB[i] === undefined) {
                                answersDB.splice(i + x,1)
                                x -= 1
                            }
                        }
                        // build answers object
                        let answersMT = new Array(answersDB.length).fill().map((_, i) => ({ id: i }));
                        answersMT.forEach(( element,k) => {
                            if(rightAnswer[k] == "1") {
                                element['outline'] = 'outlinedTrue'
                                element['badgeIcon'] = 'mdi-check'
                                element['badgeColor'] = 'green'
                                element['checkbox'] = 1
                            } else {
                                element['outline'] = 'outlinedFalse'
                                element['badgeIcon'] = 'mdi-close'
                                element['badgeColor'] = 'red'
                                element['checkbox'] = 0
                            }
                            element['choice'] = answersDB[k]
                            element['selected'] = 0
                            element['state'] = rightAnswer[k]
                            element['userCheck'] = 'blank'
                        })
                        element.answers = answersMT
                        // initial values for the navigation boxes on the right side of test
                        element['outlinedProgress'] = 'outlined'
                        element['checked'] = true
                        element['markCheckbox'] = false
                        element['userRight'] = element.number_right
                        element['fullType'] = 'Multiple Choice'
                    } else {
                        let answersDB = [
                            element.item_1,
                            element.item_2,
                            element.item_3,
                            element.item_4,
                            element.item_5,
                            element.item_6,
                        ]
                        // remove empty answer choices
                        let x = 0
                        for (let i = 0; i < 6; i++) {
                            // console.log('answersDB:', answersDB[i])
                            if(answersDB[i] === null || answersDB[i] === undefined) {
                                answersDB.splice(i + x,1)
                                x -= 1
                            }
                        }
                        // build answers object
                        let answersMT = new Array(answersDB.length).fill().map((_, i) => ({ id: i }));
                        answersMT.forEach( (element,k) => {
                            element['choice'] = answersDB[k]
                            element['userPosition'] = k
                            element['rightPosition'] = k
                            element['badgeColor'] = 'green'
                            element['badgeIcon'] = 'mdi-check'
                            element['outline'] = 'outlinedTrue'
                        })
                        element.answers = answersMT
                        let number = []
                        for(var j=1; j<=answersMT.length; j++) {
                            number.push(j)
                        }
                        element.number = number
                        // initial values for the navigation boxes on the right side of test
                        element['outlinedProgress'] = 'outlined'
                        element['checked'] = true
                        element['markCheckbox'] = false
                        element['userRight'] = element.answers.length
                        element['number_right'] = element.answers.length
                        element['fullType'] = 'Sequence'
                    }
                });
                commit('setQuestions', response.body.exam_data);
            });
    },
    getAllQuestions({commit}, data) {
        // console.log('Limit:', data[0])
        // console.log('Handlungsfelder:', data[1])
        Vue.http.post('index.php',  {   task:       'get_questions',
                                        limit:      data[0],
                                        subtopic_s: data[1],
                                        testName:   data[2] 
                                    })
            .then(response => {
                // console.log(response.body.exam_data.length)
                response.body.exam_data.forEach(element => {
                    if(element.typ == "mul") {
                        // build answer choices array
                        let answersDB = [   element.answer_1,
                                            element.answer_2,
                                            element.answer_3,
                                            element.answer_4,
                                            element.answer_5,
                                            element.answer_6,
                                        ]
                        // build right answers array
                        let rightAnswer =   [   element.answer_1_state, 
                                                element.answer_2_state, 
                                                element.answer_3_state, 
                                                element.answer_4_state, 
                                                element.answer_5_state,
                                                element.answer_6_state
                                            ]
                        // remove empty answer choices
                        let x = 0
                        for (let i = 0; i < 6; i++) {
                            // console.log('answersDB:', answersDB[i])
                            if(answersDB[i] === null || answersDB[i] === undefined) {
                                answersDB.splice(i + x,1)
                                x -= 1
                            }
                        }
                        // build answers object
                        let answersMT = new Array(answersDB.length).fill().map((_, i) => ({ id: i }));
                        let k = 0
                        answersMT.forEach(element => {
                            element['choice'] = answersDB[k]
                            element['outline'] = 'outlined'
                            element['badgeIcon'] = 'mdi-minus'
                            element['badgeColor'] = 'grey'
                            element['selected'] = 0
                            element['state'] = rightAnswer[k]
                            k++
                        })
                        // shuffle the answer choices array
                        let counter = answersMT.length;
                        // While there are elements in the array
                        while (counter > 0) {
                            // Pick a random index
                            let index = Math.floor(Math.random() * counter);
                            // Decrease counter by 1
                            counter--;
                            // And swap the last element with it
                            let temp = answersMT[counter];
                            answersMT[counter] = answersMT[index];
                            answersMT[index] = temp;
                        }
                        element.answers = answersMT
                        // initial values for the navigation boxes on the right side of test
                        element['outlinedProgress'] = 'outlined'
                        element['checked'] = false
                        element['markCheckbox'] = false
                    } else {
                        let answersDB = [
                            element.item_1,
                            element.item_2,
                            element.item_3,
                            element.item_4,
                            element.item_5,
                            element.item_6,
                        ]
                        // remove empty answer choices
                        let x = 0
                        for (let i = 0; i < 6; i++) {
                            // console.log('answersDB:', answersDB[i])
                            if(answersDB[i] === null || answersDB[i] === undefined) {
                                answersDB.splice(i + x,1)
                                x -= 1
                            }
                        }
                        // build answers object
                        let answersMT = new Array(answersDB.length).fill().map((_, i) => ({ id: i }));
                        answersMT.forEach( (element,k) => {
                            element['choice'] = answersDB[k]
                            element['userPosition'] = undefined
                            element['rightPosition'] = k
                        })
                        // shuffle the answer choices array
                        let counter = answersMT.length;
                        // While there are elements in the array
                        while (counter > 0) {
                            // Pick a random index
                            let index = Math.floor(Math.random() * counter);
                            // Decrease counter by 1
                            counter--;
                            // And swap the last element with it
                            let temp = answersMT[counter];
                            answersMT[counter] = answersMT[index];
                            answersMT[index] = temp;
                        }
                        element.answers = answersMT
                        let number = []
                        for(var j=1; j<=answersMT.length; j++) {
                            number.push(j)
                        }
                        element.number = number
                        // initial values for the navigation boxes on the right side of test
                        element['outlinedProgress'] = 'outlined'
                        element['checked'] = false
                        element['markCheckbox'] = false
                    }
                });
                commit('setAllQuestions', response.body);
                commit('setQuestionNumber', response.body.exam_data.length)
            });
    },
    getTimeLimit({commit}, time) {
        commit('setTimeLimit', time)
    },
    getHF({commit}, subtopics) {
        Vue.http.post('index.php',  {   task:       'get_subtopics',
                                        subtopics:  subtopics
                                    })
            .then(response => {
                // console.log(response.body)
                if(response.body.length != 0) {
                    var HF = [response.body.HF[0].subtopic_1, response.body.HF[0].subtopic_2, response.body.HF[0].subtopic_3, response.body.HF[0].subtopic_4]
                    commit('setHF', HF);
            }
            });    
    },
    getAllQuestionTypes({commit}) {
        Vue.http.post('index.php',  {   task: 'get_types',
                                        type: 'test',
                                    })
            .then(response => {
                // console.log(response.body)
                var type = response.body
                commit('setTypes', type)            
            });
    },
    getCurrentQuestion({commit}, data) {
        commit('setCurrentQuestion', data)
    },
    getAlterAllQuestions({commit}, currentQuestion) {
        commit('setAlterAllQuestions', currentQuestion)
    }
};

const mutations = {
    setQuestions: (state, data) => {
        state.Questions = data.sort((x, y) => +new Date(y.last_edited_date) - +new Date(x.last_edited_date))
    },
    setAllQuestions: (state, data) => {
        state.AllQuestions = data
    },
    setTimeLimit: ( state, time) => {
        state.TimeLimit = time
    },
    setHF: (state, data) => {
        state.HF = data
    },
    setQuestionNumber: (state, data) => {
        state.QuestionNumber = data
    },
    setTypes: (state, data) => {
        state.AllTypes = data
    },
    setCurrentQuestion: (state, data) => {
        state.CurrentQuestion = data
    },
    setAlterAllQuestions: (state, currentQuestion) => {
        state.AllQuestions.exam_data.forEach(element => {
            if(element.question_id == currentQuestion.question_id) {
                element.checked = currentQuestion.checked
                element.userRight = currentQuestion.userRight
                element.outlinedProgress = currentQuestion.outlinedProgress
                element.alternateResults = currentQuestion.alternateResults
            }
        });
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};