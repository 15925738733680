<template>
    <v-card tile :min-height="windowHeight - 76" elevation="0" color="rgba(0,0,0,0)">
        <v-row justify="center">
            <!-- layout of the statistics component -->
            <v-card color="rgba(0,0,0,0)" elevation="0" class="mx-5" width="1525">
                <!-- top Navigation -->
                <v-breadcrumbs :items="breadCrumbs" class="ma-0">
                    <template v-slot:divider>
                        <v-icon>mdi-chevron-right</v-icon>
                    </template>
                </v-breadcrumbs>

                <v-divider></v-divider>
                <v-card-text :style="{height: windowHeight - 122 + 'px'}" class="scroll pa-0" :class="scrollbarTheme">
                    <v-row class="ma-1" v-if="UserObject.usr_role == 'admin'">
                        <v-col cols="12" class="pa-1">
                            <!-- view finished exams -->
                            <v-card color="card">
                                <v-toolbar flat color="card">
                                    <div class="ml-5 mr-9 font-weight-bold">{{ $t('Exam.participants') }}</div>
                                    <div class="font-weight-bold ml-5">{{ $t('Exam.exam-name') }}</div>
                                    <v-spacer></v-spacer>
                                    <div class="mr-3 font-weight-bold">{{ $t('Exam.date') }}</div>
                                </v-toolbar>
                                <v-divider></v-divider>
                                <v-card-text style="height: 500px;" class="scroll pa-0" :class="scrollbarTheme">
                                    <!-- list with all previous exams -->
                                    <v-list two-line color="card">
                                        <v-list-item-group>
                                            <template v-for="(exam, i) in exams">
                                                <v-list-item :key="exam.exam_id">
                                                    <template>
                                                        <v-list-item-icon>
                                                            <div class="ml-12 mr-10">{{ exam.participants }}</div>
                                                        </v-list-item-icon>
                                                        <v-list-item-content style="margin-top: -18px" class="ml-6" @click="openDialog(i)">
                                                            <v-list-item-title>{{ exam.exam_name }}</v-list-item-title>
                                                            <v-list-item-subtitle class="text--primary">{{ $t('Exam.based-on') + ': ' + exam.test_name }}</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        <v-list-item-action style="margin-top: -5px" class="mr-3">
                                                            <v-list-item-action-text>{{ exam.valid_from }}</v-list-item-action-text>
                                                        </v-list-item-action>
                                                    </template>
                                                </v-list-item>
                                            </template>
                                        </v-list-item-group>
                                    </v-list>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-row>
        <v-dialog v-model="dialog" fullscreen hide-overlay>
            <v-card v-if="dialog == true" color="bottom">
                <v-toolbar color="primary" dark tile>
                    <v-toolbar-title>{{ exams[selected].exam_name }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn @click="dialog = false" icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text :style="{height: windowHeight - 64 + 'px'}" class="scroll" :class="scrollbarTheme">
                    <v-row justify="center" class="ma-0">
                        <v-card elevation="0" color="rgba(0,0,0,0)" width="1525">
                            <v-expansion-panels v-model="panels" multiple>
                                <v-expansion-panel class="card mt-7" :disabled="!disabled">
                                    <v-expansion-panel-header class="title">
                                        {{ $t('Exam.manage-participants') }}
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-row v-if="card1 || card2">
                                            <v-col cols="12">
                                                <!-- alert that shows update success -->
                                                <v-alert type="success" text outlined v-model="card2" border="left" dismissible color="success" icon="mdi-checkbox-marked-circle-outline" close-text="Close Alert">
                                                {{ $t('Exam.success-change') }}
                                                </v-alert>
                                                <!-- alert that shows session Id error -->
                                                <v-alert type="error" text outlined v-model="card1" border="left" dismissible color="error" icon="mdi-alert-circle-outline" close-text="Close Alert">
                                                {{ $t('UserSettings.sessID-outdated') }}
                                                </v-alert>
                                            </v-col>
                                        </v-row>
                                        <v-row align="center">
                                            <v-col cols="3">
                                                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="290px">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <!-- textfield for valid from date -->
                                                        <v-text-field v-model="date" :label="$t('Exam.date')" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined hide-details></v-text-field>
                                                    </template>
                                                    <!-- datepicker 1 -->
                                                    <v-date-picker v-model="date" no-title scrollable :first-day-of-week="1" :locale="$i18n.locale">
                                                        <v-spacer></v-spacer>
                                                        <v-btn text color="primary" @click="menuClose">Cancel</v-btn>
                                                        <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field outlined type="time" :label="$t('Exam.start-time')" :rules="timeRules" v-model="time" hide-details></v-text-field>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-btn color="primary" @click="saveDate()">{{ $t('Exam.save-date') }}</v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="8">
                                                <v-card>
                                                    <v-card-text style="height: 320px;" class="scroll pa-0" :class="scrollbarTheme">
                                                        <div v-for="(participant, i) in participants" :key="participant.key_id">
                                                            <v-row align="center" class="mx-3 my-3">
                                                                <v-icon v-if="participant.mail_send == 0" color="error">mdi-close-circle</v-icon>
                                                                <v-icon v-else color="success">mdi-check-circle</v-icon>
                                                                <v-spacer></v-spacer>
                                                                <div>{{ participant.mail }}</div>
                                                                <v-spacer></v-spacer>
                                                                <v-btn @click="sendMail(i)" color="primary" v-if="participant.mail_send == 0">{{ $t('Exam.invite') }}</v-btn>
                                                                <v-btn outlined color="primary" v-else @click="sendMail(i)">{{ $t('Exam.send-again') }}</v-btn>
                                                            </v-row>
                                                            <v-divider class="my-3" v-if="i < participants.length -1"></v-divider>
                                                            <div v-else class="my-3"></div>
                                                        </div>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-card>
                                                    <v-card-text style="height: 267px;" class="scroll pt-3 px-3" :class="scrollbarTheme">
                                                        <v-combobox outlined :label="$t('Exam.add-people')" multiple chips v-model="model">
                                                            <template v-slot:selection="{ attrs, item, parent, selected }">
                                                                <v-chip v-bind="attrs" :input-value="selected" close @click:close="parent.selectItem(item)">
                                                                    <v-avatar class="primary white--text" left v-text="item.mail.slice(0, 1).toUpperCase()"></v-avatar>
                                                                    <span class="pr-2">
                                                                        {{ item.mail }}
                                                                    </span>
                                                                </v-chip>
                                                            </template>
                                                        </v-combobox>
                                                    </v-card-text>
                                                    <v-divider></v-divider>
                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn color="primary" @click="addPeople()" :disabled="model.length == 0? true : false">
                                                            {{ $t('Exam.add') }} <v-icon class="ml-1">mdi-plus</v-icon>
                                                        </v-btn>
                                                        <v-spacer></v-spacer>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel class="card" :disabled="disabled">
                                    <v-expansion-panel-header class="title">
                                        {{ $t('Exam.exam-statistics') }}
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-row align="center">
                                            <v-col cols="3" class="px-1 py-0">
                                                <v-select outlined :label="$t('Exam.point-system')" v-model="pointSystem" :items="pointSystemItems" :disabled="exams[selected].released == 'released'? true : false" hide-details @change="alterStatistic(pointSystem)"></v-select>
                                            </v-col>
                                            <!-- <v-switch class="mx-3" true-value="point" false-value="question" :disabled="exams[selected].released == 'released'? true : false" :label="$t('Exam.question-system') + ' / ' + $t('Exam.point-system') " @change="alterStatistic(switchValue)" v-model="switchValue"></v-switch> -->
                                            <v-col cols="3">
                                                <v-btn v-if="exams[selected].released == 'released'" color="primary" class="mx-3" disabled>
                                                    {{ $t('Exam.is-released') }}
                                                    <v-icon class="ml-2">mdi-check</v-icon>
                                                </v-btn>
                                                <v-btn v-else color="primary" class="mx-3" @click="dialogResult = true">{{ $t('Exam.release-result') }}</v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="8" class="ma-0 px-1 pt-4">
                                                <v-card>
                                                    <v-toolbar flat>
                                                        <div class="ml-5 mr-9 font-weight-bold">%</div>
                                                        <div class="font-weight-bold ml-6">{{ $t('Exam.participant') }}</div>
                                                        <v-spacer></v-spacer>
                                                        <div class="font-weight-bold mr-3">{{ $t('Exam.test-results') }}</div>
                                                    </v-toolbar>
                                                    <v-divider></v-divider>
                                                    <v-card-text style="height: 527px;" class="scroll pa-0" :class="scrollbarTheme">
                                                        <!-- list with all previously solved exams -->
                                                        <v-list two-line v-if="results.length != 0">
                                                            <v-list-item-group>
                                                                <template v-for="(result, i) in results">
                                                                    <v-list-item :key="result.result_id" @click="openSingleDialog(i)">
                                                                        <template>
                                                                            <v-list-item-icon>
                                                                                <v-progress-circular :value="result.percentage" color="primary" class="ma-2" size="40" :rotate="-90">{{ result.percentage }}</v-progress-circular>
                                                                            </v-list-item-icon>
                                                                            <v-list-item-content style="margin-top: -15px">
                                                                                <v-list-item-title>{{ result.usr_first_name + ' ' + result.usr_last_name }}</v-list-item-title>
                                                                                <v-list-item-subtitle class="text--primary">{{ result.mail }}</v-list-item-subtitle>
                                                                            </v-list-item-content>
                                                                            <v-list-item-action style="margin-top: -5px" class="mr-3">
                                                                                <v-list-item-action-text v-if="pointSystem == 'question'">{{ result.points + '/' + result.possible_points + ' ' + $t('Exam.questions-answered') }}</v-list-item-action-text>
                                                                                <v-list-item-action-text v-if="pointSystem == 'point'">{{ result.part_points + '/' + result.possible_points + ' ' + $t('Exam.questions-answered') }}</v-list-item-action-text>
                                                                                <v-list-item-action-text v-if="pointSystem == 'macedonia'">{{ result.part_points_macedonia + '/' + result.possible_points + ' ' + $t('Exam.questions-answered') }}</v-list-item-action-text>
                                                                            </v-list-item-action>
                                                                        </template>
                                                                    </v-list-item>
                                                                </template>
                                                            </v-list-item-group>
                                                        </v-list>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="4" class="ma-0">
                                                <v-row class="pa-0">
                                                    <v-col cols="6" class="pa-1">
                                                        <v-card height="200px">
                                                            <v-card-title>{{ $t('Exam.participation') }}</v-card-title>
                                                            <v-card-text>
                                                                <v-row style="height: 100px" align="center" class="mx-1">
                                                                    <v-progress-linear color="primary" height="25" v-model="participationAmount">
                                                                        <template>
                                                                            <strong>{{ participated.length + '/' + participants.length }}</strong>
                                                                        </template>
                                                                    </v-progress-linear>
                                                                </v-row>
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col cols="6" class="pa-1">
                                                        <v-card height="200px">
                                                            <v-card-title>{{ $t('Exam.best-result') }}</v-card-title>
                                                            <v-card-text>
                                                                <v-row justify="center">
                                                                    <v-progress-circular size="120" :value="bestResult" :rotate="-90" width="10" color="primary">{{ bestResult + '%' }}</v-progress-circular>
                                                                </v-row>
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                                <v-row class="pa-1">
                                                    <v-col cols="12" class="pa-0">
                                                        <v-card>
                                                            <v-card-title>{{ $t('Exam.average-score') }}</v-card-title>
                                                            <v-card-text style="height: 320px;">
                                                                <v-row justify="center">
                                                                    <v-progress-circular size="300" :value="averageResult" :rotate="-90" width="30" color="primary">{{ averageResult + '%' }}</v-progress-circular>
                                                                </v-row>
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-card>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-dialog v-model="dialogResult" max-width="290">
                <v-card>
                    <v-card-title>{{ $t('Exam.release-result') + '?' }}</v-card-title>
                    <v-card-text>{{ $t('Exam.release-text') }}</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" text @click="dialogResult = false">{{ $t('Exam.cancel') }}</v-btn>
                        <v-btn color="success" text @click="releaseResults()">{{ $t('Exam.release') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- snackbar for adding people -->
            <v-snackbar v-model="snackbar" color="success">
                {{ 'People added successfully!' }}
                <template v-slot:action="{ attrs }">
                    <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
                </template>
            </v-snackbar>
            <v-snackbar v-model="snackbar2" color="error" multi-line="">
                {{ 'An Error occured. Log in again!' }}
                <template v-slot:action="{ attrs }">
                    <v-btn  text v-bind="attrs" @click="snackbar2 = false">Close</v-btn>
                </template>
            </v-snackbar>
        </v-dialog>
        <!-- dialog for a single users result -->
        <v-dialog v-model="dialogSingleResult" fullscreen hide-overlay>
            <v-card v-if="dialogSingleResult == true">
                <!-- toolbar -->
                <v-toolbar color="primary" dark tile>
                    <v-toolbar-title>{{ results[selected2].usr_first_name + ' ' + results[selected2].usr_last_name }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- pdf download button -->
                    <v-btn icon @click="generateReport()">
                        <v-icon>mdi-download</v-icon>
                    </v-btn>
                    <!-- close dialog -->
                    <v-btn @click="dialogSingleResult = false" icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text :style="{height: windowHeight - 64 + 'px'}" class="scroll" :class="scrollbarTheme">
                    <v-row justify="center">
                        <v-card tile width="1120" color="rgba(0,0,0,0)" class="mt-3 mx-3" elevation="0" v-if="userResults.length != 0">
                            <!-- pdf wrapper -->
                            <vue-html2pdf 
                                ref="html2Pdf" 
                                :show-layout="false" 
                                :paginate-elements-by-height="1400" 
                                :filename="'Test_Results_' + results[selected2].usr_first_name + '_' + results[selected2].usr_last_name" 
                                :pdf-quality="2" 
                                :manual-pagination="true" 
                                :float-layout="false"
                                :preview-modal="true"
                                pdf-format="a4"
                                pdf-orientation="landscape"
                                pdf-content-width="1120px"
                                :enable-download="false"
                            >
                                <section slot="pdf-content">
                                    <v-container fluid class="ma-0 pa-0">
                                        <template v-for="(cardNumber, i) in Math.ceil((userResults.length)/8)">
                                            <!-- the sites of the document -->
                                            <v-card tile color="#FFFFFF" :key="i+Math.E" :height="1120*(2480/3508) - 54" :style="{'padding-top': i != 0 ? '20px' : ''}" elevation="5">
                                                <!-- title of the document (first page) -->
                                                <v-card-title v-if="i == 0" style="background-color: #FFFFFF; color: #030303">
                                                    <div style="font-weight: 300; font-family: Roboto">{{ $t('Exam.exam-results') }}</div>
                                                    <v-spacer></v-spacer>
                                                    <div class="body-2" style="font-weight: bold; margin-right: 1%; font-family: Roboto">{{ $t('Exam.exam') }}</div>
                                                    <div class="body-2" style="font-weight: normal; font-family: Roboto">{{ exams[selected].exam_name }}</div>
                                                    <v-spacer></v-spacer>
                                                    <div class="body-2" style="font-weight: bold; margin-right: 1%; font-family: Roboto">{{ $t('Exam.name') }}</div>
                                                    <div class="body-2" style="font-weight: normal; font-family: Roboto">{{ results[selected2].usr_first_name + ' ' + results[selected2].usr_last_name }}</div>
                                                    <div class="body-2" style="font-weight: bold; margin-left: 2%; margin-right: 1%; font-family: Roboto">{{ $t('Exam.date2') }}</div>
                                                    <div class="body-2" style="font-weight: normal; margin-right: 3%; font-family: Roboto">{{ results[selected2].finish_time.slice(0,10) }}</div>
                                                </v-card-title>
                                                <!-- colums on top of each page -->
                                                <v-card tile elevation="0" color="#FFFFFF">
                                                    <v-row style="padding-left: 17px; padding-right: 0px">
                                                        <v-col cols="5" style="color: #030303; font-weight: 500; font-family: Roboto">{{ $t('Exam.question') }}</v-col>
                                                        <v-col style="color: #030303; font-weight: 500; font-family: Roboto; margin-left: -15px">{{ $t('Exam.answer-1') }}</v-col>
                                                        <v-col style="color: #030303; font-weight: 500; font-family: Roboto">{{ $t('Exam.answer-2') }}</v-col>
                                                        <v-col style="color: #030303; font-weight: 500; font-family: Roboto">{{ $t('Exam.answer-3') }}</v-col>
                                                        <v-col style="color: #030303; font-weight: 500; font-family: Roboto">{{ $t('Exam.answer-4') }}</v-col>
                                                        <v-col style="color: #030303; font-weight: 500; font-family: Roboto">{{ $t('Exam.answer-5') }}</v-col>
                                                        <v-col style="color: #030303; font-weight: 500; font-family: Roboto">{{ $t('Exam.answer-6') }}</v-col>
                                                        <v-col style="color: #030303; font-weight: 500; font-family: Roboto">{{ $t('Exam.points') }}</v-col>
                                                    </v-row>
                                                </v-card>
                                                <v-divider light></v-divider>
                                                <!-- the rows with questions of the document -->
                                                <v-card v-for="(userResult, x) in userResults.slice(i*8,i*8 + 8)" :key="x+i*Math.PI" tile elevation="0" color="#FFFFFF" @click="test = 1" light>
                                                    <v-row style="padding-left: 10px; padding-right: 10px; top: 50%" align="center">
                                                        <!-- question Number -->
                                                        <v-col cols="5" class="ml-3">
                                                            <v-row align="center">
                                                                <v-col align="center">
                                                                    <div style="color: #030303; font-family: Roboto">{{ x+1 + (i*8) }}</div>
                                                                </v-col>
                                                                <v-col cols="10">
                                                                    <div style="font-size: 12px; color: #030303; font-family: Roboto">{{ userResult.question }}</div>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                        <!-- answer 1 -->
                                                        <v-col style="margin-left: -10px">
                                                            <template v-if="userResult.answer_1 != null">
                                                                <v-icon v-if="userResult.answer_1_state == userResult.answer_1_usr && userResult.answer_1_usr == '1'" color="#000000">mdi-checkbox-marked</v-icon>
                                                                <v-icon v-else-if="userResult.answer_1_state != userResult.answer_1_usr && userResult.answer_1_usr == '1'" color="#9E9E9E">mdi-minus-box</v-icon>
                                                                <v-icon v-else color="#757575">mdi-checkbox-blank-outline</v-icon>
                                                                <div :style="{position: 'relative', top: '-34px', right: '-15px' }">
                                                                    <v-icon color="#FFFFFF" x-small :style="{'border-radius': '10px', 'background-color': userResult.answer_1_state == '1' ? '#4CAF50' : '#FF5252', padding: '3px'}">{{ userResult.answer_1_state == '1' ? 'mdi-check' : 'mdi-close' }}</v-icon>
                                                                </div>
                                                            </template>
                                                        </v-col>
                                                        <!-- answer 2 -->
                                                        <v-col style="margin-left: 4px">
                                                            <template v-if="userResult.answer_2 != null">                                                     
                                                                <v-icon v-if="userResult.answer_2_state == userResult.answer_2_usr && userResult.answer_2_usr == '1'" color="#000000">mdi-checkbox-marked</v-icon>
                                                                <v-icon v-else-if="userResult.answer_2_state != userResult.answer_2_usr && userResult.answer_2_usr == '1'" color="#9E9E9E">mdi-minus-box</v-icon>
                                                                <v-icon v-else color="#757575">mdi-checkbox-blank-outline</v-icon>
                                                                <div :style="{position: 'relative', top: '-34px', right: '-15px' }">
                                                                    <v-icon color="#FFFFFF" x-small :style="{'border-radius': '10px', 'background-color': userResult.answer_2_state == '1' ? '#4CAF50' : '#FF5252', padding: '3px'}">{{ userResult.answer_2_state == '1' ? 'mdi-check' : 'mdi-close' }}</v-icon>
                                                                </div>
                                                            </template>
                                                        </v-col>
                                                        <!-- answer 3 -->
                                                        <v-col style="margin-left: 4px">
                                                            <template v-if="userResult.answer_3 != null">
                                                                <v-icon v-if="userResult.answer_3_state == userResult.answer_3_usr && userResult.answer_3_usr == '1'" color="#000000">mdi-checkbox-marked</v-icon>
                                                                <v-icon v-else-if="userResult.answer_3_state != userResult.answer_3_usr && userResult.answer_3_usr == '1'" color="#9E9E9E">mdi-minus-box</v-icon>
                                                                <v-icon v-else color="#757575">mdi-checkbox-blank-outline</v-icon>
                                                                <div :style="{position: 'relative', top: '-34px', right: '-15px' }">
                                                                    <v-icon color="#FFFFFF" x-small :style="{'border-radius': '10px', 'background-color': userResult.answer_3_state == '1' ? '#4CAF50' : '#FF5252', padding: '3px'}">{{ userResult.answer_3_state == '1' ? 'mdi-check' : 'mdi-close' }}</v-icon>
                                                                </div>
                                                            </template>
                                                        </v-col>
                                                        <!-- answer 4 -->
                                                        <v-col  style="margin-left: 4px">
                                                            <template v-if="userResult.answer_4 != null">
                                                                <v-icon v-if="userResult.answer_4_state == userResult.answer_4_usr && userResult.answer_4_usr == '1'" color="#000000">mdi-checkbox-marked</v-icon>
                                                                <v-icon v-else-if="userResult.answer_4_state != userResult.answer_4_usr && userResult.answer_4_usr == '1'" color="#9E9E9E">mdi-minus-box</v-icon>
                                                                <v-icon v-else color="#757575">mdi-checkbox-blank-outline</v-icon>
                                                                <div :style="{position: 'relative', top: '-34px', right: '-15px' }">
                                                                    <v-icon color="#FFFFFF" x-small :style="{'border-radius': '10px', 'background-color': userResult.answer_4_state == '1' ? '#4CAF50' : '#FF5252', padding: '3px'}">{{ userResult.answer_4_state == '1' ? 'mdi-check' : 'mdi-close' }}</v-icon>
                                                                </div>
                                                            </template>
                                                        </v-col>
                                                        <!-- answer 5 -->
                                                        <v-col  style="margin-left: 4px">
                                                            <template v-if="userResult.answer_5 != null">
                                                                <v-icon v-if="userResult.answer_5_state == userResult.answer_5_usr && userResult.answer_5_usr == '1'" color="#000000">mdi-checkbox-marked</v-icon>
                                                                <v-icon v-else-if="userResult.answer_5_state != userResult.answer_5_usr && userResult.answer_5_usr == '1'" color="#9E9E9E">mdi-minus-box</v-icon>
                                                                <v-icon v-else color="#757575">mdi-checkbox-blank-outline</v-icon>
                                                                <div :style="{position: 'relative', top: '-34px', right: '-15px' }">
                                                                    <v-icon color="#FFFFFF" x-small :style="{'border-radius': '10px', 'background-color': userResult.answer_5_state == '1' ? '#4CAF50' : '#FF5252', padding: '3px'}">{{ userResult.answer_5_state == '1' ? 'mdi-check' : 'mdi-close' }}</v-icon>
                                                                </div>
                                                            </template>
                                                        </v-col>
                                                        <!-- answer 6 -->
                                                        <v-col  style="margin-left: 4px">
                                                            <template v-if="userResult.answer_6 != null">
                                                                <v-icon v-if="userResult.answer_6_state == userResult.answer_6_usr && userResult.answer_6_usr == '1'" color="#000000">mdi-checkbox-marked</v-icon>
                                                                <v-icon v-else-if="userResult.answer_6_state != userResult.answer_6_usr && userResult.answer_6_usr == '1'" color="#9E9E9E">mdi-minus-box</v-icon>
                                                                <v-icon v-else color="#757575">mdi-checkbox-blank-outline</v-icon>
                                                                <div :style="{position: 'relative', top: '-34px', right: '-15px' }">
                                                                    <v-icon color="#FFFFFF" x-small :style="{'border-radius': '10px', 'background-color': userResult.answer_6_state == '1' ? '#4CAF50' : '#FF5252', padding: '3px'}">{{ userResult.answer_6_state == '1' ? 'mdi-check' : 'mdi-close' }}</v-icon>
                                                                </div>
                                                            </template>
                                                        </v-col>
                                                        <!-- points -->
                                                        <v-col>
                                                            <div v-if="pointSystem == 'macedonia'" style="color: #030303; font-family: Roboto; margin-left: 16px">{{ macedoniaPoints[x + (i*8)] }}</div>
                                                            <div v-else-if="pointSystem == 'point' " style="color: #030303; font-family: Roboto; margin-left: 16px">{{ partPoints[x + (i*8)] }}</div>
                                                            <div v-else style="color: #030303; font-family: Roboto; margin-left: 16px">{{ points[x + (i*8)] }}</div>
                                                        </v-col>
                                                    </v-row>
                                                    <!-- divider after every row -->
                                                    <v-divider light></v-divider>
                                                </v-card>
                                            </v-card>
                                            <!-- page break -->
                                            <br class="html2pdf__page-break" :key="i" v-if=" i < Math.ceil((userResults.length)/8) -1">
                                        </template>
                                    </v-container>
                                </section>
                            </vue-html2pdf>
                        </v-card>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
  import VueHtml2pdf from 'vue-html2pdf'

  export default {
    name: 'manageExams',

    components: {
        VueHtml2pdf
    },

    data: function() {
        return {
            exams: [], // array with all exams

            dialog: false,
            selected: null, // which list item is clicked

            participants: [], // array with all participants
            participated: [], // array with all people that actually participated
            model: [],
            mails: [],

            panels: [],
            iterator: undefined,
            disabled: undefined,
            disabledButton: true,
            switchValue: '',
            pointSystem: '',
            pointSystemItems: [
                {text: this.$i18n.t('Exam.question-system'), value: 'question'},
                {text: this.$i18n.t('Exam.points-system'), value: 'point'},
                {text: this.$i18n.t('Exam.macedonian-system'), value: 'macedonia'},
            ],
            
            results: [],
            participationAmount: 0,
            bestResult: 0,
            averageResult: 0,

            dialogResult: false,

            menu: false,
            menuTemp: false,
            date: new Date().toISOString().substr(0, 10), // date Variable for datepicker
            time: new Date().toLocaleTimeString('en-US', {  hour12: false, 
                                                            hour: "numeric", 
                                                            minute: "numeric"}), // time Variable for time Picker
            timeRules: [
                v => !!v || this.$i18n.t('Exam.valid-time'),
            ],
            card1: false,
            card2: false,

            dialogSingleResult: false,
            selected2: null,
            iterator2: undefined,
            userResults: [],

            points: [],
            macedoniaPoints: [],
            partPoints: [],

            snackbar: false,
            snackbar2: false,

            breadCrumbs: [
                {text: this.$i18n.t('AppNavigationMenu.dashboard'), disabled: false, to: '/dashboard', exact: true},
                {text: this.$i18n.t('Exam.exams'), disabled: false, to: '(/dashboard/exams)', exact: true},
                {text: this.$i18n.t('Exam.manage'), disabled: true, href: 'breadcrumbs_manage'},
            ],

            windowWidth: 0, // width of the window
            windowHeight: 0, // height of the window
        }
    },

    mounted() {

        // event listener for window size
        this.$nextTick(function() {
            window.addEventListener('resize', this.getWindowWidth);
            window.addEventListener('resize', this.getWindowHeight);

            // Init
            this.getWindowWidth()
            this.getWindowHeight()
        })
    },

    created() {
        this.initialize()
    },

    beforeDestroy() {

        // remove eventlisteners when the component is destroyed
        window.removeEventListener('resize', this.getWindowWidth);
        window.removeEventListener('resize', this.getWindowHeight);
    },

    watch: {

        dialogSingleResult() {
            if(this.dialogSingleResult == false) {
                this.points = []
            }
        },

        // when datepicker is closed the date value has to be saved
        menu() {
            if(this.menu == false && this.menuTemp == false) {
                this.$refs.menu.save(this.date) 
            } else if(this.menu == true) {
                this.menuTemp = false
            }
        },

        dialog() {
            if(this.dialog == false) {
                this.participants = []
                this.model = []
                this.mails = []
                this.results = []
                this.switchValue = ''
            } else {
                // console.log(this.exams[this.selected].released)
                this.switchValue = this.exams[this.selected].point_system
                this.date = new Date(this.exams[this.selected].valid_from.substring(0,10)).toISOString().substr(0, 10)
                this.time = this.exams[this.selected].valid_from.substring(11,19)
                this.pointSystem = this.exams[this.selected].point_system
            }
        },

        model (val, prev) {
            // console.log(this.model)
            if (val.length === prev.length) return

            this.model = val.map(v => {
                if (typeof v === 'string') {
                    v = {
                        mail: v,
                    }
                    this.mails.push(v)
                    this.nonce++
                }
                return v
            })
        },

    },

    computed: {

        scrollbarTheme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light';
        },

        // User Object from the store
        UserObject() {
            return this.$store.getters.userData
        },
        // sessionID from the store
        SessionID() {
            // console.log('SessionIDComponent', this.$store.getters.sessionID)
            return this.$store.getters.sessionID
        },

    },

    methods: {

        generateReport () {
            this.$refs.html2Pdf.generatePdf()
        },

        openSingleDialog(i) {
            this.dialogSingleResult = true
            this.selected2 = i
            this.getUserResults()
            this.iterator2 = i
            this.results[i]
        },

        getUserResults() {
            var userResultsObject = {}
            userResultsObject.usr_id = this.results[this.selected2].usr_id
            userResultsObject.start_time = this.exams[this.selected].start_time
            userResultsObject.test_name_db = this.exams[this.selected].test_name_db
            this.$http.post('index.php',    {   task:               'get_user_results',
                                                userResultsObject:  userResultsObject,
                                            })
                    .then(response => {
                        response.body.forEach(element => {
                            // calculation of points
                            var selected = 0
                            if(element.answer_1_usr != null) {
                                selected += parseInt(element.answer_1_usr)
                            }
                            if(element.answer_2_usr != null) {
                                selected += parseInt(element.answer_2_usr)
                            }
                            if(element.answer_3_usr != null) {
                                selected += parseInt(element.answer_3_usr)
                            }
                            if(element.answer_4_usr != null) {
                                selected += parseInt(element.answer_4_usr)
                            }
                            if(element.answer_5_usr != null) {
                                selected += parseInt(element.answer_5_usr)
                            }
                            if(element.answer_6_usr != null) {
                                selected += parseInt(element.answer_6_usr)
                            }
                            var dbSelected = 0
                            if(element.answer_1_state != null) {
                                dbSelected += parseInt(element.answer_1_state)
                            }
                            if(element.answer_2_state != null) {
                                dbSelected += parseInt(element.answer_2_state)
                            }
                            if(element.answer_3_state != null) {
                                dbSelected += parseInt(element.answer_3_state)
                            }
                            if(element.answer_4_state != null) {
                                dbSelected += parseInt(element.answer_4_state)
                            }
                            if(element.answer_5_state != null) {
                                dbSelected += parseInt(element.answer_5_state)
                            }
                            if(element.answer_6_state != null) {
                                dbSelected += parseInt(element.answer_6_state)
                            }
                            var numberRight = 0
                            if(element.answer_1_state == element.answer_1_usr && element.answer_1_state == '1') {
                                numberRight += 1
                            }
                            if(element.answer_2_state == element.answer_2_usr && element.answer_2_state == '1') {
                                numberRight += 1
                            }
                            if(element.answer_3_state == element.answer_3_usr && element.answer_3_state == '1') {
                                numberRight += 1
                            }
                            if(element.answer_4_state == element.answer_4_usr && element.answer_4_state == '1') {
                                numberRight += 1
                            }
                            if(element.answer_5_state == element.answer_5_usr && element.answer_5_state == '1') {
                                numberRight += 1
                            }
                            if(element.answer_6_state == element.answer_6_usr && element.answer_6_state == '1') {
                                numberRight += 1
                            }
                            // console.log('db: ' + dbSelected + ' user: ' + selected + ' right: ' + numberRight)
                            if(numberRight == dbSelected) {
                                this.macedoniaPoints.push(1)
                                this.partPoints.push(1)
                                this.points.push(1)
                            } else if(selected == dbSelected && numberRight == dbSelected-1) {
                                this.macedoniaPoints.push(0.5)
                                 if((numberRight -(selected - numberRight)) > 0){
                                    this.partPoints.push(((numberRight -(selected - numberRight))/dbSelected).toFixed(2))
                                } else {
                                    this.partPoints.push(0)
                                }
                                this.points.push(0)
                            } else {
                                this.macedoniaPoints.push(0)
                                if((numberRight -(selected - numberRight)) > 0){
                                    this.partPoints.push(((numberRight -(selected - numberRight))/dbSelected).toFixed(2))
                                } else {
                                    this.partPoints.push(0)
                                }
                                this.points.push(0)
                            }

                        });
                        // console.log(this.points)
                        this.userResults = response.body
                        // console.log(response.body)
                    });

        },

        saveDate() {
            var dateObj = {}
            dateObj.valid_from = this.date + ' ' +  this.time + ':00'
            var hours = parseInt(this.time.substr(0,2))
            var min = parseInt(this.time.substr(3,4))
            min += 15
            if(min >= 60) {
                if(hours < 23) {
                    hours += 1
                    min -= 60
                } else {
                    min += (59-min)
                }
            }
            dateObj.valid_to = this.date + ' ' +  hours + ':' + min + ':00'
            this.$http.post('index.php',    {   task:       'update_exam_date',
                                                dateObject: dateObj,
                                                examObject: this.exams[this.selected],
                                                userObject: this.UserObject,
                                            })
                    .then(response => {
                        if(response.body == 'success') {
                            this.card2 = true
                            this.getExams()
                        } else {
                            this.card1 = true
                        }
                    });
        },

        // close function for the datepicker
        menuClose() {
            this.menu = false
            this.menuTemp = true
        },

        releaseResults() {
            this.$http.post('index.php',    {   task:       'release_results',
                                                examObject: this.exams[this.selected].exam_id,
                                                userObject: this.UserObject,
                                            })
                    .then(response => {
                        console.log(response.body)
                        this.dialogResult = false
                        this.getExams() 
                    });
        },

        alterStatistic(val) {
            this.$http.post('index.php',    {   task:               'update_exam_point_system',
                                                pointSystemObject:  val,
                                                examObject:         this.exams[this.selected].exam_id,
                                                userObject:         this.UserObject,
                                            })
            this.exams[this.selected].point_system = val
            if(val == 'question') {
                this.results.forEach(element => {
                    element.percentage = Math.round((element.points / element.possible_points) * 100)
                });
                var best = 0
                var allResults = 0
                this.results.forEach(element => {
                    if(parseInt(element.percentage) > best) {
                        
                        best = parseInt(element.percentage)
                    }
                    allResults += parseInt(element.percentage)
                });
                this.bestResult = best
                if(allResults == 0) {
                    this.averageResult = 0
                } else {
                    this.averageResult = Math.round(allResults / this.results.length)
                }
            } else if (val == 'point') {
                this.results.forEach(element => {
                    element.percentage = Math.round((element.part_points / element.possible_points) * 100)
                });
                var best2 = 0
                var allResults2 = 0
                this.results.forEach(element => {
                    if(parseInt(element.percentage) > best2) {
                        
                        best2 = parseInt(element.percentage)
                    }
                    allResults2 += parseInt(element.percentage)
                });
                this.bestResult = best2
                if(allResults2 == 0) {
                    this.averageResult = 0
                } else {
                    this.averageResult = (allResults2 / this.results.length).toFixed(0)
                }
            } else {
                this.results.forEach(element => {
                    element.percentage = Math.round((element.part_points_macedonia / element.possible_points) * 100)
                });
                var best3 = 0
                var allResults3 = 0
                this.results.forEach(element => {
                    if(parseInt(element.percentage) > best3) {
                        
                        best3 = parseInt(element.percentage)
                    }
                    allResults3 += parseInt(element.percentage)
                });
                this.bestResult = best3
                if(allResults3 == 0) {
                    this.averageResult = 0
                } else {
                    this.averageResult = (allResults3 / this.results.length).toFixed(0)
                }
            }
        },

        addPeople() {
            this.mails.forEach(element => {
                // random in function
                function getRandomInt( min, max ) {
                    return Math.floor( Math.random() * ( max - min + 1 ) ) + min;
                }
                // function that generates a product key
                function generateProductKey() {
                    var tokens = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
                        chars = 4,
                        segments = 4,
                        keyString = ""; 
                    for( var i = 0; i < segments; i++ ) {
                        var segment = "";
                        for( var j = 0; j < chars; j++ ) {
                            var k = getRandomInt( 0, 35 );
                            segment += tokens[ k ];
                        }
                        keyString += segment;
                        if( i < ( segments - 1 ) ) {
                            keyString += "-";
                        }
                    }  
                    return keyString;
                }
                element.key_phrase = generateProductKey()
                element.key_id = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) );
                element.exam_id = this.participants[0].exam_id
                element.test_name = this.participants[0].test_name
                element.test_id = this.participants[0].test_id
                element.exam_name = this.participants[0].exam_name
                element.valid_from = this.participants[0].valid_from
                element.valid_to = this.participants[0].valid_to
                element.duration = this.participants[0].duration
                element.exam_creator_id = this.participants[0].exam_creator_id
                element.participants = parseInt(this.participants[0].participants) + parseInt(this.mails.length)
                element.mail_send = 0
                // console.log(element)
            });
            
            // console.log(this.mails)
            this.$http.post('index.php',    {   task:       'update_exam_key',
                                                keyObject:  this.mails, 
                                                userObject: this.UserObject,
                                            })
                    .then(response => {
                        // console.log(response.body)
                        if(response.body == 'success') {
                            this.getParticipants(this.iterator)
                            this.snackbar = true
                            this.model = []
                            this.mail = []
                        } else {
                            this.snackbar2 = true
                        }  
            });
        },

        // invite people by mail
        sendMail(i) {
            this.participants[i].mail_send = 1
            window.open('mailto:' + this.participants[i].mail + '?subject=' + this.participants[i].exam_name + '; ' + this.participants[i].valid_from + '&body=Key: ' + this.participants[i].key_phrase)
            this.$http.post('index.php',    {   task:       'mail_send',
                                                mailObject:  this.participants[i],
                                            })
                    // .then(response => {
                    //     console.log(response.body)
                    // });
        },

        // open dialog for exam statistics
        openDialog(i) {
            this.dialog = true
            this.selected = i
            this.getParticipants(i)
            this.iterator = i
        },

        getParticipants(i) {
            this.$http.post('index.php',    {   'task'      : 'get_table',
                                                'table'     : 'colcons_exam_keys',
                                                'filter'    : [ { 'op': 'eq', 'field': 'exam_id', 'value': this.exams[i].exam_id } ],
                                                'usr_id'    : this.UserObject.usr_id,
                                                'sessionid' : this.SessionID,
                                            })
                    .then(response => {
                        // console.log(response.body)
                        if (response.body.sessionerror) {
                            this.$store.dispatch('getSnackbar', { status: true, timeout: 3000, color: 'error', btnColor: 'buttonText', text: this.$i18n.t('UserSettings.sessID-outdated') });
                        } else {
                            this.participants = response.body // get all participants
                            this.participated = response.body.filter(el => { // get all people that participated
                                if(el.key_status == 'used') {
                                    return el
                                }
                            })
                            // console.log('Participation: ', this.participants, this.participated)
                            this.participationAmount = (this.participated.length * 100) / this.participants.length
                            let partpans = response.body;
                            if(new Date(this.participants[0].valid_from) > new Date()) {
                                this.panels = [0];
                                this.disabled = true;
                            } else {
                                this.panels = [1];
                                this.disabled = false;
                                let resultIds = [];
                                response.body.forEach(element => {
                                    resultIds.push(element.result_id)
                                });
                                this.$http.post('index.php',    {   'task'      : 'get_table',
                                                                    'table'     : 'colcons_test_results',
                                                                    'filter'    : [ { 'op': 'like', 'field': 'result_id', 'value': resultIds.join(',') } ],
                                                                    'usr_id'    : this.UserObject.usr_id,
                                                                    'sessionid' : this.SessionID,
                                                                })
                                        .then(response2 => {
                                            // console.log(response2.body)
                                            if (response2.body.sessionerror) {
                                                this.$store.dispatch('getSnackbar', { status: true, timeout: 3000, color: 'error', btnColor: 'buttonText', text: this.$i18n.t('UserSettings.sessID-outdated') });
                                            } else {
                                                if (response2.body == 'null') return; // TODO: show text that nobody participated
                                                response2.body.forEach(element => {
                                                    if(element != null) {
                                                        partpans.forEach(element2 => {
                                                            if(element2.result_id == element.result_id) {
                                                                element.usr_first_name = element2.usr_first_name
                                                                element.usr_last_name = element2.usr_last_name
                                                                element.mail = element2.mail
                                                                this.results.push(element)
                                                            }
                                                        });
                                                    }
                                                });
                                                this.alterStatistic(this.exams[this.selected].point_system)
                                            }
                                        })
                            }
                        }
                        // console.log(this.panels)
                    });
        },

        // get your exams from DB
        getExams() {
            this.$http.post('index.php',    {   'task'          : 'get_table',
                                                'table'         : 'colcons_exam_keys',
                                                'filter'        : [ { 'op': 'eq', 'field': 'exam_creator_id', 'value': this.UserObject.usr_id } ],
                                                'field_list'    : [ 'key_id', 'test_name', 'test_id', 'key_phrase', 'key_status', 'valid_from', 'valid_to', 'duration', 'exam_name', 'exam_id', 'mail', 'usr_first_name', 'usr_last_name', 'mail_send', 'exam_creator_id', 'result_id', 'participants', 'released', 'point_system' ],
                                                'groupconcat'   : [ 'start_time' ],
                                                'groupby'       : ['exam_id'],
                                                'usr_id'        : this.UserObject.usr_id,
                                                'sessionid'     : this.SessionID,
                                            })
                    .then(response => {
                        // console.log(response.body)
                        if(response.body.sessionerror) {
                            this.$store.dispatch('getSnackbar', { status: true, timeout: 3000, color: 'error', btnColor: 'buttonText', text: this.$i18n.t('UserSettings.sessID-outdated') });
                        } else {
                            let exams = response.body.sort((x, y) => +new Date(y.valid_from) - +new Date(x.valid_from));
                            let testNames = [];
                            exams.forEach(element => {
                                testNames.push(element.test_name);
                            });
                            this.$http.post('index.php',    {   'task'          : 'get_table',
                                                                'table'         : 'colcons_tests',
                                                                'filter'        : [ { 'op': 'like', 'field': 'test_name_db', 'value': testNames.join(',') } ],
                                                                'usr_id'        : this.UserObject.usr_id,
                                                                'sessionid'     : this.SessionID,
                                                            })
                                    .then(response2 => {
                                        // console.log(response2.body)
                                        if(response.body.sessionerror) {
                                            this.$store.dispatch('getSnackbar', { status: true, timeout: 3000, color: 'error', btnColor: 'buttonText', text: this.$i18n.t('UserSettings.sessID-outdated') });
                                        } else {
                                            exams.forEach(element => {
                                                if(element['GROUP_CONCAT(start_time)'] != null) {
                                                    element.start_time = element['GROUP_CONCAT(start_time)'].split(',')[0];
                                                }
                                                response2.body.forEach(element2 => {
                                                    if(element.test_name == element2.test_name_db) {
                                                        element.test_name_db = element.test_name;
                                                        element.test_name = element2.test_name;
                                                    }
                                                });
                                            });
                                        }
                                    })
                            this.exams = exams
                        }
                    });
        },

        // get the width of the window
        getWindowWidth() {
            this.windowWidth = document.documentElement.clientWidth;
        },

        // get the height of the window
        getWindowHeight() {
            this.windowHeight = document.documentElement.clientHeight;
        },

        // get test results from DB
        initialize() {

            this.getExams()
        },

    },

  }
</script>

<style>
  .scroll {
    overflow-y: auto
  }
</style>