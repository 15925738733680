<template>

    <!-- help dialog with short explanation -->
    <v-dialog v-model="dialogHelp" width="500px">
        <template v-slot:activator="{ on, attrs}">
            <v-btn v-on="on" v-bind="attrs" rounded outlined class="ml-3 mb-1" @click="dialog = true" v-if="testMode != 'exam_mode' && testMode != 'exam_preparation'">
        <v-icon>mdi-help</v-icon> {{ $t('Questions.help') }}
      </v-btn>
        </template>
      <v-card color="card">
        <v-card-title>{{ $t('Questions.information') }}</v-card-title>
        <v-card-text>
          <v-row class="mt-3" align="center">
            <v-col cols="3">
              <v-badge value="1" overlap icon="mdi-check" color="green">
                <v-card class="outlinedTrue" height="30px" width="80px" elevation="0"></v-card>
              </v-badge>
            </v-col>
            <v-col cols="1">
              <div>:</div>
            </v-col>
            <v-col cols="8">
              <div class="font-weight-regular">{{ $t('Questions.answer-right') }}</div>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="3">
              <v-badge value="1" overlap icon="mdi-close" color="red">
                <v-card class="outlinedFalse" height="30px" width="80px" elevation="0"></v-card>
              </v-badge>
            </v-col>
            <v-col cols="1">
              <div>:</div>
            </v-col>
            <v-col cols="8">
              <div class="font-weight-regular">{{ $t('Questions.answer-wrong') }}</div>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="3">
              <v-icon color="checkboxTrue" class="ml-7">mdi-checkbox-marked</v-icon>
            </v-col>
            <v-col cols="1">
              <div>:</div>
            </v-col>
            <v-col cols="8">
              <div class="font-weight-regular">{{ $t('Questions.your-answer-right') }}</div>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="3">
              <v-icon color="checkboxFalse" class="ml-7">mdi-minus-box</v-icon>
            </v-col>
            <v-col cols="1">
              <div>:</div>
            </v-col>
            <v-col cols="8">
              <div class="font-weight-regular">{{ $t('Questions.your-answer-wrong') }}</div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialogHelp = false">{{ $t('Questions.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name: 'QuestionsHelp',
        data: () => {
            return {
                dialogHelp: false, // navigation variable to handle the help dialog state
            }
        },

        computed: {
          // get Test Mode from Store
          testMode() {
              return this.$store.getters.mode
          },
        },
    }
</script>