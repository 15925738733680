<template>
    <v-container class="ma-0 pa-0" fluid>
        <v-card color="card" height="284" class="pa-3">
            <v-card height="258" color="elevatedCard">
                <v-row align="center" justify ="space-between" class="pr-6 pl-2">
                    <v-col cols="9">
                        <v-list color="elevatedCard" dense>
                            <v-list-item dense>
                                <v-list-item-avatar color="primary">
                                    <v-icon color="buttonText">mdi-counter</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>Number of Questions:</v-list-item-title>
                                    <v-list-item-subtitle class="primary--text">{{ number }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item dense>
                                <v-list-item-avatar color="primary">
                                    <v-icon color="buttonText">mdi-clock-outline</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>Length of Test:</v-list-item-title>
                                    <v-list-item-subtitle class="primary--text">{{ duration + 'min' }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item dense>
                                <v-list-item-avatar color="primary">
                                    <v-icon color="buttonText">mdi-currency-eur</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>Price of Test:</v-list-item-title>
                                    <v-list-item-subtitle class="primary--text">{{ price + '€' }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item dense>
                                <v-list-item-avatar color="primary">
                                    <v-icon color="buttonText">mdi-numeric-positive-1</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>Point System:</v-list-item-title>
                                    <v-list-item-subtitle v-if="system != ''" class="primary--text">{{ pointSystemItems.find(element => element.value == system).text  }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn icon elevation="2" class="appBar" @click="dialog = true" :disabled="disableEditing"><v-icon>mdi-pencil</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-card>
        <!-- edit settings dialog -->
        <v-dialog width="650px" v-model="dialog">
            <v-card color="card">
                <v-card-title class="headline cardHeader white--text">Edit Test Settings</v-card-title>
                <v-divider></v-divider>
                <v-card-text class="mt-2">
                    <v-form ref="form" v-model="form">
                        <v-row align="center">
                            <v-col cols="6" class="pb-0">
                                <v-text-field outlined v-model="questionNumber" label="Number of Questions" :rules="numberRules" type="number" :disabled="disableEditing" suffix="Questions"></v-text-field>
                                <v-text-field outlined v-model="testDuration" label="Length of Test" :rules="durationRules" type="number" :disabled="disableEditing" suffix="Minutes"></v-text-field>
                            </v-col>
                            <v-col cols="6" class="pb-0">
                                <!-- <v-text-field outlined v-model="testPrice" label="Price of Test" :rules="priceRules" :disabled="disableEditing"></v-text-field> -->
                                <vuetify-money v-model="testPrice" outlined label="Price of Test" :options="options" backgroundColor="transparent" :rules="priceRules" :disabled="disableEditing"/>
                                <v-select outlined label="Point System" v-model="pointSystem" :items="pointSystemItems" :disabled="disableEditing"></v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-divider style="margin-top: -20px"></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="error" @click="dialog = false">cancel</v-btn>
                    <v-btn class="buttonText--text" color="primary" @click="saveTestSettings" :disabled="!form || disableEditing">Save Test Settings</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>

    export default {
        name: 'EditDBTestSettings',
        props: ['disableEditing'],
        data: function() {
            return {
                dialog: false,
                form: false,
                saveTestObject: {},

                questionNumber: '',
                testDuration: '',
                testPrice: '',
                pointSystem: '',
                number: '',
                duration: '',
                price: '',
                system: '',

                pointSystemItems: [
                    {text: 'All-or-Nothing', value: 'question'},
                    {text: 'Point System', value: 'point'},
                    {text: 'Macedonian System', value: 'macedonia'},
                ],

                options: {
                    locale: "pt-DE",
                    prefix: "€",
                    suffix: "",
                    length: 5,
                    precision: 2
                },

                numberRules: [
                    v => !!v || 'Question Number is required',
                    v => (v && v <= 10000) || 'Question Number must be less than 10000',
                ],
                durationRules: [
                    v => !!v || 'Test Duration is required',
                    v => (v && v.length <= 8) || 'Test Duration must be less than 8 characters',
                ],
                priceRules: [
                    v => !!v || 'Test Price is required',
                    v => (v && v.length <= 20) || 'Test Price must be less than 20 characters',
                ],
            }
        },
        mounted() {
            this.questionNumber = parseInt(this.getTestData.test_question_nr)
            this.testDuration = this.getTestData.test_duration
            this.testPrice = this.getTestData.test_price
            this.pointSystem = this.getTestData.point_system
            this.number = parseInt(this.getTestData.test_question_nr)
            this.duration = this.getTestData.test_duration
            this.price = this.getTestData.test_price
            this.system = this.getTestData.point_system
        },
        watch: {
            getTestData() {
                this.questionNumber = parseInt(this.getTestData.test_question_nr)
                this.testDuration = this.getTestData.test_duration
                this.testPrice = this.getTestData.test_price
                this.pointSystem = this.getTestData.point_system
                this.number = parseInt(this.getTestData.test_question_nr)
                this.duration = this.getTestData.test_duration
                this.price = this.getTestData.test_price
                this.system = this.getTestData.point_system
            },
            dialog() {
                this.questionNumber = parseInt(this.getTestData.test_question_nr)
                this.testDuration = this.getTestData.test_duration
                this.testPrice = this.getTestData.test_price
                this.pointSystem = this.getTestData.point_system
            },
        },
        computed: {
            // test data from the store
            getTestData() {
                // console.log(this.$store.getters.TestData)
                return this.$store.getters.TestData
            },
            // User Object from the store
            UserObject() {
                return this.$store.getters.userData
            },
        },
        methods: {
            // save test settings
            saveTestSettings() {
                this.saveTestObject.test_question_nr = this.questionNumber
                this.saveTestObject.test_duration = this.testDuration
                this.saveTestObject.test_price = this.testPrice
                this.saveTestObject.point_system = this.pointSystem
                this.saveTestObject.usr_session_id = this.UserObject.usr_session_id
                this.saveTestObject.usr_id = this.UserObject.usr_id
                this.saveTestObject.test_id = this.getTestData.test_id
                this.$http.post('index.php',    {   task:           'save_settings',
                                                    saveTestObject: this.saveTestObject, 
                                                })
                        .then(response => {
                            // console.log(response.body)
                            if(response.body == 'success') {
                                this.$store.dispatch('getMyTests', this.UserObject)
                                this.$store.dispatch('getMyEdits', this.UserObject)
                                this.number = this.questionNumber
                                this.duration = this.testDuration
                                this.price = this.testPrice
                                this.system = this.pointSystem
                                this.$store.dispatch('getSnackbar', {status: true, timeout: 4000, color: 'success', btnColor: 'buttonText', text: 'Updated Test Settings' })
                                this.dialog = false
                            } else {
                                this.$store.dispatch('getSnackbar', {status: true, timeout: 4000, color: 'error', btnColor: 'buttonText', text: 'SessionID Outdated. Log in again!' })
                            }
                        });
            },
        }
    }
</script>