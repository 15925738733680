<template>
    <div>
        <!-- mobile theme list item -->
        <v-list-item v-if="isMobile">
            <v-list-item-icon>
                <v-icon>mdi-brightness-4</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('AppNavigationUser.dark-theme') }}</v-list-item-title>
            <v-switch dense v-model="darkMode" @change="changeTheme"></v-switch>
        </v-list-item>
        <!-- theme selection for desktop devices -->
        <v-menu left offset-x :close-on-content-click="false" bottom color="menu" v-if="!isMobile">
            <template v-slot:activator="{ on }">
                <!-- list entry for theme selection -->
                <v-list-item v-on="on">
                    <v-list-item-icon>
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('AppNavigationUser.dark-theme') }}</v-list-item-title>
                    <v-list-item-icon>
                        <v-icon>mdi-brightness-4</v-icon>
                    </v-list-item-icon>
                </v-list-item>   
            </template>
            <!-- theme menu -->
            <v-card color="menu">
                <v-list color="menu">
                    <v-list-item>
                        <v-list-item-avatar>
                            <v-icon color="yellow">mdi-brightness-4</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('AppNavigationTheme.theme') }}</v-list-item-title>
                            <v-list-item-subtitle>{{ $t('AppNavigationTheme.switch') }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <!-- switch theme functionality -->
                <v-list color="menu">
                    <v-list-item>
                        <v-list-item-action>
                            <v-switch v-model="darkMode" @change="changeTheme"></v-switch>
                        </v-list-item-action>
                        <v-list-item-title>{{ $t('AppNavigationTheme.enable') }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
// mobile device detection (plugin)
import { isMobile } from 'mobile-device-detect';
import { Storage } from '@capacitor/storage';

export default {
    // component name
    name: 'AppNavigationTheme',
    // local variables/data
    data: () => {
        return {
            darkMode: false, // if the darkmode is enabled
            isMobile: false // stores which type of device the user has
        }
    },

    mounted() {
        // sets the mobile variable on mount of the side
        this.isMobile = isMobile
        // sets the theme variable for the switch on mount
        if(this.$vuetify.theme.dark == true ) {
            this.darkMode = true
        } else {
            this.darkMode = false
        }
    },
    watch: {
        darkTheme() {
            if(this.$vuetify.theme.dark) {
                this.darkMode = true
            } else {
                this.darkMode = false
            }
        }
    },
    methods: {

        // set the language from menu actions
        changeTheme() {
            if(this.darkMode) {
                this.$vuetify.theme.dark = true;
                const setTheme= async () => {
                    await Storage.set({
                        key: 'theme',
                        value: true,
                    });
                };
                setTheme();
            } else {
                this.$vuetify.theme.dark = false;
                const setTheme= async () => {
                    await Storage.set({
                        key: 'theme',
                        value: false,
                    });
                };
                setTheme();
            }
        },
    }

};

</script>