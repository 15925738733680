<template>
    <v-container class="ma-0 pa-0" fluid>
        <v-card color="card" height="102px" class="pa-3">
            <v-list color="elevatedCard" rounded elevation="3">
                <v-list-item>
                    <v-list-item-content class="py-2">
                        <v-clamp autoresize :max-lines="1" class="subtitle-1 font-weight-light">{{ testName }}</v-clamp>
                        <v-clamp autoresize :max-lines="1" class="caption" style="margin-top: -5px">{{ testInfo }}</v-clamp>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-btn icon elevation="2" class="appBar" @click="open()" :disabled="disabled"><v-icon>mdi-pencil</v-icon></v-btn>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-card>
        <v-dialog v-model="dialog" width="650px">
            <v-card>
                <v-card-title class="headline cardHeader white--text">Edit Test Name and Test Info</v-card-title>
                <v-divider></v-divider>
                <v-card-text class="mt-5">
                    <v-form v-model="form" ref="form">
                        <v-text-field label="Test Name" v-model="name" :rules="nameRules" outlined></v-text-field>
                        <v-textarea label="Test Info" v-model="info" :rules="infoRules" outlined auto-grow counter="700"></v-textarea>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="error" @click="close()">cancel</v-btn>
                    <v-btn color="primary" class="buttonText--text" :disabled="!form" @click="save()">save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
    import VClamp from 'vue-clamp'

    export default {
        name: 'EditDBTestNameInfo',
        props: ['disabled'],
        components: {
            VClamp,
        },
        data: function() {
            return {
                dialog: false,
                name: '',
                info: '',
                testName: '',
                testInfo: '',
                form: false,

                nameRules: [
                    v => !!v || 'Test Name is required',
                    v => (v && v.length <= 50) || 'Test Name must be less than 50 characters',
                ],
                infoRules: [
                    v => !!v || 'Test Info is required',
                    v => (v && v.length <= 700) || 'Test Info must be less than 700 characters',
                ],
            }
        },
        mounted() {
            this.testName = this.getTestData.test_name
            this.testInfo = this.getTestData.test_info
        },
        watch: {
            getTestData() {
                this.testName = this.getTestData.test_name
                this.testInfo = this.getTestData.test_info
            }
        },
        computed: {
            // test data from the store
            getTestData() {
                // console.log(this.$store.getters.TestData)
                return this.$store.getters.TestData
            },
            // User Object from the store
            UserObject() {
                return this.$store.getters.userData
            },
        },
        methods: {
            // save test name and Info
            save() {
                var saveObject = {}
                saveObject.test_name = this.name
                saveObject.test_info = this.info
                saveObject.usr_session_id = this.UserObject.usr_session_id
                saveObject.usr_id = this.UserObject.usr_id
                saveObject.test_id = this.getTestData.test_id
                this.$http.post('index.php',    {   task:       'save_name',
                                                    saveObject: saveObject, 
                                                })
                        .then(response => {
                            if(response.body == 'success') {
                                this.$store.dispatch('getMyTests', this.UserObject)
                                this.$store.dispatch('getMyEdits', this.UserObject)
                                this.$store.dispatch('getSnackbar', {status: true, timeout: 4000, color: 'success', btnColor: 'buttonText', text: 'Updated Test Name and Info' })
                                this.testName = saveObject.test_name
                                this.testInfo = saveObject.test_info
                                this.close()
                            } else {
                                this.$store.dispatch('getSnackbar', {status: true, timeout: 4000, color: 'error', btnColor: 'buttonText', text: 'SessionID Outdated. Log in again!' })
                            }
                        });
            },
            // open dialog
            open() {
                this.name = this.getTestData.test_name
                this.info = this.getTestData.test_info
                this.dialog = true
            },
            // close dialog
            close() {
                this.name = this.getTestData.test_name
                this.info = this.getTestData.test_info
                this.dialog = false
            }
        },
    }
</script>
