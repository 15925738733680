import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { colors } from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                bottom: '#FFFFFF',
                background: '#f9f9f9',
                navigation: '#ffffff',
                subNavigation: '#efefef',
                menu: 'ffffff',
                card: '#fafafa',
                card2: '#1e1e1e',
                cardHeader: '#424242',
                cardTitle: '#E5E5E5',
                toolbarIcon: '#1976d2',
                navText: '#030303',
                icon: '#606060',
                iconHighlight: '#1867c0',
                textHighlight: '#1867c0',
                listHighlight: '#e4edf8',
                checkboxTrue: '#000000',
                checkboxFalse: '#9e9e9e',
                checkboxEmpty: '#757575',
                questionText: '#757575',
                button: '#F0F0F0',
                buttonText: '#ffffff',
                expansionPanel: '#ffffff',
                sliderBackground: colors.grey.lighten2,
                footer1: '#42A5F5',
                footer2: '#64B5F6',
                footer3: '#1976D2',
                homeButton: '#F6F6F6',
                lightButton: '#212121',
                appBar: '#F5F5F5',
                reverseButton: '#383838',
                divider: '#D2D2D2',
                elevatedCard: '#EBEBEB',
                subtit: '#606060',
            },
            dark: {
                bottom: '#121212',
                background: '#181818',
                navigation: '#202020',
                subNavigation: '#151515',
                menu: '#343434',
                card: '#1e1e1e',
                card2: '#fafafa',
                cardHeader: '#282828',
                cardTitle: '#282828',
                toolbarIcon: 'ffffff',
                navText: '#ffffff',
                icon: '#909090',
                iconHighlight: '#ffffff',
                textHighlight: '#ffffff',
                listHighlight: '#383838',
                checkboxTrue: '#ffffff',
                checkboxFalse: '#5e5e5e',
                checkboxEmpty: '#aeaeae',
                questionText: '#aeaeae',
                button: '#383838',
                buttonText: '#272727',
                expansionPanel: '#212121',
                sliderBackground: colors.grey.darken2,
                footer1: '#1976D2',
                footer2: '#1E88E5',
                footer3: '#0D47A1',
                homeButton: '#303030',
                lightButton: '#212121',
                appBar: '#272727',
                reverseButton: '#E5E5E5',
                divider: '#3B3B3B',
                elevatedCard: '#424242',
                subtit: '#B5B5B5',
            }
        }   
    }
});
