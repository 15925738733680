var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","min-height":_vm.windowHeight - 76,"elevation":"0","color":"rgba(0,0,0,0)"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-card',{staticClass:"mx-5",attrs:{"color":"rgba(0,0,0,0)","elevation":"0","width":"1525"}},[_c('v-breadcrumbs',{staticClass:"ma-0",attrs:{"items":_vm.breadCrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])}),_c('v-divider'),_c('v-card-text',{staticClass:"scroll py-0 px-0",style:({height: _vm.windowHeight - 122 + 'px'})},[_c('v-row',{staticClass:"ma-1"},[_c('v-col',{staticClass:"pa-1",attrs:{"cols":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"text-center mx-auto",attrs:{"color":"card","height":"300px","to":'/'}},[_c('v-img',{attrs:{"height":"235","src":require("@/assets/ImageHome.svg"),"contain":""}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out v-card--reveal",staticStyle:{"height":"100%","background-color":"rgb(255, 176, 0)"}},[_c('v-col',[_c('div',{staticClass:"font-weight-light title buttonText--text"},[_vm._v(_vm._s(_vm.$t('Dashboard.content')))]),_c('br'),_c('div',{staticClass:"buttonText--text"},[_vm._v(_vm._s(_vm.$t('Dashboard.getting-started')))]),_c('div',{staticClass:"buttonText--text"},[_vm._v(_vm._s(_vm.$t('Dashboard.features')))]),_c('div',{staticClass:"buttonText--text"},[_vm._v(_vm._s(_vm.$t('Dashboard.tutorials')))])])],1):_vm._e()])],1),_c('v-card-text',{staticClass:"menu",staticStyle:{"position":"relative","height":"120"}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('AppNavigationMenu.home')))])])],1)]}}])})],1),_c('v-col',{staticClass:"pa-1",attrs:{"cols":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"text-center mx-auto",attrs:{"color":"card","height":"300px","to":'/dashboard/store'}},[_c('v-img',{attrs:{"height":"235","src":require("@/assets/ImageStore.svg"),"contain":""}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out v-card--reveal",staticStyle:{"height":"100%","background-color":"rgb(146, 209, 0)"}},[_c('v-col',[_c('div',{staticClass:"font-weight-light title buttonText--text"},[_vm._v(_vm._s(_vm.$t('Dashboard.content')))]),_c('br'),_c('div',{staticClass:"buttonText--text"},[_vm._v(_vm._s(_vm.$t('Dashboard.buy')))]),_c('div',{staticClass:"buttonText--text"},[_vm._v(_vm._s(_vm.$t('Dashboard.keys')))]),_c('div',{staticClass:"buttonText--text"},[_vm._v(_vm._s(_vm.$t('Dashboard.memberships')))])])],1):_vm._e()])],1),_c('v-card-text',{staticClass:"menu",staticStyle:{"position":"relative","height":"120"}},[_c('testStoreKey',{attrs:{"btnStyle":"dashboard"}}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('AppNavigationMenu.test-store')))])],1)],1)]}}])})],1),_c('v-col',{staticClass:"pa-1",attrs:{"cols":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"text-center mx-auto",attrs:{"color":"card","height":"300px","to":'/dashboard/exercises'}},[_c('v-img',{attrs:{"height":"235","src":require("@/assets/ImageTests.svg"),"contain":""}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out v-card--reveal",staticStyle:{"height":"100%","background-color":"rgb(0, 149, 255)"}},[_c('v-col',[_c('div',{staticClass:"font-weight-light title buttonText--text"},[_vm._v(_vm._s(_vm.$t('Dashboard.content')))]),_c('br'),_c('div',{staticClass:"buttonText--text"},[_vm._v(_vm._s(_vm.$t('Dashboard.my-tests')))])])],1):_vm._e()])],1),_c('v-card-text',{staticClass:"menu",staticStyle:{"position":"relative","height":"120"}},[_c('v-btn',{staticClass:"white--text",attrs:{"absolute":"","color":"#005a9c","fab":"","right":"","top":"","to":"/dashboard/store"}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('AppNavigationMenu.my-tests')))])],1)],1)]}}])})],1),_c('v-col',{staticClass:"pa-1",attrs:{"cols":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"text-center mx-auto",attrs:{"color":"card","height":"300px"}},[_c('v-img',{attrs:{"height":"235","src":require("@/assets/ImageExams.svg"),"contain":""}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out v-card--reveal",staticStyle:{"height":"100%","background-color":"rgb(255, 176, 0)"}},[_c('v-col',[(_vm.UserObject.usr_role == 'admin')?_c('v-btn',{attrs:{"rounded":"","to":"/dashboard/exams/create"}},[_vm._v(_vm._s(_vm.$t('AppNavigationMenu.create')))]):_vm._e(),_c('br'),(_vm.UserObject.usr_role == 'admin')?_c('v-btn',{staticClass:"my-3",attrs:{"rounded":"","to":"/dashboard/exams/manage"}},[_vm._v(_vm._s(_vm.$t('AppNavigationMenu.manage')))]):_vm._e(),_c('br'),_c('v-btn',{attrs:{"rounded":"","to":"/dashboard/exams/participate"}},[_vm._v(_vm._s(_vm.$t('AppNavigationMenu.participate')))])],1)],1):_vm._e()])],1),_c('v-card-text',{staticClass:"menu",staticStyle:{"position":"relative","height":"120"}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('AppNavigationMenu.exams')))])])],1)]}}])})],1)],1),_c('v-row',{staticClass:"ma-1"},[_c('v-col',{staticClass:"pa-1",attrs:{"cols":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"text-center mx-auto",attrs:{"color":"card","height":"300px","to":'/dashboard/statistics'}},[_c('v-img',{attrs:{"height":"235","src":require("@/assets/ImageStatistics.svg"),"contain":""}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out v-card--reveal",staticStyle:{"height":"100%","background-color":"rgb(255, 94, 121)"}},[_c('v-col',[_c('div',{staticClass:"font-weight-light title buttonText--text"},[_vm._v(_vm._s(_vm.$t('Dashboard.content')))]),_c('br'),_c('div',{staticClass:"buttonText--text"},[_vm._v(_vm._s(_vm.$t('Dashboard.statistics')))]),_c('div',{staticClass:"buttonText--text"},[_vm._v(_vm._s(_vm.$t('Dashboard.results')))])])],1):_vm._e()])],1),_c('v-card-text',{staticClass:"menu",staticStyle:{"position":"relative","height":"120"}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('AppNavigationMenu.statistics')))])])],1)]}}])})],1),_c('v-col',{staticClass:"pa-1",attrs:{"cols":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"text-center mx-auto",attrs:{"color":"card","height":"300px"}},[_c('v-img',{attrs:{"height":"235","src":require("@/assets/ImageAchievements.svg"),"contain":""}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out v-card--reveal",staticStyle:{"height":"100%","background-color":"rgb(0, 149, 255)"}},[_c('v-col',[_c('div',{staticClass:"font-weight-light title buttonText--text"},[_vm._v(_vm._s(_vm.$t('Dashboard.content')))]),_c('br'),_c('div',{staticClass:"buttonText--text"},[_vm._v(_vm._s(_vm.$t('Dashboard.achievements')))]),_c('div',{staticClass:"buttonText--text"},[_vm._v(_vm._s(_vm.$t('Dashboard.badges')))]),_c('div',{staticClass:"buttonText--text"},[_vm._v(_vm._s(_vm.$t('Dashboard.progress')))])])],1):_vm._e()])],1),_c('v-card-text',{staticClass:"menu",staticStyle:{"position":"relative","height":"120"}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('AppNavigationMenu.achievements')))])])],1)]}}])})],1),((_vm.UserObject.usr_role == 'admin' || _vm.UserObject.usr_role == 'editor'))?_c('v-col',{staticClass:"pa-1",attrs:{"cols":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"text-center mx-auto",attrs:{"color":"card","height":"300px","to":'/dashboard/edit'}},[_c('v-img',{attrs:{"height":"235","src":require("@/assets/ImageEdit.svg"),"contain":""}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out v-card--reveal",staticStyle:{"height":"100%","background-color":"rgb(146, 209, 0)"}},[_c('v-col',[_c('div',{staticClass:"font-weight-light title buttonText--text"},[_vm._v(_vm._s(_vm.$t('Dashboard.content')))]),_c('br'),_c('div',{staticClass:"buttonText--text"},[_vm._v(_vm._s(_vm.$t('Dashboard.edit')))]),_c('div',{staticClass:"buttonText--text"},[_vm._v(_vm._s(_vm.$t('Dashboard.release')))]),_c('div',{staticClass:"buttonText--text"},[_vm._v(_vm._s(_vm.$t('Dashboard.manage')))])])],1):_vm._e()])],1),_c('v-card-text',{staticClass:"menu",staticStyle:{"position":"relative","height":"120"}},[_c('AppNavigationNewTest',{attrs:{"pill":true}}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('AppNavigationMenu.edit-tests')))])],1)],1)]}}],null,false,2407835318)})],1):_vm._e(),(_vm.SuccessfullLogin == true)?_c('v-col',{staticClass:"pa-1",attrs:{"cols":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"text-center mx-auto",attrs:{"color":"card","height":"300px","to":'/dashboard/settings'}},[_c('v-img',{attrs:{"height":"235","src":require("@/assets/ImageSettings.svg"),"contain":""}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out v-card--reveal",staticStyle:{"height":"100%","background-color":"rgb(255, 94, 121)"}},[_c('v-col',[_c('div',{staticClass:"font-weight-light title buttonText--text"},[_vm._v(_vm._s(_vm.$t('Dashboard.content')))]),_c('br'),_c('div',{staticClass:"buttonText--text"},[_vm._v(_vm._s(_vm.$t('Dashboard.userdata')))]),_c('div',{staticClass:"buttonText--text"},[_vm._v(_vm._s(_vm.$t('Dashboard.password')))])])],1):_vm._e()])],1),_c('v-card-text',{staticClass:"menu",staticStyle:{"position":"relative","height":"120"}},[_c('v-btn',{staticClass:"white--text",attrs:{"absolute":"","color":"#9c2d40","fab":"","right":"","top":"","small":""},on:{"click":function($event){$event.preventDefault();return _vm.changeTheme()}}},[_c('v-icon',[_vm._v("mdi-weather-sunny")])],1),_c('v-btn',{staticClass:"white--text",staticStyle:{"right":"70px"},attrs:{"absolute":"","color":"#9c2d40","fab":"","right":"","top":"","small":""},on:{"click":function($event){$event.preventDefault();return _vm.changeLanguage()}}},[_c('v-icon',[_vm._v("mdi-translate")])],1),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('AppNavigationUser.user-settings')))])],1)],1)]}}],null,false,3544155102)})],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }