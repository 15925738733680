import Home                     from './components/Home';
import HomeMobile               from './components/HomeMobile';
import Dashboard                from './components/dashboard';
import DashboardMobile          from './components/dashboardMobile';
import testStore                from './components/test_store/testStore';
import testStoreMobile          from './components/test_store/testStoreMobile';
import testStoreDetails         from './components/test_store/testStoreDetails';
import testStoreDetailsMobile   from './components/test_store/testStoreDetailsMobile';
import testStoreBuy             from './components/test_store/testStoreBuy';
import testStoreBuyMobile       from './components/test_store/testStoreBuyMobile';
import TestList                 from './components/TestList';
import TestListMobile           from './components/TestListMobile';
import Exercises                from './components/test_exercises/Exercises';
import ExercisesMobile          from './components/test_exercises/ExercisesMobile';
import Questions                from './components/test_exercises/Questions';
import createExams              from './components/exams/createExams';
import manageExams              from './components/exams/manageExams';
import participateExams         from './components/exams/participateExams';
import Statistics               from './components/Statistics';
import StatisticsMobile         from './components/StatisticsMobile';
import EditList                 from './components/EditList';
import EditDB                   from './components/edit_tests/EditDB';
import EditDBTable              from './components/edit_tests/EditDBTable';
import EditDBReview             from './components/edit_tests/EditDBReview';
import UserSettings             from './components/UserSettings';
import UserSettingsMobile       from './components/UserSettingsMobile';

export const routes = [
    { path: '/',                                        component: Home,                    name: 'Home' },
    { path: '/mobile/',                                 component: HomeMobile,              name: 'HomeMobile' },
    { path: '/dashboard',                               component: Dashboard,               name: 'Dashboard' },
    { path: '/mobile/dashboard',                        component: DashboardMobile,         name: 'DashboardMobile' },
    { path: '/dashboard/store/',                        component: testStore,               name: 'testStore' },
    { path: '/mobile/dashboard/store/',                 component: testStoreMobile,         name: 'testStoreMobile' },
    { path: '/dashboard/store/product/:product',        component: testStoreDetails,        name: 'testStoreDetails' },
    { path: '/mobile/dashboard/store/product/:product', component: testStoreDetailsMobile,  name: 'testStoreDetailsMobile' },
    { path: '/dashboard/store/buy/:step',               component: testStoreBuy,            name: 'testStoreBuy' },
    { path: '/mobile/dashboard/store/buy/:step',        component: testStoreBuyMobile,      name: 'testStoreBuyMobile' },
    { path: '/dashboard/exercises/',                    component: TestList,                name: 'TestList' },
    { path: '/mobile/dashboard/exercises/',             component: TestListMobile,          name: 'TestListMobile' },
    { path: '/dashboard/exercises/:name',               component: Exercises,               name: 'Exercises' },
    { path: '/mobile/dashboard/exercises/:name',        component: ExercisesMobile,         name: 'ExercisesMobile' },
    { path: '/test/:iterator',                          component: Questions,               name: 'Questions' },
    { path: '/dashboard/exams/create',                  component: createExams,             name: 'createExams' },
    { path: '/dashboard/exams/manage',                  component: manageExams,             name: 'manageExams' },
    { path: '/dashboard/exams/participate',             component: participateExams,        name: 'participateExams' },
    { path: '/dashboard/statistics',                    component: Statistics,              name: 'Statistics' },
    { path: '/mobile/dashboard/statistics/',            component: StatisticsMobile,        name: 'StatisticsMobile' },
    { path: '/dashboard/edit/',                         component: EditList,                name: 'EditList' },
    { path: '/dashboard/edit/:name',                    component: EditDB,                  name: 'EditDB' },
    { path: '/edit-test/table-view',                    component: EditDBTable,             name: 'EditDBTable' },
    { path: '/edit-test/list-view',                     component: EditDBReview,            name: 'EditDBReview' },
    { path: '/dashboard/settings',                      component: UserSettings,            name: 'UserSettings' },
    { path: '/mobile/dashboard/settings',               component: UserSettingsMobile,      name: 'UserSettingsMobile' },
    { path: '*',                                        component: Home }, // 404
];