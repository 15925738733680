<template>
    <v-container class="ma-0 pa-0" fluid>
        <v-card color="card" height="102px" class="pa-3">
            <v-list color="elevatedCard" rounded elevation="3">
                <v-list-item>
                    <v-list-item-avatar color="primary">
                        <v-icon color="buttonText">mdi-calendar</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{ getTestData.test_creation_date.slice(0,10) }}</v-list-item-title>
                        <v-list-item-subtitle>{{ getTestData.test_creation_date.slice(11,16) }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </v-container>
</template>
<script>

    export default {
        name: 'EditDBTestDate',
        data: function() {
            return {
            }
        },
        computed: {
            // test data from the store
            getTestData() {
                // console.log(this.$store.getters.TestData)
                return this.$store.getters.TestData
            },
        },
    }
</script>
