<template>
    <div>
        <!-- Login Dialog -->
        <v-dialog v-model="dialog" fullscreen>
            <template v-slot:activator="{ on: dialog }">
                <!-- Login Button in Appbar -->
                <v-btn fab color="primary" v-on="dialog" icon>
                    <v-icon large>mdi-account-circle</v-icon>
                </v-btn>
            </template>               
            <!-- Login Card -->
            <v-card tile>
                <v-toolbar color="primary">
                    <v-toolbar-title class="buttonText--text">{{ $t('AppNavigationLogin.enter-login-info') }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialog = false" color="buttonText"><v-icon>mdi-close</v-icon></v-btn>
                </v-toolbar>
                <v-progress-linear :active="loading" :indeterminate="loading" absolute bottom color="primary"></v-progress-linear>
                <v-card-text class="mt-8">
                    <v-form class="px-3" @keyup.native.enter="sendLoginData">
                        <!-- Username Textfield -->
                        <v-text-field :label="$t('AppNavigationLogin.username-mail')" prepend-icon="mdi-account" v-model="authObject.user_name" autocomplete="username" outlined autocapitalize="none"></v-text-field>
                        <!-- Password Textfield -->
                        <v-text-field :label="$t('AppNavigationLogin.password')" prepend-icon="mdi-lock" v-model="authObject.user_password" type="password" autocomplete="current-password" outlined></v-text-field>
                        <!-- Bottom Navigation -->
                        <v-row>
                            <v-spacer></v-spacer>
                            <v-btn color="primary buttonText--text" @click="sendLoginData" class="mx-3">Login</v-btn>  
                        </v-row>
                        <v-divider class="mt-2 mb-1 mx-0"></v-divider>
                        <v-row>
                            <v-btn text color="primary" class="body-1 text-decoration-underline text-capitalize" @click="dialog2 = !dialog2">{{ $t('AppNavigationLogin.not-registered') }}</v-btn>
                        </v-row>                  
                    </v-form>
                </v-card-text>
                <!-- alert that shows the successfull registration -->
                <v-alert v-if="responseText != ''" class="mt-3 mx-3" type="success" text outlined border="left" dismissible color="success" icon="mdi-checkbox-marked-circle-outline" close-text="Close Alert" dense>
                    <v-card-title class="success--text">{{ $t('AppNavigationLogin.registration-success') }}</v-card-title>
                    <v-card-text>{{ responseText }}</v-card-text>
                </v-alert>
                <!-- alert that shows if the login attempt was a failure -->
                <v-alert v-if="FailedLogin == true" class="mt-3 mx-3" outlined text type="error" border="left" dismissible color="error" icon="mdi-alert-circle-outline" close-text="Close Alert" dense>
                    <v-card-title class="error--text">{{ $t('AppNavigationLogin.wrong-pass') }}</v-card-title>
                    <v-card-text>{{ $t('AppNavigationLogin.try-again') }}</v-card-text>
                </v-alert>
            </v-card>
        </v-dialog>
        <!-- Register Dialog -->
        <v-dialog v-model="dialog2" fullscreen persistent>
            <v-card tile>
                <v-toolbar>
                    <v-toolbar-title>{{ $t('AppNavigationLogin.register-new') }}</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="my-2" style="height: calc(100vh - 126px)">
                    <!-- Form With Username, E-Mail and Password -->
                    <v-form ref="form" v-model="form">
                        <v-text-field :label="$t('AppNavigationLogin.first-name')" class="mx-5" v-model="first_name" autocomplete="given-name" :rules="firstNameRules"></v-text-field>
                        <v-text-field :label="$t('AppNavigationLogin.last-name')" class="mx-5" v-model="last_name" autocomplete="family-name" :rules="lastNameRules"></v-text-field>
                        <v-divider></v-divider>
                        <v-text-field label="E-Mail *" prepend-icon="mdi-email" class="mx-5" v-model="mail" autocomplete="email" :rules="mailRules" :error-messages="checkMail2()" @input="checkMail()"></v-text-field>
                        <v-text-field :label="$t('AppNavigationLogin.username')" prepend-icon="mdi-account" class="mx-5" v-model="username" name="username" autocomplete="off" :rules="userNameRules" :error-messages="checkName2()" @input="checkName()"></v-text-field>
                        <v-text-field :label="$t('AppNavigationLogin.password2')" prepend-icon="mdi-lock" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show1 = !show1" :type="show1 ? 'text' : 'password'" class="mx-5" v-model="password" autocomplete="new-password" :rules="passwordRules"></v-text-field>
                        <v-text-field :label="$t('AppNavigationLogin.confirm-password')" prepend-icon="mdi-lock" type="password" class="mx-5" v-model="password2" autocomplete="new-password" :error-messages="passwordMatchError()"></v-text-field>
                    </v-form>
                </v-card-text>
                <!-- Bottom Navigation -->
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn rounded outlined color="error" @click="dialog2 = false">{{ $t('AppNavigationLogin.cancel') }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn rounded color="primary buttonText--text" @click="registerUser" :disabled="!form">{{ $t('AppNavigationLogin.register') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

export default {
    name: 'AppNavigationUserLoginMobile',

    data: function() {
        return {
            loading: false, // loading state of the component
            authObject: {}, // username/mail + password object
            registerObject: {}, // object with the user registration data
            dialog: false, // handling variable for login dialog
            dialog2: false, // handling variable for registration dialog
            show1: false, // handling variable to show the entered password
            form: false, // form for the registration
            // Register Variables
            first_name: '',
            last_name: '',
            username: '',
            mail: '',
            password: '',
            password2: '',
            // response variables
            responseText: '', // message after failed login or successfull registration
            nameExists: false, // if username already exists
            mailExists: false, // if mail already exists
            // rules for the registration form
            firstNameRules: [
                v => !!v || this.$i18n.t('UserSettings.first-name-requiered'),
            ],
            lastNameRules: [
                v => !!v || this.$i18n.t('UserSettings.last-name-requiered'),
            ],
            userNameRules: [
                v => !!v || this.$i18n.t('UserSettings.username-requiered'),
                v => (v && v.length >= 3) || this.$i18n.t('UserSettings.username-rules'),
            ],
            passwordRules: [
                v => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[[!@#§/()=?_-€<>°$%^&*\]])(?=.{8,})/.test(v) || this.$i18n.t('UserSettings.password-rules'),
            ],
            mailRules: [
                v => !!v || this.$i18n.t('UserSettings.mail-requiered'),
                v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || this.$i18n.t('UserSettings.mail-rules'),
            ],

        }
    },

    watch: {
        dialog() {
            if(this.dialog == false) {
                this.responseText = ''
                this.authObject.user_name = ''
                this.authObject.user_password = ''
                // reset failed login after new registration
                this.$store.dispatch('resetFail')
            }
        },

        dialog2() {
            if(this.dialog2 == false) {
                this.first_name = '',
                this.last_name= '',
                this.username = ''
                this.mail = ''
                this.password = ''
                this.password2 = ''
                // reset failed login after new registration
                this.$store.dispatch('resetFail')
            }
        },

        FailedLogin() {
            if(this.FailedLogin == true) {
                this.loading = false
            }
        },

        LoginEvent() {
            if(this.LoginEvent == true) {
                this.openDialog()
                this.$store.dispatch('getLoginEvent', false)
            }
        }

    },

    computed: {
        // vuetify theme
        theme() {
            return this.$vuetify.theme.dark
        },

        // gets message that the login failed
        FailedLogin() {
            return this.$store.getters.failedLogin
        },

        LoginEvent() {
            // console.log(this.$store.getters.loginEvent)
            return this.$store.getters.loginEvent
        },

    },

    methods: {

        openDialog() {
            if(this.LoginEvent == true) {
                this.dialog = true
            }
        },

        // check if username already exists
        checkName() {
            this.$http.post('index.php',    {   task:   'check_name2',
                                                name:   this.username
                                            })
            .then(response => {
                // console.log(response.body)
                if((typeof response.body) != 'object') {
                    if(response.body.slice(1,-1) === this.username) {
                        // console.log('exists')
                        this.nameExists = true
                    } else {
                        // console.log('dont exist')
                        this.nameExists = false
                    }
                } else {
                    // console.log('dont exist')
                    this.nameExists = false
                }
            });    
        },
        checkName2() {
            return (this.nameExists === false) ? '' : this.$i18n.t('UserSettings.username-taken')
        },

        // check if e-mail already exists
        checkMail() {
            this.$http.post('index.php',    {   task:   'check_mail2',
                                                mail:   this.mail
                                            })
            .then(response => {
                // console.log(response.body)
                if((typeof response.body) != 'object') {
                    if(response.body.slice(1,-1) === this.mail) {
                        // console.log('exists')
                        this.mailExists = true
                    } else {
                        // console.log('dont exist')
                        this.mailExists = false
                    }
                } else {
                    // console.log('dont exist')
                    this.mailExists = false
                }
            });    
        },
        checkMail2() {
            return (this.mailExists === false) ? '' : this.$i18n.t('UserSettings.mail-taken')
        },

        // confirm password
        passwordMatchError () { 
            return (this.password === this.password2) ? '' : this.$i18n.t('UserSettings.password-match')
        },

        // register a new User
        registerUser() {

            this.registerObject.usr_first_name = this.first_name
            this.registerObject.usr_last_name = this.last_name
            this.registerObject.usr_name = this.username
            this.registerObject.usr_mail = this.mail
            this.registerObject.usr_password = this.password

            // calculation of user id
            this.registerObject.usr_id = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) );

            // For todays date;
            Date.prototype.today = function () { 
                return this.getFullYear() +"-"+(((this.getMonth()+1) < 10)?"0":"") + (this.getMonth()+1) +"-"+ ((this.getDate() < 10)?"0":"") + this.getDate();
            }
            // For the time now
            Date.prototype.timeNow = function () {
                return ((this.getHours() < 10)?"0":"") + this.getHours() +":"+ ((this.getMinutes() < 10)?"0":"") + this.getMinutes() +":"+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds();
            }
            // when and by who it was added
            this.registerObject.usr_created = new Date().today() + " " + new Date().timeNow()

            // console.log(this.registerObject)

            this.$http.post('index.php',    {   task:           'register_user',
                                                registerObject: this.registerObject, 
                                            })
            .then(response => {
                this.responseText = 'The User ' + response.body.add_user + ' was successfully created'
            });
            this.dialog2 = false
        },

        // sends the login data to the store (username + password)
        sendLoginData()  {
            this.loading = true
            // console.log('login', this.authObject)
            this.$store.dispatch('getLoginData', this.authObject)
        },

    }

};

</script>