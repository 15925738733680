<template>
    <div>
        <!-- Login Dialog -->
        <v-dialog v-model="dialog" max-width="450px">
            <template v-slot:activator="{ on, attrs}">
                <!-- Login Button in Appbar -->
                <v-btn outlined color="primary" v-on="on" v-bind="attrs">
                    <v-icon left>mdi-account-circle</v-icon> Login
                </v-btn>
            </template>
            <!-- Login Card -->
            <v-card>
                <!-- loading bar for login -->
                <v-progress-linear :active="loading" :indeterminate="loading" absolute bottom color="primary"></v-progress-linear>
                <!-- login dialog title -->
                <v-card-title class="cardText">
                    <div class="title">{{ $t('AppNavigationLogin.enter-login-info')}}</div>
                </v-card-title>
                <!-- dialog content -->
                <v-card-text>
                    <v-form class="px-3" @keyup.native.enter="sendLoginData">
                        <!-- Username Textfield -->
                        <v-text-field :label="$t('AppNavigationLogin.username-mail')" prepend-icon="mdi-account" v-model="authObject.user_name" autocomplete="username"></v-text-field>
                        <!-- Password Textfield -->
                        <v-text-field :label="$t('AppNavigationLogin.password')" prepend-icon="mdi-lock" v-model="authObject.user_password" type="password" autocomplete="current-password" hide-details></v-text-field>                       
                    </v-form>
                    <v-btn v-if="1==2" text color="primary" class="body-1 text-capitalize ml-7" @click="dialog3 = !dialog3">{{ $t('AppNavigationLogin.forgot-password') }}</v-btn>
                    <!-- <v-row justify="center">
                        <v-btn tile @click="getAuth()">
                            <v-icon class="mr-1">mdi-google</v-icon>
                            Sign in with Google
                        </v-btn>
                    </v-row> -->
                </v-card-text>
                <v-divider></v-divider>
                <!-- Bottom Navigation -->
                <v-card-actions>
                    <v-btn text color="primary" class="body-1 text-decoration-underline text-capitalize ml-3" @click="dialog2 = !dialog2">{{ $t('AppNavigationLogin.not-registered') }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary buttonText--text mr-3" @click="sendLoginData">Login</v-btn>
                </v-card-actions>
            </v-card>
            <!-- card that shows the successfull registration -->
            <v-card v-if="responseText != ''" class="mt-3">
                <v-card-title class="success--text">{{ $t('AppNavigationLogin.registration-success') }}</v-card-title>
                <v-card-text>{{ responseText }}</v-card-text>
            </v-card>
            <!-- card that shows if the login attempt was a failure -->
            <v-card v-if="FailedLogin == true" class="mt-3">
                <v-card-title class="error--text">{{ $t('AppNavigationLogin.wrong-pass') }}</v-card-title>
                <v-card-text>{{ $t('AppNavigationLogin.try-again') }}</v-card-text>
            </v-card>
        </v-dialog>
        <!-- Register Dialog -->
        <v-dialog v-model="dialog2" max-width="500px" persistent>
            <v-card>
                <v-card-title>{{ $t('AppNavigationLogin.register-new') }}</v-card-title>
                <v-divider></v-divider>
                <v-card-text class="my-2">
                    <!-- Form With Username, E-Mail and Password -->
                    <v-form ref="form" v-model="form">
                        <v-text-field :label="$t('AppNavigationLogin.first-name')" class="mx-5" v-model="first_name" autocomplete="given-name" :rules="firstNameRules"></v-text-field>
                        <v-text-field :label="$t('AppNavigationLogin.last-name')" class="mx-5" v-model="last_name" autocomplete="family-name" :rules="lastNameRules"></v-text-field>
                        <v-divider></v-divider>
                        <v-text-field label="E-Mail *" prepend-icon="mdi-email" class="mx-5" v-model="mail" autocomplete="email" :rules="mailRules" :error-messages="checkMail2()" @input="checkMail()"></v-text-field>
                        <v-text-field :label="$t('AppNavigationLogin.username')" prepend-icon="mdi-account" class="mx-5" v-model="username" name="username" autocomplete="off" :rules="userNameRules" :error-messages="checkName2()" @input="checkName()"></v-text-field>
                        <v-text-field :label="$t('AppNavigationLogin.password2')" prepend-icon="mdi-lock" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show1 = !show1" :type="show1 ? 'text' : 'password'" class="mx-5" v-model="password" autocomplete="new-password" :rules="passwordRules"></v-text-field>
                        <v-text-field :label="$t('AppNavigationLogin.confirm-password')" prepend-icon="mdi-lock" type="password" class="mx-5" v-model="password2" autocomplete="new-password" :error-messages="passwordMatchError()"></v-text-field>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <!-- Bottom Navigation -->
                <v-card-actions>
                    <v-btn rounded outlined color="error" @click="dialog2 = false">{{ $t('AppNavigationLogin.cancel') }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn rounded color="primary buttonText--text" @click.prevent="registerUser" :disabled="!form">{{ $t('AppNavigationLogin.register') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Password reset -->
        <v-dialog v-model="dialog3" max-width="450px" persistent>
            <v-card>
                <v-card-title>{{ $t('AppNavigationLogin.forgot') }}</v-card-title>
                <v-divider></v-divider>
                <v-card-text class="my-2">
                    <v-alert type="error" text outlined v-model="alert2" border="left" dismissible color="error" icon="mdi-alert-circle-outline" close-text="Close Alert">
                        {{ $t('AppNavigationLogin.no-mail') }}
                    </v-alert>
                    <div>{{ $t('AppNavigationLogin.forgot-text') }}</div>
                    <!-- Form With Email-->
                    <v-form ref="form" v-model="form2">
                        <v-text-field label="Your Email" prepend-icon="mdi-email" class="mx-5" v-model="mail2" autocomplete="email" :rules="mailRules"></v-text-field>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <!-- Bottom Navigation -->
                <v-card-actions>
                    <v-btn outlined color="primary" class="ma-1 button" @click="dialog3 = false">{{ $t('AppNavigationLogin.cancel') }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary buttonText--text" class="ma-1 button" @click.prevent="resetPassword()" :disabled="!form2">{{ $t('AppNavigationLogin.reset-password') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import emailjs from 'emailjs-com';
import{ init } from 'emailjs-com';
init("user_QdQ1PeDEykUkrlvIa0kS1");

export default {
    name: 'AppNavigationUserLogin',
    // local variables/data
    data: function() {
        return {
            loading: false, // loading state of the component
            authObject: {}, // username/mail + password object
            registerObject: {}, // object with the user registration data
            dialog: false, // handling variable for login dialog
            dialog2: false, // handling variable for registration dialog
            show1: false, // handling variable to show the entered password
            form: false, // form for the registration

            dialog3: false,
            form2: false,
            alert2: false,
            mail2: '',

            // google login

            // Register Variables
            first_name: '',
            last_name: '',
            username: '',
            mail: '',
            password: '',
            password2: '',
            // response variables
            responseText: '', // message after failed login or successfull registration
            nameExists: false, // if username already exists
            mailExists: false, // if mail already exists
            // rules for the registration form
            firstNameRules: [
                v => !!v || this.$i18n.t('UserSettings.first-name-requiered'),
                v => (v && v.length <= 100) || this.$i18n.t('UserSettings.first-name-length'),
            ],
            lastNameRules: [
                v => !!v || this.$i18n.t('UserSettings.last-name-requiered'),
                v => (v && v.length <= 100) || this.$i18n.t('UserSettings.last-name-length'),
            ],
            userNameRules: [
                v => !!v || this.$i18n.t('UserSettings.username-requiered'),
                v => (v && v.length <= 50) || this.$i18n.t('UserSettings.username-length'),
                v => (v && v.length >= 3) || this.$i18n.t('UserSettings.username-rules'),
            ],
            passwordRules: [
                v => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[[!@#§/()=?_-€<>°$%^&*\]])(?=.{8,})/.test(v) || this.$i18n.t('UserSettings.password-rules'),
            ],
            mailRules: [
                v => !!v || this.$i18n.t('UserSettings.mail-requiered'),
                v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || this.$i18n.t('UserSettings.mail-rules'),
            ],

        }
    },

    watch: {

        dialog() {
            if(this.dialog == false) {
                // reset the response text and login variables after the login dialog is (re)opened
                this.$store.dispatch('resetFail')
                this.responseText = ''
                this.authObject.user_name = ''
                this.authObject.user_password = ''
            }
        },

        dialog2() {
            if(this.dialog2 == false) {
                // reset the registration variables after the login
                this.first_name = '',
                this.last_name= '',
                this.username = ''
                this.mail = ''
                this.password = ''
                this.password2 = ''
                // reset failed login after new registration
                this.$store.dispatch('resetFail')
            }
        },

        FailedLogin() {
            if(this.FailedLogin == true) {
                // also end loading state with a failed login
                this.loading = false
            }
        },

    },

    computed: {

        // gets message that the login failed
        FailedLogin() {
            return this.$store.getters.failedLogin
        },

    },

    methods: {

        // getAuth() {
        //     const authCode = this.$gAuth.getAuthCode()
        //     console.log(authCode)
        // },

        resetPassword() {
            this.$http.post('index.php',    {   task:   'check_mail2',
                                                mail:   this.mail2
                                            })
            .then(response => {
                // console.log(response.body)
                if((typeof response.body) != 'object') {
                    if(response.body.slice(1,-1) === this.mail2) {
                        // console.log('exists')
                        this.dialog3 = false
                        // enerate password
                        var password = (
                            length = 10,
                            wishlist = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$"
                            ) => Array(length)
                                .fill('')
                                .map(() => wishlist[Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * wishlist.length)])
                                .join('');
                        var pass = password()
                        // console.log(this.mail2)
                        // send mail with temporary password
                        emailjs.send('service_xlauszb', 'template_510r3hc', {
                            usr_mail: this.mail2,
                            password: pass
                        })
                            .then(function(response) {
                                console.log('SUCCESS!', response.status, response.text);
                            }, function(error) {
                                console.log('FAILED...', error);
                            });
                        // push new password to DB
                        // console.log(pass, this.mail2)
                        this.$http.post('index.php',    {   task:       'reset_password',
                                                            password:   pass,
                                                            mail:       this.mail2
                                                        })
                                .then(response => {
                                    console.log(response.body)
                                });
                    } else {
                        this.alert2 = true
                    }
                } else {
                    this.alert2 = true
                }
            }); 
        },

        // check if username already exists in the DB
        checkName() {
            this.$http.post('index.php',    {   task:   'check_name2',
                                                name:   this.username
                                            })
            .then(response => {
                // console.log(response.body)
                if((typeof response.body) != 'object') {
                    if(response.body.slice(1,-1) === this.username) {
                        // console.log('exists')
                        this.nameExists = true
                    } else {
                        // console.log('dont exist')
                        this.nameExists = false
                    }
                } else {
                    // console.log('dont exist')
                    this.nameExists = false
                }
            });    
        },
        // create registration inline response text for taken username
        checkName2() {
            return (this.nameExists === false) ? '' : this.$i18n.t('UserSettings.username-taken')
        },
        // check if e-mail already exists in the DB
        checkMail() {
            this.$http.post('index.php',    {   task:   'check_mail2',
                                                mail:   this.mail
                                            })
            .then(response => {
                // console.log(response.body)
                if((typeof response.body) != 'object') {
                    if(response.body.slice(1,-1) === this.mail) {
                        // console.log('exists')
                        this.mailExists = true
                    } else {
                        // console.log('dont exist')
                        this.mailExists = false
                    }
                } else {
                    // console.log('dont exist')
                    this.mailExists = false
                }
            });    
        },
        // create registration inline response text for taken mail
        checkMail2() {
            return (this.mailExists === false) ? '' : this.$i18n.t('UserSettings.mail-taken')
        },
        // confirm password
        passwordMatchError () { 
            return (this.password === this.password2) ? '' : this.$i18n.t('UserSettings.password-match')
        },
        // register a new User
        registerUser() {
            // fill registration object
            var mail = this.mail
            var first_name = this.first_name
            var last_name = this.last_name
            this.registerObject.usr_first_name = this.first_name
            this.registerObject.usr_last_name = this.last_name
            this.registerObject.usr_name = this.username
            this.registerObject.usr_mail = this.mail
            this.registerObject.usr_password = this.password
            // calculation of user id
            this.registerObject.usr_id = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) );
            // For todays date;
            Date.prototype.today = function () { 
                return this.getFullYear() +"-"+(((this.getMonth()+1) < 10)?"0":"") + (this.getMonth()+1) +"-"+ ((this.getDate() < 10)?"0":"") + this.getDate();
            }
            // For the time now
            Date.prototype.timeNow = function () {
                return ((this.getHours() < 10)?"0":"") + this.getHours() +":"+ ((this.getMinutes() < 10)?"0":"") + this.getMinutes() +":"+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds();
            }
            // when the user was created
            this.registerObject.usr_created = new Date().today() + " " + new Date().timeNow()
            // console.log(this.registerObject)
            // post request to DB
            this.$http.post('index.php',    {   task:           'register_user',
                                                registerObject: this.registerObject, 
                                            })
            .then(response => {
                this.responseText = response.body.add_user
                // send confirmation mail
                // console.log(mail)
                emailjs.send('service_xlauszb', 'template_9e245mw', {
                    usr_first_name: first_name,
                    usr_last_name: last_name,
                    usr_mail: mail,
                })
                    .then(function(response) {
                        console.log('SUCCESS!', response.status, response.text);
                    }, function(error) {
                        console.log('FAILED...', error);
                    });
            });
            // close registration dialog
            this.dialog2 = false
        },
        // sends the login data to the store (username + password)
        sendLoginData()  {
            this.loading = true
            // console.log('login', this.authObject)
            this.$store.dispatch('getLoginData', this.authObject)
        },

    }

};

</script>