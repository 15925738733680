<template>
    <v-dialog v-model="dialog" max-width="750px" persistent>
        <template v-slot:activator="{ on }">
            <v-btn v-if="!pill" outlined class="mx-5 my-4" v-on="on">
                <v-icon class="mr-2" color="primary">mdi-plus</v-icon>{{ $t('AppNavigationMenu.add-test') }}
            </v-btn>
            <v-btn @click.prevent="dialog = true" v-if="UserObject.usr_role == 'admin' && pill" absolute color="#689401" class="white--text" fab right top>
                <v-icon>mdi-pencil-plus</v-icon>
            </v-btn>
        </template>
        <v-card color="card">
            <v-card-title class="cardHeader">
                <span class="headline white--text">{{ $t('AppNavigationMenu.new-test') }}</span>
            </v-card-title>
            <v-card-text style="height: 600px;" class="scroll">
                <v-container>
                    <v-row>
                        <v-expansion-panels accordion multiple v-model="panel">
                            <v-form ref="form" v-model="form">
                                <v-container style="width: 720px">
                                    <v-expansion-panel class="expansionPanel">
                                        <v-expansion-panel-header>{{ $t('AppNavigationMenu.fill') }}</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-text-field outlined :label="$t('AppNavigationMenu.test-name')" @input="checkName()" v-model="testName" :error-messages="nameCheck" :rules="testNameRules"></v-text-field>
                                            <v-textarea outlined auto-grow rows="1" :label="$t('AppNavigationMenu.test-info')" v-model="testInfo"></v-textarea>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-container>
                            </v-form>
                        </v-expansion-panels>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <!-- card actions (save or cancel) -->
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" outlined @click="dialog = false">{{ $t('AppNavigationMenu.cancel') }}</v-btn>
                <v-btn color="primary buttonText--text" :disabled="!form" @click="createTest()">{{ $t('AppNavigationMenu.add') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name: 'AppNavigationNewTest',
        props: ['pill'],
        data() {
            return {
                dialog: false,
                form: false,
                panel: [0,1,2],

                testName: '',
                testInfo: '',
                message: '',
                check: '',
                testNameRules: [
                    v => !!v || 'You have to enter a Test Name in Order to create one!',
                    v => (v && v.length <= 50) || 'Test Name must be less than 50 Characters!',
                    v => (v && v.length >= 3) || 'Test Name must be more than 3 Characters!',
                ],
            }
        },
        computed: {
            // User Object from the store
            UserObject() {
                return this.$store.getters.userData
            },
            nameCheck() {
                if(this.check == 'valid') {
                    return ''
                } else if (this.check == 'not_valid') {
                    return 'You already have a Test with this Name!'
                } else if(this.check == 'sessionID-outdated') {
                    return 'Session ID is outdated. Please log in again!'
                } else {
                    return ''
                }
            },
        },
        methods: {
            createTest() {
                var test_name_db = "test__" + this.testName.trimStart().trimEnd().split(" ").join('_').toLowerCase().trimRight('_') + '_' + this.UserObject.usr_name
                var test_name = this.testName.trimStart().trimEnd()
                var testObject = {}
                testObject.test_id = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) );
                testObject.test_name = test_name
                testObject.test_name_db = test_name_db
                testObject.test_info = this.testInfo
                testObject.test_creator = this.UserObject.usr_name
                testObject.test_creator_id = this.UserObject.usr_id
                testObject.test_question_nr = 1
                testObject.test_duration = 10
                testObject.test_subtopic_id = 'ad0d0a5c-4174-4364-9880-e28d3381c574'
                testObject.test_price = '0.00'
                testObject.test_in_store = 0
                testObject.point_system = 'question'
                // console.log(testObject)

                var creatorObject = {}
                creatorObject.edit_right_id = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) );
                creatorObject.test_name = test_name_db
                creatorObject.test_id = testObject.test_id
                creatorObject.usr_name = this.UserObject.usr_name
                creatorObject.usr_id = this.UserObject.usr_id
                // console.log(creatorObject)

                this.$http.post('index.php',    {   task:           'create_test',
                                                    testObject:     testObject,
                                                    creatorObject:  creatorObject,
                                                    userObject:     this.UserObject,
                                                })
                        .then(response => {
                            this.$store.dispatch('getSnackbar', {status: true, timeout: 3000, color: '', btnColor: 'error', text: 'Test Created: ' + response.body })
                            this.$store.dispatch('getMyEdits', this.UserObject)
                        })
                this.dialog = false
            },

            checkName() {
                var test_name_db = "test__" + this.testName.trimStart().trimEnd().split(" ").join('_').toLowerCase().trimRight('_') + '_' + this.UserObject.usr_name
                
                this.$http.post('index.php',    {   task:        'check_test_name',
                                                    name:        test_name_db,
                                                    userObject:  this.UserObject,
                                                })
                        .then(response => {
                            this.check = response.body
                        })
            },
        },
    }
</script>