<template>

    <!-- help dialog with short explanation -->
    <v-dialog v-model="dialogHelp" width="750px">
        <template v-slot:activator="{ on, attrs}">
            <v-btn v-on="on" v-bind="attrs" rounded outlined class="ml-3 mb-1">
                <v-icon>mdi-help</v-icon> {{ $t('Questions.help') }}
            </v-btn>
        </template>
        <v-card color="card">
            <v-card-title>{{ 'Helpful Information' }}</v-card-title>
            <v-card-text style="height: 600px" class="scroll">
                <v-container class="ma-0 pa-0 fluid" v-if="editedIndex == -1">
                    <div class="font-weight-light subtitle-1 mb-3">Create new Question:</div>
                    <ol>
                        <li>Selected one Subtopic from the available Choices</li>
                        <li>Select one Question Type (This can't be changed later on!)</li>
                    </ol>
                </v-container>
                <v-container class="ma-0 pa-0 fluid" v-else>
                    <div class="font-weight-light subtitle-1 mb-3">Edit Question:</div>
                    <ul>
                        <li>You can change the Subtopic of the Question</li>
                        <li>You can verify/unverify the Question by clicking the big colored Checkbox</li>
                    </ul>
                    <v-row class="my-3 ml-3">
                        <v-icon small class="mx-3">mdi-information-outline</v-icon>
                        <div class="font-weight-light">The Question Type can't be edited!</div>
                    </v-row>
                    <v-row class="my-3 ml-3">
                        <v-icon small class="mx-3">mdi-information-outline</v-icon>
                        <div class="font-weight-light">Please supply the Question ID when you need support from a Developer</div>
                    </v-row>
                </v-container>
                <v-divider class="mt-3"></v-divider>
                <v-container class="ma-0 pa-0 fluid">
                    <div class="subtitle-2 font-weight-light my-3">Question Header:</div>
                    <ol>
                        <li>Write an explanatory Situation Text for your Question (mandatory!)</li>
                        <li>Enter the Question Text itself (mandatory!)</li>
                        <li>You can add an Intro Text (this Text is displayed right before the answer choices of the Question)</li>
                        <li>You can also add a Hint (This Text will be displayed after checking the user input of the Question in either of the Exercise Modes)</li>
                    </ol>
                    <v-row class="my-3 ml-3">
                        <v-icon small class="mx-3">mdi-information-outline</v-icon>
                        <div class="font-weight-light">Intro and Hint can be removed after adding it</div>
                    </v-row>
                </v-container>
                <v-divider></v-divider>
                <v-container v-if="typ == 'mul'" class="ma-0 pa-0 fluid">
                    <div class="subtitle-2 font-weight-light my-3">Answer Choices:</div>
                    <ul>
                        <li>Enter atleast two Answer Choices!</li>
                        <li>Atleast one Answer has to be correct!</li>
                        <li>The Checkbox on the left controls the State of each Answer Choice (Checked means the Answer is correct)</li>
                        <li>You can add up to six Answer Choices</li>
                    </ul>
                    <v-row class="my-3 ml-3">
                        <v-icon small class="mx-3">mdi-information-outline</v-icon>
                        <div class="font-weight-light">It is possible to add and remove Answers later on</div>
                    </v-row>
                </v-container>
                <v-container v-else class="ma-0 pa-0 fluid">
                    <div class="subtitle-2 font-weight-light my-3">Answer Choices:</div>
                    <ul>
                        <li>Give atleast two Items that can be sorted</li>
                        <li>The Order of Items (from Up to Down) equals the correct sorting Order</li>
                        <li>You can add up to six Items</li>
                    </ul>
                    <v-row class="my-3 ml-3">
                        <v-icon small class="mx-3">mdi-information-outline</v-icon>
                        <div class="font-weight-light">It is possible to add and remove Items later on</div>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="dialogHelp = false">{{ $t('Questions.close') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name: 'EditDBHelp',
        props: ['editedIndex', 'editedItem'],
        data: () => {
            return {
                dialogHelp: false, // navigation variable to handle the help dialog state
                typ: '',
            }
        },
        watch: {
            editedItem: {
                deep: true,
                handler() {
                    if(this.editedItem.fullType == 'Multiple Choice') {
                        this.typ = 'mul'
                    } else {
                        this.typ = 'sor'
                    }
                }
            }
        },
        computed: {
        },
    }
</script>