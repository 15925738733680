<template>
    <div>
        <!-- language selection for mobile devices -->
        <v-list-group v-if="isMobile" prepend-icon="mdi-translate" :value="menu" @click="menu = true">
            <!-- displays current language -->
            <template v-slot:activator>
                <v-list-item-title>{{ $t('AppNavigationUser.language') + ': ' + currentLanguage }}</v-list-item-title>
            </template>
            <!-- list with all selectable languages -->
            <v-list-item v-for="(language, i) in languages" :key="i" @click="setLanguage(language)">
                <v-list-item-title>{{ language }}</v-list-item-title>
                <v-list-item-icon>
                    <v-icon color="primary" v-if="language == currentLanguage">mdi-checkbox-marked-circle</v-icon>
                </v-list-item-icon>
            </v-list-item>
        </v-list-group>
        <!-- clickable menu entry for language selection on desktop devices -->
        <v-menu left offset-x color="menu" v-if="!isMobile">
            <template v-slot:activator="{ on }">
                <v-list-item v-on="on">
                    <v-list-item-icon>
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-list-item-icon>
                    <!-- displays current language -->
                    <v-list-item-title>{{ $t('AppNavigationUser.language') + ': ' + currentLanguage }}</v-list-item-title>
                    <v-list-item-icon>
                        <v-icon>mdi-translate</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </template>
            <!-- list with all selectable languages -->
            <v-list color="menu">
                <v-list-item v-for="(language, i) in languages" :key="i" @click="setLanguage(language)">
                    <v-list-item-title>{{ language }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
    // mobile device detection (plugin)
    import { isMobile } from 'mobile-device-detect';
    import { Storage } from '@capacitor/storage';

    export default {
        // component Name
        name: 'AppNavigationLanguage',
        // local variables/data
        data: () => {
            return {
                languages: ['English', 'Deutsch'], // supported languages
                currentLanguage: 'en', // the currently selected language
                isMobile: false, // stores which type of device the user has
                menu: false, // if the mobile language menu is open
            }
        },

        mounted() {
            // sets the mobile variable on mount of the side
            this.isMobile = isMobile
            if(this.appLanguage == 'de') {
                this.currentLanguage = 'Deutsch'
            } else {
                this.currentLanguage = 'English'
            }
        },
        watch: {
            appLanguage() {
                console.log(this.appLanguage)
                if(this.appLanguage == 'de') {
                    this.currentLanguage = 'Deutsch'
                } else {
                    this.currentLanguage = 'English'
                }
            }
        },
        computed: {
            appLanguage() {
                return this.$i18n.locale 
            }
        },
        methods: {
            // set the language from menu actions
            setLanguage(lan) {
                if(lan == 'English') {
                    this.$i18n.locale = 'en'
                    const setLanguage= async () => {
                        await Storage.set({
                            key: 'language',
                            value: 'en',
                        });
                    };
                    setLanguage();
                } else if(lan == 'Deutsch') {
                    this.$i18n.locale = 'de'
                    const setLanguage= async () => {
                        await Storage.set({
                            key: 'language',
                            value: 'de',
                        });
                    };
                    setLanguage();
                }
                this.currentLanguage = lan;
            },
        }
    };
</script>