var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"py-3 px-2",attrs:{"tile":"","min-height":_vm.windowHeight - 76,"elevation":"0","color":"rgba(0,0,0,0)"}},[_c('v-data-table',{staticClass:"elevation-1 mt-2",attrs:{"multi-sort":"","fixed-header":"","headers":_vm.headers,"items":_vm.questions,"height":_vm.windowHeight - 232,"search":_vm.searchTerm,"dense":"","loading":_vm.loading,"locale":_vm.$i18n.locale},scopedSlots:_vm._u([{key:"item.verified",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":"","true-value":"1","false-value":"0"},model:{value:(item.verified),callback:function ($$v) {_vm.$set(item, "verified", $$v)},expression:"item.verified"}})]}},{key:"item.answer_1_state",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":"","true-value":"1","false-value":"0"},model:{value:(item.answer_1_state),callback:function ($$v) {_vm.$set(item, "answer_1_state", $$v)},expression:"item.answer_1_state"}})]}},{key:"item.answer_2_state",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":"","true-value":"1","false-value":"0"},model:{value:(item.answer_2_state),callback:function ($$v) {_vm.$set(item, "answer_2_state", $$v)},expression:"item.answer_2_state"}})]}},{key:"item.answer_3_state",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":"","true-value":"1","false-value":"0"},model:{value:(item.answer_3_state),callback:function ($$v) {_vm.$set(item, "answer_3_state", $$v)},expression:"item.answer_3_state"}})]}},{key:"item.answer_4_state",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":"","true-value":"1","false-value":"0"},model:{value:(item.answer_4_state),callback:function ($$v) {_vm.$set(item, "answer_4_state", $$v)},expression:"item.answer_4_state"}})]}},{key:"item.answer_5_state",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":"","true-value":"1","false-value":"0"},model:{value:(item.answer_5_state),callback:function ($$v) {_vm.$set(item, "answer_5_state", $$v)},expression:"item.answer_5_state"}})]}},{key:"item.answer_6_state",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":"","true-value":"1","false-value":"0"},model:{value:(item.answer_6_state),callback:function ($$v) {_vm.$set(item, "answer_6_state", $$v)},expression:"item.answer_6_state"}})]}},{key:"top",fn:function(){return [_c('v-card',{attrs:{"elevation":"0"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s('Edit ' + _vm.getTestData.test_name))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","outlined":"","dense":"","hide-details":"","single-line":""},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('v-select',{staticClass:"ml-3",staticStyle:{"width":"50px"},attrs:{"outlined":"","color":"primary","items":_vm.questionTypes,"dense":"","hide-details":"","label":"Question Type"},on:{"change":_vm.changeType},model:{value:(_vm.questionType),callback:function ($$v) {_vm.questionType=$$v},expression:"questionType"}}),_c('v-switch',{staticClass:"mx-3 mt-1 mb-1",attrs:{"label":"Review Mode","hide-details":""},on:{"change":_vm.emitMenu},model:{value:(_vm.switchMode),callback:function ($$v) {_vm.switchMode=$$v},expression:"switchMode"}}),_c('EditDBDialog',{attrs:{"triggerEdit":_vm.triggerEdit,"editItem":_vm.editedItem,"editIndex":_vm.editedIndex}})],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }