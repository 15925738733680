<template>
    <v-dialog v-model="dialog" max-width="750px" persistent>
        <!-- Button to handle the Dialog open event -->
        <template v-slot:activator="{ on }">
            <v-btn color="primary buttonText--text" v-on="on" @click="editedIndex = -1">
                <v-icon class="mr-1 ml-0">mdi-plus</v-icon> New Question
            </v-btn>
        </template>
        <!-- Dialog Card -->
        <v-card color="card">
            <!-- Dialog Title -->
            <v-card-title class="cardHeader">
                <span class="headline white--text">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text style="height: 600px;" class="scroll" :class="scrollbarTheme">
                <v-container>
                    <v-row justify="center">
                        <!-- expansion panels to enter question information -->
                        <v-expansion-panels accordion multiple v-model="panel">
                            <!-- form with rules to ensure the added question data is correct -->
                            <v-form ref="form" v-model="form">
                                <v-container style="width: 720px">
                                    <!-- subtopics and question ID (only shown when editing a question) -->
                                    <v-row align="center">
                                        <v-col cols="12" md="5" class="pa-0">
                                            <v-select clearable outlined v-model="editedItem.subtopic" :items="allHF" label="Subtopic" :rules="subtopicRules" required class="ml-3 mr-1"></v-select>
                                        </v-col>
                                        <v-col cols="12" md="7" class="pa-0">
                                            <v-select outlined v-model="editedItem.fullType" :items="questionTypes" label="Question Type" :rules="typeRules" required class="mr-3 ml-2" :disabled="editedIndex != -1 ? true : false"></v-select>
                                        </v-col>
                                    </v-row>
                                    <!-- verify button -->
                                    <v-row align="center" v-if="editedIndex != -1">
                                        <v-col cols="12" md="5" class="pa-0">
                                            <v-alert outlined text :color="editedItem.verified == 1 ? 'success' : 'error' " class="ml-3 mr-1">
                                                <v-card-title>
                                                    <v-btn icon :color="editedItem.verified == 1 ? 'success' : 'error'" @click="verify()" class="mr-3">
                                                        <v-icon large>{{ editedItem.verified == 1 ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
                                                    </v-btn>
                                                    <div>{{ editedItem.verified == 1 ? 'Verified' : 'Not Verified' }}</div>
                                                </v-card-title>
                                            </v-alert>
                                        </v-col>
                                        <v-col cols="12" md="7" class="pa-0">
                                            <v-text-field label="Question ID" :value="editedItem.question_id" class="ml-2 mr-3" outlined disabled></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <!-- first expansion panel for question header -->
                                    <v-expansion-panel class="expansionPanel">
                                        <v-expansion-panel-header>Question Header</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row>
                                                <!-- Situation -->
                                                <v-col cols="12" md="12" ref="areaWidth">
                                                    <v-textarea v-model="editedItem.situation" label="Situation" outlined auto-grow rows="2" counter="500" :rules="situationRules"></v-textarea>
                                                </v-col>
                                                <!-- Question -->
                                                <v-col cols="12" md="12">
                                                    <v-textarea v-model="editedItem.question" label="Question" outlined auto-grow rows="2" counter="200" :rules="questionRules"></v-textarea>
                                                </v-col>
                                                <!-- Intro -->
                                                <v-col cols="12" md="12" v-if="editedItem.intro || intro">
                                                    <v-btn @click="intro = false, editedItem.intro = ''" small icon fab style="position: absolute; margin-left: 628px; margin-top: -20px; z-index: 2"><v-icon small style="border-style: solid; border-radius: 14px; border-width: 1px">mdi-close</v-icon></v-btn>
                                                    <v-textarea v-model="editedItem.intro" label="Intro" outlined auto-grow rows="2" counter="150" :rules="introRules"></v-textarea>
                                                </v-col>
                                                <!-- Button to add an Intro -->
                                                <v-col cols="12" md="12" v-if="!editedItem.intro && !intro">
                                                    <v-btn @click="intro = true" class="mb-3">
                                                        <v-icon class="mr-2">mdi-plus</v-icon> Add Intro
                                                    </v-btn>
                                                </v-col>
                                                <!-- hint -->
                                                <v-col cols="12" md="12" v-if="editedItem.hint || hint">
                                                    <v-btn @click="hint = false, editedItem.hint = ''" small icon fab style="position: absolute; margin-left: 628px; margin-top: -20px; z-index: 2"><v-icon small style="border-style: solid; border-radius: 14px; border-width: 1px">mdi-close</v-icon></v-btn>
                                                    <v-textarea outlined label="Hint" v-model="editedItem.hint" auto-grow rows="2" counter="1000" :rules="hintRules"></v-textarea>
                                                </v-col>
                                                <!-- Button to add a Hint -->
                                                <v-col cols="12" md="12" v-if="!editedItem.hint && !hint">
                                                    <v-btn @click="hint = true" style="margin-top: -32px">
                                                        <v-icon class="mr-2">mdi-plus</v-icon> Add Hint
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <!-- second expansion panel to edit the question choices -->
                                    <v-expansion-panel class="expansionPanel" v-if="editedItem.fullType == 'Multiple Choice' && editedItem.answers != undefined">
                                        <v-expansion-panel-header>Answer Choices</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-row v-for="(answer,i) in editedItem.answers" :key="answer.id" cols="12" class="mx-1 mb-6" align="center" justify="center">
                                                        <!-- answer choices -->
                                                        <v-row align="center" width="600px" class="mx-1 my-2">
                                                            <v-checkbox class="ma-1" v-model="answer.checkbox" @change="changeVisuals(i)" :true-value="1" :false-value="0"></v-checkbox>                                                       
                                                            <v-textarea v-model="answer.choice" :label="'Answer ' + parseInt(i+1)" outlined auto-grow rows="2" counter="200" :rules="answerRulesRequiered" :error-messages="numberRight()"></v-textarea>
                                                            <v-badge overlap :icon="answer.badgeIcon" :color="answer.badgeColor" :style="{position: 'absolute', right: '30px', top: 82 + i *128 +'px'}"></v-badge>
                                                        </v-row>
                                                    </v-row>
                                                </v-col>                            
                                                <!-- buttons to add and remove answer choices -->
                                                <v-col cols="12" v-if="dialog">
                                                    <v-btn v-if="buttonVisible && editedItem.answers.length < 6" @click="addAnswer"><v-icon class="mr-2">mdi-plus</v-icon> add answer</v-btn>
                                                    <v-btn @click="removeAnswer" class="ml-5" v-if="editedItem.answers.length > 2"><v-icon class="mr-2">mdi-minus</v-icon> remove answer</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <!-- second expansion panel to edit the items -->
                                    <v-expansion-panel class="expansionPanel" v-if="editedItem.fullType == 'Sequence' && editedItem.answers != undefined">
                                        <v-expansion-panel-header>Sorting Items</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row>
                                                <v-col cols="12" class="pb-0">
                                                    <v-row v-for="(answer,i) in editedItem.answers" :key="answer.id" cols="12" class="mx-1 mb-6" align="center" justify="center">
                                                        <!-- sorting items -->
                                                        <v-row align="center" width="600px" class="mx-1 my-2">
                                                            <v-textarea v-model="answer.choice" :label="'Item ' + parseInt(i+1)" outlined auto-grow rows="2" counter="200" :rules="answerRulesRequiered"></v-textarea>
                                                        </v-row>
                                                    </v-row>
                                                </v-col>
                                                <!-- buttons to add and remove answer choices -->
                                                <v-col cols="12" v-if="dialog">
                                                    <v-btn v-if="buttonVisible && editedItem.answers.length < 6" @click="addAnswer"><v-icon class="mr-2">mdi-plus</v-icon> add answer</v-btn>
                                                    <v-btn @click="removeAnswer" class="ml-5" v-if="editedItem.answers.length > 2"><v-icon class="mr-2">mdi-minus</v-icon> remove answer</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-container>
                            </v-form>
                        </v-expansion-panels>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <!-- card actions (save or cancel) -->
            <v-card-actions>
                <EditDBHelp :editedIndex="editedIndex" :editedItem="editedItem"></EditDBHelp>
                <v-spacer></v-spacer>
                <v-btn color="primary" outlined @click="close">Cancel</v-btn>
                <v-btn color="primary buttonText--text" @click="save" :disabled="!form">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import EditDBHelp from './EditDBHelp';
    export default {
        name: 'EditDBDialog',
        props: ['triggerEdit', 'editItem', 'editIndex'],
        components: {
            EditDBHelp,
        },
        data: () => {
            return {
                dialog: false, // navigation variable to handle the edit dialog state

                editedItem: {}, // data for edited question
                editedIndex: -1, // type of editing dialog
                allHF: [], // array with all subtopics
                questionTypes: [ // select which question type is shown in table 
                    'Multiple Choice',
                    'Sequence',
                ],

                panel: [0,1], // which panels are open by default

                form: false, // is true when every input folows the rules (save button get's enabled)
                subtopicRules: [
                    v => !!v || 'Subtopic is required',
                ],
                typeRules: [
                    v => !!v || 'Question Type is required',
                ],
                hintRules: [
                    v => (v && v.length <= 1000) || 'Hint must be less than 1000 characters',
                ],
                situationRules: [
                    v => !!v || 'Situation is required',
                    v => (v && v.length <= 500) || 'Situation must be less than 500 characters',
                ],
                questionRules: [
                    v => !!v || 'Question is required',
                    v => (v && v.length <= 200) || 'Question must be less than 200 characters',
                ],
                introRules: [
                    v => (v && v.length <= 150) || 'Intro must be less than 150 characters',
                    ],
                answerRulesRequiered: [
                    v => !!v || 'This Answer is requiered',
                    v => (v && v.length <= 200) || 'Answer must be less than 200 characters',
                ],

                hint: false, // used to add and remove the hint text
                intro: false, // used to add and remove the intro text
                buttonVisible: true, // button to add new answer choices
            }
        },
        mounted() {
            this.editedIndex = this.editIndex
        },
        watch: {
            editItem() {
                this.editedItem = this.editItem
                // console.log(this.editItem)
            },
            editIndex() {
                this.editedIndex = this.editIndex
            },
            // store all subtopics in global variable
            getHF() {
                this.allHF = this.getHF
            },
            // dialog handling
            dialog (val) {
                // fill defaults when creating a new entry
                if(this.editedIndex == -1 && val) {
                    this.editedItem = {
                        subtopic: '',
                        situation: '',
                        question: '',
                        intro: null,
                        hint: null,
                        typ: 'mul',
                        fullType: 'Multiple Choice',
                        answers: [
                            {badgeColor: 'red', badgeIcon: 'mdi-close', checkbox: 0, choice: '', id: 0, },
                            {badgeColor: 'red', badgeIcon: 'mdi-close', checkbox: 0, choice: '', id: 1, },
                        ]
                    }
                    
                }
            },
            triggerEdit() {
                if(this.triggerEdit) {
                    this.dialog = true
                    this.editedIndex = this.editIndex
                }
            },
            editedItem() {
                // fill defaults when switching question type
                if(this.editedIndex == -1) {
                    if(this.editedItem.fullType == 'Multiple Choice') {
                        this.editedItem.answers = [
                            {badgeColor: 'red', badgeIcon: 'mdi-close', checkbox: 0, choice: '', id: 0 },
                            {badgeColor: 'red', badgeIcon: 'mdi-close', checkbox: 0, choice: '', id: 1 },
                        ]
                    } else {
                        this.editedItem.answers = [
                            {choice: '', id: 0 },
                            {choice: '', id: 1 },
                        ]
                    }
                }
            },
        },
        computed: {
            // function to to determine which dialog title should be shown
            formTitle () {
                return this.editedIndex === -1 ? 'New Question' : 'Edit Question'
            },
            // get all subtopics from store
            getHF() {
                // console.log(this.$store.getters.allHF)
                return this.$store.getters.allHF
            },
            // User Object from the store
            UserObject() {
                return this.$store.getters.userData
            },
            // get the test data from the store
            getTestData() {
                // console.log(this.$store.getters.TestData)
                return this.$store.getters.TestData
            },
            // custom scrollbar
            scrollbarTheme() {
                return this.$vuetify.theme.dark ? 'dark' : 'light';
            },
        },
        methods: {
            // return error text when not enough answers are right
            numberRight() {
                return (this.editedItem.answers.filter(item => item.checkbox == 1).length > 0 ) ? '' : 'Atleast one Answer has to be correct!'
            },
            // method to verify a question
            verify() {
                if(this.editedItem.verified == 0) {
                    this.editedItem.verified = 1
                } else {
                    this.editedItem.verified = 0
                }
            },
            // change badges of answer choices depending on the checkbox state of said answer choice
            changeVisuals: function(element) {
                // false checkbox
                if(this.editedItem.answers[element].checkbox == 0) {
                    this.editedItem.answers[element].badgeIcon = 'mdi-close'
                    this.editedItem.answers[element].badgeColor = 'red'
                } else { // true checkbox
                    this.editedItem.answers[element].badgeIcon = 'mdi-check'
                    this.editedItem.answers[element].badgeColor = 'green'
                }
            },
            // close the dialog without saving the changes
            close () {
                this.dialog = false
                this.intro = false
                this.hint = false
                this.buttonVisible = true // "add answer" button reset after closing dialog
                this.$emit('closed', this.editedItem, this.editIndex) // event to parent component
                this.editedItem.answers = [] // reset answers arraray after closing dialog
            },
            // save changes and close the dialog
            save () {
                if (this.editedIndex > -1) {
                // SAVE EDITED QUESTION
                    this.fillQuestionData(this.editedItem.typ)
                    // post to save to DB
                    this.$http.post('index.php',    {   task:           'edit_question',
                                                        questionObject: this.editedItem,
                                                        testName:       this.getTestData.test_name_db
                                                    })
                        .then(response => {
                            // console.log('edit response:', response.body)
                            this.$store.dispatch('getSnackbar', {status: true, timeout: 3000, color: '', btnColor: 'error', text: 'edited item: ' + response.body.edit_item })
                            this.$store.dispatch('getQuestions', this.getTestData.test_name_db)
                        });
                } else {
                // SAVE NEW QUESTION
                    if(this.editedItem.fullType == 'Multiple Choice') {
                        this.editedItem.typ = 'mul'
                    } else {
                        this.editedItem.typ = 'sor'
                    }
                    this.fillQuestionData(this.editedItem.typ)
                    this.editedItem.verified = 0
                    // when and by who it was added
                    this.editedItem.added_date = new Date().today() + " " + new Date().timeNow()
                    this.editedItem.added_by = this.UserObject.usr_name
                    // calculation of question id
                    this.editedItem.question_id = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) );
                    // post to save to DB
                    this.$http.post('index.php',    {   task:           'insert_question',
                                                        questionObject: this.editedItem,
                                                        testName:       this.getTestData.test_name_db,
                                                    })
                        .then(response => {
                            // console.log('add response:', response.body)
                            this.$store.dispatch('getSnackbar', {status: true, timeout: 3000, color: '', btnColor: 'error', text: 'added item: ' + response.body.add_item })
                            this.$store.dispatch('getQuestions', this.getTestData.test_name_db)
                        });
                }
                this.close()
            },
            // fills question data when saving
            fillQuestionData: function(typ) {
                if(typ == 'mul') {
                    // set new data
                    this.editedItem.number_right = 0
                    this.editedItem.answers.forEach( element => {
                        this.editedItem['answer_' + parseInt(element.id+1) + '_state'] = element.checkbox.toString()
                        this.editedItem['answer_' + parseInt(element.id+1)] = element.choice
                        this.editedItem.number_right += element.checkbox
                    });
                    // clear empty data
                    for(let i = this.editedItem.answers.length; i < 6; i++) {
                        this.editedItem['answer_' + parseInt(i+1) + '_state'] = null
                        this.editedItem['answer_' + parseInt(i+1)] = null
                    }
                } else {
                    this.editedItem.number_right = null
                    this.editedItem.answers.forEach( element => {
                        this.editedItem['item_' + parseInt(element.id+1)] = element.choice
                    });
                    // clear empty data
                    for(let i = this.editedItem.answers.length; i < 6; i++) {
                        this.editedItem['answer_' + parseInt(i+1)] = null
                    }
                }
                // For todays date;
                Date.prototype.today = function () { 
                    return this.getFullYear() +"-"+(((this.getMonth()+1) < 10)?"0":"") + (this.getMonth()+1) +"-"+ ((this.getDate() < 10)?"0":"") + this.getDate();
                }
                // For the time now
                Date.prototype.timeNow = function () {
                    return ((this.getHours() < 10)?"0":"") + this.getHours() +":"+ ((this.getMinutes() < 10)?"0":"") + this.getMinutes() +":"+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds();
                }
                // when and by who it was edited
                this.editedItem.last_edited_date = new Date().today() + " " + new Date().timeNow()
                this.editedItem.last_edited_by = this.UserObject.usr_name
            },
            // add new answer choices
            addAnswer() {
                if(this.editedItem.typ == 'mul' || this.editedItem.fullType == 'Multiple Choice') {
                    this.editedItem.answers.push({badgeColor: 'red', badgeIcon: 'mdi-close', checkbox: 0, choice: '', id: this.editedItem.answers.length })
                } else {
                    this.editedItem.answers.push({choice: '', id: this.editedItem.answers.length })
                }
                
            },
            // remove answer choices
            removeAnswer() {
                this.buttonVisible = true // when an answer is removed the "add answer" button has to be shown
                this.editedItem.answers.pop()
            },
        },
    }
</script>

<style scoped>
</style>