<template>
    <v-card tile :min-height="windowHeight - 76" elevation="0" color="rgba(0,0,0,0)">
        <v-row justify="center">
            <!-- layout of the statistics component -->
            <v-card color="rgba(0,0,0,0)" elevation="0" class="mx-5" width="1525">
                <!-- top Navigation -->
                <v-breadcrumbs :items="breadCrumbs" class="ma-0">
                    <template v-slot:divider>
                        <v-icon>mdi-chevron-right</v-icon>
                    </template>
                </v-breadcrumbs>

                <v-divider></v-divider>
                <v-card-text :style="{height: windowHeight - 122 + 'px'}" class="scroll pa-0" :class="scrollbarTheme">
                    <v-row class="ma-1">
                        <v-col cols="12" class="pa-1">
                            <!-- participate in exam -->
                            <v-card color="card">
                                <v-card-title>{{ $t('Exam.participate-exam') }}</v-card-title>
                                <v-divider></v-divider>
                                <v-card-text>
                                    <v-row align="center">
                                        <!-- enter access key -->
                                        <v-col cols="6">
                                            <v-text-field outlined :label="$t('Exam.enter-key')" :hint="$t('Exam.key-looks')" @input="checkKey()" :error-messages="keyCheck()" v-model="key" :success-messages="keyCheck2()"></v-text-field>
                                        </v-col>
                                        <!-- start exam button -->
                                        <v-col>
                                            <v-btn color="primary" style="margin-top: -12px" :disabled="disabled" @click="startExam()">{{ $t('Exam.start-exam') }}</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="ma-1">
                        <v-col cols="12" class="pa-1">
                            <!-- previously solved exams -->
                            <v-card color="card">
                                <v-toolbar flat color="card">
                                    <div class="ml-5 mr-9 font-weight-bold">%</div>
                                    <div class="font-weight-bold ml-5">{{ $t('Exam.exam-results') }}</div>
                                    <v-spacer></v-spacer>
                                    <div class="mr-3 font-weight-bold">{{ $t('Exam.date') }}</div>
                                </v-toolbar>
                                <v-divider></v-divider>
                                <v-card-text style="height: 320px;" class="scroll pa-0" :class="scrollbarTheme">
                                    <!-- list with all previously solved exams -->
                                    <v-list two-line color="card">
                                        <v-list-item-group>
                                            <template v-for="result in results">
                                                <v-list-item :key="result.result_id">
                                                    <template>
                                                        <v-list-item-icon>
                                                            <v-progress-circular :value="result.percentage" color="primary" class="ma-2" size="40" :rotate="-90">{{ result.percentage }}</v-progress-circular>
                                                        </v-list-item-icon>
                                                        <v-list-item-content style="margin-top: -15px">
                                                            <v-list-item-title>{{ result.test_name }}</v-list-item-title>
                                                            <v-list-item-subtitle class="text--primary">{{ result.points + '/' + 100 + ' ' + $t('Exam.questions-answered') }}</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        <v-list-item-action style="margin-top: -5px" class="mr-3">
                                                            <v-list-item-action-text>{{ result.finish_time }}</v-list-item-action-text>
                                                        </v-list-item-action>
                                                    </template>
                                                </v-list-item>
                                            </template>
                                        </v-list-item-group>
                                    </v-list>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-row>
    </v-card>
</template>

<script>

  export default {
    name: 'manageExams',

    components: {

    },

    data: function() {
        return {

            testName: '',

            key: '',
            message: '',
            disabled: true,

            results: [],

            examKey: '',

            breadCrumbs: [
                {text: this.$i18n.t('AppNavigationMenu.dashboard'), disabled: false, to: '/dashboard', exact: true},
                {text: this.$i18n.t('Exam.exams'), disabled: false, to: '(/dashboard/exams)', exact: true},
                {text: this.$i18n.t('Exam.participate'), disabled: true, href: 'breadcrumbs_participate'},
            ],

            windowWidth: 0, // width of the window
            windowHeight: 0, // height of the window
        }
    },

    mounted() {

        // event listener for window size
        this.$nextTick(function() {
            window.addEventListener('resize', this.getWindowWidth);
            window.addEventListener('resize', this.getWindowHeight);

            // Init
            this.getWindowWidth()
            this.getWindowHeight()
        })
    },

    created() {
        this.initialize()
    },

    beforeDestroy() {

        // remove eventlisteners when the component is destroyed
        window.removeEventListener('resize', this.getWindowWidth);
        window.removeEventListener('resize', this.getWindowHeight);
    },

    watch: {

    },

    computed: {

        scrollbarTheme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light';
        },

        // User Object from the store
        UserObject() {
            return this.$store.getters.userData
        },
        // sessionID from the store
        SessionID() {
            // console.log('SessionIDComponent', this.$store.getters.sessionID)
            return this.$store.getters.sessionID
        },

    },

    methods: {

        startExam() {
            // check if valid_from, valid_to still ok!
             this.$http.post('index.php',   {   task:           'get_my_exam',
                                                test_name_db:   this.testName,
                                                key:            this.key,
                                                userObject:     this.UserObject
                                            })
                    .then(response => {
                        // console.log(response.body)
                        this.$store.dispatch('getAssists', true)
                        this.$store.dispatch('getMode', 'exam_mode')
                        this.$store.dispatch('getExamKey', this.examKey)
                        this.$store.dispatch('getCurrentTest', response.body[0])
                        this.$router.push('/test/Question-1') // set the new route to start the test
                        // this.$store.dispatch('getCurrentMenu', 'Questions')
                        this.$store.dispatch('getTimeLimit', response.body[0].duration)
                        this.$store.dispatch('getAllQuestions', [response.body[0].test_question_nr, 'noHF', this.testName])
                    })
            
        },

        checkKey() {
            this.$http.post('index.php',    {   'task'      : 'get_table',
                                                'table'     : 'colcons_exam_keys',
                                                'filter'    : [ {'op': 'eq', 'field': 'key_phrase', 'value': this.key} ],
                                                'usr_id'    : this.UserObject.usr_id,
                                                'sessionid' : this.SessionID,
                                            })
                    .then(response => {
                        // console.log(response.body)
                        if (response.body.sessionerror) {
                            this.$store.dispatch('getSnackbar', { status: true, timeout: 3000, color: 'error', btnColor: 'buttonText', text: this.$i18n.t('UserSettings.sessID-outdated') });
                            this.disabled = true;
                        } else {
                            if(response.body == 'null') {
                                // console.log('wrong key')
                                this.message = this.$i18n.t('Exam.wrong-key');
                                this.disabled = true;
                            } else if(response.body[0].key_status == 'used') {
                                this.message = this.$i18n.t('Exam.key-used')
                                // console.log('key already used')
                                this.disabled = true
                            } else {
                                // console.log(response.body)
                                if(new Date(response.body[0].valid_to) > new Date() && new Date(response.body[0].valid_from) > new Date()) {
                                    this.message = this.$i18n.t('Exam.exam-starts') + response.body[0].valid_from + this.$i18n.t('Exam.come-later')
                                    this.disabled = true
                                } else if(new Date(response.body[0].valid_to) < new Date()) {
                                    this.message = this.$i18n.t('Exam.old-exam')
                                    this.disabled = true
                                } else {
                                    this.message = this.$i18n.t('Exam.start-now')
                                    this.disabled = false
                                    this.testName = response.body[0].test_name
                                    this.examKey = response.body[0].key_id
                                }
                            }
                        }
                    });
        },

        keyCheck() {
            if(this.message != 'Exam can be started. Good Luck!') {
                return this.message
            } else {
                return ''
            }
        }, 

        keyCheck2() {
            if(this.message == 'Exam can be started. Good Luck!') {
                return this.message
            } else {
                return ''
            }
        }, 

        // get the width of the window
        getWindowWidth() {
            this.windowWidth = document.documentElement.clientWidth;
        },

        // get the height of the window
        getWindowHeight() {
            this.windowHeight = document.documentElement.clientHeight;
        },

        // get exam results from DB
        initialize() {
            this.$http.post('index.php',    {   'task'      : 'get_table',
                                                'table'     : 'colcons_exam_keys',
                                                'filter'    : [ {'op': 'eq', 'field': 'released', 'value': 'released'}, {'op': 'neq', 'field': 'result_id', 'value': 'null'}, {'op': 'eq', 'field': 'usr_id', 'value': this.UserObject.usr_id} ],
                                                'usr_id'    : this.UserObject.usr_id,
                                                'sessionid' : this.SessionID,
                                            })
                .then(response => {
                    // console.log(response.body);
                    if (response.body.sessionerror) {
                        this.$store.dispatch('getSnackbar', { status: true, timeout: 3000, color: 'error', btnColor: 'buttonText', text: this.$i18n.t('UserSettings.sessID-outdated') });
                    } else {
                        let resultIds = [];
                        response.body.forEach(element => {
                            resultIds.push(element.result_id)
                        });
                        this.$http.post('index.php',    {   'task'      : 'get_table',
                                                            'table'     : 'colcons_test_results',
                                                            'filter'    : [ {'op': 'like', 'field': 'result_id', 'value': resultIds.join(',')} ],
                                                            'usr_id'    : this.UserObject.usr_id,
                                                            'sessionid' : this.SessionID,
                                                        })
                            .then(response2 => {
                                // console.log(response2.body);
                                if (response2.body.sessionerror) {
                                    this.$store.dispatch('getSnackbar', { status: true, timeout: 3000, color: 'error', btnColor: 'buttonText', text: this.$i18n.t('UserSettings.sessID-outdated') });
                                } else {
                                    if (response2.body == 'null') return;
                                    let merged = [];
                                    for (let i = 0; i < response.body.length; i++) {
                                        merged.push({
                                            ...response.body[i],
                                            ...(response2.body.find((itmInner) => itmInner.result_id === response.body[i].result_id))
                                        });
                                    }
                                    // console.log(merged);
                                    merged.forEach(element => {
                                        if(element.point_system == 'point') {
                                            element.percentage = Math.round((element.part_points / element.possible_points) *100)
                                            element.points = Math.round((element.part_points / element.possible_points) *100)
                                        } else if(element.point_system == 'question') {
                                            element.percentage = Math.round((element.points / element.possible_points) *100)
                                            element.points = Math.round((element.points / element.possible_points) *100)
                                        } else if(element.point_system == 'macedonia') {
                                            element.percentage = Math.round((element.part_points_macedonia / element.possible_points) *100)
                                            element.points = Math.round((element.part_points_macedonia / element.possible_points) *100)
                                        }
                                        
                                    });
                                    this.results = merged.sort((x, y) => +new Date(y.finish_time) - +new Date(x.finish_time))
                                }
                            });
                    }
                });
        },

    },

  }
</script>

<style scoped>
  .scroll {
    overflow-y: auto
  }
</style>