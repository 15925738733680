<template>
    <v-card tile :min-height="windowHeight - 76" elevation="0" color="rgba(0,0,0,0)">
        <v-row align="center" justify="center">
            <v-card color="rgba(0,0,0,0)" elevation="0" class="mx-5" width="1525">
                <!-- top Navigation -->
                <v-breadcrumbs :items="breadCrumbs" class="ma-0">
                    <template v-slot:divider>
                        <v-icon>mdi-chevron-right</v-icon>
                    </template>
                </v-breadcrumbs>

                <v-divider></v-divider>

                <v-card-text :style="{height: windowHeight - 122 + 'px'}" class="scroll py-0 px-0" :class="scrollbarTheme">
                    <v-card color="card" elevation="1" class="mt-2 mx-2">
                        <v-toolbar flat color="card">
                            <v-btn icon @click="backToStore">
                                <v-icon>mdi-arrow-left</v-icon>
                            </v-btn>
                            <v-divider class="ml-1 mr-4" inset vertical></v-divider>
                            <v-toolbar-title>{{ currentStoreItem.test_name }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <!-- add to cart -->
                            <v-btn v-if="currentStoreItem.test_price != undefined" color="primary buttonText--text" outlined rounded @click="toCart(currentStoreItem)">
                                {{ $t('testStore.to-cart') }}
                                <v-icon class="ml-1">mdi-cart</v-icon>
                            </v-btn>
                            <!-- buy -->
                            <v-btn v-if="parseInt(currentStoreItem.test_price) != 0 && currentStoreItem.test_price != undefined" rounded color="rgb(249, 195, 56)" class="mx-3 buttonText--text" elevation="0" @click="openBuy">{{ $t('testStore.buy') + ' ' + currentStoreItem.test_price + ' €' }}</v-btn>
                            <v-btn v-if="parseInt(currentStoreItem.test_price) == 0 && currentStoreItem.test_price != undefined" rounded color="success" class="mx-3 buttonText--text" elevation="0" @click="openBuy">{{ $t('testStore.get-free') }}</v-btn>
                            <!-- paypal -->
                            <div ref="paypal" style="margin-top: 5px"></div>
                            <!-- like -->
                            <v-btn color="error" icon @click="wishlistItem(currentStoreItem)" class="ml-10">
                                <v-icon v-if="Wishlist.length > 0 && Wishlist != 'null'">{{ Wishlist.find(element => element.test_id == currentStoreItem.test_id) != undefined  ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
                                <v-icon v-else> mdi-heart-outline</v-icon>
                            </v-btn>
                            <!-- share -->
                            <v-btn icon v-if="currentStoreItem.test_price != undefined" @click="share()">
                                <v-icon>mdi-share-variant</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-divider></v-divider>

                        <v-card-text class="scroll">
                            <v-row>
                                <v-col cols="5">
                                    <v-img :aspect-ratio="4/3" contain :src="currentStoreItem.test_img" :style="{'border-style': 'solid', 'border-width': 1 + 'px', 'border-radius': 5 + 'px', 'border-color': theme.isDark ? '#3B3B3B' : '#D2D2D2'}"></v-img>
                                </v-col>
                                <v-col cols="7">
                                    <v-card-title>{{ currentStoreItem.test_name }}</v-card-title>
                                    <v-card-text>{{ currentStoreItem.test_info }}</v-card-text>
                                </v-col>
                            </v-row>
                            <v-divider class="mt-3"></v-divider>
                            <!-- reviews -->
                            <v-card-title>
                                <div>Reviews</div>
                                <v-subheader style="margin-left: -10px">({{ reviews.length }})</v-subheader>
                                <v-rating :value="reviewStars" class=" ml-5" readonly half-increments color="yellow darken-3" background-color="grey lighten-1" half-icon="mdi-star-half-full"></v-rating>
                                <v-subheader>({{ reviewStars }})</v-subheader>
                                <v-spacer></v-spacer>
                                <!-- open review dialog -->
                                <v-btn color="primary" text @click="dialog = true" v-if="SuccessfullLogin && myTests.find(element => element.test_id == currentStoreItem.test_id) != undefined && reviews.find(element => element.usr_id == UserObject.usr_id) == undefined">{{ $t('testStore.write-review') }}</v-btn>
                            </v-card-title>
                            <v-card-text>
                                <!-- table with all reviews for the current test -->
                                <v-data-table hide-default-header class="menu" :headers="headers" :items="textReviews" :locale="$i18n.locale" :footer-props="{'items-per-page-text': $t('testStore.per-page')}" :no-data-text="$t('testStore.no-reviews')">
                                    <template v-slot:item.review_stars="{ item }">
                                        <v-rating :value="parseInt(item.review_stars)" style="margin-left: -5px; margin-right: -60px" small readonly color="yellow darken-3" background-color="grey lighten-1"></v-rating>
                                    </template>
                                    <template v-slot:item.review_text="{ item }">
                                        <div class="body-2 subtit--text mt-5">{{ item.usr_name }}</div>
                                        <div class="subtitle-1 mb-5">{{ item.review_text }}</div>
                                    </template>
                                    <template v-slot:item.review_time="{ item }">
                                        <div class="body-2 subtit--text">{{ item.review_time.substring(0,10) }}</div>
                                    </template>
                                    <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} {{ $t('Exercises.of') }} {{ items.itemsLength }} </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card-text>
                    </v-card>
                </v-card-text>
            </v-card>
        </v-row>
        <v-dialog width="650" v-model="dialog">
            <v-card>
                <v-card-title class="headline cardHeader white--text">{{ $t('testStore.write-review') }}</v-card-title>
                <v-card-text>
                    <v-rating class="my-3" v-model="rating" color="yellow darken-3" background-color="grey lighten-1" hover></v-rating>
                    <v-textarea outlined :label="$t('testStore.review-text')" v-model="ratingText"></v-textarea>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" outlined @click="dialog = false">{{ $t('testStore.cancel') }}</v-btn>
                    <v-btn color="primary" class="buttonText--text" :disabled="rating > 0 ? false : true" @click="sendReview()">{{ $t('testStore.post-review') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
  </v-card>
</template>

<script>

  export default {
    name: 'testStoreDetails',

    data() {
        return {
            dialog: false,
            headers: [
                { text: 'rating', value: 'review_stars', sortable: false, align: 'left', width: '60px'},
                { text: 'text', value: 'review_text', sortable: false, align: 'left'},
                { text: 'date', value: 'review_time', sortable: false, align: 'end'},
            ],
            rating: 0, // rating of the test
            ratingText: '',
            reviews: [],
            reviewStars: 0,
            textReviews: [],

            currentStoreItem: {},

            errorMsg: undefined,
            resp: undefined,
            orderMsg: undefined,

            script: undefined,

            breadCrumbs: [
                {text: this.$i18n.t('AppNavigationMenu.dashboard'), disabled: false, to: '/dashboard', exact: true},
                {text: this.$i18n.t('AppNavigationMenu.test-store'), disabled: false, to: '/dashboard/store', exact: true},
                {text: '', disabled: true, to: '/dashboard/store', exact: true},
            ],

            windowWidth: 0, // width of the window
            windowHeight: 0, // height of the window
        }
    },

    created() {
        this.$http.post('index.php',    {   'task'      : 'get_table',
                                            'table'     : 'colcons_tests',
                                            'filter'    : [{ op: 'eq', field: 'test_id', value: this.$route.query.test_id }],
                                            'usr_id'    : this.UserObject.usr_id,
                                            'sessionid' : this.SessionID,
                                        })
                .then(response => {
                    // console.log(response.body);
                    if (response.body.sessionerror) {
                        this.$store.dispatch('getSnackbar', { status: true, timeout: 3000, color: 'error', btnColor: 'buttonText', text: this.$i18n.t('UserSettings.sessID-outdated') });
                    } else {
                        this.currentStoreItem = response.body[0];
                        // initialize breadcrumbs
                        this.breadCrumbs[2].text = this.currentStoreItem.test_name;
                    }
                });
    },

    mounted() {
        // event listener for window size
        this.$nextTick(function() {
            window.addEventListener('resize', this.getWindowWidth);
            window.addEventListener('resize', this.getWindowHeight);

            // Init
            this.getWindowWidth()
            this.getWindowHeight()
        })
        // new paypal smart buttons
        this.script = document.createElement("script");
        this.script.src = "https://www.paypal.com/sdk/js?client-id=ARyXu5BHZ00mrHC5UQP_9D3nZHVi0UpjBhiIm3BfAflXFNSrfSbhYTvHT64vLb3x4tfRauroJ1z5n96h&currency=EUR&components=buttons,funding-eligibility";
        this.script.addEventListener("load", this.setLoaded);
        // get wishlist from DB
        if(Object.keys(this.$store.getters.userData).length != 0) {
            this.$store.dispatch('getWishlist', this.UserObject)
        }
    },

    beforeDestroy() {
      // remove eventlisteners when the component is destroyed
      window.removeEventListener('resize', this.getWindowWidth);
      window.removeEventListener('resize', this.getWindowHeight);
    },

    watch: {
        UserObject() {
            if(Object.keys(this.$store.getters.userData).length != 0) {
                // get wishlist from DB
                this.$store.dispatch('getWishlist', this.UserObject)
                // mount paypal button
                if(parseInt(this.currentStoreItem.test_price) > 0 && this.UserObject.usr_address != null)
                    document.body.appendChild(this.script);
            }
        },
        currentStoreItem() {
            // get reviews on initialize
            this.getReviews()
            // mount paypal button
            if(Object.keys(this.$store.getters.userData).length != 0) {
                if(parseInt(this.currentStoreItem.test_price) > 0 && this.UserObject.usr_address != null) {
                    document.body.appendChild(this.script);
                }
            }
        },
    },

    computed: {
        // gets the theme from the vuetify plugin (dark/light)
        theme() {
            return this.$vuetify.theme.dark
        },
        // custom toolbar theme
        scrollbarTheme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light';
        },
        // gets confirmation from the store if the login was successfull
        SuccessfullLogin() {
            // console.log('User logged in?', this.$store.getters.successfullLogin)
            return this.$store.getters.successfullLogin
        },
        // sessionID from the store
        SessionID() {
            // console.log('SessionIDComponent', this.$store.getters.sessionID)
            return this.$store.getters.sessionID
        },
        // User Object from the store
        UserObject() {
            return this.$store.getters.userData
        },
        // wishlist array from store
        Wishlist() {
            // console.log(this.$store.getters.wishlist)
            return this.$store.getters.wishlist
        },
        // gets own Tests from the store
        myTests() {
            return this.$store.getters.MyTests
        },
    },

    methods: {
        // get reviews from DB
        getReviews() {
            this.$http.post('index.php',    {   task:           'get_reviews',
                                                testObject:     this.currentStoreItem,
                                            })
                    .then(response => {
                        if(response.body != 'null') {
                            this.reviews = response.body
                            var reviewStars = 0
                            response.body.forEach(element => {
                                reviewStars += parseInt(element.review_stars)
                            });
                            this.reviewStars = reviewStars / response.body.length
                            // console.log(response.body)
                            this.textReviews = response.body.filter(review => review.review_text != null)
                        }
                    })
        },
        // write a review for the Test
        sendReview() {
            var reviewItem = {}
            reviewItem.review_id = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) );
            reviewItem.review_stars = this.rating
            reviewItem.review_text = this.ratingText
            this.$http.post('index.php',    {   task:           'send_review',
                                                userObject:     this.UserObject,
                                                testObject:     this.currentStoreItem,
                                                reviewObject:   reviewItem,
                                            })
                    .then(response => {
                        if(response.body == 'success') {
                            this.dialog = false
                            this.rating = 0
                            this.ratingText = ''
                            this.$store.dispatch('getSnackbar', {status: true, timeout: 4000, color: 'success', btnColor: 'buttonText', text: this.$i18n.t('testStore.review-published') })
                            this.getReviews()
                        } else {
                            this.$store.dispatch('getSnackbar', {status: true, timeout: 4000, color: 'error', btnColor: 'buttonText', text: this.$i18n.t('UserSettings.sessID-outdated') })
                        }
                    })
        },
        // share store item method
        share() {
            navigator.clipboard.writeText(window.location.href).then(() => {
                this.$store.dispatch('getSnackbar', {status: true, timeout: 3000, color: '', btnColor: '', text: this.$i18n.t('testStore.link-copy') })
            }, () => {
                this.$store.dispatch('getSnackbar', {status: true, timeout: 5000, color: 'error', btnColor: 'buttonText', text: this.$i18n.t('testStore.error') })
            });
        },
        // add/remove test from wishlist
        wishlistItem(test) {
            if(Object.keys(this.$store.getters.userData).length === 0) {
                this.$store.dispatch('getSnackbar', {status: true, timeout: 5000, color: 'error', btnColor: 'buttonText', text: this.$i18n.t('testStore.login') })
                return
            }
            var wish_id
            var remove
            if(this.Wishlist.length > 0 && this.Wishlist != 'null') {
                if(this.Wishlist.find(element => element.test_id == test.test_id)) {
                    remove = true
                    wish_id = this.Wishlist.find(element => element.test_id == test.test_id).wish_id
                } else {
                    remove = false
                    wish_id = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) );
                }
                } else {
                remove = false
                wish_id = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) );
            }
            this.$http.post('index.php',    {   task:       'wishlist_test',
                                                userObject: this.UserObject,
                                                testObject: test,
                                                wishID:     wish_id,
                                                remove:     remove,
                                            })
                    .then(response => {
                        if(response.body == 'success') {
                            this.$store.dispatch('getWishlist', this.UserObject)
                            if(remove) {
                                this.$store.dispatch('getSnackbar', {status: true, timeout: 4000, color: 'success', btnColor: 'buttonText', text: this.$i18n.t('testStore.remove-wish') })
                            } else {
                                this.$store.dispatch('getSnackbar', {status: true, timeout: 4000, color: 'success', btnColor: 'buttonText', text: this.$i18n.t('testStore.success-wish') })
                            }
                        } else {
                            this.$store.dispatch('getSnackbar', {status: true, timeout: 4000, color: 'error', btnColor: 'buttonText', text: this.$i18n.t('UserSettings.sessID-outdated') })
                        }
                    });
        },
        // add Test to shopping Cart
        toCart: function(test) {
            this.$store.dispatch('getCartItems', test)
            this.$store.dispatch('getSnackbar', {status: true, timeout: 3000, color: 'primary', btnColor: 'buttonText', text: this.$i18n.t('testStore.added') })
            this.$root.$emit('buttonEvent', true)
        },
        // open the buy window
        openBuy() {
            var items = []
            this.currentStoreItem.buy_number = 1
            items[0] = this.currentStoreItem
            console.log(items)
            this.$store.dispatch('getBuyItems', items)
            // this.$store.dispatch('getCurrentMenu', 'testStoreBuy')
            this.$router.push('/dashboard/store/buy/article-overview')
        },
        // paypal function
        setLoaded() {
            this.loaded = true;
            window.paypal.Buttons({
                style: {
                    color:  'gold',
                    shape:  'pill',
                    label:  'pay',
                    layout: 'horizontal',
                    tagline: 'false',
                    height: 37
                },
                createOrder: (data, actions) => {
                    return actions.order.create({
                        purchase_units: [
                            {
                                description: this.currentStoreItem.test_info,
                                amount: {
                                    currency_code: 'EUR',
                                    value: this.currentStoreItem.test_price,
                                }
                            }
                        ],
                        "application_context" :  { 
                            "shipping_preference":"NO_SHIPPING"
                        }
                    });
                },
                onApprove: async(data, actions) => {
                    const order = await actions.order.capture();
                    var transaction_id = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) );
                    // For todays date;
                    Date.prototype.today = function () { 
                        return this.getFullYear() +"-"+(((this.getMonth()+1) < 10)?"0":"") + (this.getMonth()+1) +"-"+ ((this.getDate() < 10)?"0":"") + this.getDate();
                    }
                    var currentDate = new Date()
                    // console.log(currentDate.getMonth()+1)
                    if(currentDate.getMonth()+1 == 10) {
                        Date.prototype.later = function () { 
                            return this.getFullYear()+1 +"-"+ '01' +"-"+ ((this.getDate() < 10)?"0":"") + this.getDate();
                        }
                    } else if (currentDate.getMonth()+1 == 11) {
                        Date.prototype.later = function () { 
                            return this.getFullYear()+1 +"-"+ '02' +"-"+ ((this.getDate() < 10)?"0":"") + this.getDate();
                        }
                    } else if (currentDate.getMonth()+1 == 12) {
                        Date.prototype.later = function () { 
                            return this.getFullYear()+1 +"-"+ '03' +"-"+ ((this.getDate() < 10)?"0":"") + this.getDate();
                        }
                    } else {
                    // when a year is over add to the year!!!
                        Date.prototype.later = function () { 
                            return this.getFullYear() +"-"+(((this.getMonth()+4) < 10)?"0":"") + (this.getMonth()+4) +"-"+ ((this.getDate() < 10)?"0":"") + this.getDate();
                        }
                    }
                    // For the time now
                    Date.prototype.timeNow = function () {
                        return ((this.getHours() < 10)?"0":"") + this.getHours() +":"+ ((this.getMinutes() < 10)?"0":"") + this.getMinutes() +":"+ ((this.getSeconds() < 10)?"0":"") + this.getSeconds();
                    }
                    // when and by who it was edited
                    var validFrom = new Date().today() + " " + new Date().timeNow()
                    var validTo = new Date().later() + " " + new Date().timeNow()
                    // console.log(validTo)

                    this.orderMsg = order

                    this.$http.post('index.php',    {   task:           'unlock_test',
                                                        userObject:     this.UserObject,
                                                        testObject:     this.currentStoreItem,
                                                        transactionID:  transaction_id,
                                                        valid_from:     validFrom,
                                                        valid_to:       validTo
                                                    })
                                .then(response => {
                                    this.resp = response.body
                                    this.$store.dispatch('getSnackbar', {status: true, timeout: 5000, color: '#1E1E1E', btnColor: 'error', text: this.$i18n.t('testStore.success-unlock') })
                                    this.$store.dispatch('getMyTests', this.UserObject)
                                });
                },
                onError: err => {
                    this.$store.dispatch('getSnackbar', {status: true, timeout: 5000, color: '#1E1E1E', btnColor: 'error', text: this.$i18n.t('testStore.error') })
                    this.errorMsg = err
                }
            })
            .render(this.$refs.paypal);
        },

        // // when pay with paypal was cancelled
        // payCancelled(response) {
        //     console.log(response)
        // },

        // // when pay with paypal was authorized
        // payAuthorized(response) {
        //     console.log(response)
        // },

        // // when pay with paypal was successfull
        // payCompleted(response) {
        //     console.log(response)
        // },

        backToStore() {
            this.$store.dispatch('getCurrentMenu', 'testStore')
            this.$router.push({path: '/dashboard/store'})
        },
        // get the width of the window
        getWindowWidth() {
            this.windowWidth = document.documentElement.clientWidth;
        },

        // get the height of the window
        getWindowHeight() {
            this.windowHeight = document.documentElement.clientHeight;
        },
    },
    
  }
</script>

<style scoped>
  .scroll {
    overflow-y: auto
  }

</style>