<template>
    <v-container class="pa-0" :class="scrollbarTheme">
        <v-list color="navigation" class="pa-0">
            <v-card :height="windowHeight -65 + 'px'" elevation="0" color="rgba(0,0,0,0)" :class="scrollbarTheme" style="overflow-y: auto" tile>
                <!-- home submenu -->
                <!-- Start Screen -->
                <v-list-item v-if="selected == 'Home' && isEditing == false" @click="emitMenu('Home')" class="listHighlight" to="/?page=welcome">
                    <v-list-item-icon>
                        <v-icon class="iconHighlight--text">mdi-home</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="textHighlight--text font-weight-medium">{{ $t('AppNavigationMenu.home') }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-else @click="emitMenu('Home')" to="/?page=welcome">
                    <v-list-item-icon>
                        <v-icon class="icon--text">mdi-home</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="navText--text">{{ $t('AppNavigationMenu.home') }}</v-list-item-title>
                </v-list-item>
                <!-- Dashboard -->
                <v-list-item v-if="selected == 'Dashboard' && isEditing == false" @click="emitMenu('Dashboard')" class="listHighlight" to="/dashboard" exact>
                    <v-list-item-icon>
                        <v-icon class="iconHighlight--text">mdi-view-dashboard-variant</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="textHighlight--text font-weight-medium">{{ $t('AppNavigationMenu.dashboard') }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-else @click="emitMenu('Dashboard')" to="/dashboard" exact>
                    <v-list-item-icon>
                        <v-icon class="icon--text">mdi-view-dashboard-variant</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="navText--text">{{ $t('AppNavigationMenu.dashboard') }}</v-list-item-title>
                </v-list-item>
                <!-- Test Store -->
                <v-list-item v-if="selected == 'testStore' && isEditing == false" @click="emitMenu('testStore')" class="listHighlight" to="/dashboard/store">
                    <v-list-item-icon>
                        <v-icon class="iconHighlight--text">mdi-cart</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="textHighlight--text font-weight-medium">{{ $t('AppNavigationMenu.test-store') }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-else @click="emitMenu('testStore')" to="/dashboard/store">
                    <v-list-item-icon>
                        <v-icon class="icon--text">mdi-cart</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="navText--text">{{ $t('AppNavigationMenu.test-store') }}</v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>
                <!-- My Tests Group -->
                <v-list-group prepend-icon="mdi-book-open-page-variant" color="icon" no-action value="true">
                    <template v-slot:activator>
                        <v-list-item-title class="navText--text">{{ $t('AppNavigationMenu.my-tests') }}</v-list-item-title>
                        <v-progress-linear :active="loading1" :indeterminate="loading1" absolute bottom color="primary"></v-progress-linear>
                    </template>
                    <!-- My Test -->
                    <v-list-item v-for="test in myTests" :key="test.transaction_id" link class="subNavigation" @click="emitCurrentTest(test)" :to="'/dashboard/exercises/' + test.test_name.replace(/\s/g, '')">  
                        <v-list-item-title v-text="test.test_name"></v-list-item-title>
                        <!-- <v-list-item-icon>
                            <v-icon>{{ admin[1] }}</v-icon>
                        </v-list-item-icon> -->
                    </v-list-item>
                    <!-- login prompt -->
                    <v-list-item v-if="myTests.length == 0 && SuccessfullLogin == false" link class="subNavigation" disabled>  
                        <v-list-item-title>{{ $t('AppNavigationMenu.login-to-see') }}</v-list-item-title>
                    </v-list-item>
                    <!-- use store prompt -->
                    <v-list-item v-if="myTests.length == 0 && SuccessfullLogin == true" link class="subNavigation" disabled>  
                        <v-list-item-title>{{ $t('AppNavigationMenu.visit-store') }}</v-list-item-title>
                    </v-list-item>
                </v-list-group>

                <v-divider v-if="SuccessfullLogin == true"></v-divider>
                <!-- Exams -->
                <v-list-group prepend-icon="mdi-school" color="icon" no-action v-if="SuccessfullLogin == true">
                    <template v-slot:activator>
                        <v-list-item-title class="navText--text">{{ $t('AppNavigationMenu.exams') }}</v-list-item-title>
                    </template>
                    <!-- Create -->
                    <v-list-item link class="subNavigation" @click="emitMenu('createExams')" v-if="UserObject.usr_role == 'admin' && selected == 'createExams'" to="/dashboard/exams/create">  
                        <v-list-item-title class="textHighlight--text font-weight-medium">{{ $t('AppNavigationMenu.create') }}</v-list-item-title>
                        <v-list-item-icon>
                            <v-icon class="iconHighlight--text">mdi-plus</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-list-item link class="subNavigation" @click="emitMenu('createExams')" v-if="UserObject.usr_role == 'admin' && selected != 'createExams'" to="/dashboard/exams/create">  
                        <v-list-item-title class="navText--text">{{ $t('AppNavigationMenu.create') }}</v-list-item-title>
                        <v-list-item-icon>
                            <v-icon class="icon--text">mdi-plus</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <!-- Manage -->
                    <v-list-item link class="subNavigation" @click="emitMenu('manageExams')" v-if="UserObject.usr_role == 'admin' && selected == 'manageExams'" to="/dashboard/exams/manage">  
                        <v-list-item-title class="textHighlight--text font-weight-medium">{{ $t('AppNavigationMenu.manage') }}</v-list-item-title>
                        <v-list-item-icon>
                            <v-icon class="iconHighlight--text">mdi-pencil</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-list-item link class="subNavigation" @click="emitMenu('manageExams')" v-if="UserObject.usr_role == 'admin' && selected != 'manageExams'" to="/dashboard/exams/manage">  
                        <v-list-item-title class="navText--text">{{ $t('AppNavigationMenu.manage') }}</v-list-item-title>
                        <v-list-item-icon>
                            <v-icon class="icon--text">mdi-pencil</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <!-- participate -->
                    <v-list-item link class="subNavigation" @click="emitMenu('participateExams')" v-if="selected == 'participateExams'" to="/dashboard/exams/participate">  
                        <v-list-item-title class="textHighlight--text font-weight-medium">{{ $t('AppNavigationMenu.participate') }}</v-list-item-title>
                        <v-list-item-icon>
                            <v-icon class="iconHighlight--text">mdi-book-education</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-list-item link class="subNavigation" @click="emitMenu('participateExams')" v-if="selected != 'participateExams'" to="/dashboard/exams/participate">  
                        <v-list-item-title class="navText--text">{{ $t('AppNavigationMenu.participate') }}</v-list-item-title>
                        <v-list-item-icon>
                            <v-icon class="icon--text">mdi-book-education</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                </v-list-group>
                
                <v-divider v-if="SuccessfullLogin == true"></v-divider>
                <!-- user submenu -->
                <v-subheader v-if="SuccessfullLogin == true">{{ $t('AppNavigationMenu.user') }}</v-subheader>

                <v-list-item v-if="selected == 'Statistics' && isEditing == false && SuccessfullLogin == true" @click="emitMenu('Statistics')" class="listHighlight" to="/dashboard/statistics">
                    <v-list-item-icon>
                        <v-icon class="iconHighlight--text">mdi-chart-areaspline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="textHighlight--text font-weight-medium">{{ $t('AppNavigationMenu.statistics') }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="SuccessfullLogin == true && selected != 'Statistics' && isEditing == false" @click="emitMenu('Statistics')" to="/dashboard/statistics">
                    <v-list-item-icon>
                        <v-icon class="icon--text">mdi-chart-areaspline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="navText--text">{{ $t('AppNavigationMenu.statistics') }}</v-list-item-title>
                </v-list-item>

                <v-list-item v-if="selected == 'Achievements' && isEditing == false && SuccessfullLogin == true" @click="emitMenu('Achievements')" class="listHighlight">
                    <v-list-item-icon>
                        <v-icon class="iconHighlight--text">mdi-star</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="textHighlight--text font-weight-medium">{{ $t('AppNavigationMenu.achievements') }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="SuccessfullLogin == true && selected != 'Achievements' && isEditing == false" @click="emitMenu('Achievements')" disabled>
                    <v-list-item-icon>
                        <v-icon class="icon--text">mdi-star</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="navText--text">{{ $t('AppNavigationMenu.achievements') }}</v-list-item-title>
                </v-list-item>

                <!-- administration submenu -->
                <v-divider v-if="(UserObject.usr_role == 'admin' || UserObject.usr_role == 'editor') && SuccessfullLogin == true"></v-divider>
                <!-- <v-subheader>{{ $t('AppNavigationMenu.admin') }}</v-subheader> -->
                <!-- Edit Tests Group -->
                <v-list-group v-if="(UserObject.usr_role == 'admin' || UserObject.usr_role == 'editor') && SuccessfullLogin == true" prepend-icon="mdi-pencil" color="icon" no-action>
                    <template v-slot:activator>
                        <v-list-item-title class="navText--text">{{ $t('AppNavigationMenu.edit-tests') }}</v-list-item-title>
                        <v-progress-linear :active="loading2" :indeterminate="loading2" absolute bottom color="primary"></v-progress-linear>
                    </template>
                    <!-- My Test -->
                    <v-list-item v-for="(test, i) in myEdits" :key="i" link class="subNavigation" @click="emitCurrentEdit(test)">  
                        <v-list-item-title v-text="test.test_name"></v-list-item-title>
                        <!-- <v-list-item-icon>
                            <v-icon>{{ admin[1] }}</v-icon>
                        </v-list-item-icon> -->
                    </v-list-item>
                    <!-- if empty -->
                    <v-list-item v-if="myEdits.length == 0 && SuccessfullLogin == true" link class="subNavigation" disabled>  
                        <v-list-item-title>Empty</v-list-item-title>
                    </v-list-item>
                </v-list-group>
                <v-list-item v-if="UserObject.usr_role == 'admin' && SuccessfullLogin == true">
                    <AppNavigationNewTest :pill="false"></AppNavigationNewTest>
                </v-list-item>
            </v-card>
        </v-list>
    </v-container>
</template>

<script>
    import AppNavigationNewTest  from './AppNavigationNewTest'

    export default {
        name: 'AppNavigationMenu',
        components: {
            AppNavigationNewTest
        },
        data() {
            return {
                selected: 'Home',
                loading1: false,
                loading2: false,

                components: [
                    {route: '/', component: 'Home'},
                    {route: '/dashboard', component: 'dashboard'},
                    {route: '/dashboard/store', component: 'testStore'},
                    {route: '/dashboard/exams/create', component: 'createExams'},
                    {route: '/dashboard/exams/manage', component: 'manageExams'},
                    {route: '/dashboard/exams/participate', component: 'participateExams'},
                    {route: '/dashboard/statistics', component: 'Statistics'}
                ],

                windowWidth: 0, // width of the window
                windowHeight: 0, // height of the window
            }
        },

        watch: {
            '$route'(to) {
                // console.log(to.path)
                if(to.path != '/' && !to.path.includes('/dashboard/store') && to.path != '/dashboard' && this.SuccessfullLogin == false) {
                    this.$router.push('/')
                    // code to open login
                    // redirict to entered route after login
                } else {
                    this.components.forEach(element => {
                        if(element.route == to.path) {
                            this.selected = element.component
                            this.emitMenu(element.component)
                        }
                    });
                }
                // make reloading possible again
                if(!to.path.includes('/test/Question')) {
                    this.$store.dispatch('getIsEditing', false)
                }
            },

            currentMenu() {
                if(this.currentMenu != 'Home' && this.currentMenu != 'Exercises' && this.currentMenu != 'testStore' && this.currentMenu != 'Statistics' && this.currentMenu != 'Achievements' && this.currentMenu != 'editDB' && this.currentMenu != 'createExams' && this.currentMenu != 'manageExams' && this.currentMenu != 'participateExams' && this.currentMenu != 'dashboard') {
                    this.selected = ''
                }
                if(this.currentMenu == 'testStore') {
                    this.selected = 'testStore'
                }
            },

            // get confirmation from store if user is logged in
            SuccessfullLogin() {
                if(this.SuccessfullLogin == true) {
                    this.loading1 = true
                    this.loading2 = true
                    this.$store.dispatch('getMyTests', this.UserObject)
                    this.$store.dispatch('getMyEdits', this.UserObject)
                }
            },

            myTests() {
                this.loading1 = false
            },

            myEdits() {
                this.loading2 = false
            },

        },

        mounted() {
            this.components.forEach(element => {
                if(element.route == this.$route.fullPath) {
                    this.selected = element.component
                    this.emitMenu(element.component)
                }
            });

            // event listener for window size
            this.$nextTick(function() {
                window.addEventListener('resize', this.getWindowWidth);
                window.addEventListener('resize', this.getWindowHeight);

                // Init
                this.getWindowWidth()
                this.getWindowHeight()
            })

        },

        beforeDestroy() {

            // remove eventlisteners when the component is destroyed
            window.removeEventListener('resize', this.getWindowWidth);
            window.removeEventListener('resize', this.getWindowHeight);
        },

        methods: {

            emitMenu: function(menu) {
                this.selected = menu
                this.$store.dispatch('getCurrentMenu', menu)
            },

            emitCurrentTest: function(testData) {
                // testData['iterator'] = i
                // testData['numberOfTests'] = this.myTests.length
                // console.log(testData)
                this.selected = testData.test_name
                this.$store.dispatch('getCurrentMenu', 'Exercises')
                this.$store.dispatch('getCurrentTest', testData)
            },
            
            emitCurrentEdit: function(testData) {
                this.selected = testData.test_name
                this.$store.dispatch('getCurrentMenu', 'editDB')
                this.$store.dispatch('getCurrentTest', testData)
                if('/dashboard/edit/' + testData.test_name.replace(/\s/g, '') != this.$route.fullPath)
                this.$router.push('/dashboard/edit/' + testData.test_name.replace(/\s/g, ''))
            },

            // get the width of the window
            getWindowWidth() {
                this.windowWidth = document.documentElement.clientWidth;
            },

            // get the height of the window
            getWindowHeight() {
                this.windowHeight = document.documentElement.clientHeight;
            },
            
        },

        computed: {
            
            scrollbarTheme() {
                return this.$vuetify.theme.dark ? 'dark' : 'light';
            },

            // get the current menu from the store
            currentMenu() {
                // console.log(this.$store.getters.currentMenu)
                return this.$store.getters.currentMenu
            },

            // gets confirmation from the store if the login was successfull
            SuccessfullLogin() {
                // console.log('User logged in?', this.$store.getters.successfullLogin)
                return this.$store.getters.successfullLogin
            },
            
            // sessionID from the store
            SessionID() {
                // console.log('SessionIDComponent', this.$store.getters.sessionID)
                return this.$store.getters.sessionID
            },

            // User Object from the store
            UserObject() {
                return this.$store.getters.userData
            },

            // gets own Tests from the store
            myTests() {
                let tests = this.$store.getters.MyTests
                let nameObject = {}
                tests.forEach(element => {
                    if(element.test_name in nameObject) {
                        nameObject[element.test_name] += 1
                    } else {
                        nameObject[element.test_name] = 0
                    }
                    element['iterator'] = nameObject[element.test_name]
                });
                return tests
            },
            // gets own editable Tests from Store
            myEdits() {
                return this.$store.getters.MyEdits
            },

            // get info from store if user is currently in test
            isEditing() {
                return this.$store.getters.isEditing
            },
        },
    }

</script>
<style scoped>
  .scroll {
    overflow-y: auto
  }
</style>