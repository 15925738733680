<template>
  <v-card tile :min-height="windowHeight - 76" elevation="0" color="rgba(0,0,0,0)" class="py-3 px-2">
    <!-- table component -->
    <v-data-table multi-sort fixed-header :headers="headers" :items="questions" class="elevation-1 mt-2" :height="windowHeight - 232" :search="searchTerm" dense :loading="loading" :locale="$i18n.locale">
      <!-- checkboxes for states -->
      <template v-slot:item.verified="{ item }">
        <v-checkbox v-model="item.verified" disabled true-value="1" false-value="0"></v-checkbox>
      </template>
      <template v-slot:item.answer_1_state="{ item }">
        <v-checkbox v-model="item.answer_1_state" disabled true-value="1" false-value="0"></v-checkbox>
      </template>
      <template v-slot:item.answer_2_state="{ item }">
        <v-checkbox v-model="item.answer_2_state" disabled true-value="1" false-value="0"></v-checkbox>
      </template>
      <template v-slot:item.answer_3_state="{ item }">
        <v-checkbox v-model="item.answer_3_state" disabled true-value="1" false-value="0"></v-checkbox>
      </template>
      <template v-slot:item.answer_4_state="{ item }">
        <v-checkbox v-model="item.answer_4_state" disabled true-value="1" false-value="0"></v-checkbox>
      </template>
      <template v-slot:item.answer_5_state="{ item }">
        <v-checkbox v-model="item.answer_5_state" disabled true-value="1" false-value="0"></v-checkbox>
      </template>
      <template v-slot:item.answer_6_state="{ item }">
        <v-checkbox v-model="item.answer_6_state" disabled true-value="1" false-value="0"></v-checkbox>
      </template>
      <template v-slot:top>
        <!-- Row on top of the Table with Title and Buttons -->
        <v-card elevation="0">
        <v-toolbar flat>
          <!-- Title -->
          <v-toolbar-title>{{ 'Edit ' + getTestData.test_name }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <!-- searchbar -->
          <v-text-field v-model="searchTerm" append-icon="mdi-magnify" label="Search" outlined dense hide-details single-line></v-text-field>
          <!-- question type select -->
          <v-select @change="changeType" outlined color="primary" :items="questionTypes" v-model="questionType" dense hide-details class="ml-3" label="Question Type" style="width: 50px"></v-select>
          <v-switch class="mx-3 mt-1 mb-1" label="Review Mode" hide-details v-model="switchMode" @change="emitMenu"></v-switch>
          <!-- Dialog (+ Button to open the Dialog) for adding a new Question (or edit an existing one) -->
          <EditDBDialog :triggerEdit="triggerEdit" :editItem="editedItem" :editIndex="editedIndex"></EditDBDialog>
        </v-toolbar>
        </v-card>
      </template>
      <!-- data table edit action (inline button) -->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <!-- data table delete action (inline button) -->
        <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import EditDBDialog from './EditDBDialog'

  export default {
    name: 'EditDBTable',
    components: {
      EditDBDialog,
    },
    data: () => {
      return{
        searchTerm: '', // search variable with the user input
        questionTypes: [ // select which question type is shown in table 
          'Multiple Choice',
          'Sequence',
        ],
        questionType: 'Multiple Choice', // which question type is currently shown
        switchMode: false, // wether the review mode is active or not
        headers: [], // all headers for the table
        questions: [], // all data for the table
        loading: true, // loading state of data table

        editedIndex: -1, // index of the edited Question (also used to handle the title of the dialog)
        editedItem: {}, // all data from the currently edited item in the table (or from the new item)
        triggerEdit: false, // triggers edit dialog in child component

        windowWidth: 0, // width of the window
        windowHeight: 0, // height of the window
      }
    },

    watch: {
      // change route on view mode switch
      switchMode() {
        if(this.switchMode == true) {
          this.$router.push('/edit-test/list-view')
        }
      },
      // initialize component when all questions are imported from the store
      getAllQuestions() {
        this.initialize()
      },
      // reset trigger 1 second after it launched
      triggerEdit() {
        setTimeout(() => this.triggerEdit = false, 1000);
      },
    },

    mounted() {
      // event listener for window size
      this.$nextTick(function() {
        window.addEventListener('resize', this.getWindowWidth);
        window.addEventListener('resize', this.getWindowHeight);

        // Init
        this.getWindowWidth()
        this.getWindowHeight()
      })
      if(this.getAllQuestions.length > 0) {
        this.initialize()
      }
    },

    beforeDestroy() {
      // remove eventlisteners when the component is destroyed
      window.removeEventListener('resize', this.getWindowWidth);
      window.removeEventListener('resize', this.getWindowHeight);
    },

    computed: {
      // get questions array from store
      getAllQuestions() {
          // console.log('QuestionData:', this.$store.getters.allQuestions)
          return this.$store.getters.questions
      },
      // get the test data from the store
      getTestData() {
        // console.log(this.$store.getters.TestData)
        return this.$store.getters.TestData
      },
      // custom scrollbar
      scrollbarTheme() {
          return this.$vuetify.theme.dark ? 'dark' : 'light';
      },
    },

    methods: {
      // prepares data for the selected type
      setType: function(type) {
        if(type == 'mul') {
          // create the header array
          var header = Object.keys(this.getAllQuestions[0])
          let headerObject = new Array(24).fill().map((_, i) => ({ id: i }));
          headerObject.forEach( (element,i) => {
            if(i == 0) {
              element['sortable'] = true
              element['align'] ='start'
            } else if(i == 1 || i == 2) {
              element['text'] = header[i+1]
              element['value'] = header[i+1]
            } else if(i < 20) {
              element['text'] = header[i+2]
              element['value'] = header[i+2]
            } else {
              element['text'] = header[headerObject.length-16 +i]
              element['value'] = header[headerObject.length-16 +i]
            }
          });
          headerObject.unshift({ text: 'Actions', value: 'actions', sortable: false },)
          this.headers = headerObject
          // create the questions array
          this.questions = this.getAllQuestions.filter( element => {
              return element.typ == 'mul'
          })
        } else {
          // create the header array
          let header = Object.keys(this.getAllQuestions[0])
          let headerObject = new Array(17).fill().map((_, i) => ({ id: i }));
          headerObject.forEach( (element,i) => {
            if(i == 0) {
              element['sortable'] = true
              element['align'] ='start'
            } else if(i == 1 || i == 2) {
              element['text'] = header[i+1]
              element['value'] = header[i+1]
            } else if(i < 7) {
              element['text'] = header[i+2]
              element['value'] = header[i+2]
            } else {
              element['text'] = header[i+15]
              element['value'] = header[i+15]
            } 
          });
          headerObject.unshift({ text: 'Actions', value: 'actions', sortable: false },)
          this.headers = headerObject
          // create the questions array
          this.questions = this.getAllQuestions.filter( element => {
            return element.typ == 'sor'
          })
        }
      },
      // change displayed question type
      changeType() {
        if(this.questionType == 'Multiple Choice') {
          this.setType('mul')
        } else {
          this.setType('sor')
        }
      },
      // change view to reviewer mode
      emitMenu: function() {
        this.$store.dispatch('getCurrentMenu', 'EditDBReview')
      },
      // edit the question
      editItem (item) {
          this.editedIndex = this.questions.indexOf(item)
          this.editedItem = Object.assign({}, item)
          this.triggerEdit = true
      },
      // delete selected question from table
      deleteItem (item) {
        // console.log('delete this item:', item)
        const index = this.questions.indexOf(item)
        var confirmed = confirm('Are you sure you want to delete this item?') && this.questions.splice(index, 1)
        if(confirmed != false) {
          this.$http.post('index.php',  { task:       'delete_question',
                                          questionID: item.question_id,
                                          testName:   this.getTestData.test_name_db
                                        })
              .then(response => {
                  // console.log('delete response:', response.body)
                  this.$store.dispatch('getSnackbar', {status: true, timeout: 3000, color: '', btnColor: 'error', text: 'deleted item: ' + response.body.delete_item })
              });
        }
      },
      // initialize the component/table
      initialize() {
        if(this.questionType == 'Multiple Choice') {
          this.setType('mul')
        } else {
          this.setType('sor')
        }
        // dispatch action to later get subtopics from store
        this.$store.dispatch('getHF', this.getTestData.test_subtopic_id)
        this.loading = false
      },
      // get the width of the window
      getWindowWidth() {
        this.windowWidth = document.documentElement.clientWidth;
      },
      // get the height of the window
      getWindowHeight() {
        this.windowHeight = document.documentElement.clientHeight;
      },
    },
  }
</script>

<style>
  .v-data-table-header th {
    white-space: nowrap;
  }
</style>