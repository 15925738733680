import Vue from 'vue';
import VueResource from 'vue-resource'

Vue.use(VueResource, {})

const state = {
    CurrentMenu: 'Home',
    Leave: false,
    Assists: true,
    Mode: '',
    ExamKey: '',
    IsEditing: false,
    Snackbar: {},
};

const getters = {
    currentMenu: state => {
        return state.CurrentMenu
    },
    leave: state => {
        return state.Leave
    },
    assists: state => {
        return state.Assists
    },
    mode: state => {
        return state.Mode
    },
    examKey: state => {
        return state.ExamKey
    },
    isEditing: state => {
        return state.IsEditing
    },
    snackbar: state => {
        return state.Snackbar
    },
};

const actions = {
    getCurrentMenu({commit}, menu) {
        commit('setCurrentMenu', menu);
    },
    leaveTest({commit}, v) {
        commit('setLeave', v)
    },
    getAssists({commit}, assist) {
        commit('setAssists', assist);
    },
    getMode({commit}, mode) {
        commit('setMode', mode)
    },
    getExamKey({commit}, key) {
        commit('setExamKey', key)
    },
    getIsEditing({commit}, v) {
        commit('setIsEditing', v)
    },
    getSnackbar({commit}, snackbarObj) {
        commit('setSnackbar', snackbarObj)
    },
};

const mutations = {
    setCurrentMenu: (state, menu) => {
        // console.log(menu)
        state.CurrentMenu = menu
    },
    setLeave: (state, v) => {
        state.Leave = v
    },
    setAssists: (state, assist) => {
        state.Assists = assist
    },
    setMode: (state, mode) => {
        state.Mode = mode
    },
    setExamKey: (state, key) => {
        state.ExamKey = key
    },
    setIsEditing: (state, v) => {
        state.IsEditing = v
    },
    setSnackbar: (state, snackbarObj) => {
        state.Snackbar = snackbarObj
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};